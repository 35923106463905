import {
  createContext, FC, ReactNode, useContext, useEffect, useMemo, useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { LocationContext } from 'context/LocationContext';

import { getProductsRoute } from 'hooks/useRouting';
import { getCategories } from 'services/Product';

import { ALL_CATEGORIES } from 'constants/general';
import { Category } from 'types/category.interface';
import { getOnboardingInfo } from 'utils/storageUtils';

const CategoryContext = createContext({
  isLoading: false,
  categories: [] as Category[],
  selectedCategories: [] as string[],
  selectors: [] as Category[][],
});

const CategoryDispatchContext = createContext({
  removeCategory: (categoryCode: string) => {},// eslint-disable-line
  onCategoryChanges: (value: string, index: number) => {},// eslint-disable-line
  getSelectedValue: (index: number, arr: Category[]) => '' as string,// eslint-disable-line
});

interface CategoryProviderProps {
  children: ReactNode;
}

const CategoryProvider:FC<CategoryProviderProps> = ({ children }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [rawCategories, setRawCategories] = useState<Category[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { onboardingInfo } = useContext(LocationContext);
  const { storeId, useType } = onboardingInfo;
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const categoryCodes = searchParams?.get('category');
  const selectors = useMemo(() => {
    const temp = [categories];

    if (!selectedCategories?.length || selectedCategories[0] === ALL_CATEGORIES) {
      return temp;
    }

    selectedCategories.forEach((val, index) => {
      const root = temp[index]?.find(({ code }) => code === val);

      if (root?.children) {
        temp.push(root.children);
      }
    });

    return temp;
  }, [selectedCategories, categories]);

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (rawCategories?.length) {
      setCategories(rawCategories);
    }
  }, [storeId, useType]);

  useEffect(() => {
    if (categoryCodes) {
      setSelectedCategories(categoryCodes.split(','));
    } else if (selectedCategories?.length && !categoryCodes) {
      setSelectedCategories([]);
    }
  }, [categoryCodes]);

  const fetchCategories = async () => {
    try {
      setIsLoading(true);
      const { useType } = getOnboardingInfo() || {};
      const params = new URLSearchParams();

      if (useType) {
        params.append('tag', useType);
        params.append('tag', 'is_non_cannabis');
      }

      const { data } = await getCategories(params);
      const categories = data?.children || [];

      setCategories(categories);
      setRawCategories(categories);
    } catch (e) {
      setCategories([]);
    } finally {
      setIsLoading(false);
    }
  };

  const removeCategory = (categoryCode: string) => {
    setCategories((prevState) => {
      const availableCategories = prevState.filter(({ code }) => code !== categoryCode);
      return [...availableCategories];
    });
  };

  const handleCategoryChanges = (value: string, index: number) => {
    let newCategories = [...selectedCategories];
    newCategories[index] = value as string;
    newCategories = newCategories.slice(0, value === ALL_CATEGORIES && index ? index : index + 1);
    const route = getProductsRoute(newCategories?.length ? `category=${newCategories.join(',')}` : '');

    return navigate(route);
  };

  const getSelectedValue = (index: number, currCategories: Category[]): string => {
    if (selectedCategories[index]) return selectedCategories[index];
    if (currCategories?.length === 1) return currCategories[0].code;
    return ALL_CATEGORIES;
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <CategoryContext.Provider value={{
      categories,
      isLoading,
      selectedCategories,
      selectors,
    }}
    >
      {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
      <CategoryDispatchContext.Provider value={{
        removeCategory,
        onCategoryChanges: handleCategoryChanges,
        getSelectedValue,
      }}
      >
        {children}
      </CategoryDispatchContext.Provider>
    </CategoryContext.Provider>
  );
};

export {
  CategoryProvider,
  CategoryContext,
  CategoryDispatchContext,
};
