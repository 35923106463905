import { Typography } from '@mui/material';
import React, { FC } from 'react';

// import { StyledTypography } from 'components/OrderPaymentInfo/index.styled';

import CartTotal from 'components/CartTotal';

import { PAYMENT_METHOD_LABELS } from 'constants/labels';
import { CartPriceDetails } from 'types/cart.interface';
import { OrderDetails } from 'types/order.interface';
import { calculateTotalQuantity } from 'utils/cartUtils';

interface OrderPaymentInfoProps {
  order: OrderDetails;
  cartDetails: CartPriceDetails;
}

const OrderPaymentInfo: FC<OrderPaymentInfoProps> = ({ order, cartDetails }) => ( // eslint-disable-line
  <>
    <Typography variant="body2" fontWeight={600} mb={2}>
      Payment Method:
    </Typography>

    <Typography variant="body2" fontWeight={600} mb={2} sx={{ opacity: 0.7 }}>
      {order?.paymentInfo?.paymentMethod ? PAYMENT_METHOD_LABELS[order?.paymentInfo?.paymentMethod] : ''}
    </Typography>

    {/* <StyledTypography variant="body2" fontWeight={600} mb={2}> */}
    {/*  PAYMENT ACCEPTED */}
    {/* </StyledTypography> */}

    <CartTotal
      cartPriceDetails={cartDetails}
      fontWeight={600}
      variant="body2"
      sx={{ minWidth: '150px', opacity: 0.7 }}
      productsCount={calculateTotalQuantity(order?.productsInfo?.products)}
    />

    {/* <StyledLink to="asd"> */}
    {/* <Typography variant="body2" fontWeight={600} sx={{ opacity: 0.7 }} mb={3}> */}
    {/*  INVOICE #12345 */}
    {/* </Typography> */}
    {/* </StyledLink> */}
  </>
);

export default OrderPaymentInfo;
