import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import * as React from 'react';

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  borderRadius: '12px',
  padding: theme.spacing(1.5, 2.5),

  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<KeyboardArrowLeftIcon />}
    {...props}
  />
))(({ theme }) => ({
  minHeight: 'initial',
  padding: theme.spacing(1, 0),
  '& .MuiAccordionSummary-content': {
    margin: 0,
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(-90deg)',
  },
  '& .MuiSvgIcon-root': {
    fill: theme.palette.general.lightGrey12,
  },
}));
