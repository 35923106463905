import MuiPaper from '@mui/material/Paper';
import { alpha, styled } from '@mui/material/styles';

export const Paper = styled(MuiPaper)(({ theme }) => ({
  padding: theme.spacing(4.5, 5),
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: '12px !important',

  '&:before': {
    display: 'none',
  },

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3),
  },
}));

export const EditWrapper = styled('div')<{ isDisabled: boolean }>(({ theme, isDisabled }) => ({
  backgroundColor: alpha(theme.palette.common.black, 0.05),
  borderRadius: '6px',
  padding: theme.spacing(1.5),
  display: 'flex',
  opacity: isDisabled ? 0.4 : 1,
  cursor: isDisabled ? 'not-allowed' : 'pointer',
}));
