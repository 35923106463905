import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FC, useContext } from 'react';

import CategoriesMobile from 'containers/Categories/CategoriesMobile';
import MainCategories from 'containers/Categories/MainCategories';
import { CategoryContext, CategoryDispatchContext } from 'context/CategoryContext';

const Categories:FC = () => {
  const theme = useTheme();
  const { categories, selectedCategories } = useContext(CategoryContext);
  const { onCategoryChanges } = useContext(CategoryDispatchContext);

  const matchesScreenSizeMd = useMediaQuery(theme.breakpoints.down('md'));

  if (matchesScreenSizeMd) {
    return (
      <CategoriesMobile
        categories={categories}
        selectedCategories={selectedCategories}
        onCategoryChanges={onCategoryChanges}
      />
    );
  }

  return (
    <MainCategories />
  );
};

export default Categories;
