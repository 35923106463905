import { DialogActions, DialogContent } from '@mui/material';
import { get } from 'lodash';
import { ChangeEvent, FC, useState } from 'react';

import CustomButton from 'components/CustomButton';
import DialogTitle from 'containers/Onboarding/DialogTitle';
import { DatePickerField, InputField, Wrapper } from 'containers/Onboarding/MedicalInfo/index.styled';

import { MEDICAL_ID, MEDICAL_ID_EXP } from 'constants/fields';
import { OnboardingInfo } from 'types/onboarding.interface';
import { handleApiErrors } from 'utils/errorUtils';
import { medicalSchema } from 'validation/checkoutFormSchema';

interface MedicalInfoProps {
  setup: OnboardingInfo;
  onChange: (data: Partial<OnboardingInfo>) => void;
  onBack: () => void;
  onSubmit: () => void;
}

const MedicalInfo:FC<MedicalInfoProps> = ({
  setup,
  onChange,
  onBack,
  onSubmit,
}) => {
  const isDisabled = !setup?.medical?.medicalId || !setup?.medical?.medicalIdExp;
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<Record<string, string>>({});

  const handleMedicalIdExpiration = (value: any) => {
    onChange({
      ...setup,
      medical: {
        ...setup.medical,
        medicalIdExp: value,
      },
    });
  };

  const handleInputChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...setup,
      medical: {
        ...setup.medical,
        medicalId: value,
      },
    });
    setFieldErrors({ ...fieldErrors, [name]: '' });
  };

  const handleSubmit = async () => {
    try {
      await medicalSchema.validate(setup, { abortEarly: false });
      setIsSubmitDisabled(true);
      onSubmit();
    } catch (err) {
      handleApiErrors(err, setFieldErrors);
    }
  };

  return (
    <>
      <DialogTitle>
        Please provide
        <br />
        Medical ID and Date of Expiration info
      </DialogTitle>
      <DialogContent sx={{ py: 3, px: 8 }}>
        <Wrapper>
          <div>
            <InputField
              {...MEDICAL_ID}
              label=""
              value={setup?.medical?.medicalId || ''}
              placeholder={MEDICAL_ID.label}
              onChange={handleInputChange}
              error={!!get(fieldErrors, MEDICAL_ID.name)}
              helperText={get(fieldErrors, MEDICAL_ID.name)}
            />
          </div>
          <div>
            <DatePickerField
              {...MEDICAL_ID_EXP}
              label=""
              value={setup?.medical?.medicalIdExp || null}
              onChange={handleMedicalIdExpiration}
              error={!!get(fieldErrors, MEDICAL_ID_EXP.name)}
              helperText={get(fieldErrors, MEDICAL_ID_EXP.name)}
            />
          </div>
        </Wrapper>
      </DialogContent>
      <DialogActions sx={{ pb: 3, px: 8 }}>
        <CustomButton
          id="button-goBack"
          variant="outlined"
          onClick={onBack}
        >
          Back
        </CustomButton>
        <CustomButton
          id="button-continue"
          variant="contained"
          onClick={handleSubmit}
          disabled={isDisabled || isSubmitDisabled}
        >
          Continue
        </CustomButton>
      </DialogActions>
    </>
  );
};

export default MedicalInfo;
