import { Box, Skeleton, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import { SkeletonWrapper } from 'containers/Products/ProductFilters/index.styled';

const ProductFiltersSkeleton = () => {
  const theme = useTheme();
  const matchesScreenSizeMd = useMediaQuery(theme.breakpoints.down('md'));

  return (
    matchesScreenSizeMd
      ? (
        <div>
          <Skeleton animation="wave" variant="rounded" height={60} width="100%" />
        </div>
      ) : (
        <Box display="flex" flexDirection="column" gap={3.5}>
          <SkeletonWrapper>
            <Box display="flex" alignItems="center" justifyContent="space-between" gap={1} mt={1} mb={1.5}>
              <Skeleton animation="wave" variant="rounded" height={34} width={175} />
              <Skeleton animation="wave" variant="rounded" height={20} width={20} />
            </Box>
            <div>
              <Box display="flex" alignItems="center" my={1}>
                <Skeleton animation="wave" variant="rounded" height={20} width={20} sx={{ mr: 1 }} />
                <Skeleton animation="wave" variant="rounded" height={34} width={146} />
              </Box>
              <Box display="flex" alignItems="center" my={1}>
                <Skeleton animation="wave" variant="rounded" height={20} width={20} sx={{ mr: 1 }} />
                <Skeleton animation="wave" variant="rounded" height={34} width={146} />
              </Box>
              <Box display="flex" alignItems="center" my={1}>
                <Skeleton animation="wave" variant="rounded" height={20} width={20} sx={{ mr: 1 }} />
                <Skeleton animation="wave" variant="rounded" height={34} width={146} />
              </Box>
              <Box display="flex" alignItems="center" my={1}>
                <Skeleton animation="wave" variant="rounded" height={20} width={20} sx={{ mr: 1 }} />
                <Skeleton animation="wave" variant="rounded" height={34} width={146} />
              </Box>
            </div>
          </SkeletonWrapper>
          <SkeletonWrapper>
            <Box display="flex" alignItems="center" justifyContent="space-between" mt={1} mb={1.5}>
              <Skeleton animation="wave" variant="rounded" height={34} width={175} />
              <Skeleton animation="wave" variant="rounded" height={20} width={20} />
            </Box>
            <div>
              <Box display="flex" alignItems="center" my={1}>
                <Skeleton animation="wave" variant="rounded" height={20} width={20} sx={{ mr: 1 }} />
                <Skeleton animation="wave" variant="rounded" height={34} width={146} />
              </Box>
              <Box display="flex" alignItems="center" my={1}>
                <Skeleton animation="wave" variant="rounded" height={20} width={20} sx={{ mr: 1 }} />
                <Skeleton animation="wave" variant="rounded" height={34} width={146} />
              </Box>
              <Box display="flex" alignItems="center" my={1}>
                <Skeleton animation="wave" variant="rounded" height={20} width={20} sx={{ mr: 1 }} />
                <Skeleton animation="wave" variant="rounded" height={34} width={146} />
              </Box>
              <Box display="flex" alignItems="center" my={1}>
                <Skeleton animation="wave" variant="rounded" height={20} width={20} sx={{ mr: 1 }} />
                <Skeleton animation="wave" variant="rounded" height={34} width={146} />
              </Box>
            </div>
          </SkeletonWrapper>
          <SkeletonWrapper>
            <Box display="flex" alignItems="center" justifyContent="space-between" mt={1} mb={1.5}>
              <Skeleton animation="wave" variant="rounded" height={34} width={175} />
              <Skeleton animation="wave" variant="rounded" height={20} width={20} />
            </Box>
            <div>
              <Box display="flex" alignItems="center" my={1}>
                <Skeleton animation="wave" variant="rounded" height={20} width={20} sx={{ mr: 1 }} />
                <Skeleton animation="wave" variant="rounded" height={34} width={146} />
              </Box>
              <Box display="flex" alignItems="center" my={1}>
                <Skeleton animation="wave" variant="rounded" height={20} width={20} sx={{ mr: 1 }} />
                <Skeleton animation="wave" variant="rounded" height={34} width={146} />
              </Box>
              <Box display="flex" alignItems="center" my={1}>
                <Skeleton animation="wave" variant="rounded" height={20} width={20} sx={{ mr: 1 }} />
                <Skeleton animation="wave" variant="rounded" height={34} width={146} />
              </Box>
              <Box display="flex" alignItems="center" my={1}>
                <Skeleton animation="wave" variant="rounded" height={20} width={20} sx={{ mr: 1 }} />
                <Skeleton animation="wave" variant="rounded" height={34} width={146} />
              </Box>
            </div>
          </SkeletonWrapper>
        </Box>
      )
  );
};

export default ProductFiltersSkeleton;
