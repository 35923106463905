import { Box } from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import Navbar from 'components/_Navbar';
import KioskNavbar from 'components/_Navbar/index.kiosk';
import ShopContent from 'components/_ShopContent';
import OnboardingFlow from 'containers/Onboarding';
import { LocationContextDispatchContext } from 'context/LocationContext';
import { OnboardingContextProvider } from 'context/OnboardingContext';
import { SystemContext } from 'context/SystemContext';
import { Content, CardMedia } from 'layouts/OnboardingLayout/index.styled';

import { useKiosk } from 'hooks/useKiosk';

import { BackgroundArea } from 'constants/enums';
import { getPublicUrlForImage } from 'utils/publicUrl';

const OnboardingLayout = () => {
  const [showOnboarding, setShowOnboarding] = useState(false);
  const { setSetupCompleted } = useContext(LocationContextDispatchContext);
  const { clientInfo, shopSettings } = useContext(SystemContext);
  const onboardingBackgroundUrl = shopSettings?.backgrounds?.[BackgroundArea.LandingPage]
    ?.urls?.onboardingBackgroundUrl?.url;
  const { kioskMode } = useKiosk();

  useEffect(() => {
    setShowOnboarding(true);
  }, []);

  const handleClose = () => {
    setSetupCompleted(true);
    setShowOnboarding(false);
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      {kioskMode
        ? <KioskNavbar clientInfo={clientInfo} />
        : <Navbar hasShoppingCart clientInfo={clientInfo} />}
      <Content>
        <ShopContent sx={{ height: '100%' }}>
          <CardMedia
            component="img"
            alt="onboarding flow"
            src={onboardingBackgroundUrl || getPublicUrlForImage('images/default-onboarding.jpg')}
          />
        </ShopContent>
      </Content>
      <OnboardingContextProvider closeModal={handleClose} isModalOpen={showOnboarding}>
        {showOnboarding && <OnboardingFlow kioskMode={kioskMode} />}
      </OnboardingContextProvider>
    </Box>
  );
};

export default OnboardingLayout;
