import { ref } from 'firebase/database';

import { runGetApiRequest, runPatchApiRequest, runPostApiRequest } from 'services/Api';

import { db } from 'config/firebase';
import {
  MY_ORDERS_ROUTE,
  OPEN_ORDER_DETAILS_ROUTE,
  ORDER_DETAILS_OPEN_ROUTE,
  ORDER_DETAILS_ROUTE,
  PAY_ORDER_ROUTE,
  PLACE_ORDER_OPEN_ROUTE,
  SUMMARY_ROUTE,
  UPDATE_ORDER_STATUS_ROUTE,
} from 'constants/apiRoutes';
import { OrderStatus } from 'constants/enums';
import { ApiUpdateOrder, Order, OrderSummary } from 'types/order.interface';
import { formatRoute } from 'utils/formatters';

export const placeOrder = (order: Order) => runPostApiRequest(PLACE_ORDER_OPEN_ROUTE, order);

export const createCartSummary = (order: OrderSummary) => runPostApiRequest(SUMMARY_ROUTE, order);

export const patchOrder = (order: ApiUpdateOrder, orderId: string) => (
  runPatchApiRequest(formatRoute(ORDER_DETAILS_OPEN_ROUTE, { orderId }), order)
);

export const getMyOrders = (userId: string, params: { start: number; length: number}) => (
  runGetApiRequest(formatRoute(MY_ORDERS_ROUTE, { userId }), params)
);

export const getOrderDetails = (
  { userId, orderId, isOpened = false } : { userId: string; orderId: string; isOpened?: boolean },
) => {
  const route = isOpened ? OPEN_ORDER_DETAILS_ROUTE : ORDER_DETAILS_ROUTE;
  return runGetApiRequest(formatRoute(route, { userId, orderId }));
};

export const updateOrderStatus = (orderId: string, status: OrderStatus) => (
  runPostApiRequest(formatRoute(UPDATE_ORDER_STATUS_ROUTE, { orderId, status }))
);

export const getReceiptStatusRef = (orderId: string, userId: string) => (
  ref(db, `/userSpecials/${userId}/orders/${orderId}/bounty/order/customerInfo/receiptStatus/`)
);

export const payOrder = (orderId: string, kioskId: string) => (
  runPostApiRequest(formatRoute(PAY_ORDER_ROUTE, { orderId }), { kioskId })
);

export const bountyCommonLayerRef = (orderId: string) => (
  ref(db, `/common/bounties/${orderId}/content/order`)
);
