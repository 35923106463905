import PercentIcon from '@mui/icons-material/Percent';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { Tab, Tabs } from '@mui/material';
import {
  FC, SyntheticEvent, useEffect, useState,
} from 'react';

import CartTotal from 'components/CartTotal';
import Coupon from 'components/Coupon';
import CustomButton from 'components/CustomButton';
import FailedSummaryMessage from 'components/FailedSummaryMessage';
import OrderDisclaimerMessage from 'components/OrderDisclaimerMessage';
import { StyledSummary, StyledSummaryPrice } from 'containers/CartListingPage/Summary/index.styled';
import MedicalBalance from 'containers/MedicalBalance';

import { CartPriceDetails } from 'types/cart.interface';

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tab-${index}`,
  };
}

interface SummaryProps {
  onSubmit: () => void;
  isSummaryLoading?: boolean;
  hasSummaryFailed?: boolean;
  disabled?: boolean;
  showMedicalBalance: boolean;
  cartPriceDetails: CartPriceDetails;
  onChangeSummary: (coupon?: string) => void;
  productsCount: number;
  orderDisclaimerMessage?: string | null;
}

const Summary:FC<SummaryProps> = ({
  onSubmit,
  isSummaryLoading = false,
  hasSummaryFailed = false,
  cartPriceDetails,
  disabled,
  onChangeSummary,
  showMedicalBalance,
  productsCount,
  orderDisclaimerMessage,
}) => {
  const [tab, setTab] = useState(0);

  const handleTabChange = (_: SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  useEffect(() => {
    if (!showMedicalBalance && tab !== 0) {
      setTab(0);
    }
  }, [showMedicalBalance]);

  return (
    <StyledSummary>
      {showMedicalBalance && (
        <Tabs value={tab} onChange={handleTabChange} aria-label="tab" sx={{ pt: 3, ml: 3 }}>
          <Tab
            icon={<SummarizeIcon />}
            label="Summary"
            iconPosition="start"
            {...a11yProps(1)}
          />
          <Tab
            icon={<PercentIcon />}
            label="Allotment"
            iconPosition="start"
            {...a11yProps(0)}
          />
        </Tabs>
      )}

      {tab === 0 && (
        <>
          <Coupon onChangeSummary={onChangeSummary} inline variant="subtitle1" />
          <StyledSummaryPrice>
            <CartTotal
              cartPriceDetails={cartPriceDetails}
              isLoading={isSummaryLoading}
              hasFailed={hasSummaryFailed}
              productsCount={productsCount}
              totalVariant="h4"
              variant="subtitle1"
            />
            <CustomButton
              id="button-goToCheckout"
              variant="contained"
              fullWidth
              sx={{ mt: 3 }}
              onClick={onSubmit}
              disabled={disabled}
              isLoading={isSummaryLoading}
              size="large"
            >
              Go to checkout
            </CustomButton>

            {orderDisclaimerMessage && (
              <OrderDisclaimerMessage message={orderDisclaimerMessage} />
            )}
          </StyledSummaryPrice>

          {hasSummaryFailed && <FailedSummaryMessage sx={{ mt: 2 }} />}
        </>
      )}

      {tab === 1 && (
        <MedicalBalance
          balances={cartPriceDetails.balances}
          checkSummary={onChangeSummary}
        />
      )}
    </StyledSummary>
  );
};

export default Summary;
