import {
  InventoryConditionItemName,
  InventoryConditionItemType,
  InventoryConditionOperatorName,
  InventoryGroupTypeOperator,
} from 'constants/enums';
import { INVENTORY_CONDITION_ITEM_LABELS, INVENTORY_CONDITION_OPERATOR_LABELS } from 'constants/labels';
import { InventoryConditionItemOption, InventoryConditionOperatorOption } from 'types/customInventoryFilters.interface';
import { Option } from 'types/option.interface';

export const CONDITION_OPERATORS: InventoryConditionOperatorOption[] = [
  {
    value: INVENTORY_CONDITION_OPERATOR_LABELS[InventoryConditionOperatorName.EQ],
    key: InventoryConditionOperatorName.EQ,
  },
  {
    value: INVENTORY_CONDITION_OPERATOR_LABELS[InventoryConditionOperatorName.NOT_EQ],
    key: InventoryConditionOperatorName.NOT_EQ,
  },
  {
    value: INVENTORY_CONDITION_OPERATOR_LABELS[InventoryConditionOperatorName.STARTS_WITH],
    key: InventoryConditionOperatorName.STARTS_WITH,
  },
  {
    value: INVENTORY_CONDITION_OPERATOR_LABELS[InventoryConditionOperatorName.ENDS_WITH],
    key: InventoryConditionOperatorName.ENDS_WITH,
  },
  {
    value: INVENTORY_CONDITION_OPERATOR_LABELS[InventoryConditionOperatorName.CONTAINS],
    key: InventoryConditionOperatorName.CONTAINS,
  },
  {
    value: INVENTORY_CONDITION_OPERATOR_LABELS[InventoryConditionOperatorName.NOT_CONTAIN],
    key: InventoryConditionOperatorName.NOT_CONTAIN,
  },
  {
    value: INVENTORY_CONDITION_OPERATOR_LABELS[InventoryConditionOperatorName.NULL],
    key: InventoryConditionOperatorName.NULL,
  },
  {
    value: INVENTORY_CONDITION_OPERATOR_LABELS[InventoryConditionOperatorName.NOT_NULL],
    key: InventoryConditionOperatorName.NOT_NULL,
  },
  {
    value: INVENTORY_CONDITION_OPERATOR_LABELS[InventoryConditionOperatorName.EMPTY],
    key: InventoryConditionOperatorName.EMPTY,
  },
  {
    value: INVENTORY_CONDITION_OPERATOR_LABELS[InventoryConditionOperatorName.NOT_EMPTY],
    key: InventoryConditionOperatorName.NOT_EMPTY,
  },
  {
    value: INVENTORY_CONDITION_OPERATOR_LABELS[InventoryConditionOperatorName.HAS_VALUE],
    key: InventoryConditionOperatorName.HAS_VALUE,
  },
  {
    value: INVENTORY_CONDITION_OPERATOR_LABELS[InventoryConditionOperatorName.HAS_NO_VALUE],
    key: InventoryConditionOperatorName.HAS_NO_VALUE,
  },
];

export const CONDITION_ITEMS: InventoryConditionItemOption[] = [
  {
    value: INVENTORY_CONDITION_ITEM_LABELS[InventoryConditionItemName.ITEM],
    type: InventoryConditionItemType.STRING,
    key: InventoryConditionItemName.ITEM,
  },
  {
    value: INVENTORY_CONDITION_ITEM_LABELS[InventoryConditionItemName.BATCH],
    type: InventoryConditionItemType.STRING,
    key: InventoryConditionItemName.BATCH,
  },
  {
    value: INVENTORY_CONDITION_ITEM_LABELS[InventoryConditionItemName.AREA],
    type: InventoryConditionItemType.OPTIONS,
    key: InventoryConditionItemName.AREA,
  },
  {
    value: INVENTORY_CONDITION_ITEM_LABELS[InventoryConditionItemName.STATUS],
    type: InventoryConditionItemType.OPTIONS,
    key: InventoryConditionItemName.STATUS,
  },
  {
    value: INVENTORY_CONDITION_ITEM_LABELS[InventoryConditionItemName.HAS_INVENTORY],
    type: InventoryConditionItemType.BOOLEAN,
    key: InventoryConditionItemName.HAS_INVENTORY,
  },
  {
    value: INVENTORY_CONDITION_ITEM_LABELS[InventoryConditionItemName.QC_HOLD],
    type: InventoryConditionItemType.BOOLEAN,
    key: InventoryConditionItemName.QC_HOLD,
  },
  {
    value: INVENTORY_CONDITION_ITEM_LABELS[InventoryConditionItemName.CATEGORY],
    type: InventoryConditionItemType.OPTIONS,
    key: InventoryConditionItemName.CATEGORY,
  },
  {
    value: INVENTORY_CONDITION_ITEM_LABELS[InventoryConditionItemName.BRAND],
    type: InventoryConditionItemType.OPTIONS,
    key: InventoryConditionItemName.BRAND,
  },
  {
    value: INVENTORY_CONDITION_ITEM_LABELS[InventoryConditionItemName.VENDOR],
    type: InventoryConditionItemType.OPTIONS,
    key: InventoryConditionItemName.VENDOR,
  },
  {
    value: INVENTORY_CONDITION_ITEM_LABELS[InventoryConditionItemName.STRAIN],
    type: InventoryConditionItemType.OPTIONS,
    key: InventoryConditionItemName.STRAIN,
  },
  {
    value: INVENTORY_CONDITION_ITEM_LABELS[InventoryConditionItemName.AVAILABLE_FOR_RETAIL],
    type: InventoryConditionItemType.BOOLEAN,
    key: InventoryConditionItemName.AVAILABLE_FOR_RETAIL,
  },
  {
    value: INVENTORY_CONDITION_ITEM_LABELS[InventoryConditionItemName.STORED_UOM],
    type: InventoryConditionItemType.OPTIONS,
    key: InventoryConditionItemName.STORED_UOM,
  },
  {
    value: INVENTORY_CONDITION_ITEM_LABELS[InventoryConditionItemName.HARVEST_BATCH],
    type: InventoryConditionItemType.STRING,
    key: InventoryConditionItemName.HARVEST_BATCH,
  },
];

export const BOOLEAN_CONDITION_OPTIONS: Option[] = [
  { key: 'true', value: 'True' }, { key: 'false', value: 'False' },
];

export const GROUP_CONDITION_OPTIONS: Option[] = [
  { key: InventoryGroupTypeOperator.AND, value: InventoryGroupTypeOperator.AND },
  { key: InventoryGroupTypeOperator.OR, value: InventoryGroupTypeOperator.OR },
];

export const INVENTORY_STATUS_OPTIONS: Option[] = [
  { key: 'open', value: 'Open' },
  { key: 'closed', value: 'Closed' },
  { key: 'qc_hold', value: 'QC-Hold' },
];

export const INVENTORY_CONDITION_OPERATORS_WITHOUT_VALUE = [
  InventoryConditionOperatorName.NULL,
  InventoryConditionOperatorName.NOT_NULL,
  InventoryConditionOperatorName.EMPTY,
  InventoryConditionOperatorName.NOT_EMPTY,
  InventoryConditionOperatorName.HAS_VALUE,
  InventoryConditionOperatorName.HAS_NO_VALUE,
];
