import { isNil } from 'lodash';

import { OnboardingUseType, PointCurrency, WeightUnit } from 'constants/enums';
import {
  Reward, Price, AltPrices, TierPrice,
} from 'types/money.interface';
import { Product, ProductSpecificInfo } from 'types/product.interface';
import { formatReward, multiply } from 'utils/currencyUtils';
import { isZero } from 'utils/moneyUtils';
import { getPointsAmount } from 'utils/pointUtils';
import { isFlwr } from 'utils/productUtils';

function isDiscount(initial: Reward|undefined, current: Reward|undefined): boolean {
  if (initial && current) {
    return !(JSON.stringify(initial) === JSON.stringify(current));
  }

  return false;
}

export function hasDiscount(initial: Price|null, current: Price|null): boolean {
  if (initial && current) {
    return isDiscount(initial.mainPrice, current.mainPrice)
      || isDiscount(initial.altPrice1, current.altPrice1)
      || isDiscount(initial.altPrice2, current.altPrice2);
  }

  return false;
}

function hasNoPrice(reward: Reward|undefined): boolean {
  if (!reward) {
    return true;
  }

  return (!reward.money || isZero(reward.money)) && !getPointsAmount(reward, PointCurrency.POINT);
}

export function isFree(price: Price|null): boolean {
  if (!price) {
    return true;
  }

  return hasNoPrice(price.mainPrice) && hasNoPrice(price.altPrice1) && hasNoPrice(price.altPrice2);
}

function hasVariants(variants: { [key: string]: ProductSpecificInfo }): boolean {
  return Boolean(variants && Object.keys(variants).length);
}

function getVariant(variants: { [key: string]: ProductSpecificInfo }, sku: string): ProductSpecificInfo|null {
  if (!sku || !hasVariants(variants)) {
    return null;
  }

  return variants[sku];
}

export function getTopLevelVariant(product?: Product): ProductSpecificInfo|null {
  if (!product) {
    return null;
  }

  const { merchantSku, variants, imageUrl } = product;
  const productSpecificInfo = getVariant(variants, merchantSku);

  if (productSpecificInfo && !productSpecificInfo?.sku) {
    productSpecificInfo.sku = merchantSku;
  }

  if (productSpecificInfo && !productSpecificInfo?.imageUrl) {
    productSpecificInfo.imageUrl = imageUrl;
  }

  return productSpecificInfo;
}

interface ProductPrice {
  useType?: OnboardingUseType;
  altPrices?: AltPrices;
  productPrice?: Price;
  productMsrp?: Price;
  weight?: number | null;
  quantity?: number | null;
  sortUnit?: WeightUnit;
}

export function getPrice({
  useType,
  altPrices,
  productPrice,
  productMsrp,
  weight,
  quantity,
  sortUnit,
}: ProductPrice) {
  let price = productPrice || null;
  let msrp = productMsrp || null;

  if (useType === OnboardingUseType.is_adult_use && altPrices?.retailAdultUse
    && !isFree(altPrices?.retailAdultUse)) {
    if (altPrices?.saleAdultUse && !isFree(altPrices?.saleAdultUse)) {
      price = altPrices?.saleAdultUse;
      msrp = altPrices?.retailAdultUse;
    } else {
      price = altPrices?.retailAdultUse;
    }
  }

  if (useType === OnboardingUseType.is_medical_use && altPrices?.retailMedicalUse
    && !isFree(altPrices?.retailMedicalUse)) {
    if (altPrices?.saleMedicalUse && !isFree(altPrices?.saleMedicalUse)) {
      price = altPrices?.saleMedicalUse;
      msrp = altPrices?.retailMedicalUse;
    } else {
      price = altPrices?.retailMedicalUse;
    }
  }

  const newProductPrice = !isNil(weight) && altPrices?.tierPrices && useType // In case we have tierPricing, we look there first
    ? getTierPriceByWeight(weight, altPrices?.tierPrices, useType) || price
    : price;
  const flwrPrice = newProductPrice?.mainPrice && quantity
    ? multiply(newProductPrice?.mainPrice, quantity)
    : null;

  return {
    msrp: msrp || null,
    price: isFlwr(sortUnit) && flwrPrice
      ? { mainPrice: flwrPrice }
      : newProductPrice,
  };
}

export function getProductPrice(
  product?: Product,
  useType?: OnboardingUseType,
): { msrp: Price|null; price: Price|null } {
  if (!product) {
    return {
      msrp: null,
      price: null,
    };
  }

  const productInfo = getTopLevelVariant(product);

  return getPrice({
    useType,
    altPrices: product?.altPrices,
    productPrice: productInfo?.price,
    productMsrp: productInfo?.msrp,
  });
}

export function formatPrice(price: Price|null) {
  if (!price) {
    return '';
  }

  const parts = [];

  if (price.mainPrice && Object.keys(price.mainPrice).length) {
    const reward = formatReward(price.mainPrice, '0');

    if (reward) {
      parts.push(reward);
    }
  }

  if (price.altPrice1 && Object.keys(price.altPrice1).length) {
    const reward = formatReward(price.altPrice1, '0');

    if (reward) {
      parts.push(reward);
    }
  }

  if (price.altPrice2 && Object.keys(price.altPrice2).length) {
    const reward = formatReward(price.altPrice2, '0');

    if (reward) {
      parts.push(reward);
    }
  }

  return parts.join(' / ');
}

export function getTierPrice(useType: OnboardingUseType | undefined, tierPrice: TierPrice) {
  let price = tierPrice?.retailPerItem || tierPrice?.retailPerGram;
  const retailAdultUse = tierPrice?.retailAdultUsePerItem || tierPrice?.retailAdultUsePerGram;
  const retailMedicalUse = tierPrice?.retailMedicalUsePerItem || tierPrice?.retailMedicalUsePerGram;

  if (useType === OnboardingUseType.is_adult_use && retailAdultUse && !isFree(retailAdultUse)) {
    price = retailAdultUse;
  }

  if (useType === OnboardingUseType.is_medical_use && retailMedicalUse && !isFree(retailMedicalUse)) {
    price = retailMedicalUse;
  }

  return price;
}

export function getPriceDiscount(initialPrice: Price, newPrice: Price) {
  const initialMoneyAmount = initialPrice?.mainPrice?.money?.amount;
  const newMoneyAmount = newPrice?.mainPrice?.money?.amount;

  if (!initialMoneyAmount || !newMoneyAmount) {
    return 0;
  }

  return 100 - (+newMoneyAmount * 100) / +initialMoneyAmount;
}

export function getTierPriceByWeight(
  weight: number,
  tierPrices: TierPrice[],
  useType: OnboardingUseType,
) {
  let tierPriceIndex = -1;

  tierPrices.forEach((tierPrice, index) => {
    if (tierPrice?.weight <= weight) {
      tierPriceIndex = index;
    }
  });

  return tierPriceIndex >= 0
    ? getTierPrice(useType, tierPrices[tierPriceIndex])
    : null;
}

export function getFullTierPrice(useType: OnboardingUseType | undefined, tierPrice: TierPrice) {
  let price = tierPrice?.retailPerItem || tierPrice?.retailPerGram;
  const retailAdultUse = tierPrice?.retailAdultUseFull;
  const retailMedicalUse = tierPrice?.retailMedicalUseFull;

  if (useType === OnboardingUseType.is_adult_use && retailAdultUse && !isFree(retailAdultUse)) {
    price = retailAdultUse;
  }

  if (useType === OnboardingUseType.is_medical_use && retailMedicalUse && !isFree(retailMedicalUse)) {
    price = retailMedicalUse;
  }

  return price;
}
