import { useContext, useEffect, useState } from 'react';

import { UserContext } from 'context/UserContext';

import { getClientInformation, getCompanyLocations } from 'services/Client';

import { UserType } from 'constants/enums';
import { ApiLocation } from 'types/companyLocations.interface';
import { handleApiErrors } from 'utils/errorUtils';

const useUserStoreAccess = () => {
  const [shopOptions, setShopOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { user, userInfo } = useContext(UserContext);

  useEffect(() => {
    if (!user?.isAnonymous && userInfo?.userType !== UserType.Individual && userInfo.clientId) {
      fetchLocationsWithRedirect();
    }
  }, [userInfo?.clientId, user]);

  const fetchLocationsWithRedirect = async () => {
    try {
      setIsLoading(true);
      const { data: clientDetails } = await getClientInformation(userInfo.clientId);

      if (clientDetails.hasLocations) {
        const { data } = await getCompanyLocations(userInfo.clientId);

        if (data.list.length === 1) {
          window.location.replace(`${window.location.origin}/${data.list[0].locationId}/${data.list[0].id}/welcome`);
        } else {
          setShopOptions(data.list.map(({ locationId, id, name }: ApiLocation) => ({
            key: `${locationId}/${id}`,
            value: name,
          })));
        }
      } else {
        window.location.replace(`${window.location.origin}/${clientDetails.parentId}/${clientDetails.id}/welcome`);
      }
    } catch (e) {
      handleApiErrors(e);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    shopOptions,
  };
};

export default useUserStoreAccess;
