import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import { Box, Typography } from '@mui/material';
import { FC } from 'react';

import ChipButton from 'components/ChipButton';
import DialogTitle from 'containers/Onboarding/DialogTitle';
import { DialogContent } from 'containers/Onboarding/index.styled';

import { OnboardingUseType as OnboardingUseTypeEnum } from 'constants/enums';
import { USE_TYPE_OPTIONS } from 'constants/options';

interface UseTypePickerProps {
  onChange: (useType: OnboardingUseTypeEnum) => void;
}

const UseTypePicker:FC<UseTypePickerProps> = ({ onChange }) => (
  <>
    <DialogTitle>
      Please choose
      <br />
      your experience
    </DialogTitle>
    <DialogContent>
      <Box py={0}>
        <ChipButton
          height="100%"
          sx={{ mb: 1 }}
          icon={<SelfImprovementIcon className="useType" sx={{ fontSize: '40px' }} />}
          onClick={() => onChange(USE_TYPE_OPTIONS[0].key)}
        >
          <div>
            <Typography fontWeight={700} className="title">
              {USE_TYPE_OPTIONS[0].value.toUpperCase()}
            </Typography>
            <Typography variant="body2">
              No medical card
            </Typography>
          </div>
        </ChipButton>

        <ChipButton
          icon={<MedicalServicesOutlinedIcon className="useType" sx={{ fontSize: '40px' }} />}
          height="100%"
          onClick={() => onChange(USE_TYPE_OPTIONS[1].key)}
        >
          <div>
            <Typography fontWeight={700} className="title">
              {USE_TYPE_OPTIONS[1].value.toUpperCase()}
            </Typography>
            <Typography variant="body2">
              Medical card required
            </Typography>
          </div>
        </ChipButton>
      </Box>
    </DialogContent>
  </>
);

export default UseTypePicker;
