import ArrowBack from '@mui/icons-material/ArrowBackIosNew';
import ArrowForward from '@mui/icons-material/ArrowForwardIos';
import { Box, IconButton, MobileStepper } from '@mui/material';
import { FC, useReducer } from 'react';
import { useSwipeable } from 'react-swipeable';
import { SwipeEventData } from 'react-swipeable/src/types';

import {
  CarouselContainer,
  CarouselSlot,
  Item,
  Root,
  SlideButtonContainer,
  Wrapper,
  StyledImage,
} from 'components/ImagesCarousel/index.styled';
import {
  Direction,
  NEXT,
  PREV,
  reducer,
  getInitialState,
} from 'components/ImagesCarousel/reducer';

interface ImagesCarouselProps {
  pictures: string[];
  classNameStepper?: string;
}

const getOrder = (index: number, pos: number, numItems: number) => (
  index - pos < 0 ? numItems - Math.abs(index - pos) : index - pos
);

const ImagesCarousel:FC<ImagesCarouselProps> = ({ pictures, classNameStepper = '' }) => {
  const numItems = pictures.length;
  const [state, dispatch] = useReducer(reducer, getInitialState());

  const slide = (dir: Direction) => {
    dispatch({ type: dir, numItems });
    setTimeout(() => {
      dispatch({ type: 'stopSliding' });
    }, 50);
  };

  const handleSwipeLeft = (eventData: SwipeEventData) => {
    if (state.pos < numItems - 1) {
      eventData.event.stopPropagation();
      slide(NEXT);
    }
  };

  const handleSwipeRight = (eventData: SwipeEventData) => {
    if (state.pos > 0) {
      eventData.event.stopPropagation();
      slide(PREV);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleSwipeLeft,
    onSwipedRight: handleSwipeRight,
    rotationAngle: 30,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  return (
    <Root>
      <Box position="relative" {...handlers}>
        <Wrapper className="carouselWrapper">
          <CarouselContainer dir={state.dir} sliding={state.sliding}>
            {pictures.map((url, index) => (
              <CarouselSlot
                key={index}
                order={getOrder(index, state.pos, numItems)}
              >
                <Item />
                <StyledImage
                  component="img"
                  src={url}
                />
              </CarouselSlot>
            ))}
          </CarouselContainer>
        </Wrapper>
        {numItems > 1 && (
          <SlideButtonContainer>
            <div>
              <IconButton
                onClick={() => slide(PREV)}
              >
                <ArrowBack />
              </IconButton>
            </div>
            <div>
              <IconButton
                onClick={() => slide(NEXT)}
              >
                <ArrowForward />
              </IconButton>
            </div>
          </SlideButtonContainer>
        )}
      </Box>
      {numItems > 1 && (
        <MobileStepper
          steps={numItems}
          position="static"
          sx={{ justifyContent: 'center' }}
          activeStep={state.pos}
          backButton={null}
          nextButton={null}
          className={classNameStepper}
        />
      )}
    </Root>
  );
};

export default ImagesCarousel;
