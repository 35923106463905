import { Box, Typography } from '@mui/material';
import {
  FC, useContext, useEffect, useState,
} from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import Footer from 'components/_Footer';
import ShopContent from 'components/_ShopContent';
import CustomButton from 'components/CustomButton';
import SwitchShop from 'containers/SwitchShop';
import { PaymentProvider } from 'context/PaymentContext';
import { SystemContext } from 'context/SystemContext';
import { UserContext } from 'context/UserContext';
import { UserLocationAccessContext } from 'context/UserLocationAccessContext';
import { Root } from 'layouts/ManagementLayout/index.styled';
import ManagementNavbar from 'layouts/ManagementLayout/Navbar';

import { WELCOME_ROUTE } from 'constants/clientRoutes';
import { adminDetailsIncomplete } from 'utils/clientUtils';

const ManagementLayout:FC = () => {
  const [showLogin, setShowLogin] = useState(false);
  const [showSwitchShop, setShowSwitchShop] = useState(false);
  const { user, userInfo } = useContext(UserContext);
  const { shopSettings, clientInfo } = useContext(SystemContext);
  const { shopOptions, isLoading: loadingStores } = useContext(UserLocationAccessContext);
  const { pathname } = useLocation();

  useEffect(() => {
    if (adminDetailsIncomplete(user, userInfo, shopSettings) && !showLogin) {
      setShowLogin(true);
    }
  }, [user?.isAnonymous, shopSettings, userInfo?.userType, showLogin]);

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <ManagementNavbar clientInfo={clientInfo} />
      <Root>
        <ShopContent sx={{ flex: 1 }}>
          <Box display="flex" alignItems="center" gap={2}>
            {clientInfo?.name && (
              <Typography variant="h5" fontWeight={700} py={4}>
                {clientInfo.name}
              </Typography>
            )}
            {shopOptions?.length > 1 && pathname === WELCOME_ROUTE && (
            <CustomButton
              variant="outlined"
              id="btn-switchShop"
              onClick={() => setShowSwitchShop(true)}
            >
              Switch Shop
            </CustomButton>
            )}
          </Box>
          <PaymentProvider>
            <Outlet />
          </PaymentProvider>
        </ShopContent>
        <Footer />
      </Root>

      {showSwitchShop && (
      <SwitchShop
        onClose={() => setShowSwitchShop(false)}
        shopOptions={shopOptions}
        isLoading={loadingStores}
      />
      )}
    </Box>
  );
};

export default ManagementLayout;
