import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useMemo } from 'react';
import Slider from 'react-slick';

import SectionHeader from 'components/SectionHeader';
import CategoryCardSkeleton from 'containers/CategoriesCarousel/CategoryCard/index.skeleton';

import { getCategoriesCarouselSettings } from 'utils/carousel';

const CategoriesCarouselSkeleton = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const settings = {
    ...getCategoriesCarouselSettings(theme, 7),
  };

  const handleNextCategories = () => {};

  const handlePreviousCategories = () => {};

  const renderMobileSlider = useMemo(() => (
    <Slider {...settings}>
      <CategoryCardSkeleton />
      <CategoryCardSkeleton />
    </Slider>
  ), []);

  const renderSlider = useMemo(() => (
    <Slider {...settings}>
      <CategoryCardSkeleton />
      <CategoryCardSkeleton />
      <CategoryCardSkeleton />
      <CategoryCardSkeleton />
      <CategoryCardSkeleton />
      <CategoryCardSkeleton />
      <CategoryCardSkeleton />
    </Slider>
  ), []);

  return (
    <div>
      <SectionHeader
        title="Categories"
        onNext={handleNextCategories}
        onPrevious={handlePreviousCategories}
      />
      {isMobile ? renderMobileSlider : renderSlider}
    </div>
  );
};

export default CategoriesCarouselSkeleton;
