import { AxiosResponse } from 'axios';

import { runGetApiRequest } from 'services/Api';

import {
  CLIENT_INFORMATION_OPEN_ROUTE,
  CLIENT_LOCATIONS_ROUTE,
  GUESS_CLIENT_ROUTE,
  LOCATION_GUESS_ROUTE,
  LOCATION_ROUTE,
  PICKUP_ORDERS_ROUTE,
  PROMOTION_ROUTE,
  SHOP_SETTINGS_ROUTE,
  US_STATES_ROUTE,
} from 'constants/apiRoutes';
import { DeliveryMethod } from 'constants/enums';
import { Promotion } from 'types/promotion.interface';
import { formatRoute } from 'utils/formatters';
import { formatShopSettings } from 'utils/storeUtils';

export const getClientInformation = async (slug: string|number|undefined) => (
  runGetApiRequest(formatRoute(CLIENT_INFORMATION_OPEN_ROUTE, { slug }), {}, true));

export const getUsStates = async () => runGetApiRequest(US_STATES_ROUTE, {});

export const getApiLocation = async () => runGetApiRequest(LOCATION_ROUTE, {});

export const getCompanyLocations = async (clientId: string) => (
  runGetApiRequest(formatRoute(CLIENT_LOCATIONS_ROUTE, { clientId }))
);

export const getPromotions = async (
  type: string,
  queryParams: { [key: string]: string | number } = {},
): Promise<AxiosResponse<Promotion[], any>> => {
  const endpoint = formatRoute(PROMOTION_ROUTE, { type });
  return runGetApiRequest(endpoint, queryParams);
};

export const getShopSettings = async (companyId?: string) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const { data } = await runGetApiRequest(SHOP_SETTINGS_ROUTE, { companyId });
    return { data: formatShopSettings(data) };
  } catch (e) {
    throw e;
  }
};

export const getUserNearestLocation = async (params: { [key: string]: string } = {}) => (
  runGetApiRequest(LOCATION_GUESS_ROUTE, params)
);

export const guessClient = async (params: { [key: string]: string|undefined } = {}) => (
  runGetApiRequest(GUESS_CLIENT_ROUTE, params)
);

export const getPickupOrdersLimit = async (slug: string, pickupDate: string, method: DeliveryMethod) => {
  const queryParams = { pickup_date: pickupDate, delivery_method: method };
  return runGetApiRequest(formatRoute(PICKUP_ORDERS_ROUTE, { slug }), queryParams);
};
