import { Box, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import {
  ChangeEvent, FC, ReactNode, useContext, useMemo, useState,
} from 'react';
import { toast } from 'react-toastify';

import QuantitySelector from 'components/QuantitySelector';
import WeightSelector from 'components/WeightSelector';
import { GridWrapper } from 'containers/ProductPriceSection/TierPriceSelector/index.styled';
import { CartContext, CartDispatchContext } from 'context/CartContext';

import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { useKiosk } from 'hooks/useKiosk';

import { OnboardingUseType } from 'constants/enums';
import { Bounty } from 'types/bounty.interface';
import { Cart } from 'types/cart.interface';
import { TierPrice } from 'types/money.interface';
import { multiplyMoney } from 'utils/currencyUtils';
import { extractNumberFromNumericFormat } from 'utils/numbers';
import {
  formatPrice, getProductPrice, getTierPriceByWeight, getTopLevelVariant,
} from 'utils/priceUtils';
import { getRealQuantity, getRealWeight } from 'utils/productUtils';

interface TierPriceSelectorProps {
  bounty: Bounty;
  tierPrices: TierPrice[];
  shouldUseProductWeight: boolean;
  useType: OnboardingUseType;
  onSuccess?: (cart: Cart) => void;
  children: (params: { onAddToCart: () => void; isDisabled?: boolean }) => ReactNode;
}

const TierPriceSelector:FC<TierPriceSelectorProps> = ({
  bounty,
  tierPrices,
  shouldUseProductWeight,
  useType,
  children,
  onSuccess,
}) => {
  const { kioskMode } = useKiosk();
  const cart = useContext(CartContext);
  const { addItem, addItemWithWeight } = useContext(CartDispatchContext);
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [currentWeight, setCurrentWeight] = useState<string>(bounty?.product?.sortWeight?.toString() || '');
  const { product } = bounty;
  const { quantity, maxAllowedQty } = getTopLevelVariant(product) || {};
  const productQuantity = Math.max(0, quantity || 0);
  const allowableQuantity = Math.min(getRealQuantity(quantity, bounty.id, cart), maxAllowedQty || Number.MAX_VALUE);
  const realWeight = getRealWeight(bounty?.product, bounty.id, cart);
  const { weightUnit, weight = 0 } = getTopLevelVariant(bounty?.product) || {};
  const { handleTrackAddItemToCart } = useGoogleAnalytics();
  const { price: productPrice } = getProductPrice(bounty?.product, useType);

  const handleQuantityChanges = (newQuantity: number) => {
    setSelectedQuantity(newQuantity);
  };

  const handleInputChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setCurrentWeight(value);
  };

  const handleAddToCart = () => {
    if (shouldUseProductWeight) {
      addItemWithWeight({
        bounty,
        weight: currentWeight,
        onSuccess: (updatedCart) => {
          setCurrentWeight(bounty?.product?.sortWeight?.toString() || '');
          handleTrackAddItemToCart({ bounty, weight: currentWeight });

          if (!kioskMode) toast.success('Product added to cart!');
          if (onSuccess) onSuccess(updatedCart);
        },
      });

      return;
    }

    addItem({
      bounty,
      quantity: selectedQuantity,
      onSuccess: (updatedCart) => {
        setSelectedQuantity(1);
        handleTrackAddItemToCart({ bounty, selectedQuantity });

        if (!kioskMode) toast.success('Product added to cart!');
        if (onSuccess) onSuccess(updatedCart);
      },
    });
  };

  const { totalPrice, discount } = useMemo(() => {
    const currentWeightNumber = extractNumberFromNumericFormat({ value: currentWeight });
    const tierPrice = getTierPriceByWeight(
      shouldUseProductWeight ? currentWeightNumber : weight * selectedQuantity,
      tierPrices,
      useType,
    );
    const price = tierPrice || productPrice;

    const quantityToUse = shouldUseProductWeight
      ? currentWeightNumber / weight
      : selectedQuantity;
    const { amount: discountedAmount } = multiplyMoney(price?.mainPrice?.money, quantityToUse) || {};
    const { amount: originalAmount } = multiplyMoney(productPrice?.mainPrice?.money, quantityToUse) || {};

    return {
      totalPrice: formatPrice({
        mainPrice: {
          money: multiplyMoney(price?.mainPrice?.money, quantityToUse),
        },
      }),
      discount: originalAmount && discountedAmount
        ? +originalAmount - +discountedAmount
        : 0,
    };
  }, [currentWeight, selectedQuantity, tierPrices, productPrice]);

  if (allowableQuantity <= 0) {
    return (
      <div>
        <Typography variant="body2" color="error">
          Out of stock
        </Typography>
        {children({ onAddToCart: handleAddToCart, isDisabled: true })}
      </div>
    );
  }

  return (
    <Box>
      <GridWrapper container alignItems="center" size={12}>
        <Grid size={4}>
          {shouldUseProductWeight
            ? (
              <WeightSelector
                selectedWeight={currentWeight}
                onChange={handleInputChange}
                weightUnit={weightUnit}
                disabled={!realWeight}
              />
            ) : (
              <QuantitySelector
                selectedQuantity={selectedQuantity}
                handleQuantity={handleQuantityChanges}
                allowableQuantity={allowableQuantity}
                disabled={productQuantity <= 0 || allowableQuantity <= 0}
              />
            )}
        </Grid>
        <Grid size={4}>
          <Typography fontWeight={600}>
            {totalPrice}
          </Typography>
        </Grid>
        {discount > 0 && (
          <Grid size={4}>
            <Typography fontWeight={600} color="general.darkGreen2">
              {`YOU SAVE $${discount.toFixed(2)}!`}
            </Typography>
          </Grid>
        )}
      </GridWrapper>
      {children({ onAddToCart: handleAddToCart })}
    </Box>
  );
};

export default TierPriceSelector;
