import { EmailAuthProvider, GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth';

import { FilterCriteriaType, LoginMethod } from 'constants/enums';

export const ADMIN_PIN = '101';

export const DEFAULT_DATE_FORMAT = 'MM-dd-yyyy';
export const SCHEDULE_DATE_FORMAT = 'MM/dd/yyyy';
export const PICKUP_DATE_FORMAT = 'dd-MM-yyyy';
export const DEFAULT_TIME_FORMAT = 'HH:mm';
export const ORDER_DATE_FORMAT = 'yyyy-MM-dd';
export const EXTENDED_ORDER_DATE_FORMAT = 'MMM dd, yyyy, HH:mm';
export const DETAILED_DAY_DATE_FORMAT = 'MMM dd, yyyy';
export const FULL_DATE_FORMAT = 'MM/dd/yyyy HH:mm:ss';
export const DEFAULT_PAGINATION_LIMIT = 10;
export const DEFAULT_PRODUCTS_SKELETONS = 15;
export const DEFAULT_MOBILE_PRODUCTS_SKELETONS = 3;

export const HEADER_HEIGHT = 100;
export const KIOSK_HEADER_HEIGHT = 100;
export const KIOSK_SIDEBAR_WEIGHT = 240;

export const LOGIN_METHOD_ID = {
  [LoginMethod.EMAIL]: EmailAuthProvider.PROVIDER_ID,
  [LoginMethod.GOOGLE]: GoogleAuthProvider.PROVIDER_ID,
  [LoginMethod.FACEBOOK]: FacebookAuthProvider.PROVIDER_ID,
  [LoginMethod.APPLE]: 'apple.com',
  [LoginMethod.MICROSOFT]: 'microsoft.com',
};

export const DEFAULT_SIGNIN_METHODS = [EmailAuthProvider.PROVIDER_ID];

export const CHAT_MESSAGES_LIMIT = 10;

export const ACCEPTED_IMAGE_TYPES = {
  'image/jpeg': ['.jpeg'],
  'image/png': ['.png'],
  'image/jpg': ['.jpg'],
};

export const ACCEPTED_CHAT_FILE_TYPES = {
  ...ACCEPTED_IMAGE_TYPES,
  'application/pdf': ['.pdf'],
  'video/*': [],
};

export const US_COUNTRY_CODE = 'US';
export const DEFAULT_STATE_CODE = 'CA';

export const TIMESTAMP_MINUTE = 60000;
export const PICKUP_MINUTES_STEP = 15;
export const INACTIVE_USER_TIMESTAMP = 2 * 60 * 1000;
export const WARNING_INACTIVE_USER_TIMESTAMP = INACTIVE_USER_TIMESTAMP - 1000 * 10;

export const CHEMICALS_FOR_SIMPLIFY_MODE = [
  'THC',
  'THCa',
  'CBD',
  'CBG',
  'CBN',
];

export const ALL_CATEGORIES = 'all';
export const FILTER_CODE_SEPARATOR = '--';
export const EMPTY_STATE = '-';

export const prefixByCriteriaType: Record<FilterCriteriaType, string> = {
  [FilterCriteriaType.PRICE]: '$ ',
  [FilterCriteriaType.CANNABINOIDS]: '% ',
  [FilterCriteriaType.TAG]: ' ',
};
