import { ButtonProps, CircularProgress, SxProps } from '@mui/material';
import { FC, ReactElement } from 'react';

import { Button } from 'components/CustomButton/index.styled';

import { useKiosk } from 'hooks/useKiosk';

interface CustomButtonProps {
  children: string | ReactElement;
  isLoading?: boolean;
  disabled?: boolean;
  id: string;
  icon?: ReactElement | null;
  iconPlacement?: 'start' | 'end';
  sx?: SxProps;
}

const CustomButton:FC<ButtonProps & CustomButtonProps> = (props): ReactElement => {
  const {
    children,
    isLoading = false,
    variant = 'contained',
    color = 'primary',
    icon = null,
    iconPlacement = 'start',
    disabled = false,
    id,
    sx = {},
    ...remainingProps
  } = props;
  const btnIcon = icon && isLoading ? <CircularProgress color="inherit" size="12px" /> : icon;
  const isDisabled = disabled || isLoading;
  const { kioskMode } = useKiosk();
  return (
    <Button
      {...remainingProps}
      sx={sx}
      id={id}
      isLoading={isLoading}
      disabled={isDisabled}
      variant={variant}
      color={color}
      endIcon={iconPlacement === 'end' && btnIcon}
      startIcon={iconPlacement === 'start' && btnIcon}
    >
      {isLoading && !icon
        ? (
          <>
            <span className="placeholder">L</span>
            <CircularProgress color="inherit" size={kioskMode ? '1.6rem' : '1.5rem'} />
          </>
        )
        : children}
    </Button>
  );
};

export default CustomButton;
