import { Box, Typography, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import CustomButton from 'components/CustomButton';
import PageTitle from 'components/PageTitle';
import QrCodeWrapper from 'components/QrCodeWrapper';
import OrderConfirmationLayout from 'containers/OrderConfirmationPage/OrderConfirmationLayout';
import { PaymentContext } from 'context/PaymentContext';
import { UserContext, UserDispatchContext } from 'context/UserContext';

import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useKiosk } from 'hooks/useKiosk';
import { useReceipt } from 'hooks/useReceipt';
import {
  getDigitalReceiptRoute, getHomeRoute, getOrderDetailsRoute, useOrderId,
} from 'hooks/useRouting';

import { DeliveryMethod, ReceiptStatus } from 'constants/enums';
import { getPublicUrlForImage } from 'utils/publicUrl';

import { CardMedia, ContentCard } from './index.styled';

const OrderConfirmationPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const placedOrderId = useOrderId() || '';
  const { paymentDetails } = useContext(PaymentContext);
  const { renewKioskUser } = useContext(UserDispatchContext);
  const { kioskMode } = useKiosk();
  const { user } = useContext(UserContext);
  const theme = useTheme();
  const matchesScreenSizeSm = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    alleavesOrderId, deliveryMethod, receiptUrl, orderId,
  } = paymentDetails;
  const alleavesOrderIdParam = searchParams?.get('alleavesOrderId');
  const displayedOrderId = alleavesOrderId || alleavesOrderIdParam;

  const { receiptStatus } = useReceipt(placedOrderId, deliveryMethod);
  const isQrCodeVisible = [DeliveryMethod.PICKUP, DeliveryMethod.KIOSK].includes(deliveryMethod) && receiptUrl;
  const homeRoute = getHomeRoute();

  useDocumentTitle({ title: 'Order Confirmation' });

  useEffect(() => {
    if (kioskMode && user?.isAnonymous) {
      renewKioskUser();
    }
  }, [kioskMode]);

  const handleSeeOrder = () => {
    navigate(getOrderDetailsRoute(placedOrderId));
  };

  const handleSeeAllProducts = () => {
    navigate(homeRoute);
  };

  const handleRedirectToReceipt = () => (orderId && !kioskMode
    ? navigate(getDigitalReceiptRoute(orderId))
    : null);

  useEffect(() => {
    if (receiptStatus === ReceiptStatus.Scanned) {
      navigate(homeRoute);
      toast.success('Digital receipt sent to your mobile device.');
    }
  }, [receiptStatus]);

  const getQRsize = () => {
    if (kioskMode) {
      return 300;
    }

    return matchesScreenSizeSm ? 75 : 125;
  };

  return (
    <OrderConfirmationLayout kioskMode={kioskMode}>
      {!kioskMode && (
        <PageTitle hasBackButton href={homeRoute} title="Thank you" />
      )}
      <Box display="flex" alignItems="center" flexDirection="column" mt={3}>
        <ContentCard>
          <CardMedia
            kioskMode={kioskMode}
            component="img"
            src={getPublicUrlForImage('images/shipped_order.gif')}
            alt="shippedOrderGif"
          />

          {isQrCodeVisible
            ? (
              <Box p={2} display="flex" justifyContent="center" flexDirection="column">
                <Typography variant="subtitle1" fontWeight={500} mt={1.5} mb={4} textAlign="center">
                  {`Order ${displayedOrderId ? `#${displayedOrderId}` : ''}`}
                  <br />
                  placed successfully
                </Typography>

                {!kioskMode && (
                <CustomButton
                  id="button-seeOrder"
                  variant="outlined"
                  onClick={handleSeeOrder}
                  className="button-secondary"
                >
                  View order details
                </CustomButton>
                )}
              </Box>
            ) : (
              <>
                <Typography variant="subtitle1" fontWeight={600} mt={3} mb={2}>
                  Thank you for your purchase!
                </Typography>

                <Typography mb={4}>
                  {`Order ${displayedOrderId ? `#${displayedOrderId}` : ''} has been placed successfully!`}
                </Typography>

                {!kioskMode && (
                <CustomButton
                  id="button-seeOrder"
                  variant="outlined"
                  onClick={handleSeeOrder}
                  sx={{ mb: 1.5 }}
                  className="button-secondary"
                >
                  See order
                </CustomButton>
                )}

                <CustomButton
                  id="button-seeAllProducts"
                  variant="outlined"
                  onClick={handleSeeAllProducts}
                  className="button-secondary"
                >
                  See all products
                </CustomButton>
              </>
            )}
        </ContentCard>

        {isQrCodeVisible && (
          <QrCodeWrapper
            backgroundColor="transparent"
            size={getQRsize()}
            src={receiptUrl}
            sx={{ mt: 10, p: kioskMode ? 10 : 5, borderRadius: '4px' }}
            innerSx={{
              display: 'flex', flexDirection: 'column', gap: 2.5, alignItems: 'center',
            }}
            isMobileView={matchesScreenSizeSm}
            onRedirect={handleRedirectToReceipt}
          >
            <Typography variant={kioskMode ? 'subtitle1' : 'body2'} textAlign="center" mt={4}>
              Scan this QR Code to get your receipt.
              <br />
              Show the page at pickup.
            </Typography>
          </QrCodeWrapper>
        )}
      </Box>
    </OrderConfirmationLayout>
  );
};

export default OrderConfirmationPage;
