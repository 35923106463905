import { Box, Typography } from '@mui/material';
import { FC, useContext } from 'react';

import ProductActions from 'containers/ProductDetailsPage/ProductActions';
import InlineWeightSelector from 'containers/ProductPriceSection/InlineWeightSelector';
import PriceVariantsSelector from 'containers/ProductPriceSection/PriceVariantsSelector';
import QuantitySelector from 'containers/ProductPriceSection/QuantitySelector';
import TierPrices from 'containers/ProductPriceSection/TierPrices';
import TierPriceSelector from 'containers/ProductPriceSection/TierPriceSelector';
import { LocationContext } from 'context/LocationContext';

import { Bounty } from 'types/bounty.interface';
import { Cart } from 'types/cart.interface';
import { isFlwr, shouldUseProductWeight } from 'utils/productUtils';

interface ProductPriceSectionProps {
  bounty: Bounty;
  isLoading: boolean;
  fullWidth?: boolean;
  onSuccess?: (cart: Cart) => void;
  onCancel: () => void;
}

const ProductPriceSection: FC<ProductPriceSectionProps> = ({
  bounty,
  isLoading,
  onSuccess,
  onCancel,
  fullWidth = false,
}) => {
  const { product } = bounty;
  const { onboardingInfo } = useContext(LocationContext);

  if (isFlwr(product?.sortUnit)) {
    return (
      <Box mt={2}>
        <PriceVariantsSelector
          bounty={bounty}
          useType={onboardingInfo.useType}
          onSuccess={onSuccess}
        >
          {({ onAddToCart, isDisabled }) => (
            <ProductActions
              fullWidth={fullWidth}
              onCancel={onCancel}
              onSubmit={onAddToCart}
              disabled={isDisabled}
            />
          )}
        </PriceVariantsSelector>
      </Box>
    );
  }

  if (product?.altPrices?.tierPrices?.length > 0) {
    return (
      <Box display="flex" flexDirection="column" gap={2}>
        <TierPrices
          tierPrices={product?.altPrices?.tierPrices}
          useType={onboardingInfo.useType}
          product={product}
          shouldUseProductWeight={shouldUseProductWeight(product)}
        />
        <TierPriceSelector
          bounty={bounty}
          tierPrices={product?.altPrices?.tierPrices}
          shouldUseProductWeight={shouldUseProductWeight(product)}
          useType={onboardingInfo.useType}
          onSuccess={onSuccess}
        >
          {({ onAddToCart, isDisabled = false }) => (
            <ProductActions
              fullWidth={fullWidth}
              onCancel={onCancel}
              onSubmit={onAddToCart}
              disabled={isDisabled}
            />
          )}
        </TierPriceSelector>
      </Box>
    );
  }

  const SelectorComponent = shouldUseProductWeight(bounty?.product)
    ? InlineWeightSelector
    : QuantitySelector;

  return (
    <>
      <Typography variant="h5" mb={1} fontWeight={500}>
        {shouldUseProductWeight(bounty?.product) ? 'Weight' : 'Units'}
      </Typography>

      <SelectorComponent
        isLoading={isLoading}
        bounty={bounty}
        onSuccess={onSuccess}
        onCancel={onCancel}
        fullWidth={fullWidth}
      />
    </>
  );
};

export default ProductPriceSection;
