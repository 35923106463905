import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  ChangeEvent, FC, FormEvent, SyntheticEvent, useEffect, useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Root } from 'containers/SearchProducts/index.styled';
import MobileContent from 'containers/SearchProducts/MobileContent';
import SearchForm from 'containers/SearchProducts/SearchForm';

import { useKiosk } from 'hooks/useKiosk';
import { getProductsRoute } from 'hooks/useRouting';

import { ALL_CATEGORIES } from 'constants/general';

const SearchProducts: FC = () => {
  const { kioskMode } = useKiosk();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const [search, setSearch] = useState('');
  const categoryCode = searchParams?.get('category') || ALL_CATEGORIES;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const searchFilter = searchParams?.get('filter.q') || '';
  const showMobileFlow = isMobile && !kioskMode;

  useEffect(() => {
    setSearch(searchFilter);
  }, [searchFilter]);

  const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setSearch(value);
  };

  const handleSearch = (e: SyntheticEvent | FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const searchParam = search
      ? `&filter.q=${encodeURIComponent(search)}`
      : '';
    const route = getProductsRoute(`category=all${searchParam}`);
    navigate(route);
  };

  const handleClearSearch = () => {
    setSearch('');

    if (!showMobileFlow) {
      navigate(getProductsRoute(`category=${categoryCode}`));
    }
  };

  return (
    <Root kioskMode={kioskMode}>
      {showMobileFlow ? (
        <MobileContent
          search={search}
          setSearch={setSearch}
          onSearch={handleSearch}
          onChange={handleChange}
          onClear={handleClearSearch}
        />
      ) : (
        <SearchForm
          search={search}
          onSearch={handleSearch}
          onChange={handleChange}
          onClear={handleClearSearch}
        />
      )}
    </Root>
  );
};

export default SearchProducts;
