import { useEffect, useRef, useState } from 'react';

const useSlickNavigation = (settings: any) => {
  const sliderRef = useRef<any>(null);
  const [canNavigate, setCanNavigate] = useState(false);

  useEffect(() => {
    const checkCanNavigate = () => {
      if (sliderRef.current) {
        const screenWidth = window.innerWidth;

        const configIndex = settings.responsive?.findIndex(({ breakpoint }: { breakpoint: number }) => (
          screenWidth > breakpoint
        ));
        const slidesToShow = settings.responsive[configIndex - 1]?.settings?.slidesToShow || 0;
        const totalSlides = sliderRef.current.innerSlider.state.slideCount;
        const isVisible = totalSlides > slidesToShow;
        setCanNavigate(isVisible);
      }
    };

    checkCanNavigate();
    window.addEventListener('resize', checkCanNavigate);
    return () => window.removeEventListener('resize', checkCanNavigate);
  }, [sliderRef.current]);

  const handleNext = () => {
    const slickNext = sliderRef?.current?.slickNext;

    if (slickNext) {
      slickNext();
    }
  };

  const handlePrevious = () => {
    const slickPrev = sliderRef?.current?.slickPrev;

    if (slickPrev) {
      slickPrev();
    }
  };

  return {
    sliderRef,
    canNavigate,
    onNext: handleNext,
    onPrevious: handlePrevious,
  };
};

export default useSlickNavigation;
