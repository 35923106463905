import MuiDivider from '@mui/material/Divider';
import MuiSlider from '@mui/material/Slider';
import { alpha, styled } from '@mui/material/styles';

import NumberFormatField from 'components/NumberFormatField';

export const Divider = styled(MuiDivider)(({ theme }) => ({
  width: '10px',
  borderWidth: '1.5px',
  backgroundColor: alpha(theme.palette.common.black, 0.45),
}));

export const NumberField = styled(NumberFormatField)(({ theme }) => ({
  '& input': {
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
}));

export const Slider = styled(MuiSlider)(({ theme }) => ({
  '& .MuiSlider-rail': {
    backgroundColor: theme.palette.general.lightGrey13,
    opacity: 1,
    height: '6px',
  },

  '& .MuiSlider-thumb': {
    height: '32px',
    width: '32px',
    border: `3px solid ${theme.palette.general.lightGrey13}`,

    '&:hover': {
      boxShadow: '0 0 0 4px rgba(0, 0, 0, 0.16)',
    },

    '& .airbnb-bar': {
      height: 1,
      width: 9,
      backgroundColor: theme.palette.general.lightGrey13,
      marginLeft: 1,
      marginRight: 1,
    },
  },

  '& .MuiSlider-thumb:before': {
    border: `3px solid ${theme.palette.common.white}`,
  },

  '& .MuiSlider-track': {
    height: '8px',
  },
}));
