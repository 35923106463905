import { Box, Rating, Typography } from '@mui/material';
import { FC } from 'react';

import RatingProgress from 'containers/ProductReviews/RatingProgress';

import { ReviewStats } from 'types/review.interface';
import { getPercentage } from 'utils/ratingUtils';

interface ReviewOverviewProps {
  reviewStats: ReviewStats;
}

const ReviewOverview:FC<ReviewOverviewProps> = ({ reviewStats }) => (
  <Box>
    <Box display="flex" mb={4}>
      <Typography variant="h5" fontWeight={700}>{reviewStats.averageRating}</Typography>
      <Box ml={2}>
        <Rating value={reviewStats.averageRating} precision={0.5} readOnly />
        <Typography variant="body2">
          {reviewStats.total && reviewStats.total === 1 ? '1 Review' : `${reviewStats.total} Reviews`}
        </Typography>
      </Box>
    </Box>

    <Box>
      <RatingProgress star={5} percent={getPercentage(reviewStats.total, reviewStats.totalFiveStarRatings)} />
      <RatingProgress star={4} percent={getPercentage(reviewStats.total, reviewStats.totalFourStarRatings)} />
      <RatingProgress star={3} percent={getPercentage(reviewStats.total, reviewStats.totalThreeStarRatings)} />
      <RatingProgress star={2} percent={getPercentage(reviewStats.total, reviewStats.totalTwoStarRatings)} />
      <RatingProgress star={1} percent={getPercentage(reviewStats.total, reviewStats.totalOneStarRatings)} />
    </Box>
  </Box>
);

export default ReviewOverview;
