import { Box, Typography } from '@mui/material';
import { FC } from 'react';

import CategoryIcon from 'containers/Categories/CategoryIcon';
import { CategoryCard, CategoryWrapper } from 'containers/Categories/index.styled';

import { Category } from 'types/category.interface';

interface SubcategoriesProps {
  categories: Category[];
  selectedCategories: string[];
  onCategoryChanges: (category: string, index: number) => void;
  level: number;
  startLevel: number;
}

const Subcategories:FC<SubcategoriesProps> = ({
  categories,
  selectedCategories,
  onCategoryChanges,
  level,
  startLevel,
}) => (
  <Box pl={level === startLevel ? 0 : 2}>
    {categories.map((category) => (
      <div key={category.code}>
        <CategoryWrapper
          isActive={selectedCategories.includes(category.code)}
          disabled
          mb={1}
        >
          <CategoryCard
            isActive={selectedCategories.includes(category.code)}
            disabled
            onClick={() => onCategoryChanges(category.code, level)}
          >
            <CategoryIcon category={category} active={selectedCategories.includes(category.code)} />
            <Typography noWrap>
              {category?.name}
            </Typography>
          </CategoryCard>
        </CategoryWrapper>
        {selectedCategories.includes(category.code) && category.children && (
          <Subcategories
            categories={category.children}
            selectedCategories={selectedCategories}
            onCategoryChanges={onCategoryChanges}
            level={level + 1}
            startLevel={startLevel}
          />
        )}
      </div>
    ))}
  </Box>
);

export default Subcategories;
