import { Box, Grid, Typography } from '@mui/material';
import { isNil } from 'lodash';
import { FC, useMemo } from 'react';

import Brand from 'components/Brand';
import { FreeText, Root, StyledImage } from 'components/PreviewCartProduct/index.styled';
import ProductWeight from 'components/ProductWeight';

import { CART_PRODUCT_PREVIEW_COVER_SIZE } from 'constants/cover';
import { OnboardingUseType, WeightUnit } from 'constants/enums';
import { WEIGHT_UNIT_LABELS } from 'constants/labels';
import { ProductInfo } from 'types/cart.interface';
import { getProductCover } from 'utils/attachmentsUtils';
import { formatProductQuantity } from 'utils/formatters';
import { formatPrice, getPrice, isFree } from 'utils/priceUtils';
import { formatProductWeight, shouldUseProductWeight } from 'utils/productUtils';
import { getPublicUrlForImage } from 'utils/publicUrl';

interface PreviewCartProductProps {
  product: ProductInfo;
  useType?: OnboardingUseType;
  size?: 'small' | 'medium';
}

const PreviewCartProduct :FC<PreviewCartProductProps> = ({
  product,
  useType,
  size = 'small',
}) => {
  const {
    weight = '', sortWeight = 0, quantity = 0, sortUnit,
  } = product;
  const isSmall = size === 'small';
  const variant = isSmall ? 'body2' : 'subtitle1';
  const productWeight = weight ? +weight : null;
  const { price } = getPrice({
    useType,
    altPrices: product?.altPrices,
    productPrice: { mainPrice: product?.price || {} },
    productMsrp: product?.msrp,
    weight: shouldUseProductWeight(product)
      ? productWeight
      : sortWeight * quantity,
    sortUnit,
    quantity,
  });

  const coverUrl = useMemo(() => {
    const cover = getProductCover(
      product.imageUrl,
      CART_PRODUCT_PREVIEW_COVER_SIZE.w,
      CART_PRODUCT_PREVIEW_COVER_SIZE.h,
      product.imageCdn,
    );
    return cover.cdnUrl || cover.url;
  }, [product.id]);

  return (
    <Root isSmall={isSmall}>
      <StyledImage
        src={coverUrl || getPublicUrlForImage('images/placeholder_image.jpg')}
        alt={product?.name}
        isSmall={isSmall}
      />
      <Grid container width="100%">
        <Grid size={{ xs: 12, lg: isSmall ? 12 : 7 }}>
          <Box display="flex" flexDirection="column" gap={isSmall ? 0.5 : 1}>
            <Typography variant={variant} fontWeight={700} noWrap>
              {product?.name}
            </Typography>
            {!!product?.manufacturer && <Brand variant={variant} brand={product?.manufacturer} />}
            {product?.sortWeight && (
              <ProductWeight
                fontWeight={400}
                variant={variant}
                weight={formatProductWeight(product.sortWeight)}
                weightUnit={product?.weightUnit}
              />
            )}
          </Box>
        </Grid>
        <Grid size={{ xs: 12, lg: isSmall ? 12 : 5 }} justifyItems="flex-end">
          {isNil(product?.weight)
            ? (
              <Typography variant={variant} flexGrow={1} fontWeight={700} textAlign="right">
                {isFree(price) ? <FreeText>Free</FreeText> : formatPrice(price)}
                {' X '}
                {formatProductQuantity(quantity, product?.sortUnit)}
              </Typography>
            ) : (
              <Box display="flex" alignItems="center" gap={0.5}>
                <ProductWeight
                  fontWeight={700}
                  variant={variant}
                  flexGrow={1}
                  textAlign="right"
                  weight={product?.weight}
                  weightUnit={product?.weightUnit}
                />
                <Typography variant={variant} fontWeight={700}>
                  {`(${formatPrice(price)} / 1 ${WEIGHT_UNIT_LABELS[product?.weightUnit || WeightUnit.G]})`}
                </Typography>
              </Box>
            )}
        </Grid>
      </Grid>
    </Root>
  );
};

export default PreviewCartProduct;
