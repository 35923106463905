import CategoryDefaultIcon from '@mui/icons-material/Category';
import { Typography } from '@mui/material';
import { FC } from 'react';

import { Root, CardMedia } from 'containers/CategoriesCarousel/CategoryCard/index.styled';

import { Category } from 'types/category.interface';

interface CategoryCardProps {
  category: Category;
}

const CategoryCard: FC<CategoryCardProps> = ({ category }) => (
  <Root>
    {category?.imageUrl
      ? <CardMedia component="img" src={category?.imageUrl} />
      : <CategoryDefaultIcon />}
    <Typography variant="subtitle1" fontWeight={600} mb={2} textAlign="center">
      {category?.name}
    </Typography>
  </Root>
);

export default CategoryCard;
