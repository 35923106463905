import { AxiosResponse } from 'axios';

import { runGetApiRequest, runPostApiRequest, runDeleteApiRequest } from 'services/Api';

import { DELETE_POS_QUEUE, POS_QUEUE_ROUTE, POS_CREATE_CUSTOMER_ROUTE } from 'constants/apiRoutes';
import { POSQueue } from 'types/pos.interface';
import { formatRoute } from 'utils/formatters';
import { getClientDetailedInfo } from 'utils/storageUtils';
import { NewCustomerFormData } from 'validation/newCustomerSchema';

export const getPOSQueues = (): Promise<AxiosResponse<POSQueue[]>> => {
  const clientId = getClientDetailedInfo()?.clientInfo?.id;
  return runGetApiRequest(formatRoute(POS_QUEUE_ROUTE, { clientId }));
};

export const createPOSCustomer = (
  customer: NewCustomerFormData,
): Promise<AxiosResponse<NewCustomerFormData>> => {
  const clientId = getClientDetailedInfo()?.clientInfo?.id;
  return runPostApiRequest(
    formatRoute(POS_CREATE_CUSTOMER_ROUTE, { clientId }),
    customer,
  );
};

export const deletePOSQueue = (queueId: number): Promise<AxiosResponse<void>> => {
  const clientId = getClientDetailedInfo()?.clientInfo?.id;
  return runDeleteApiRequest(formatRoute(DELETE_POS_QUEUE, { clientId, queueId }));
};
