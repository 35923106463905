import { useEffect, useState } from 'react';

import { useKiosk } from 'hooks/useKiosk';

import { DeliveryMethod, PaymentMethod } from 'constants/enums';
import { DELIVERY_METHODS_OPTIONS, PAYMENT_METHODS_OPTIONS } from 'constants/options';
import { Option } from 'types/option.interface';
import { getShopSettingsFromStorage } from 'utils/storageUtils';
import { getStoreDeliveryMethods, getStorePaymentMethods } from 'utils/storeUtils';

const useCheckoutConfiguration = (
  { deliveryMethod }: { deliveryMethod: DeliveryMethod },
) => {
  const shopSettings = getShopSettingsFromStorage() || {};
  const { kioskMode } = useKiosk();
  const [deliveryMethods, setDeliveryMethods] = useState<Option[]>(DELIVERY_METHODS_OPTIONS);
  const [paymentMethods, setPaymentMethods] = useState<Option[]>(PAYMENT_METHODS_OPTIONS);

  useEffect(() => {
    formatDeliveryMethods();
  }, []);

  useEffect(() => {
    formatPaymentMethods();
  }, [deliveryMethod]);

  const formatDeliveryMethods = () => {
    if (!shopSettings?.deliveryMethods) {
      setDeliveryMethods(DELIVERY_METHODS_OPTIONS);
    } else {
      const allowedDeliveryMethods = getStoreDeliveryMethods();
      let clone: Option[] = JSON.parse(JSON.stringify(DELIVERY_METHODS_OPTIONS));
      clone = clone.filter(({ key }) => allowedDeliveryMethods.includes(key as DeliveryMethod));

      setDeliveryMethods(clone);
    }
  };

  const formatPaymentMethods = () => {
    const allowedPaymentMethods = getStorePaymentMethods({ storedDeliveryMethod: deliveryMethod, kioskMode });
    let clone: Option[] = JSON.parse(JSON.stringify(PAYMENT_METHODS_OPTIONS));

    clone = clone.filter(({ key }) => allowedPaymentMethods.includes(key as PaymentMethod));
    setPaymentMethods(clone);
  };

  return {
    deliveryMethods,
    paymentMethods,
  };
};

export default useCheckoutConfiguration;
