import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import { GenericTable } from 'components/Table';

export const StyledInventoryPaper = styled(Paper)(({ theme }) => ({
  maxHeight: 800,
  width: '100%',
  maxWidth: '95%',
  margin: `${theme.spacing(6.25)} auto`,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(2),
  boxShadow: theme.shadows[3],
}));

export const StyledInventoryDataGrid = styled(GenericTable)(() => ({
  border: 0,
  flexGrow: 1,
}));

export const StyledInventoryFiltersBox = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '80%',
  margin: `${theme.spacing(6.25)} auto`,
  display: 'flex',
  padding: theme.spacing(2),
  boxShadow: theme.shadows[3],
}));
