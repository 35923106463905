import {
  Box, Divider, Skeleton, useTheme,
} from '@mui/material';
import Slider from 'react-slick';

import ProductCardSkeleton from 'components/ProductCard/index.skeleton';

import { getProductsCarouselSettings } from 'utils/carousel';

const CategoryCarouselSkeleton = () => {
  const theme = useTheme();

  return (
    <Box mb={10}>
      <Skeleton animation="wave" variant="text" width={250} height={36} sx={{ mb: 2 }} />
      <Divider sx={{ my: 2.5 }} />
      <Slider {...getProductsCarouselSettings(theme)}>
        <ProductCardSkeleton />
        <ProductCardSkeleton />
        <ProductCardSkeleton />
        <ProductCardSkeleton />
        <ProductCardSkeleton />
        <ProductCardSkeleton />
        <ProductCardSkeleton />
      </Slider>
    </Box>
  );
};

export default CategoryCarouselSkeleton;
