import { Box, Typography } from '@mui/material';
import { FC } from 'react';

import { StyledLinearProgress } from 'containers/ProductReviews/RatingProgress/index.styled';

interface RatingProgressProps {
  star: number;
  percent: number;
}

const RatingProgress:FC<RatingProgressProps> = ({ star, percent }) => (
  <Box display="flex" alignItems="center">
    <Typography mr={2} minWidth="50px">
      {star === 1 ? '1 star' : `${star} stars`}
    </Typography>
    <StyledLinearProgress variant="determinate" value={percent} />
    <Typography ml={2} minWidth="50px">
      {percent}
      %
    </Typography>
  </Box>
);

export default RatingProgress;
