import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC } from 'react';

export const StyledBrand = styled(Typography)<{disabled?: boolean}>(({ theme, disabled }) => ({
  cursor: disabled ? 'cursor' : 'pointer',
  color: theme.palette.primary.main,
  '&:hover': {
    textDecoration: disabled ? 'none' : 'underline',
  },
})) as typeof Typography | FC<{ disabled?: boolean }>;
