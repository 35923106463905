import { Typography } from '@mui/material';
import { FC } from 'react';

import { Card, CategoryIconWrapper, CategoryImage } from 'containers/Categories/KioskCategoryCard/index.styled';

import { Category } from 'types/category.interface';

interface KioskCategoryCardProps {
  category: Category;
  isActive: boolean;
  onSelect: () => void;
}

const KioskCategoryCard:FC<KioskCategoryCardProps> = ({ category, isActive, onSelect }) => {
  const { name, imageUrl } = category;

  return (
    <Card
      onClick={onSelect}
      isActive={isActive}
    >
      <CategoryIconWrapper isActive={isActive}>
        {!!imageUrl && <CategoryImage component="img" alt={name} src={imageUrl} />}
      </CategoryIconWrapper>
      <Typography fontWeight={700}>
        {name}
      </Typography>
    </Card>
  );
};

export default KioskCategoryCard;
