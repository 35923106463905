import { Grid, SelectChangeEvent } from '@mui/material';
import { get } from 'lodash';
import { ChangeEvent, FC } from 'react';

import CustomDatePicker from 'components/CustomDatePicker';
import CustomDropdown from 'components/CustomDropdown';
import CustomInput from 'components/CustomInput';

import { FieldRuleType } from 'constants/enums';
import {
  DRIVER_LICENSE_EXP_DATE,
  DRIVER_LICENSE_NUMBER,
  DRIVER_LICENSE_OR_TRIBAL_NUMBER,
  DRIVER_LICENSE_OR_TRIBAL_STATE,
  DRIVER_LICENSE_STATE,
} from 'constants/fields';
import { DriverLicenseFormInfo } from 'types/checkout.interface';
import { isTribalIdAllowed } from 'utils/checkoutUtils';
import { getLocalUsStates } from 'utils/storageUtils';

interface DriverLicenseFormProps {
  onChange: (e: ChangeEvent<HTMLInputElement> | SelectChangeEvent) => void;
  onValueChange: (value: any, name: string) => void;
  driverLicense: DriverLicenseFormInfo;
  fieldErrors: Record<string, string>;
  sx?: object;
  fieldsRules?: Record<string, FieldRuleType>;
  kioskMode?: boolean;
  spacing?: number;
}

const DL_STATE = isTribalIdAllowed() ? DRIVER_LICENSE_OR_TRIBAL_STATE : DRIVER_LICENSE_STATE;
const DL_NUMBER = isTribalIdAllowed() ? DRIVER_LICENSE_OR_TRIBAL_NUMBER : DRIVER_LICENSE_NUMBER;

const DriverLicenseForm :FC<DriverLicenseFormProps> = ({
  onChange,
  onValueChange,
  driverLicense,
  fieldErrors,
  sx,
  fieldsRules,
  kioskMode = false,
  spacing = 4,
}) => {
  const usStates = getLocalUsStates() || [];

  return (
    <Grid container spacing={spacing} sx={sx}>
      {fieldsRules?.driverLicenseState !== FieldRuleType.Hidden && (
        <Grid size={{ xs: 12, sm: 6 }}>
          <CustomDropdown
            {...DL_STATE}
            variant="outlined"
            required={fieldsRules?.driverLicenseState === FieldRuleType.Required}
            size="small"
            options={usStates}
            onChange={(e) => onChange(e as SelectChangeEvent)}
            value={driverLicense?.state}
            error={!!get(fieldErrors, DL_STATE.name)}
            helperText={get(fieldErrors, DL_STATE.name)}
          />
        </Grid>
      )}
      {fieldsRules?.driverLicenseNumber !== FieldRuleType.Hidden && (
        <Grid size={{ xs: 12, sm: 6 }}>
          <CustomInput
            {...DL_NUMBER}
            size="small"
            required={fieldsRules?.driverLicenseNumber === FieldRuleType.Required}
            onChange={(e) => onChange(e as ChangeEvent<HTMLInputElement>)}
            value={driverLicense?.number || ''}
            error={!!get(fieldErrors, DL_NUMBER.name)}
            helperText={get(fieldErrors, DL_NUMBER.name)}
          />
        </Grid>
      )}
      {fieldsRules?.driverLicenseExpirationDate !== FieldRuleType.Hidden && (
        <Grid size={{ xs: 12, sm: 6 }}>
          <CustomDatePicker
            {...DRIVER_LICENSE_EXP_DATE}
            disableOpenPicker={kioskMode}
            onChange={onValueChange}
            required={fieldsRules?.driverLicenseExpirationDate === FieldRuleType.Required}
            size="small"
            value={driverLicense?.expDate}
            error={!!get(fieldErrors, DRIVER_LICENSE_EXP_DATE.name)}
            helperText={get(fieldErrors, DRIVER_LICENSE_EXP_DATE.name)}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default DriverLicenseForm;
