export enum LocalStorageKeys {
  ClientInfo = 'clientInfo',
  ShopSettings = 'shopSettings',
  Onboarding = 'onboardingInfo',
  CompanyLocations = 'companyLocations',
  USstates = 'usStates',
  Cart = 'cart',
  CheckoutDetails = 'checkoutDetails',
  BaseUrl = 'baseUrl',
  Uid = 'uid',
}

export enum BucketingPolicy {
  NONE = 'NONE',
  COMMON = 'COMMON',
  BY_TYPE = 'BY_TYPE',
  BY_COMPANY = 'BY_COMPANY',
  BY_COMPANY_AND_TYPE = 'BY_COMPANY_AND_TYPE',
}

export enum DisplayStyle {
  INLINE = 'INLINE',
  ATTACH = 'ATTACH',
}

export enum ChemicalKind {
  Cannabinoid = 'CANNABINOID',
  Terpene = 'TERPENE',
  Other = 'OTHER',
}

export enum AttachmentMeaning {
  PRODUCT = 'PRODUCT',
  PROMOTION = 'PROMOTION',
  RESUME = 'RESUME',
  badgeIcon = 'badgeIcon',
  OTHER = 'OTHER',
}

export enum AttachmentType {
  IMAGE = 'IMAGE',
  GIF = 'GIF',
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
  PDF = 'PDF',
  YOUTUBE = 'YOUTUBE',
  WEBSITE = 'WEBSITE',
  FILE = 'FILE',
  OTHER = 'OTHER',
}

export enum AttachmentVisibility {
  NONE = 'NONE',
  CREATOR = 'CREATOR',
  PARTICIPANTS = 'PARTICIPANTS',
  ALL = 'ALL',
}

export enum WeightUnit {
  FLWR = 'FLWR',
  FLWR_2 = 'FLWR_2',
  FLWR_4 = 'FLWR_4',
  EA = 'EA',
  G = 'G',
  KG = 'KG',
  LB = 'LB',
  OZ = 'OZ',
}

export enum InventoryPolicy {
  Deny = 'DENY',
  Continue = 'CONTINUE',
}

export enum PointCurrency {
  NONE = 'NONE',
  UNKNOWN = 'UNKNOWN',
  STAR = 'STAR',
  STAR2 = 'STAR2',
  STAR3 = 'STAR3',
  POINT = 'POINT',
  RATING = 'RATING',
  REPU = 'REPU',
  LIKE = 'LIKE',
  MIN = 'MIN',
  INVITE = 'INVITE',
}

export enum CartType {
  AMEX = 'AMEX',
  MC = 'MC',
  VISA = 'VISA',
  GENERIC = 'GENERIC'
}

export enum CurrencyTypes {
  NONE = 'NONE',
  UNKNOWN = 'UNKNOWN',
  USD = 'USD',
  CAD = 'CAD',
  EUR = 'EUR',
  RON = 'RON',
  MDL = 'MDL',
  GBP = 'GBP',
  PLN = 'PLN',
  GEL = 'GEL',
  ARS = 'ARS',
  AED = 'AED',
}

export enum PhoneStatus {
  UNVERIFIED = 'UNVERIFIED',
  VERIFIED = 'VERIFIED',
}

export enum PaymentMethod {
  CASH = 'CASH',
  DEBIT = 'DEBIT',
  BILLMYBANK = 'BILLMYBANK',
  CHARGEE = 'CHARGEE',
}

export enum DeliveryMethod {
  PICKUP = 'PICKUP',
  CURBSIDE = 'CURBSIDE',
  DELIVERY = 'DELIVERY',
  RETAIL = 'RETAIL',
  KIOSK = 'KIOSK',
}

export enum FilterType {
  SELECT = 'SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
  CATEGORY = 'CATEGORY',
  CHECK = 'CHECK',
  RANGE = 'RANGE',
  LOOKUP = 'LOOKUP',
}

export enum FilterCriteriaType {
  PRICE = 'PRICE',
  CANNABINOIDS = 'CANNABINOIDS',
  TAG = 'TAG'
}

export enum ProductUseType {
  is_adult_use = 'adult',
  is_medical_use = 'medical',
}

export enum ClientErrorCodes {
  missing = 'missing',
  wrong = 'wrong',
  unknown = 'unknown',
}

export enum QueueActions {
  UpdateUser = 'user_update',
}

export enum OrderStatus {
  NONE = 'NONE',
  NEW = 'NEW',
  PROPOSED = 'PROPOSED',
  PAYED = 'PAYED',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  READY_FOR_PICKUP = 'READY_FOR_PICKUP',
  SHIPPED = 'SHIPPED',
  MERCH_RECEIVED = 'MERCH_RECEIVED',
  SETTLED = 'SETTLED',
  CHARGEBACK = 'CHARGEBACK',
  DISPUTED = 'DISPUTED',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  NEGOTIATION = 'NEGOTIATION',
  DECLINED = 'DECLINED',
  CANCELLED = 'CANCELLED',
  UNKNOWN = 'UNKNOWN',
}

export enum LoginMethod {
  NONE = 'NONE',
  SSO = 'SSO',
  GOOGLE = 'GOOGLE',
  APPLE = 'APPLE',
  MICROSOFT = 'MICROSOFT',
  FACEBOOK = 'FACEBOOK',
  TWITTER = 'TWITTER',
  EMAIL = 'EMAIL',
  INTERNET = 'INTERNET',
}

export enum UnitOfMeasurementType {
  Grams = 'g',
  Kilograms = 'kg',
}

export enum PeriodType {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY',
  Last7d = 'LAST7D',
  Last30d = 'LAST30D',
  OneTime = 'ONETIME',
}

export enum AgeFilterType {
  Age18 = 18,
  Age21 = 21,
  Age0 = 0,
  None = 'none',
}

export enum AnonUsersType {
  AuthAtStart = 'AUTH_AT_START',
  AuthAtOrder = 'AUTH_AT_ORDER',
  AllowPurchase = 'ALLOW_PURCHASE',
}

export enum MedicalIdPromptType {
  AtStart = 'AT_START',
  AtOrder = 'AT_ORDER',
}

export enum CustomerBalanceType {
  InCart = 'IN_CART',
  None = 'NONE',
}

export enum OnboardingSteps {
  DeliveryType = 'deliveryType',
  UseType = 'useType',
  MedicalInfo = 'medicalInfo',
  OnlyMedicalInfo = 'onlyMedicalInfo',
}

export enum OperationStatus {
  Open = 'OPEN',
  Closed = 'CLOSED',
  AsMon = 'AS_MON',
  Common = 'COMMON',
  Inherit = 'INHERIT',
  Default = 'DEFAULT'
}
export enum ShopStatusType {
  Auto = 'AUTO',
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export enum OnlineShop {
  On = 'ON',
}

export enum ChatRoomType {
  Private = 'PRIVATE',
  Support = 'SUPPORT',
  PSG = 'PSG',
  Unknown = 'UNKNOWN',
}

export enum ChatState {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Dismissed = 'DISMISSED',
}

export enum LandingPageType {
  Default = 'DEFAULT',
  AllProducts = 'ALL_PRODUCTS',
}

export enum FieldRuleType {
  Hidden = 'HIDDEN',
  Required = 'REQUIRED',
  Optional = 'OPTIONAL',
}

export enum ProductSortOptions {
  Featured = '',
  PriceAsc = 'product.price_value$asc',
  PriceDesc = 'product.price_value$desc',
  NameAsc = 'product.name$asc',
  NameDesc = 'product.name$desc',
  WeightAsc = 'product.sort_weight$asc',
  WeightDesc = 'product.sort_weight$desc',
  ManufacturerAsc = 'product.manufacturer$asc',
  ManufacturerDesc = 'product.manufacturer$desc',
  Popularity = 'product.sort_popularity$desc',
}

export enum BackgroundArea {
  LandingPage = 'LANDING_PAGE',
}

export enum UrlType {
  Image = 'IMAGE',
  Video = 'VIDEO',
}

export enum ReceiptStatus {
  Created = 'CREATED',
  Scanned = 'SCANNED',
}

export enum ClientStatus {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
}

export enum TerminalPosition {
  Up = 'UP',
  Down = 'DOWN',
  Right = 'RIGHT',
  Left = 'LEFT',
  UpRight = 'UP_RIGHT',
  UpLeft = 'UP_LEFT',
  DownRight = 'DOWN_RIGHT',
  DownLeft = 'DOWN_LEFT',
}

export enum StartButtonPosition {
  None = 'NONE',
  UpLeft = 'UP_LEFT',
  Up = 'UP',
  UpRight = 'UP_RIGHT',
  Left = 'LEFT',
  Center = 'CENTER',
  Right = 'RIGHT',
  DownLeft = 'DOWN_LEFT',
  Down = 'DOWN',
  DownRight = 'DOWN_RIGHT',
  Unknown = 'UNKNOWN',
}

export enum LayoutType {
  Default = 'Default',
  Warning = 'Warning',
}

export enum ViewMode {
  Full = 'FULL',
  Simplified = 'SIMPLIFIED',
}

export enum AddressAutocompleteMode {
  None = 'NONE',
  Google = 'GOOGLE',
  Default = 'DEFAULT',
}

export enum TribalIdMode {
  None = 'NONE',
  AsDriverLicense = 'AS_DRIVERLICENSE',
}

export enum ShopPages {
  Products = 'products',
  Cart = 'cart',
  Orders = 'orders',
  DigitalReceipt = 'digital-receipt',
  Checkout = 'checkout',
  CheckoutComplete = 'checkout-complete',
  CheckoutPayment = 'checkout-payment',
}
export enum BannerType {
  Hero = 'HERO',
  Highlights = 'HIGHLIGHTS',
  Recommendations = 'RECOMMENDATIONS',
  Specials = 'SPECIALS',
}

export enum BountyType {
  Product = 'PRODUCT',
  Order = 'ORDER',
  Banner = 'BANNER',
  Promotion = 'PROMOTION',
  Currency = 'CURRENCY'
}

export enum AttachmentActions {
  OpenUrl = 'OPEN_URL',
  OpenProduct = 'OPEN_PRODUCT',
  Search = 'SEARCH',
}

export enum GoogleFields {
  LINE_1 = 'LINE_1',
  CITY = 'CITY',
  ZIP_CODE = 'ZIP_CODE',
  STATE = 'STATE',
}

export enum QueueAlias {
  AddComment = 'comment_add',
}

export enum CommentType {
  Bounty = 'BOUNTY',
  B = 'B',
}

export enum ChemicalViewMode {
  Hidden = 'HIDDEN',
  Tile = 'TILE',
  Details = 'DETAILS',
  TileDetails = 'TILE_DETAILS',
}

export enum SEOType {
  Landing = 'LANDING',
  ProductDescription = 'PRODUCT_DESC',
}

export enum SEOVariables {
  StoreName = '{{storeName}}',
  StoreAddress = '{{storeAddress}}',
  StoreWebsite = '{{storeWebsite}}',
  ProductName = '{{productName}}',
  ProductDescription = '{{productDescription}}',
  ProductCategory = '{{productCategory}}',
  ProductBrand = '{{productBrand}}',
  ProductCannabisType = '{{productCannabisType}}',
}

export enum ChatSupportState {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
}

export enum OnboardingUseType {
  is_adult_use = 'is_adult_use',
  is_medical_use = 'is_medical_use',
}

export enum FlwrQuantityVariant {
  eightOz = 'eightOz',
  quarterOz = 'quarterOz',
  halfOz = 'halfOz',
  oneOz = 'oneOz',
}

export enum PaymentSteps {
  HowToPay = 'HOW_TO_PAY',
  WaitForCard = 'WAIT_FOR_CARD',
  PaymentSuccessful = 'PAYMENT_SUCCESSFUL',
}

export enum CheckoutSections {
  ReservationDetails = 'ReservationDetails',
  ContactInfo = 'ContactInfo',
  PaymentMethod = 'PaymentMethod',
}

export enum ObjectFit {
  Contain = 'contain',
  Cover = 'cover',
}

export enum FontSize {
  Small = 'SMALL',
  Medium = 'MEDIUM',
  Large = 'LARGE',
}

export enum TextAlignment {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export enum InventoryFiltersType {
  GROUP = 'group',
  CONDITION = 'condition',
}

export enum InventoryGroupTypeOperator {
  AND = 'AND',
  OR = 'OR',
}

export enum InventoryConditionOperatorName {
  EQ = 'EQ',
  NOT_EQ = 'NOT_EQ',
  STARTS_WITH = 'STARTS_WITH',
  ENDS_WITH = 'ENDS_WITH',
  CONTAINS = 'CONTAINS',
  NOT_CONTAIN = 'NOT_CONTAIN',
  NULL = 'NULL',
  NOT_NULL = 'NOT_NULL',
  EMPTY = 'EMPTY',
  NOT_EMPTY = 'NOT_EMPTY',
  HAS_VALUE = 'HAS_VALUE',
  HAS_NO_VALUE = 'HAS_NO_VALUE',
}

export enum InventoryConditionItemName {
  ITEM = 'ITEM',
  CATEGORY = 'CATEGORY',
  BRAND = 'BRAND',
  VENDOR = 'VENDOR',
  HAS_INVENTORY= 'HAS_INVENTORY',
  STRAIN = 'STRAIN',
  AVAILABLE_FOR_RETAIL = 'AVAILABLE_FOR_RETAIL',
  STORED_UOM = 'STORED_UOM',
  BATCH = 'BATCH',
  AREA = 'AREA',
  STATUS = 'STATUS',
  QC_HOLD = 'QC_HOLD',
  HARVEST_BATCH = 'HARVEST_BATCH',
}

export enum InventoryConditionItemType {
  STRING = 'string',
  BOOLEAN = 'boolean',
  OPTIONS = 'options',
}

export enum UserType {
  Anon = 'ANON',
  Applicants = 'APPLICANTS',
  Company = 'COMPANY',
  Employee = 'EMPLOYEE',
  Employees = 'EMPLOYEES',
  None = 'NONE',
  Individual = 'INDIVIDUAL',
  Influencers = 'INFLUENCERS',
  Group = 'GROUP',
  GroupsRoot = 'GROUPS_ROOT',
  Unknown = 'UNKNOWN',
}

export enum ClientInfoStatus {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED',
}

export enum UserResultStatus {
  Deleted = 'DELETED',
  Rejected = 'REJECTED',
  Accepted = 'ACCEPTED',
  Pending = 'PENDING',
  Suspended = 'SUSPENDED'
}
