import { Box } from '@mui/material';
import { FC, useContext } from 'react';
import LazyLoad from 'react-lazyload';

import ShopContent from 'components/_ShopContent';
import CategoriesCarousel from 'containers/CategoriesCarousel';
import ProductsCarousel from 'containers/Dashboard/ProductsCarousel';
import ProductsCarouselSkeleton from 'containers/Dashboard/ProductsCarousel/index.skeleton';
import PromotionBanner from 'containers/PromotionBanner';
import Specials from 'containers/Specials';
import { CategoryContext } from 'context/CategoryContext';

import { useDocumentTitle } from 'hooks/useDocumentTitle';

import { Promotion } from 'types/promotion.interface';

interface DashboardProps {
  promotions: Promotion[];
  specials: Promotion[];
}

const Dashboard: FC<DashboardProps> = ({ promotions, specials }) => {
  useDocumentTitle({ title: 'Dashboard' });
  const { categories } = useContext(CategoryContext);

  return (
    <ShopContent>
      {promotions?.length > 0 && <PromotionBanner promotions={promotions} />}

      <Box mt={2.5} mb={10}>
        <CategoriesCarousel />
      </Box>

      <Specials specials={specials} />

      {categories.map((category) => (
        <LazyLoad
          key={category.code}
          once
          offset={50}
          placeholder={<ProductsCarouselSkeleton />}
        >
          <ProductsCarousel category={category} />
        </LazyLoad>
      ))}
    </ShopContent>
  );
};

export default Dashboard;
