import { SxProps } from '@mui/material';
import Typography from '@mui/material/Typography';
import { FC, ReactNode } from 'react';

import {
  Accordion,
  AccordionSummary,
} from 'components/CustomAccordionSection/index.styled';

interface CustomAccordionSectionProps {
  name: string;
  children: ReactNode | ReactNode[];
  icon?: ReactNode;
  defaultExpanded?: boolean;
  sx?: SxProps;
}

const CustomAccordionSection:FC<CustomAccordionSectionProps> = ({
  children,
  name,
  defaultExpanded = false,
  sx = {},
  icon,
}) => (
  <Accordion defaultExpanded={defaultExpanded} sx={sx}>
    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
      <Typography variant="h5" fontWeight={600}>{name}</Typography>
      {icon}
    </AccordionSummary>
    {children}
  </Accordion>
);

export default CustomAccordionSection;
