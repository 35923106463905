import { useContext, useEffect, useState } from 'react';

import Dashboard from 'containers/Dashboard';
import { LocationContext } from 'context/LocationContext';

import { getPromotions } from 'services/Client';

import { BannerType } from 'constants/enums';
import { Promotion } from 'types/promotion.interface';

const DashboardPage = () => {
  const { onboardingInfo } = useContext(LocationContext);
  const { storeId } = onboardingInfo;
  const [promotions, setPromotions] = useState<Record<string, Promotion[]>>({
    [BannerType.Hero]: [],
    [BannerType.Specials]: [],
  });

  useEffect(() => {
    if (storeId) {
      fetchPromotions();
    }
  }, [storeId]);

  const fetchPromotions = async () => {
    try {
      const [heroes, specials] = await Promise.all([
        getPromotions(BannerType.Hero, { locationPin: storeId }),
        getPromotions(BannerType.Specials, { locationPin: storeId }),
      ]);
      setPromotions({
        [BannerType.Hero]: heroes.data || [],
        [BannerType.Specials]: specials.data || [],
      });
    } catch (e) {
      setPromotions({
        [BannerType.Hero]: [],
        [BannerType.Specials]: [],
      });
    }
  };

  return <Dashboard promotions={promotions[BannerType.Hero]} specials={promotions[BannerType.Specials]} />;
};

export default DashboardPage;
