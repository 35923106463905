import {
  FormControl, FormHelperText, FormLabel, SxProps, Typography,
} from '@mui/material';
import { FC, ChangeEvent } from 'react';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { StyledWrapper } from 'components/CustomPhoneInput/index.styled';

import { useKiosk } from 'hooks/useKiosk';

import { PhoneInterface } from 'types/phone.interface';

interface CustomPhoneInputProps {
  fullWidth?: boolean;
  required?: boolean;
  label?: string;
  onChange: (phoneNumber: any, name: string) => unknown;
  disabled?: boolean;
  name: string;
  phoneNumber: PhoneInterface;
  error?: boolean;
  helperText?: string;
  sx?: SxProps;
}

const CustomPhoneInput :FC<CustomPhoneInputProps> = (props) => {
  const {
    label, fullWidth = false, required, name, phoneNumber, onChange, error, sx, helperText, ...remainingProps
  } = props;

  const { phoneNumber: rawNumber, phoneStatus } = phoneNumber;
  const { kioskMode } = useKiosk();

  const handleChanges = (
    value: string,
    data: CountryData,
    event: ChangeEvent<HTMLInputElement>,
    formattedValue: string,
  ) => {
    const phoneNumber = {
      phoneNumber: formattedValue === '+' ? '' : formattedValue,
      countryCode: data?.countryCode,
      phoneStatus,
    };

    onChange(phoneNumber, name);
  };

  return (
    <StyledWrapper hasError={error} kioskMode={kioskMode} sx={sx}>
      <FormControl component="div" fullWidth={fullWidth}>
        {label && (
          <FormLabel required={required}>
            <Typography component="span" color="textPrimary">
              {label}
            </Typography>
          </FormLabel>
        )}
        <PhoneInput
          {...remainingProps}
          inputProps={{
            name,
            id: `input-${name}`,
          }}
          specialLabel=""
          value={rawNumber}
          country="us"
          onChange={handleChanges}
          onlyCountries={['us']}
          // disableCountryGuess
        />
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </StyledWrapper>
  );
};

export default CustomPhoneInput;
