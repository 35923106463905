import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';

export const Drawer = styled(MuiDrawer)(({ theme }) => ({
  '& > .MuiPaper-root': {
    width: '700px',
    paddingTop: theme.spacing(4),

    [theme.breakpoints.down(700)]: {
      width: '100%',
    },
  },
}));
