import MuiDialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';

export const Content = styled('div')(({ theme }) => ({
  minHeight: '300px',
  width: '350px',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    marginTop: theme.spacing(4),
  },
}));

export const Dialog = styled(MuiDialog)(({ theme }) => ({
  '.MuiPaper-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '450px',

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));
