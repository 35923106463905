import { useTheme } from '@mui/material';
import { FC } from 'react';
import Slider from 'react-slick';

import SectionHeader from 'components/SectionHeader';
import SpecialCard from 'containers/Specials/SpecialCard';

import useSlickNavigation from 'hooks/useSlickNavigation';

import { Promotion } from 'types/promotion.interface';
import { getSpecialsCarouselSettings } from 'utils/carousel';

interface SpecialsProps {
  specials: Promotion[];
}

const Specials:FC<SpecialsProps> = ({ specials }) => {
  const theme = useTheme();
  const settings = getSpecialsCarouselSettings(theme);
  const {
    sliderRef,
    canNavigate,
    onNext,
    onPrevious,
  } = useSlickNavigation(settings);

  if (!specials?.length) {
    return null;
  }

  return (
    <div>
      <SectionHeader
        canNavigate={canNavigate}
        title="Specials"
        onNext={onNext}
        onPrevious={onPrevious}
      />
      <Slider {...settings} ref={sliderRef}>
        {specials?.map((special, index) => (
          <SpecialCard
            key={index}
            special={special}
          />
        ))}
      </Slider>
    </div>
  );
};

export default Specials;
