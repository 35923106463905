import {
  Grid, Box, useTheme,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  useContext, Suspense, lazy, useMemo, FC,
} from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import ShopContent from 'components/_ShopContent';
import EmptyState from 'components/EmptyState';
import ProductCardSkeleton from 'components/ProductCard/index.skeleton';
import Categories from 'containers/Categories';
import SubcategoriesSection from 'containers/Categories/SubcategoriesSection';
import ProductDetailsPage from 'containers/ProductDetailsPage';
import FiltersSummary from 'containers/Products/FiltersSummary';
import { SortContainer } from 'containers/Products/index.styled';
import ProductFilters from 'containers/Products/ProductFilters';
import ProductSort from 'containers/Products/ProductSort';
import PromotionBanner from 'containers/PromotionBanner';
import { CategoryContext } from 'context/CategoryContext';
import { LocationContext } from 'context/LocationContext';
import { SystemContext } from 'context/SystemContext';

import useBountyDetails from 'hooks/useBountyDetails';
import useFilters from 'hooks/useFilters';

import { BannerType, ProductSortOptions, ViewMode } from 'constants/enums';
import { Bounty } from 'types/bounty.interface';
import { Promotion } from 'types/promotion.interface';

const ProductCard = lazy(() => import('components/ProductCard'));

interface ProductsProps {
  promotions?: Record<BannerType, Promotion[]>;
  onLoadProducts: () => void;
  skeletonProducts: number[];
  products: Bounty[] | null;
  hasMore: boolean;
  isLoading: boolean;
  isLoadingMore: boolean;
  onSort: (newSort: ProductSortOptions) => void;
  sortOption: ProductSortOptions;
}
const Products: FC<ProductsProps> = ({
  promotions,
  onLoadProducts,
  skeletonProducts,
  products,
  hasMore,
  isLoadingMore,
  isLoading,
  onSort,
  sortOption,
}) => {
  const theme = useTheme();
  const matchesScreenSizeMd = useMediaQuery(theme.breakpoints.down('md'));

  const { shopSettings } = useContext(SystemContext);
  const { onboardingInfo } = useContext(LocationContext);
  const { selectors } = useContext(CategoryContext);
  const {
    isLoading: areFiltersLoading, activeFilters, filters, ...remainingFilterProps
  } = useFilters();
  const hasFilters = useMemo(() => areFiltersLoading || filters?.length > 0, [areFiltersLoading, filters]);
  const isDescriptionVisible = shopSettings?.viewConfigs?.PRODUCT_DESCRIPTION?.viewMode === ViewMode.Full;
  const heroes = promotions?.[BannerType.Hero];
  const { selectedBounty, openBountyDetails, closeBountyDetails } = useBountyDetails();
  const showLeftSection = Boolean(hasFilters || selectors?.length > 1);

  return (
    <Box position="relative">
      <Categories />
      <ShopContent>
        {(heroes && heroes?.length > 0) && <PromotionBanner promotions={heroes} />}
        <Grid container spacing={2}>
          {!matchesScreenSizeMd && showLeftSection && (
            <Grid size={{ xs: 12, md: 3, lg: 2 }}>
              {selectors?.length > 1 && <SubcategoriesSection />}
              {hasFilters && (
                <ProductFilters
                  {...remainingFilterProps}
                  isLoading={areFiltersLoading}
                  filters={filters}
                  activeFilters={activeFilters}
                />
              )}
            </Grid>
          )}

          <Grid size={{ xs: 12, md: showLeftSection ? 9 : 12, lg: showLeftSection ? 10 : 12 }}>
            <Grid container spacing={1} mb={2}>
              {hasFilters && matchesScreenSizeMd && (
                <Grid size={{ xs: 4, sm: 4 }}>
                  <ProductFilters
                    {...remainingFilterProps}
                    isLoading={areFiltersLoading}
                    filters={filters}
                    activeFilters={activeFilters}
                  />
                </Grid>
              )}
              <Grid size={{ xs: hasFilters ? 8 : 12, sm: hasFilters ? 8 : 12, md: 12 }}>
                <SortContainer>
                  <FiltersSummary
                    isLoading={areFiltersLoading}
                    activeFilters={activeFilters}
                    filters={filters}
                    removeFilter={remainingFilterProps.removeFilter}
                  />
                  <ProductSort value={sortOption} onChange={onSort} />
                </SortContainer>
              </Grid>
            </Grid>

            {!isLoading && !products?.length ? (
              <EmptyState message="No products found!" />
            ) : (
              <InfiniteScroll
                pageStart={0}
                loadMore={onLoadProducts}
                hasMore={hasMore}
              >
                <Grid container spacing={2}>
                  {!isLoading && products && products.map((bounty, index) => (
                    <Grid
                      key={`${index}-${bounty.id}`}
                      size={{
                        xs: 12,
                        sm: 6,
                        md: 4,
                        lg: 3,
                      }}
                    >
                      <Suspense fallback={<ProductCardSkeleton />}>
                        <ProductCard
                          openDetails={() => openBountyDetails(bounty)}
                          bounty={bounty}
                          hasAddButton
                          isDescriptionVisible={isDescriptionVisible}
                          useType={onboardingInfo?.useType}
                        />
                      </Suspense>
                    </Grid>
                  ))}

                  {(isLoading || isLoadingMore) && skeletonProducts.map((loader) => (
                    <Grid
                      key={`productSkeleton-${loader}`}
                      size={{
                        xs: 12,
                        sm: 6,
                        md: 4,
                        lg: 3,
                      }}
                    >
                      <ProductCardSkeleton />
                    </Grid>
                  ))}
                </Grid>
              </InfiniteScroll>
            )}
          </Grid>
        </Grid>
      </ShopContent>
      <ProductDetailsPage
        bountyDetails={selectedBounty}
        onClose={closeBountyDetails}
        isOpen={!!selectedBounty}
      />
    </Box>
  );
};

export default Products;
