import MuiCardMedia from '@mui/material/CardMedia';
import { styled } from '@mui/material/styles';
import { FC } from 'react';

export const CardMedia = styled(
  MuiCardMedia,
  { shouldForwardProp: (props) => props !== 'isActive' && props !== 'size' },
)<{ isActive?: boolean; size: number }>(({ isActive, size }) => ({
  height: `${size}px`,
  width: `${size}px`,
  //  default image is black
  filter: `invert(${isActive ? '100%' : '0%'})`,
})) as typeof MuiCardMedia | FC<{ isActive?: boolean; size: number }>;
