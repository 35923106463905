import { Box } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import { alpha, styled } from '@mui/material/styles';

import CustomButton from 'components/CustomButton';

export const Root = styled('div')(() => ({
  height: '220px',
  backgroundColor: 'transparent',
}));

export const ImageWrapper = styled('div')(() => ({
  height: '200px',
  borderRadius: '12px',
  position: 'relative',

  video: {
    objectFit: 'cover',
    borderRadius: '12px',
  },
}));

export const StyledCardMedia = styled(CardMedia)(() => ({
  borderRadius: '8px',
  height: '200px',
  cursor: 'pointer',
  objectFit: 'cover',
})) as typeof CardMedia;

export const OverlayContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 1,
  width: '100%',
  height: '100%',
  borderRadius: '12px',
  backgroundColor: alpha(theme.palette.common.black, 0.5),
}));

export const HeadlineContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  top: 0,
  flexDirection: 'column',
  justifyContent: 'center',
  zIndex: 2,
  width: '100%',
  height: '100%',
  color: theme.palette.common.white,
}));

export const BorderContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 2,
  width: `calc(100% - ${theme.spacing(2)})`,
  height: `calc(100% - ${theme.spacing(2)})`,
  borderRadius: '12px',
  margin: theme.spacing(1),
  border: `4px solid ${theme.palette.common.white}`,
}));

export const BuyButton = styled(CustomButton)(({ theme }) => ({
  position: 'absolute',
  bottom: -20,
  zIndex: 3,
  left: '50%',
  transform: 'translateX(-50%)',
  padding: theme.spacing(1, 7),
  border: `4px solid ${theme.palette.common.white}`,
  whiteSpace: 'nowrap',
}));
