import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const NewCustomerSection = styled(Box)({
  flex: 1,
});

export const NewCustomerRow = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const NewCustomerFormBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
}));

export const ButtonsBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  display: 'flex',
  justifyContent: 'space-between',
}));
