import { Select } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SortWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '58px',
  gap: theme.spacing(1),

  [theme.breakpoints.only('xs')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',

    '& .MuiTypography-root': {
      display: 'none',
    },
  },

  [theme.breakpoints.down(370)]: {
    height: 'auto',
  },
}));

export const Dropdown = styled(Select)(({ theme }) => ({
  borderRadius: '22px',
  backgroundColor: theme.palette.general.lightGrey5,
  height: '34px',

  '&.Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.border.main}`,
    },
  },
  '&:hover:not(&.Mui-focused, &.Mui-disabled)': {
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.border.main}`,
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${theme.palette.border.main}`,
  },

  [theme.breakpoints.down(370)]: {
    width: '-webkit-fill-available',

    '& .MuiSelect-select': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      display: 'initial',
      paddingTop: theme.spacing(1),
    },
  },
}));
