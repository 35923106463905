import HomeIcon from '@mui/icons-material/Home';
import { Tab, Tabs, Typography } from '@mui/material';
import {
  FC, useContext,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import CategoryIcon from 'containers/Categories/CategoryIcon';
import { CategoryCard, CategoryWrapper } from 'containers/Categories/index.styled';
import { Root } from 'containers/Categories/MainCategories/index.styled';
import { CategoryContext, CategoryDispatchContext } from 'context/CategoryContext';

import { getHomeRoute } from 'hooks/useRouting';

import { ALL_CATEGORIES } from 'constants/general';

function a11yProps(code: string) {
  return {
    id: `tab-${code}`,
    'aria-controls': `tab-${code}`,
  };
}

const MainCategories:FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const bountyIds = searchParams.getAll('bountyIds');
  const { categories, selectedCategories } = useContext(CategoryContext);
  const { onCategoryChanges } = useContext(CategoryDispatchContext);
  const mainCategory = selectedCategories[0] || (!bountyIds?.length ? ALL_CATEGORIES : 0);

  const handleChanges = (code: string) => {
    if (code === 'home') {
      navigate(getHomeRoute());
    } else {
      onCategoryChanges(code, 0);
    }
  };

  return (
    <Root>
      <Tabs
        value={categories?.length ? mainCategory : 0}
        variant="scrollable"
        scrollButtons
        aria-label="main categories"
        selectionFollowsFocus
      >
        <Tab
          label={(
            <CategoryCard
              disabled
              sx={{ width: 'fit-content' }}
              onClick={() => handleChanges('home')}
            >
              <HomeIcon />
              <Typography>
                Home
              </Typography>
            </CategoryCard>
             )}
          {...a11yProps('home')}
        />
        <Tab
          value={ALL_CATEGORIES}
          label={(
            <CategoryWrapper
              isActive={mainCategory === ALL_CATEGORIES}
              disabled
            >
              <CategoryCard
                onClick={() => handleChanges(ALL_CATEGORIES)}
                isActive={mainCategory === ALL_CATEGORIES}
                disabled
              >
                <Typography noWrap>
                  All items
                </Typography>
              </CategoryCard>
            </CategoryWrapper>
             )}
          {...a11yProps(ALL_CATEGORIES)}
        />
        {categories?.map((category) => (
          <Tab
            value={category.code}
            key={category?.code}
            label={(
              <CategoryWrapper
                key={category.code}
                isActive={mainCategory === category.code}
                disabled
              >
                <CategoryCard
                  isActive={mainCategory === category.code}
                  disabled
                  onClick={() => handleChanges(category.code)}
                >
                  <CategoryIcon category={category} active={mainCategory === category.code} />
                  <Typography noWrap>
                    {category?.name}
                  </Typography>
                </CategoryCard>
              </CategoryWrapper>
                )}
            {...a11yProps(category?.code)}
          />
        ))}
      </Tabs>
    </Root>
  );
};

export default MainCategories;
