import {
  Box,
  Fade,
  SelectChangeEvent,
} from '@mui/material';
import { ChangeEvent, FC } from 'react';

import DriverLicenseForm from 'components/DriverLicenseForm';
import MedicalInfoForm from 'components/MedicalInfoForm';
import SectionTitle from 'components/SectionTitle';
import UserInfoForm from 'components/UserInfoForm';

import { FieldRuleType } from 'constants/enums';
import { USE_TYPE_OPTIONS } from 'constants/options';
import { PlaceOrderInfo } from 'types/checkout.interface';
import { showCheckoutForms } from 'utils/checkoutUtils';

interface KioskCheckoutFormProps {
  onValueChange: (value: any, name: string) => void;
  checkoutForm: PlaceOrderInfo;
  fieldErrors: Record<string, string>;
  useType: string;
  checkoutFields?: Record<string, FieldRuleType>;
}

const KioskCheckoutForm :FC<KioskCheckoutFormProps> = ({
  onValueChange,
  checkoutForm,
  fieldErrors,
  useType,
  checkoutFields = {},
}) => {
  const {
    customer, medical, driverLicense,
  } = checkoutForm || {};
  const { showUserInfoForm, showDriverLicenseForm } = showCheckoutForms(checkoutFields);
  const showMedicalForm = useType === USE_TYPE_OPTIONS[1].key;

  const handleChange = ({
    target: { value, name },
  }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
    onValueChange(value, name);
  };

  return (
    <Box width="100%">
      {showUserInfoForm && (
        <>
          <SectionTitle title="Contact Info" sx={{ mb: 2 }} />
          <UserInfoForm
            onValueChange={onValueChange}
            customer={customer}
            fieldErrors={fieldErrors}
            fieldsRules={checkoutFields}
            kioskMode
          />
        </>
      )}

      {showDriverLicenseForm && (
        <DriverLicenseForm
          kioskMode
          onChange={handleChange}
          onValueChange={onValueChange}
          driverLicense={driverLicense}
          fieldErrors={fieldErrors}
          sx={{ mt: 4 }}
          fieldsRules={checkoutFields}
        />
      )}

      {showMedicalForm && (
        <Fade
          in={showMedicalForm}
          timeout={{ enter: 1000, exit: 1000 }}
        >
          <div>
            <SectionTitle
              title="Medical Info"
              sx={{ mt: showUserInfoForm || showDriverLicenseForm ? 5 : 0, mb: 2 }}
            />
            <MedicalInfoForm
              kioskMode
              onChange={handleChange}
              onValueChange={onValueChange}
              medical={medical}
              fieldErrors={fieldErrors}
            />
          </div>
        </Fade>
      )}
    </Box>
  );
};

export default KioskCheckoutForm;
