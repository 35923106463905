import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import KioskInactivityChecker from 'containers/KioskInactivityChecker';
import { CartProvider } from 'context/CartContext';
import { CategoryProvider } from 'context/CategoryContext';
import { LocationContextProvider } from 'context/LocationContext';
import { PaymentProvider } from 'context/PaymentContext';

import { useKiosk } from 'hooks/useKiosk';

const GlobalProvider:FC = () => {
  const { managementMode, kioskMode } = useKiosk();

  return (
    <LocationContextProvider>
      <CartProvider>
        <CategoryProvider>
          <PaymentProvider>
            <Outlet />
            {kioskMode && !managementMode && <KioskInactivityChecker />}
          </PaymentProvider>
        </CategoryProvider>
      </CartProvider>
    </LocationContextProvider>
  );
};

export default GlobalProvider;
