import { EmailAuthProvider, User as FbUser } from 'firebase/auth';
import { get, query } from 'firebase/database';
import { auth as authUi } from 'firebaseui';
import {
  FC, useContext, useMemo, useState,
} from 'react';
import { toast } from 'react-toastify';

import Loader from 'components/Loader';
import StorePicker from 'components/StorePicker';
import { BlurryDialog, Content } from 'containers/LoginAdmin/index.styled';
import useUserStoreAccess from 'containers/LoginAdmin/useUserStoreAccess';
import StyledFirebaseAuth from 'containers/StyledFirebaseAuth';
import { UserContext, UserDispatchContext } from 'context/UserContext';

import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { getClientsRef, getUserInfo, updateUserInfo } from 'services/User';

import { auth } from 'config/firebase';
import { ClientInfoStatus, UserResultStatus, UserType } from 'constants/enums';
import { ADMIN_PIN } from 'constants/general';
import { ClientInfoInterface, DetailedClientInfo } from 'types/clientInfo.interface';
import { getLegalUrls } from 'utils/clientUtils';
import { setClientInfoToStorage } from 'utils/storageUtils';
import { reportAlert } from 'utils/utils';

const LoginAdmin:FC = () => {
  const { user, userInfo: savedUserInfo } = useContext(UserContext);
  const { setUserInfo } = useContext(UserDispatchContext);

  const { handleTrackSignUp } = useGoogleAnalytics();
  const [isLoading, setIsLoading] = useState(false);

  const { shopOptions } = useUserStoreAccess();

  const updateUser = async (fbUser: FbUser, clientId: string) => {
    const payload = {
      meta: {
        userId: fbUser.uid,
        email: fbUser.email,
        clientId,
        restEvent: true,
      },
    };
    return updateUserInfo(payload);
  };

  const handleLogin = async (fbUser: FbUser) => {
    try {
      setIsLoading(true);
      const { data: userInfo } = await getUserInfo();
      const dataSnapshot = await get(query(getClientsRef(userInfo.clientId)));
      const clientInfo = dataSnapshot.val() as ClientInfoInterface;

      if (clientInfo?.validateEmail && !user?.emailVerified) {
        toast.warning('Please verify your email first!');
        setTimeout(() => auth.signOut(), 1000);
      } else {
        if (userInfo.userType === UserType.Individual || userInfo.clientId === ADMIN_PIN) {
          toast.warning('This email address doesn\'t match an existing account.');
          setTimeout(() => auth.signOut(), 1000);
          return;
        }

        if (userInfo.block) {
          toast.warning('User does not have access privileges. Please login via mobile app');
          setTimeout(() => auth.signOut(), 1000);
          return;
        }
        setClientInfoToStorage({ clientInfo } as DetailedClientInfo);
        const { data: userResult } = await updateUser(fbUser, userInfo?.clientId);

        if (userResult.userStatus === UserResultStatus.Pending) {
          toast.warning('You account is pending approval.');
          setTimeout(() => auth.signOut(), 1000);
          return;
        }

        if (clientInfo?.status === ClientInfoStatus.Disabled) {
          // eslint-disable-next-line max-len
          toast.warning('Access denied: Your account has been locked from the Admin Console. Please contact support for assistance.');
          setTimeout(() => auth.signOut(), 1000);
          return;
        }

        setUserInfo(userInfo);
      }
    } catch (e) {
      setTimeout(() => auth.signOut(), 1000);
    } finally {
      setIsLoading(false);
    }
  };

  const firebaseUiWidget = useMemo(() => ({
    signInFlow: 'popup',
    ...getLegalUrls(),
    signInOptions: [{
      provider: EmailAuthProvider.PROVIDER_ID,
      disableSignUp: {
        status: true,
      },
    }],
    callbacks: {
      signInSuccessWithAuthResult: ({ user }: { user: FbUser}) => {
        handleTrackSignUp();
        handleLogin(user);
        return false;
      },
      signInFailure: (error: authUi.AuthUIError) => {
        if (error.message) {
          toast.error(error.message);
        }

        reportAlert(`login failed: ${JSON.stringify(error)}`);
        Promise.resolve();
      },
    },
  }), []);

  return (
    <BlurryDialog
      open
      aria-describedby="signin"
      slotProps={{
        paper: {
          sx: {
            borderRadius: '16px', py: 3,
          },
        },
      }}
    >
      <Content>
        {savedUserInfo?.userType && savedUserInfo?.userType !== UserType.Individual
          ? (
            <StorePicker
              onClose={() => {}}
              shopOptions={shopOptions}
              isLoading={isLoading}
            />
          ) : (
            <StyledFirebaseAuth
              uiConfig={firebaseUiWidget}
              firebaseAuth={auth}
            />
          )}
        {isLoading && <Loader />}
      </Content>
    </BlurryDialog>
  );
};

export default LoginAdmin;
