import { useContext } from 'react';

import { UserContext } from 'context/UserContext';

import { UserType } from 'constants/enums';
import { getClientDetailedInfo } from 'utils/storageUtils';

export const useKiosk = () => {
  const { userInfo } = useContext(UserContext);
  const detailedClientData = getClientDetailedInfo();
  const kioskMode = detailedClientData?.kioskMode
    || Boolean(userInfo.userType && userInfo.userType !== UserType.Individual)
    || false;

  return {
    kioskMode,
    managementMode: Boolean(userInfo.userType && userInfo.userType !== UserType.Individual),
  };
};
