import { isEmpty } from 'lodash';
import { FC, useContext, useMemo } from 'react';

import CartProduct from 'containers/CartProduct';
import { CartContext } from 'context/CartContext';

import { ProductInfo } from 'types/cart.interface';

interface ProductsProps {
  updatedProducts: Record<string, ProductInfo>;
  summaryFailedDetails: { hasFailed: boolean; productSkus: string[] };
  outlined?: boolean;
}
const Products:FC<ProductsProps> = ({
  updatedProducts,
  summaryFailedDetails,
  outlined,
}) => {
  const cart = useContext(CartContext);
  const products = useMemo(() => Object.values(cart?.products) || [], [cart?.products]);

  return (
    products?.map((product) => {
      const isProductUpdated = !isEmpty(updatedProducts?.[product?.id] || {});
      const newRawQuantity = updatedProducts?.[product?.id]?.quantity || 0;
      const updatedQuantity = !Number.isNaN(newRawQuantity) ? Math.max(0, newRawQuantity) : 0;
      const showErrorMessage = summaryFailedDetails?.productSkus?.length
        ? summaryFailedDetails?.productSkus?.some((sku) => sku === product?.productSku)
        : false;

      return (
        <CartProduct
          key={product?.id}
          outlined={outlined}
          product={product}
          isProductUpdated={isProductUpdated}
          updatedQuantity={updatedQuantity}
          showErrorMessage={showErrorMessage}
        />
      );
    })
  );
};

export default Products;
