export const capitalize = (string: string) => {
  if (!string) return string;

  return string
    .split(' ')
    .map((part) => capitalizeOneString(part))
    .join(' ');
};

export const capitalizeOneString = (string: string) => {
  const [first, ...rest] = string;
  return first.toUpperCase() + rest.join('').toLowerCase();
};
