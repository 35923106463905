import {
  DataGrid,
  DataGridProps,
  GridColDef,
  GridRowId,
} from '@mui/x-data-grid';

type GenericTableProps<T> = DataGridProps & {
  rows: T[];
  columns: GridColDef[];
  getRowId?: (row: T) => GridRowId;
  pageSize?: number;
};

export const GenericTable = <T, >(
  {
    rows,
    columns,
    getRowId,
    pageSize = 100,
    ...props
  }: GenericTableProps<T>,
) => (
  <DataGrid
    rows={rows}
    columns={columns}
    getRowId={getRowId || ((row: T) => JSON.stringify(row))}
    pageSizeOptions={[pageSize]}
    getRowHeight={() => 'auto'}
    initialState={{
      pagination: {
        paginationModel: { pageSize, page: 0 },
      },
    }}
    {...props}
  />
  );
