import {
  DeliveryMethod,
  FlwrQuantityVariant,
  InventoryConditionItemName,
  InventoryConditionOperatorName,
  PaymentMethod,
  PeriodType,
  WeightUnit,
} from 'constants/enums';

export const ORDER_DELIVERY_METHOD = {
  [DeliveryMethod.DELIVERY]: 'Delivery',
  [DeliveryMethod.PICKUP]: 'Pickup',
  [DeliveryMethod.RETAIL]: 'Retail',
  [DeliveryMethod.KIOSK]: 'Kiosk',
  [DeliveryMethod.CURBSIDE]: 'Curbside Pickup',
};

export const PAYMENT_METHOD_LABELS = {
  [PaymentMethod.CASH]: 'Cash',
  [`${PaymentMethod.CASH}_SANDBOX`]: 'Cash - Sandbox',
  [PaymentMethod.BILLMYBANK]: 'Bill My Bank',
  [`${PaymentMethod.BILLMYBANK}_SANDBOX`]: 'Bill My Bank - Sandbox',
  [PaymentMethod.DEBIT]: 'Debit',
  [`${PaymentMethod.DEBIT}_SANDBOX`]: 'Debit - Sandbox',
  [PaymentMethod.CHARGEE]: 'Chargee',
  [`${PaymentMethod.CHARGEE}_SANDBOX`]: 'Chargee - Sandbox',
};

export const BALANCE_PERIOD_LABELS = {
  [PeriodType.Daily]: 'daily',
  [PeriodType.Weekly]: 'weekly',
  [PeriodType.Monthly]: 'monthly',
  [PeriodType.Yearly]: 'yearly',
  [PeriodType.Last7d]: 'last 7 days',
  [PeriodType.Last30d]: 'last 30 days',
  [PeriodType.OneTime]: 'one time',
};

export const WEIGHT_UNIT_LABELS = {
  [WeightUnit.G]: 'g',
  [WeightUnit.KG]: 'kg',
  [WeightUnit.OZ]: 'oz',
  [WeightUnit.LB]: 'lb',
  [WeightUnit.EA]: 'EA',
  [WeightUnit.FLWR]: 'FLWR',
  [WeightUnit.FLWR_2]: 'FLWR_2',
  [WeightUnit.FLWR_4]: 'FLWR_4',
};

export const FLWR_QUANTITY_LABELS = {
  [FlwrQuantityVariant.eightOz]: '1/8 oz',
  [FlwrQuantityVariant.quarterOz]: '1/4 oz',
  [FlwrQuantityVariant.halfOz]: '1/2 oz',
  [FlwrQuantityVariant.oneOz]: '1 oz',
};

export const INVENTORY_CONDITION_OPERATOR_LABELS = {
  [InventoryConditionOperatorName.EQ]: 'Is equal to',
  [InventoryConditionOperatorName.NOT_EQ]: 'is not equal to',
  [InventoryConditionOperatorName.STARTS_WITH]: 'Starts with',
  [InventoryConditionOperatorName.ENDS_WITH]: 'Ends with',
  [InventoryConditionOperatorName.CONTAINS]: 'Contains',
  [InventoryConditionOperatorName.NOT_CONTAIN]: 'Does not contain',
  [InventoryConditionOperatorName.NULL]: 'Is null',
  [InventoryConditionOperatorName.NOT_NULL]: 'Is not null',
  [InventoryConditionOperatorName.EMPTY]: 'Is empty',
  [InventoryConditionOperatorName.NOT_EMPTY]: 'Is not empty',
  [InventoryConditionOperatorName.HAS_VALUE]: 'Has value',
  [InventoryConditionOperatorName.HAS_NO_VALUE]: 'Has no value',
};

export const INVENTORY_CONDITION_ITEM_LABELS = {
  [InventoryConditionItemName.ITEM]: 'Item',
  [InventoryConditionItemName.BATCH]: 'Batch',
  [InventoryConditionItemName.AREA]: 'Area',
  [InventoryConditionItemName.STATUS]: 'Status',
  [InventoryConditionItemName.HAS_INVENTORY]: 'Has Inventory',
  [InventoryConditionItemName.QC_HOLD]: 'QC Hold',
  [InventoryConditionItemName.CATEGORY]: 'Category',
  [InventoryConditionItemName.BRAND]: 'Brand',
  [InventoryConditionItemName.VENDOR]: 'Vendor',
  [InventoryConditionItemName.STRAIN]: 'Strain',
  [InventoryConditionItemName.AVAILABLE_FOR_RETAIL]: 'Available for Retail',
  [InventoryConditionItemName.STORED_UOM]: 'Stored UOM',
  [InventoryConditionItemName.HARVEST_BATCH]: 'Harvest Batch',
};
