import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { KIOSK_HEADER_HEIGHT } from 'constants/general';

export const Root = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.secondary,
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  marginTop: `${KIOSK_HEADER_HEIGHT}px`,
}));
