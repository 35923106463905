import {
  Box, SelectChangeEvent, SwipeableDrawer, Typography,
} from '@mui/material';
import { set } from 'lodash';
import {
  useState, KeyboardEvent, MouseEvent, useContext, ChangeEvent, FC, useEffect,
} from 'react';
import { toast } from 'react-toastify';

import CustomButton from 'components/CustomButton';
import DriverLicenseForm from 'components/DriverLicenseForm';
import Loader from 'components/Loader';
import SectionTitle from 'components/SectionTitle';
import UserInfoForm from 'components/UserInfoForm';
import Login from 'containers/Login';
import {
  MEDICAL_BALANCE_DRIVER_LICENSE_FIELD_RULES,
  MEDICAL_BALANCE_USER_FIELD_RULES,
} from 'containers/MedicalBalance/MissingMedicalBalance/constants';
import { UserFormRoot } from 'containers/MedicalBalance/MissingMedicalBalance/index.styled';
import { SystemContext } from 'context/SystemContext';
import { UserContext, UserDispatchContext } from 'context/UserContext';

import { createAlleavesCustomer, getUserPaymentAccount } from 'services/User';

import { PHONE_NUMBER } from 'constants/fields';
import { DEFAULT_STATE_CODE, US_COUNTRY_CODE } from 'constants/general';
import { handleApiErrors } from 'utils/errorUtils';
import { splitDisplayName } from 'utils/formatters';
import { formatUserInfoValues } from 'utils/userUtils';
import { isIOS } from 'utils/utils';
import { getCustomerInformationSchema } from 'validation/customerInformationSchema';

interface MissingMedicalBalanceProps {
  checkSummary: () => unknown;
}

const iOS = isIOS();

const MissingMedicalBalance:FC<MissingMedicalBalanceProps> = ({ checkSummary }) => {
  const { user } = useContext(UserContext);
  const { refreshPaymentAccount } = useContext(UserDispatchContext);
  const { clientInfo } = useContext(SystemContext);
  const { firstName = '', lastName = '' } = user?.displayName ? splitDisplayName(user.displayName) : {};
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<Record<string, string>>({});
  const [form, setForm] = useState({
    customer: {
      firstName: firstName || '',
      lastName: lastName || '',
      email: user?.email || '',
      address: {
        state: DEFAULT_STATE_CODE,
        phoneNumber: '',
        countryCode: US_COUNTRY_CODE,
      },
    },
    driverLicense: {
      countryCode: US_COUNTRY_CODE,
      state: DEFAULT_STATE_CODE,
      number: '',
      expDate: null,
    },
  });

  useEffect(() => {
    if (user.email && form.customer.email !== user.email) {
      setForm({
        ...form,
        customer: {
          ...form.customer,
          email: user.email,
          ...(user.displayName ? splitDisplayName(user.displayName) : {}),
        },
      });
    }
  }, [user.email]);

  const toggleDrawer = (event: KeyboardEvent | MouseEvent) => {
    if (event
          && event.type === 'keydown'
          && ((event as KeyboardEvent).key === 'Tab'
            || (event as KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    if (!isOpen && !user.isAnonymous) {
      setShowUserInfo(true);
    }

    setIsOpen(!isOpen);
  };

  const handleInputChange = ({ target: { value, name } }: ChangeEvent<HTMLInputElement> | SelectChangeEvent) => {
    handleChangeValue(value, name);
  };

  const clearErrors = (name: string) => setFieldErrors({ ...fieldErrors, [name]: '' });

  const handleChangeValue = (value: any, name: string) => {
    setForm((prev) => {
      const newCheckoutForm = { ...prev };

      if (name === PHONE_NUMBER.name) {
        set(newCheckoutForm, 'customer.address', { ...prev?.customer?.address, ...value });
      } else {
        set(newCheckoutForm, name, value);
      }

      return newCheckoutForm;
    });

    clearErrors(name);
  };

  const submitUserInfo = async () => {
    try {
      setIsSubmitting(true);
      const customerInformationSchema = getCustomerInformationSchema(MEDICAL_BALANCE_USER_FIELD_RULES);
      await customerInformationSchema.validate(form, { abortEarly: false });

      const customer = formatUserInfoValues(form);
      await createAlleavesCustomer(customer, clientInfo.companyId);
      setFieldErrors({});

      await refreshPaymentAccount();
      await checkSummary();

      setIsOpen(false);
    } catch (err: any) {
      handleApiErrors(err, setFieldErrors);
    } finally {
      setIsSubmitting(false);
    }
  };

  const checkPaymentAccount = async () => {
    try {
      setIsSubmitting(true);
      const { data } = await getUserPaymentAccount(clientInfo?.companyId);

      if (data?.alleavesCustomerId) {
        await checkSummary();
        setIsOpen(false);
      } else {
        setShowUserInfo(true);
      }
    } catch (error) {
      handleApiErrors(error);
      setShowUserInfo(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onLoginFail = () => toast.error('There was an error trying to log you in. Please try again later.');

  return (
    <>
      <Typography
        variant="body2"
        color="primary"
        className="pointer"
        onClick={toggleDrawer}
        sx={{ textDecoration: 'underline' }}
      >
        To view the Allotment Cart Balance please fill in your Contact Info.
      </Typography>
      {isOpen && (
        <SwipeableDrawer
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
          anchor="right"
          open={isOpen}
          onClose={toggleDrawer}
          onOpen={toggleDrawer}
        >
          {showUserInfo ? (
            <UserFormRoot>
              <SectionTitle title="Contact Info" sx={{ mb: 3 }} />
              <UserInfoForm
                onValueChange={handleChangeValue}
                customer={form.customer}
                fieldErrors={fieldErrors}
                fieldsRules={MEDICAL_BALANCE_USER_FIELD_RULES}
              />
              <Box mt={4}>
                <DriverLicenseForm
                  onChange={handleInputChange}
                  onValueChange={handleChangeValue}
                  driverLicense={form.driverLicense}
                  fieldErrors={fieldErrors}
                  sx={{ mt: 0 }}
                  fieldsRules={MEDICAL_BALANCE_DRIVER_LICENSE_FIELD_RULES}
                />
              </Box>
              {isSubmitting && <Loader />}
              <Box display="flex" alignItems="center" justifyContent="flex-end" gap={2} mt={4}>
                <CustomButton
                  id="button-cancelMissingMedicalBalance"
                  variant="text"
                  onClick={toggleDrawer}
                  disabled={isSubmitting}
                >
                  Cancel
                </CustomButton>
                <CustomButton
                  id="button-submitMissingMedicalBalance"
                  variant="contained"
                  onClick={submitUserInfo}
                  disabled={isSubmitting}
                >
                  Save
                </CustomButton>
              </Box>
            </UserFormRoot>
          ) : (
            <Box mt={3}>
              <Login
                onSuccess={checkPaymentAccount}
                onFail={onLoginFail}
              />
            </Box>
          )}
        </SwipeableDrawer>
      )}
    </>
  );
};

export default MissingMedicalBalance;
