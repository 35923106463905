import { FC } from 'react';

import StorePicker from 'components/StorePicker';
import { Dialog, Content } from 'containers/SwitchShop/index.styled';

import { Option } from 'types/option.interface';

interface SwitchShopProps {
  onClose: () => void;
  shopOptions: Option[];
  isLoading: boolean;
}

const SwitchShop:FC<SwitchShopProps> = ({
  shopOptions,
  onClose,
  isLoading,
}) => (
  <Dialog
    onClose={onClose}
    open
    aria-describedby="signin"
    slotProps={{
      paper: {
        sx: {
          borderRadius: '16px', py: 3,
        },
      },
    }}
  >
    <Content>
      <StorePicker
        isLoading={isLoading}
        onClose={onClose}
        shopOptions={shopOptions}
      />
    </Content>
  </Dialog>
);

export default SwitchShop;
