import {
  createContext, ReactNode, useContext, useEffect, useMemo, useState,
} from 'react';

import {
  getInventoryBrands,
  getInventoryUOMs,
  getInventoryCategories,
  getInventoryVendors,
  getInventoryAreas,
  getInventoryStrains,
} from 'services/Inventory';

import {
  InventoryBrand,
  InventoryUOM,
  InventoryCategory,
  InventoryVendor,
  InventoryArea,
  InventoryStrain,
} from 'types/customInventoryFilters.interface';
import { handleApiErrors } from 'utils/errorUtils';
import { getStoreId } from 'utils/storageUtils';

interface InventoryContextProps {
  brands: InventoryBrand[];
  strains: InventoryStrain[];
  areas: InventoryArea[];
  uoms: InventoryUOM[];
  categories: InventoryCategory[];
  vendors: InventoryVendor[];
}

const InventoryContext = createContext<InventoryContextProps>({} as InventoryContextProps);

export const InventoryContextProvider = ({ children }: { children: ReactNode }) => {
  const [brands, setBrands] = useState<InventoryBrand[]>([]);
  const [strains, setStrains] = useState<InventoryStrain[]>([]);
  const [areas, setAreas] = useState<InventoryArea[]>([]);
  const [uoms, setUOMs] = useState<InventoryUOM[]>([]);
  const [categories, setCategories] = useState<InventoryCategory[]>([]);
  const [vendors, setVendors] = useState<InventoryVendor[]>([]);

  async function fetchAndSet<T>(
    fetchFn: (clientId: string) => Promise<{ data: { list: T[] } }>,
    setFn: (data: T[]) => void,
    clientId: string,
  ) {
    try {
      const { data } = await fetchFn(clientId);
      setFn(data.list);
    } catch (e) {
      handleApiErrors(e);
    }
  }

  useEffect(() => {
    const clientId = getStoreId();
    if (!clientId) return;

    Promise.allSettled([
      fetchAndSet(getInventoryBrands, setBrands, clientId),
      fetchAndSet(getInventoryStrains, setStrains, clientId),
      fetchAndSet(getInventoryAreas, setAreas, clientId),
      fetchAndSet(getInventoryUOMs, setUOMs, clientId),
      fetchAndSet(getInventoryCategories, setCategories, clientId),
      fetchAndSet(getInventoryVendors, setVendors, clientId),
    ]);
  }, []);

  const contextValue = useMemo(() => ({
    brands,
    strains,
    areas,
    uoms,
    categories,
    vendors,
  }), [brands, strains, areas, uoms, categories, vendors]);

  return (
    <InventoryContext.Provider
      value={contextValue}
    >
      {children}
    </InventoryContext.Provider>
  );
};

export const useInventoryContext = () => {
  const context = useContext(InventoryContext);
  if (!context) {
    throw new Error('useInventoryContext must be used within an InventoryDataProvider');
  }
  return context;
};
