import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import { IconButton, Typography } from '@mui/material';
import {
  FC, useState, KeyboardEvent, MouseEvent,
} from 'react';

import ShopContent from 'components/_ShopContent';
import Subcategories from 'containers/Categories/Subcategories';

import { Category } from 'types/category.interface';
import { getCategoryName } from 'utils/categoriesUtils';
import { isIOS } from 'utils/utils';

import {
  CategoriesLabel, SwipeableDrawer, DrawerTitle, FiltersWrapper,
} from './index.styled';

const iOS = isIOS();

interface CategoriesMobileProps {
  categories: Category[];
  selectedCategories: string[];
  onCategoryChanges: (category: string, index: number) => void;
}

const CategoriesMobile:FC<CategoriesMobileProps> = ({
  categories,
  selectedCategories,
  onCategoryChanges,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const leafName = selectedCategories?.length
    ? getCategoryName(selectedCategories[selectedCategories.length - 1], categories)
    : 'All Categories';

  const toggleDrawer = (event: KeyboardEvent | MouseEvent) => {
    if (
      event?.type === 'keydown'
      && ((event as KeyboardEvent).key === 'Tab' || (event as KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setIsOpen((preState) => !preState);
  };

  return (
    <ShopContent>
      <CategoriesLabel onClick={toggleDrawer}>
        <FilterListIcon sx={{ color: 'general.lightGrey1' }} />
        <Typography>
          {leafName || 'All Categories'}
        </Typography>
      </CategoriesLabel>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        anchor="left"
        open={isOpen}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
      >
        <DrawerTitle>
          <Typography fontWeight={600}>
            Categories
          </Typography>
          <IconButton onClick={toggleDrawer} id="iconButton-closeCategories">
            <CloseIcon />
          </IconButton>
        </DrawerTitle>

        <FiltersWrapper>
          <Subcategories
            onCategoryChanges={onCategoryChanges}
            categories={categories}
            selectedCategories={selectedCategories}
            level={0}
            startLevel={0}
          />
        </FiltersWrapper>
      </SwipeableDrawer>
    </ShopContent>
  );
};

export default CategoriesMobile;
