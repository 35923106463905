import { SxProps } from '@mui/material';
import { FC, ReactNode } from 'react';

import { Root } from 'components/_ShopContent/index.styled';

interface ShopContentProps {
  children: ReactNode|ReactNode[];
  sx?: SxProps;
  className?: string;
}

const ShopContent:FC<ShopContentProps> = ({ children, sx = {}, className = '' }) => (
  <Root sx={sx} className={className}>
    {children}
  </Root>
);

export default ShopContent;
