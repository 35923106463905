import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(0, 5),

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 2),
  },
}));
