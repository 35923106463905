import Add from '@mui/icons-material/Add';
import { Box, Tooltip, Typography } from '@mui/material';
import {
  FC, MouseEvent, useMemo, useState,
} from 'react';

import Brand from 'components/Brand';
import FreeTag from 'components/FreeTag';
import AddToCartDialog from 'components/ProductCard/AddToCartDialog';
import {
  StyledSection, StyledImage, StyledPriceBox, StyledIconButton, Root,
} from 'components/ProductCard/index.styled';
import ProductPrice from 'components/ProductPrice';
import ProductWeight from 'components/ProductWeight';

import { OnboardingUseType } from 'constants/enums';
import { Bounty } from 'types/bounty.interface';
import { textTruncate } from 'utils/formatters';
import {
  getProductPrice, getTierPrice, getTopLevelVariant, isFree,
} from 'utils/priceUtils';
import {
  formatChemicalComposition,
  formatProductWeight,
  getCoverUrl,
  getManufacturer,
  getProductStrainType,
} from 'utils/productUtils';
import { getPublicUrlForImage } from 'utils/publicUrl';

import Dot from 'assets/themes/styled/Dot';

interface ProductCardProps {
  bounty: Bounty;
  useType?: OnboardingUseType;
  hasAddButton?: boolean;
  isBrandDisabled?: boolean;
  isDescriptionVisible?: boolean;
  openDetails: () => void;
}

const ProductCard:FC<ProductCardProps> = ({
  bounty,
  useType,
  hasAddButton = false,
  isBrandDisabled = false,
  isDescriptionVisible = false,
  openDetails,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { product, tags } = bounty;
  const { price, msrp } = getProductPrice(product, useType);
  const isProductFree = isFree(price);
  const manufacturer = getManufacturer(product);
  const { quantity, weightUnit } = getTopLevelVariant(product) || {};
  const productQuantity = Math.max(0, quantity || 0);
  const isOutOfStock = productQuantity <= 0;
  const strainType = tags?.tags ? getProductStrainType(tags?.tags) : null;
  const tierPrices = bounty?.product?.altPrices?.tierPrices;

  const coverUrl = useMemo(() => (
    getCoverUrl(bounty?.id, product)
  ), [bounty.id]);

  const chemicalCompositionString = useMemo(() => (
    formatChemicalComposition(product)
  ), [product?.chemicalComposition]);

  const handleOpenQuantitySelectorDialog = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setIsOpen(true);
  };

  const handleCloseDialog = () => {
    setIsOpen(false);
  };

  const handleAddToCard = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    handleOpenQuantitySelectorDialog(e);
  };

  const renderPrice = (useLowestTierPrice: boolean = false) => {
    const tierPricesCount = tierPrices?.length;
    const productPrice = (useLowestTierPrice && tierPricesCount > 0
      ? getTierPrice(useType, tierPrices[tierPricesCount - 1]) : null) || price;

    if (isOutOfStock) {
      return <Typography fontWeight={700} variant="body2" color="error">Out of stock</Typography>;
    }

    if (isProductFree) {
      return <FreeTag fontWeight={700} variant="body2" />;
    }

    return <ProductPrice price={productPrice} msrp={msrp} fontWeight={700} variant="body1" />;
  };

  return (
    <Root>
      <StyledSection onClick={openDetails}>
        <Box margin="0 auto" height={150}>
          <StyledImage
            component="img"
            src={coverUrl || getPublicUrlForImage('images/placeholder_image.jpg')}
            alt={product?.name}
          />
        </Box>

        <Box my={1}>
          {!!manufacturer && (
            <Brand brand={manufacturer} disabled={isBrandDisabled} />
          )}
          {!!product?.name && (
            <Typography fontWeight="700" gutterBottom>
              {product?.name}
            </Typography>
          )}
          {product?.description && isDescriptionVisible && (
            <Typography color="textSecondary" variant="body2" my={1}>
              {textTruncate(product?.description, 50)}
            </Typography>
          )}
          {(strainType || chemicalCompositionString) && (
            <Typography component="div" color="textSecondary" variant="body2" my={1}>
              {!!strainType && strainType.displayName}
              {!!strainType && !!chemicalCompositionString && <Dot display="inline-block" mb={0.5} mx={1} />}
              {!!chemicalCompositionString && chemicalCompositionString}
            </Typography>
          )}
        </Box>

        <StyledPriceBox>
          <Box display="flex" alignItems="center" gap={1}>
            {renderPrice()}
            {product?.sortWeight && (
            <>
              <Dot />
              <ProductWeight
                fontWeight={700}
                variant="body1"
                weight={formatProductWeight(product.sortWeight)}
                weightUnit={weightUnit}
              />
            </>
            )}
          </Box>
          {hasAddButton && (
            <Tooltip title={isOutOfStock ? 'Out of stock' : 'Add to cart'}>
              <StyledIconButton
                aria-label="add to card"
                onClick={handleAddToCard}
                disabled={isOutOfStock}
              >
                <Add />
              </StyledIconButton>
            </Tooltip>
          )}
        </StyledPriceBox>

      </StyledSection>

      {isOpen && (
        <AddToCartDialog
          isOpen
          bounty={bounty}
          onClose={handleCloseDialog}
        />
      )}
    </Root>
  );
};

export default ProductCard;
