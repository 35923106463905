import { IconButton } from '@mui/material';
import MuiChip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

export const CloseDialog = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: 8,
  top: 8,
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
  zIndex: 1000,
}));

export const Chip = styled(MuiChip)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  borderRadius: '6px',
  border: 'none',
  backgroundColor: theme.palette.common.white,
  ...theme.typography.header,

  '&.MuiButtonBase-root.MuiChip-root.MuiChip-clickable:hover': {
    backgroundColor: theme.palette.background.secondary,
  },

  '& .MuiChip-icon': {
    color: theme.palette.text.secondary,
    margin: 0,
  },

  [theme.breakpoints.only('xs')]: {
    '& .MuiChip-label': {
      display: 'none',
    },
  },
})) as typeof MuiChip;
