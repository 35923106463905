import { useTheme } from '@mui/material';
import { useContext } from 'react';
import Slider from 'react-slick';

import SectionHeader from 'components/SectionHeader';
import UnstyledLink from 'components/UnstyledLink';
import CategoryCard from 'containers/CategoriesCarousel/CategoryCard';
import CategoriesCarouselSkeleton from 'containers/CategoriesCarousel/index.skeleton';
import { CategoryContext } from 'context/CategoryContext';

import { getProductsRoute } from 'hooks/useRouting';
import useSlickNavigation from 'hooks/useSlickNavigation';

import { getCategoriesCarouselSettings } from 'utils/carousel';

const CategoriesCarousel = () => {
  const { categories, isLoading } = useContext(CategoryContext);
  const theme = useTheme();
  const nrOfCategories = categories?.length || 0;
  const settings = getCategoriesCarouselSettings(theme, nrOfCategories);
  const {
    sliderRef,
    canNavigate,
    onNext,
    onPrevious,
  } = useSlickNavigation(settings);

  if (isLoading) {
    return <CategoriesCarouselSkeleton />;
  }

  if (!categories) {
    return null;
  }

  return (
    <div>
      <SectionHeader
        canNavigate={canNavigate}
        title="Categories"
        onNext={onNext}
        onPrevious={onPrevious}
      />
      <Slider className="category" {...settings} ref={sliderRef}>
        {categories?.map((category) => (
          <UnstyledLink
            key={category.code}
            to={getProductsRoute(`category=${category.code}`)}
            height="100%"
            width="100%"
          >
            <CategoryCard category={category} />
          </UnstyledLink>
        ))}
      </Slider>
    </div>
  );
};

export default CategoriesCarousel;
