const config = {
  id: 'belong',
  title: 'Belong',
  name: 'Belong app',
  brandName: 'Belong',
  appLinkSchema: 'belong',
  appTld: 'belong.is',
  TERMS_OF_SERVICE_URL: 'http://belong.is/terms/',
  PRIVACY_STATEMENT_URL: 'http://belong.is/privacy/',
  googleMapsKey: 'AIzaSyDklVnT0bZovfpKEKfX_rpg_jhsTm5O0XA',
  tags: ['all'],
};

export default config;
