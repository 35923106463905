import { Box } from '@mui/material';
import {
  FC, useContext, useMemo,
} from 'react';

import CheckoutFooter from 'components/_CheckoutFooter';
import CustomContent from 'components/CustomContent';
import EmptyPreviewCart from 'components/EmptyPreviewCart';
import { Root, FormWrapper } from 'containers/CheckoutProviderPage/KioskCheckout/index.styled';
import KioskCheckoutForm from 'containers/CheckoutProviderPage/KioskCheckoutForm';
import KioskPaymentMethods from 'containers/KioskPaymentMethods';
import { CartContext } from 'context/CartContext';
import { LocationContext } from 'context/LocationContext';
import { PaymentContext } from 'context/PaymentContext';
import { SystemContext } from 'context/SystemContext';

import useContentDimensions from 'hooks/useContentDimensions';

import { CartPriceDetails, CartSummary } from 'types/cart.interface';
import { PlaceOrderInfo } from 'types/checkout.interface';

interface KioskCheckoutProps {
  checkoutForm: PlaceOrderInfo;
  fieldErrors: Record<string, string>;
  formattedCartDetails: CartPriceDetails;
  cartPriceDetails: CartSummary;
  onOrder: ({ ignoreDialog, shouldRedirect }: { ignoreDialog: boolean; shouldRedirect: boolean }) => Promise<string>;
  onChange: (value: any, name: string) => void;
  onCheckSummary: () => void;
  isSubmitting: boolean;
  isSummaryLoading: boolean;
  hasSummaryFailed: boolean;
}

const KioskCheckout: FC<KioskCheckoutProps> = ({
  checkoutForm,
  onOrder,
  onChange,
  fieldErrors,
  formattedCartDetails,
  cartPriceDetails,
  onCheckSummary,
  isSubmitting,
  isSummaryLoading,
  hasSummaryFailed,
}) => {
  const cart = useContext(CartContext);
  const { onboardingInfo } = useContext(LocationContext);
  const { shopSettings, detailedClientData } = useContext(SystemContext);
  const { paymentFlow } = useContext(PaymentContext);
  const products = useMemo(() => Object.values(cart?.products) || [], [cart?.products]);
  const { useType } = onboardingInfo;
  const kioskSettings = detailedClientData?.kioskId
    ? shopSettings?.kioskSettings?.[detailedClientData?.kioskId]
    : null;
  const { allowStickyFooter } = useContentDimensions();

  if (paymentFlow.showKioskPaymentMethods && kioskSettings) {
    return (
      <Root>
        <KioskPaymentMethods
          isSubmitting={isSubmitting}
          onPlaceOrder={onOrder}
          kioskSettings={kioskSettings}
          onChangeCheckout={onChange}
          cartPriceDetails={cartPriceDetails}
        />
      </Root>
    );
  }

  return (
    <Root>
      {products?.length > 0 ? (
        <>
          <CustomContent kioskMode sx={{ flexGrow: 1 }} className={allowStickyFooter ? 'hidden-scroll' : ''}>
            <FormWrapper>
              <KioskCheckoutForm
                onValueChange={onChange}
                checkoutForm={checkoutForm}
                fieldErrors={fieldErrors}
                useType={useType}
                checkoutFields={shopSettings?.checkoutFields}
              />
            </FormWrapper>
          </CustomContent>
          <CheckoutFooter
            onSubmit={() => onOrder({ ignoreDialog: false, shouldRedirect: true })}
            isSummaryLoading={isSummaryLoading}
            isSubmitting={isSubmitting}
            disabled={hasSummaryFailed}
            hasSummaryFailed={hasSummaryFailed}
            cartPriceDetails={formattedCartDetails}
            onChangeSummary={onCheckSummary}
            continueLabel="Place Order"
          />
        </>
      ) : (
        <Box mt={12}>
          <EmptyPreviewCart hasButton fontSize="large" variant="h6" shouldResetPayment />
        </Box>
      )}
    </Root>
  );
};

export default KioskCheckout;
