import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const CenteredContent = styled(Box)(({ theme }) => ({
  maxWidth: '1520px',
  margin: '0 auto',

  [theme.breakpoints.down(1600)]: {
    maxWidth: '100%',
    padding: theme.spacing(0, 5),
  },

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 2),
  },
}));
