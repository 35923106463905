import { Box, Typography } from '@mui/material';
import { FieldErrors, UseFormRegister } from 'react-hook-form';

import CustomDropdown from 'components/CustomDropdown';
import CustomInput from 'components/CustomInput';

import { Option } from 'types/option.interface';
import { NewCustomerFormData } from 'validation/newCustomerSchema';

interface OthersSectionProps {
  register: UseFormRegister<NewCustomerFormData>;
  errors: FieldErrors<NewCustomerFormData>;
  states: Option[];
}

const OthersSection = ({ register, errors, states }: OthersSectionProps) => (
  <Box sx={{ flex: 1 }}>
    <Typography variant="h6" mb={2}>Others</Typography>

    <Box display="flex" flexDirection="column" gap={2}>
      <CustomInput
        {...register('driverLicense')}
        size="small"
        label="Driver's License Number"
        fullWidth
        error={!!errors.driverLicense}
        helperText={errors.driverLicense?.message}
      />
      <CustomDropdown
        {...register('driversLicenseSt')}
        size="small"
        label="Driver's License State"
        options={states}
        fullWidth
        error={!!errors.driversLicenseSt}
        helperText={errors.driversLicenseSt?.message}
      />
      <CustomInput
        {...register('driverLicenseExpires')}
        size="small"
        label="Driver's License Expiration"
        type="date"
        fullWidth
        InputLabelProps={{ shrink: true }}
        error={!!errors.driverLicenseExpires}
        helperText={errors.driverLicenseExpires?.message}
      />
      <CustomInput
        {...register('expirationDate')}
        size="small"
        label="Expiration Date"
        type="date"
        fullWidth
        InputLabelProps={{ shrink: true }}
        error={!!errors.expirationDate}
        helperText={errors.expirationDate?.message}
      />
      <CustomInput
        {...register('patientNumber')}
        size="small"
        label="Patient Number"
        fullWidth
        error={!!errors.patientNumber}
        helperText={errors.patientNumber?.message}
      />
    </Box>
  </Box>
);

export default OthersSection;
