import {
  Container, Grid, Box, useTheme,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  FC, Suspense, useContext, useEffect,
} from 'react';
import Slider from 'react-slick';

import CarouselNextArrow from 'components/CarouselNextArrow';
import CarouselPrevArrow from 'components/CarouselPrevArrow';
import Filters from 'components/Filters';
import FiltersMobile from 'components/FiltersMobile';
import ProductCard from 'components/ProductCard';
import ProductCardSkeleton from 'components/ProductCard/index.skeleton';
import ProductDetailsPage from 'containers/ProductDetailsPage';
import { PreviewContext } from 'context/PreviewProvider';
import PreviewLayout from 'layouts/PreviewLayout';

import useBountyDetails from 'hooks/useBountyDetails';

import { LandingPageType } from 'constants/enums';
import {
  MOCK_CATEGORIES,
  MOCK_PREVIEW_PRODUCTS,
  MOCK_PRODUCT_FILTER_1,
} from 'constants/mockData';
import { getCategoriesCarouselSettings } from 'utils/carousel';

interface PreviewStorePageProps {
  isOpen?: boolean;
}

const PreviewStorePage:FC<PreviewStorePageProps> = ({ isOpen = false }) => {
  const theme = useTheme();
  const { gistContent } = useContext(PreviewContext);
  const isDashboard = gistContent?.landingPage === LandingPageType.Default;
  const matchesScreenSizeMd = useMediaQuery(theme.breakpoints.down('md'));
  const settings = {
    ...getCategoriesCarouselSettings(theme, MOCK_CATEGORIES?.length),
    nextArrow: <CarouselNextArrow />,
    prevArrow: <CarouselPrevArrow />,
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleSelectFilter = () => (): void => {};
  const { selectedBounty, openBountyDetails, closeBountyDetails } = useBountyDetails();

  useEffect(() => {
    if (isOpen) {
      openBountyDetails(MOCK_PREVIEW_PRODUCTS[0]);
    }
  }, [isOpen]);

  return (
    <PreviewLayout hideFooter hideNavbarActions>
      <div>
        <Container>
          {isDashboard ? (
            MOCK_CATEGORIES.map((category) => (
              <Box mb={10} key={category.name}>
                <Slider {...settings}>
                  {MOCK_PREVIEW_PRODUCTS.map((bounty) => (
                    <ProductCard
                      openDetails={() => openBountyDetails(bounty)}
                      key={`${category.name}-${bounty.id}`}
                      bounty={bounty}
                      hasAddButton={false}
                      isBrandDisabled
                      isDescriptionVisible
                    />
                  ))}
                </Slider>
              </Box>
            ))
          ) : (
            <Box my={5}>
              <Grid container spacing={2}>
                <Grid size={{ xs: 12, md: 3 }}>
                  {matchesScreenSizeMd ? (
                    <FiltersMobile
                      isLoading={false}
                      filters={MOCK_PRODUCT_FILTER_1}
                      activeFilters={{}}
                      onSelectFilter={handleSelectFilter}
                    />
                  ) : (
                    <Filters
                      isLoading={false}
                      filters={MOCK_PRODUCT_FILTER_1}
                      activeFilters={{}}
                      onSelectFilter={handleSelectFilter}
                    />
                  )}
                </Grid>
                <Grid container size={{ xs: 12, md: isDashboard ? 12 : 9 }} spacing={2}>
                  {MOCK_PREVIEW_PRODUCTS.map((bounty) => (
                    <Grid
                      key={bounty.id}
                      size={{
                        xs: 12, sm: 6, md: 6, lg: 4,
                      }}
                    >
                      <Suspense fallback={<ProductCardSkeleton />}>
                        <ProductCard
                          openDetails={() => openBountyDetails(bounty)}
                          bounty={bounty}
                          hasAddButton={false}
                          isBrandDisabled
                          isDescriptionVisible
                        />
                      </Suspense>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Box>
          )}
        </Container>
        <ProductDetailsPage
          skipCall
          bountyDetails={selectedBounty}
          onClose={closeBountyDetails}
          isOpen={!!selectedBounty}
        />
      </div>
    </PreviewLayout>
  );
};

export default PreviewStorePage;
