import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const OrderBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  bgcolor: theme.palette.common.black,
  padding: theme.spacing(1),
  borderRadius: '6px',
  color: theme.palette.common.white,
}));

export const CellWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
}));

export const OrderWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.2),
}));
