import { isNil } from 'lodash';
import { useContext } from 'react';
import ReactGA from 'react-ga4';

import { LocationContext } from 'context/LocationContext';
import { SystemContext } from 'context/SystemContext';

import { useKiosk } from 'hooks/useKiosk';

import { CurrencyTypes } from 'constants/enums';
import { Bounty } from 'types/bounty.interface';
import { CartSummary, ProductInfo } from 'types/cart.interface';
import { Category } from 'types/category.interface';
import { Order } from 'types/order.interface';
import { formatProductForCart } from 'utils/cartUtils';
import { formatProductsForGoogleAnalytics } from 'utils/formatters';
import { getTopLevelVariant } from 'utils/priceUtils';
import { getProductEquivalentQuantity } from 'utils/productUtils';

export const useGoogleAnalytics = () => {
  const { onboardingInfo } = useContext(LocationContext);
  const { clientInfo } = useContext(SystemContext);
  const { kioskMode } = useKiosk();

  const handleTrackViewItem = (bounty: Bounty) => {
    const { quantity = 0 } = getTopLevelVariant(bounty?.product) || {};
    const formattedProduct = formatProductForCart(bounty, quantity, null, kioskMode);
    const googleAnalyticsProduct = formatProductsForGoogleAnalytics(formattedProduct, onboardingInfo);

    ReactGA.gtag('event', 'view_item', {
      location_id: onboardingInfo?.storeId,
      slug_id: clientInfo?.parentId,
      currency: CurrencyTypes.USD,
      value: googleAnalyticsProduct?.price,
      items: [googleAnalyticsProduct],
    });
  };

  const handleTrackAddItemToCart = ({
    bounty,
    selectedQuantity,
    weight = null,
  }: { bounty: Bounty; selectedQuantity?: number; weight?: string | null}) => {
    const { quantity = 0 } = getTopLevelVariant(bounty?.product) || {};
    const equivalentQuantity = !isNil(weight) && !isNil(bounty?.product?.sortWeight)
      ? getProductEquivalentQuantity(weight, bounty?.product?.sortWeight, kioskMode)
      : 0;
    const formattedProduct = formatProductForCart(
      bounty,
      equivalentQuantity || selectedQuantity || quantity,
      weight,
      kioskMode,
    );
    const googleAnalyticsProduct = formatProductsForGoogleAnalytics(formattedProduct, onboardingInfo);

    ReactGA.gtag('event', 'add_to_cart', {
      location_id: onboardingInfo?.storeId,
      slug_id: clientInfo?.parentId,
      currency: CurrencyTypes.USD,
      value: googleAnalyticsProduct?.price,
      items: [googleAnalyticsProduct],
    });
  };

  const handleTrackViewItems = (category: Category, products: Bounty[]) => {
    ReactGA.gtag('event', 'view_item_list', {
      location_id: onboardingInfo?.storeId,
      slug_id: clientInfo?.parentId,
      item_list_id: category?.code,
      item_list_name: category?.name,
      items: Object.values(products).map((bounty) => {
        const { quantity = 0 } = getTopLevelVariant(bounty?.product) || {};
        const formattedProduct = formatProductForCart(bounty, quantity, null, kioskMode);

        return formatProductsForGoogleAnalytics(formattedProduct, onboardingInfo);
      }),
    });
  };

  const handleTrackPurchase = (
    cartPriceDetails: CartSummary,
    order:Order,
    products: ProductInfo[],
    orderId: string,
  ) => {
    ReactGA.gtag('event', 'purchase', {
      location_id: onboardingInfo?.storeId,
      slug_id: clientInfo?.parentId,
      transaction_id: orderId,
      value: cartPriceDetails.total?.money?.amount,
      tax: cartPriceDetails.taxesTotal,
      discount: cartPriceDetails.discountsTotal,
      currency: cartPriceDetails.taxesCurrency || CurrencyTypes.USD,
      paymentMethod: order?.payment?.paymentMethod,
      items: Object.values(products).map((product) => formatProductsForGoogleAnalytics(product, onboardingInfo)),
    });
  };

  const handleTrackViewCart = (cartPriceDetails: CartSummary, products: ProductInfo[]) => {
    ReactGA.gtag('event', 'view_cart', {
      location_id: onboardingInfo?.storeId,
      slug_id: clientInfo?.parentId,
      currency: cartPriceDetails.taxesCurrency || CurrencyTypes.USD,
      value: cartPriceDetails.total?.money?.amount,
      items: Object.values(products).map((product) => formatProductsForGoogleAnalytics(product, onboardingInfo)),
    });
  };

  const handleTrackBeginCheckout = (cartPriceDetails: CartSummary, products: ProductInfo[]) => {
    ReactGA.gtag('event', 'begin_checkout', {
      location_id: onboardingInfo?.storeId,
      slug_id: clientInfo?.parentId,
      currency: cartPriceDetails.taxesCurrency || CurrencyTypes.USD,
      value: cartPriceDetails.total?.money?.amount,
      items: Object.values(products).map((product) => formatProductsForGoogleAnalytics(product, onboardingInfo)),
    });
  };

  const handleTrackRemoveFromCart = (product: ProductInfo) => {
    ReactGA.gtag('event', 'remove_from_cart', {
      location_id: onboardingInfo?.storeId,
      slug_id: clientInfo?.parentId,
      currency: CurrencyTypes.USD,
      value: product?.price?.money?.amount,
      items: formatProductsForGoogleAnalytics(product, onboardingInfo),
    });
  };

  const handleTrackSignUp = () => {
    ReactGA.gtag('event', 'sign_up', {
      location_id: onboardingInfo?.storeId,
      slug_id: clientInfo?.parentId,
    });
  };

  return {
    handleTrackSignUp,
    handleTrackRemoveFromCart,
    handleTrackViewItem,
    handleTrackViewItems,
    handleTrackAddItemToCart,
    handleTrackViewCart,
    handleTrackBeginCheckout,
    handleTrackPurchase,
  };
};
