import { Box } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';

import { KIOSK_HEADER_HEIGHT } from 'constants/general';

export const Root = styled(Box)(({ theme }) => ({
  height: `calc(100vh - ${KIOSK_HEADER_HEIGHT}px)`,
  width: '100%',
  backgroundColor: theme.palette.background.secondary,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

export const Drawer = styled(MuiDrawer)(({ theme }) => ({
  '& > .MuiPaper-root': {
    width: '700px',
    paddingTop: theme.spacing(4),

    [theme.breakpoints.down(700)]: {
      width: '100%',
    },
  },
}));
