import {
  onAuthStateChanged, signInAnonymously, signOut, User,
} from 'firebase/auth';
import { isEmpty } from 'lodash';
import {
  createContext, FC, ReactNode, useEffect, useState,
} from 'react';

import Loader from 'components/Loader';

import { getUserData, getUserInfo, getUserPaymentAccount } from 'services/User';

import { auth } from 'config/firebase';
import { PaymentAccount } from 'types/alleavesCustomer.interface';
import { UserData, UserInfo } from 'types/user.interface';
import { handleApiErrors } from 'utils/errorUtils';
import { getClientInfo, saveCheckoutDetailsToStorage } from 'utils/storageUtils';

interface UserContextProps {
  user: User;
  userData: UserData;
  userInfo: UserInfo;
  showLoginFlow: boolean;
  isUpdating: boolean;
  isLoadingUserData: boolean;
  paymentAccount: PaymentAccount|null;
}

const UserContext = createContext({
  user: {} as User,
  userData: {} as UserData,
  userInfo: {} as UserInfo,
  showLoginFlow: false,
  isUpdating: false,
  isLoadingUserData: false,
  paymentAccount: null,
} as UserContextProps);

const UserDispatchContext = createContext({
  setShowLoginFlow: (showLoginFlow: boolean) => {}, // eslint-disable-line
  setIsUpdating: (isUpdating: boolean) => {}, // eslint-disable-line
  refreshPaymentAccount: () => {}, // eslint-disable-line
  setPaymentAccount: (user: PaymentAccount|null) => {}, // eslint-disable-line
  setUserData: (user: UserData) => {},  // eslint-disable-line
  setUserInfo: (user: UserInfo) => {},  // eslint-disable-line
  renewKioskUser: () => {},  // eslint-disable-line
});

interface UserProviderProps {
  children: ReactNode;
}

const UserProvider:FC<UserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User>({} as User);
  const [{ userData, userInfo }, setUserData] = useState<{ userData: UserData; userInfo: UserInfo }>({
    userData: {} as UserData,
    userInfo: {} as UserInfo,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingUserData, setIsLoadingUserData] = useState(false);
  const [isRenewingKioskUser, setRenewKioskUser] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [showLoginFlow, setShowLoginFlow] = useState(false);
  const [paymentAccount, setPaymentAccount] = useState<PaymentAccount|null>(null);

  useEffect(() => {
    const unregisterAuthObserver = onAuthStateChanged(auth, async (userResponse) => {
      if (userResponse) {
        setUser(userResponse);
        setIsLoading(false);
      } else {
        setIsLoading(true);
        setUser({} as User);
        setUserData({ userData: {} as UserData, userInfo: {} as UserInfo });

        await signInAnonymously(auth);
        setIsLoading(false);
      }
    });

    return () => {
      unregisterAuthObserver();
    };
  }, []);

  useEffect(() => {
    if (user?.uid && !user?.isAnonymous && !isUpdating) {
      fetchUserData();
    }
  }, [user, isUpdating]);

  useEffect(() => {
    if (!isEmpty(userData)) {
      refreshPaymentAccount();
    }
  }, [userData]);

  const fetchUserData = async () => {
    try {
      setIsLoadingUserData(true);
      const [{ data: userDataResponse }, { data: userInfoResponse }] = await Promise.all([
        getUserData(user?.uid),
        getUserInfo(),
      ]);

      setUserData({ userData: userDataResponse, userInfo: userInfoResponse });
    } catch (error) {
      handleApiErrors(error);
    } finally {
      setIsLoadingUserData(false);
    }
  };

  const refreshPaymentAccount = async () => {
    try {
      const clientInfo = getClientInfo();
      const { data } = await getUserPaymentAccount(clientInfo?.companyId);
      setPaymentAccount(data);
    } catch (e) {
      setPaymentAccount(null);
    }
  };

  const renewKioskUser = async () => {
    setRenewKioskUser(true);
    await signOut(auth);
    saveCheckoutDetailsToStorage();
    setPaymentAccount(null);
    await signInAnonymously(auth);
    setRenewKioskUser(false);
  };

  const handleUserDataChanges = (userData: UserData) => {
    setUserData((prevState) => ({ ...prevState, userData }));
  };

  const handleUserInfoChanges = (userInfo: UserInfo) => {
    setUserData((prevState) => ({ ...prevState, userInfo }));
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <UserContext.Provider value={{
      user,
      userData,
      userInfo,
      showLoginFlow,
      isUpdating,
      paymentAccount,
      isLoadingUserData,
    }}
    >
      {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
      <UserDispatchContext.Provider value={{
        setShowLoginFlow,
        setIsUpdating,
        refreshPaymentAccount,
        setPaymentAccount,
        setUserData: handleUserDataChanges,
        setUserInfo: handleUserInfoChanges,
        renewKioskUser,
      }}
      >
        {isLoading && !isRenewingKioskUser ? <Loader /> : children}
      </UserDispatchContext.Provider>
    </UserContext.Provider>
  );
};

export {
  UserProvider,
  UserContext,
  UserDispatchContext,
};
