import CallMadeIcon from '@mui/icons-material/CallMadeRounded';
import { Typography } from '@mui/material';
import { off, onValue, query } from 'firebase/database';
import Lottie from 'lottie-react';
import {
  FC, useContext, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import SpacingContent from 'components/SpacingContent';
import HowToPay from 'containers/KioskPaymentMethods/HowToPay';
import { PaymentContext, PaymentDispatchContext } from 'context/PaymentContext';
import { SystemDispatchContext } from 'context/SystemContext';

import { getHomeRoute } from 'hooks/useRouting';
import { bountyCommonLayerRef, payOrder, updateOrderStatus } from 'services/Order';

import { rotationDegreesByTerminalPosition } from 'constants/checkout';
import { CHECKOUT_COMPLETE_ROUTE } from 'constants/clientRoutes';
import {
  LayoutType, OrderStatus, PaymentMethod, PaymentSteps,
} from 'constants/enums';
import { PAYMENT_METHOD } from 'constants/fields';
import { CartSummary } from 'types/cart.interface';
import { OrderDetails } from 'types/order.interface';
import { KioskSettings } from 'types/shopSettings.interface';
import { handleApiErrors } from 'utils/errorUtils';
import { formatRoute } from 'utils/formatters';
import { reportAlert } from 'utils/utils';

import paymentSuccessfulAnimation from 'assets/animations/paymentSuccessful.json';
import payWithCardAnimation from 'assets/animations/payWithCard.json';

import { ArrowsWrapper, ContentCard, Root } from './index.styled';

interface KioskPaymentMethodsProps {
  onPlaceOrder: (
    { ignoreDialog, shouldRedirect }: { ignoreDialog: boolean; shouldRedirect: boolean }
  ) => Promise<string>;
  kioskSettings: KioskSettings;
  onChangeCheckout: (value: any, name: string) => void;
  isSubmitting?: boolean;
  cartPriceDetails: CartSummary;
}

const KioskPaymentMethods: FC<KioskPaymentMethodsProps> = ({
  onPlaceOrder,
  kioskSettings,
  onChangeCheckout,
  isSubmitting = false,
  cartPriceDetails,
}) => {
  const navigate = useNavigate();
  const { paymentDetails, paymentFlow } = useContext(PaymentContext);
  const { updatePaymentFlow, resetPaymentFlow } = useContext(PaymentDispatchContext);
  const { setLayoutType } = useContext(SystemDispatchContext);
  const { orderId, alleavesOrderId } = paymentDetails || {};
  const [order, setOrder] = useState<OrderDetails | null>(null);
  const [isCanceling, setIsCanceling] = useState(false);

  const handleClosePaymentMethodsScreen = () => updatePaymentFlow({ showKioskPaymentMethods: false });

  const handleApplicationLaunched = (param: string) => {
    toast.success('Application Launched');
    console.log('onApplicationLaunched - param', param); // eslint-disable-line
  };

  const handleApplicationLaunchFailed = (param: string) => {
    toast.error('Application Launched Failed');
    console.log('onApplicationLaunchFailed - param', param); // eslint-disable-line
  };

  const handleTransactionSuccessful = (param: string) => {
    toast.success('Transaction Successful');
    console.log('onTransactionSuccessful - param', param); // eslint-disable-line
  };

  const handleTransactionFailed = (param: string) => {
    toast.error('Transaction Failed');
    console.log('onTransactionFailed - param', param); // eslint-disable-line
  };

  const handlePayOrder = async (orderId: string) => {
    try {
      const totalAmount = cartPriceDetails?.total?.money?.amount;

      if (window.AndroidPaymentTerminal?.pay && totalAmount) {
        window.onApplicationLaunched = handleApplicationLaunched;
        window.onApplicationLaunchFailed = handleApplicationLaunchFailed;
        window.onTransactionSuccessful = handleTransactionSuccessful;
        window.onTransactionFailed = handleTransactionFailed;
        window.AndroidPaymentTerminal.pay(totalAmount, null, orderId);
        return;
      }

      await payOrder(orderId, kioskSettings?.kioskId);

      if (!paymentFlow.hasPaymentFailed) {
        subscribeToOrderDetails(orderId);
      }

      updatePaymentFlow({ paymentSteps: PaymentSteps.WaitForCard, hasPaymentFailed: false });
      setLayoutType(LayoutType.Default);
    } catch (error: any) {
      handleApiErrors(error);
    }
  };

  const handlePayWithCardHere = async () => {
    let id = order?.orderId || orderId;

    if (!paymentFlow.hasPaymentFailed) {
      onChangeCheckout(PaymentMethod.DEBIT, PAYMENT_METHOD.name);
      id = await onPlaceOrder({ ignoreDialog: true, shouldRedirect: false });

      if (!id) {
        handleClosePaymentMethodsScreen();
        toast.error('Something went wrong with the order, please try again.');
        return;
      }
    }

    await handlePayOrder(id);
  };

  const handleCancelOrder = async () => {
    if (!order || !order?.orderId) {
      toast.error('Missing information order id!');
      return;
    }

    try {
      setIsCanceling(true);
      await updateOrderStatus(order.orderId, OrderStatus.CANCELLED);
      toast.success('Your order has been cancelled!');
      navigate(getHomeRoute());
      resetPaymentFlow();
    } catch (e) {
      handleApiErrors(e);
    } finally {
      setIsCanceling(false);
    }
  };

  const subscribeToOrderDetails = (orderId: string) => {
    const dbQuery = query(bountyCommonLayerRef(orderId));

    onValue(dbQuery, (snapshot) => {
      const orderSnapshot = snapshot.val() as OrderDetails;

      if (orderSnapshot && orderSnapshot?.status) {
        setOrder(orderSnapshot);
      }
    }, (error) => reportAlert(`order listener from terminal: ${JSON.stringify(error)}`));
  };

  const unsubscribeFromOrderReceipt = (orderId: string) => off(bountyCommonLayerRef(orderId));

  const checkOrderStatus = async (placedOrder: OrderDetails) => {
    if (placedOrder.status === OrderStatus.PAYED) {
      unsubscribeFromOrderReceipt(placedOrder?.orderId);
      updatePaymentFlow({ paymentSteps: PaymentSteps.PaymentSuccessful });
    }

    if (placedOrder.status === OrderStatus.PAYMENT_FAILED) {
      const errorMessage = placedOrder.paymentInfo.failureMessage;
      toast.error(errorMessage || 'Something went wrong with the payment, please try again.');

      updatePaymentFlow({ paymentSteps: PaymentSteps.HowToPay, hasPaymentFailed: true });
      setLayoutType(LayoutType.Warning);
    }
  };

  useEffect(() => () => {
    setLayoutType(LayoutType.Default);
  }, []);

  useEffect(() => {
    if (order?.status) {
      checkOrderStatus(order);
    }
  }, [order?.status]);

  const seeDetails = (time = 0) => {
    setTimeout(() => {
      resetPaymentFlow();
      navigate({
        pathname: formatRoute(CHECKOUT_COMPLETE_ROUTE, { orderId }),
        search: `?alleavesOrderId=${alleavesOrderId || ''}`,
      });
    }, time);
  };

  const handlePayAtVendor = async () => {
    if (!paymentFlow.hasPaymentFailed) {
      onChangeCheckout(PaymentMethod.CASH, PAYMENT_METHOD.name);
      const id = await onPlaceOrder({ ignoreDialog: true, shouldRedirect: true });

      if (!id) {
        handleClosePaymentMethodsScreen();
      } else {
        resetPaymentFlow();
      }
    } else {
      seeDetails();
    }
  };

  return (
    <Root>
      {paymentFlow.paymentSteps === PaymentSteps.HowToPay && (
        <HowToPay
          onCancel={handleCancelOrder}
          isSubmitting={isSubmitting || isCanceling}
          onPayAtVendor={handlePayAtVendor}
          onPayWithCardHere={handlePayWithCardHere}
          hasPaymentFailed={paymentFlow.hasPaymentFailed}
        />
      )}

      {paymentFlow.paymentSteps === PaymentSteps.WaitForCard && (
        <SpacingContent>
          <ContentCard>
            <Lottie animationData={payWithCardAnimation} loop />
            <ArrowsWrapper rotationDegrees={rotationDegreesByTerminalPosition[kioskSettings.terminalPosition]}>
              <CallMadeIcon />
            </ArrowsWrapper>
          </ContentCard>
        </SpacingContent>
      )}

      {paymentFlow.paymentSteps === PaymentSteps.PaymentSuccessful && (
        <SpacingContent>
          <ContentCard>
            <Lottie loop={false} animationData={paymentSuccessfulAnimation} onComplete={() => seeDetails(1000)} />
            <Typography variant="subtitle1" textAlign="center" fontWeight={500}>
              Payment
              <br />
              Successful
            </Typography>
          </ContentCard>
        </SpacingContent>
      )}
    </Root>
  );
};

export default KioskPaymentMethods;
