import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CustomButton from 'components/CustomButton';
import CustomInput from 'components/CustomInput';

import * as routes from 'constants/clientRoutes';

import { POSHeaderBox, POSHeaderWrapper } from './index.styled';

export const POSHeader = () => {
  const navigate = useNavigate();
  const [customerSearchTerm, setCustomerSearchTerm] = useState('');

  const handleNewCustomer = () => {
    navigate(routes.NEW_CUSTOMER_ROUTE);
  };

  return (
    <POSHeaderWrapper>
      <POSHeaderBox>
        <Typography variant="h4">Visitor queue</Typography>

        <Box>
          <Typography variant="body1">Customer search</Typography>
          <CustomInput
            value={customerSearchTerm}
            onChange={(e) => setCustomerSearchTerm(e.target.value)}
          />
        </Box>
      </POSHeaderBox>

      <POSHeaderBox>
        <CustomButton
          id="guest-checkout"
          variant="contained"
          onClick={() => {}}
        >
          Start Guest Checkout
        </CustomButton>

        <CustomButton
          id="new-customer"
          variant="outlined"
          onClick={handleNewCustomer}
        >
          Create New Customer
        </CustomButton>
      </POSHeaderBox>
    </POSHeaderWrapper>
  );
};
