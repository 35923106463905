import { Box, SxProps, Typography } from '@mui/material';
import { FC, useContext, useMemo } from 'react';

import { CardMedia } from 'containers/CheckoutProviderPage/StoreDetails/index.styled';
import { SystemContext } from 'context/SystemContext';

import { formatAddress } from 'utils/formatters';
import { getPublicUrlForImage } from 'utils/publicUrl';

interface StoreDetailsProps {
  mainColor?: string;
  secondaryColor?: string;
  sx?: SxProps;
  isBold?: boolean;
}

const StoreDetails: FC<StoreDetailsProps> = ({
  mainColor = 'textPrimary',
  secondaryColor = 'textSecondary',
  isBold = false,
  sx = {},
}) => {
  const { clientInfo, locationOptions } = useContext(SystemContext);
  const { name, branding } = clientInfo || {};
  const locationInfo = useMemo(() => (
    locationOptions.list.find(({ id }) => id === clientInfo?.id)
  ), [clientInfo, locationOptions]);

  return (
    <Box sx={sx}>
      <Typography variant="subtitle1" color={mainColor} fontWeight={isBold ? 700 : 400}>
        STORE
      </Typography>
      <Box display="flex" alignItems="center" gap={2.5}>
        <CardMedia
          component="img"
          src={branding?.logoUrl || getPublicUrlForImage('images/appLogoText.png')}
          alt={name}
        />
        <div>
          <Typography variant="subtitle1" fontWeight={700} color={secondaryColor} mb={1}>
            {name}
          </Typography>
          {locationInfo?.address && (
            <Typography variant="subtitle1" color={secondaryColor}>
              {formatAddress(locationInfo?.address)}
            </Typography>
          )}
        </div>
      </Box>
    </Box>
  );
};

export default StoreDetails;
