import { useContext } from 'react';

import ShopContent from 'components/_ShopContent';
import { PreviewContext } from 'context/PreviewProvider';
import PreviewLayout from 'layouts/PreviewLayout';
import { CardMedia, Content } from 'previews/PreviewOnboardingPage/index.styled';

import { getPublicUrlForImage } from 'utils/publicUrl';

const PreviewOnboardingPage = () => {
  const { gistContent } = useContext(PreviewContext);

  return (
    <PreviewLayout hideFooter hideNavbarActions>
      <Content>
        <ShopContent sx={{ height: '100%' }}>
          <CardMedia
            component="img"
            alt="onboarding flow"
            src={gistContent?.onboardingBackgroundUrl || getPublicUrlForImage('images/default-onboarding.jpg')}
          />
        </ShopContent>
      </Content>
    </PreviewLayout>
  );
};

export default PreviewOnboardingPage;
