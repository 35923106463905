import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import CustomButton from 'components/CustomButton';
import AddressInformationSection from 'containers/NewCustomerPage/AddressInformationSection';
import { NEW_CUSTOMER_DEFAULT_VALUES } from 'containers/NewCustomerPage/constants';
import OthersSection from 'containers/NewCustomerPage/OthersSections';
import PersonalInformationSection from 'containers/NewCustomerPage/PersonalInformationSection';

import { getUsStates } from 'services/Client';
import { createPOSCustomer } from 'services/POS';

import { POS_ROUTE } from 'constants/clientRoutes';
import { Option } from 'types/option.interface';
import { handleApiErrors } from 'utils/errorUtils';
import { newCustomerSchema, NewCustomerFormData } from 'validation/newCustomerSchema';

import { ButtonsBox } from './index.styled';

const NewCustomerPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingStates, setIsLoadingStates] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [states, setStates] = useState<Option[]>([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<NewCustomerFormData>({
    defaultValues: NEW_CUSTOMER_DEFAULT_VALUES,
    resolver: yupResolver(newCustomerSchema),
  });

  const getStates = async () => {
    try {
      setIsLoadingStates(true);
      const { data } = await getUsStates();
      setStates(data.list);
      setIsLoadingStates(false);
    } catch (err) {
      handleApiErrors(err);
    } finally {
      setIsLoadingStates(false);
    }
  };

  const createCustomer = async (customer: NewCustomerFormData) => {
    try {
      setIsLoading(true);
      await createPOSCustomer(customer);
      setIsSuccess(true);

      toast.success('Customer created successfully');
    } catch (err) {
      handleApiErrors(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getStates();
  }, []);

  return (
    <Box p={4}>
      <form onSubmit={handleSubmit(createCustomer)}>
        <Box display="flex" gap={4}>
          <PersonalInformationSection register={register} errors={errors} />
          <AddressInformationSection register={register} errors={errors} states={states} />
          <OthersSection register={register} errors={errors} states={states} />
        </Box>

        <ButtonsBox>
          <CustomButton
            id="button-backToPOS"
            color="secondary"
            onClick={() => navigate(POS_ROUTE)}
          >
            Back to POS
          </CustomButton>

          <Box>
            {isSuccess && (
              <CustomButton
                id="button-addCustomerToQueue"
                color="primary"
                sx={{ mr: 2 }}
                onClick={() => {}}
              >
                Add to Queue
              </CustomButton>
            )}
            <CustomButton
              id="button-submitCustomer"
              type="submit"
              variant="contained"
              color="primary"
              loading={isLoading || isLoadingStates}
            >
              Submit
            </CustomButton>
          </Box>
        </ButtonsBox>
      </form>
    </Box>
  );
};

export default NewCustomerPage;
