import { Typography } from '@mui/material';
import {
  ChangeEvent, FC, useContext, useState,
} from 'react';
import { toast } from 'react-toastify';

import IncrementWrapper from 'components/IncrementWrapper';
import WeightSelector from 'components/WeightSelector';
import { Root } from 'containers/CartProduct/ProductQuantitySelector/index.styled';
import { CartDispatchContext } from 'context/CartContext';

import { ProductInfo } from 'types/cart.interface';
import { extractNumberFromNumericFormat } from 'utils/numbers';
import { shouldUseProductWeight } from 'utils/productUtils';
import { reportAlert } from 'utils/utils';

interface ProductQuantitySelectorProps {
  product: ProductInfo;
  kioskMode: boolean;
  onOpen: (isOpen: boolean) => void;
  isDisabled: boolean;
  onRemove: () => void;
  isProductUpdated?: boolean;
  updatedQuantity?: number;
}

const ProductQuantitySelector: FC<ProductQuantitySelectorProps> = ({
  product,
  kioskMode,
  onOpen,
  isDisabled,
  onRemove,
  isProductUpdated = false,
  updatedQuantity = 0,
}) => {
  const { setQuantity, setWeight } = useContext(CartDispatchContext);
  const [currentWeight, setCurrentWeight] = useState<string>(product?.weight || '');
  const isOutOfStock = Boolean(isProductUpdated && updatedQuantity <= 0);
  const { availableQuantity = 0, maxAllowedQty, sortWeight } = product;
  const allowableQuantity = Math.min(availableQuantity, maxAllowedQty || Number.MAX_VALUE);
  const selectedQuantity = isOutOfStock ? 0 : product.quantity;
  const isUsingWeight = shouldUseProductWeight(product);

  const handleDecrease = () => {
    if (isUsingWeight) {
      const formattedWeight = extractNumberFromNumericFormat({ value: currentWeight, hasSuffix: kioskMode });
      const newWeight = (formattedWeight - 0.5)?.toString();

      if (formattedWeight > 0.5) {
        setCurrentWeight(newWeight);
        handleSaveWeight(newWeight);
      } else {
        onOpen(true);
      }

      return;
    }

    if (selectedQuantity - 1 <= 0) {
      onOpen(true);
      return;
    }

    setQuantity(product.id, selectedQuantity > 0 ? selectedQuantity - 1 : 0);
  };

  const handleIncrease = () => {
    if (isUsingWeight) {
      const formattedWeight = extractNumberFromNumericFormat({ value: currentWeight, hasSuffix: kioskMode });
      const newWeight = (formattedWeight + 0.5)?.toString();

      if (sortWeight && allowableQuantity * sortWeight >= formattedWeight + 0.5) {
        setCurrentWeight(newWeight);
        handleSaveWeight(newWeight);
      }

      return;
    }

    if (allowableQuantity === selectedQuantity) {
      toast.warning('You\'ve reached the maximum available amount for this product!');
      return;
    }

    setQuantity(product.id, selectedQuantity + 1);
  };

  const handleWeightChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => setCurrentWeight(value);

  const handleBlurWeight = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => handleSaveWeight(value);

  const handleSaveWeight = (value: string) => {
    if (sortWeight && allowableQuantity * sortWeight < +value) {
      toast.warning('You\'ve reached the maximum available weight for this product!');
      setCurrentWeight((allowableQuantity * sortWeight)?.toString());
      return;
    }

    if (!sortWeight) {
      reportAlert(`Cannot change product weight, missing sortWeight:\n${JSON.stringify(product)}`);
      toast.info('The product is missing important information, we can\'t change the weight.');
      return;
    }

    if (!value || +value === 0) {
      onRemove();
      return;
    }

    setWeight(product?.id, value, sortWeight);
  };

  return (
    <Root>
      <IncrementWrapper
        kioskMode={kioskMode}
        onDecrease={handleDecrease}
        onIncrease={handleIncrease}
      >
        {isUsingWeight
          ? (
            <WeightSelector
              selectedWeight={currentWeight}
              weightUnit={product?.weightUnit}
              onChange={handleWeightChange}
              onBlur={handleBlurWeight}
              isContained
              disabled={isOutOfStock || isDisabled}
            />
          ) : (
            <Typography p={1} textAlign="center" flexGrow={1}>
              {selectedQuantity}
            </Typography>
          )}
      </IncrementWrapper>
    </Root>
  );
};

export default ProductQuantitySelector;
