import { IconButton } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import { alpha, styled } from '@mui/material/styles';
import { FC } from 'react';

export const StyledSection = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: '12px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all .15s ease-in-out',
  backgroundColor: theme.palette.common.white,

  '&:hover': {
    border: `1px solid ${theme.palette.common.black}`,
  },
}));

export const Root = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '100%',
  border: `1px solid ${theme.palette.common.white}`,
  borderRadius: '12px',

  '&:hover': {
    border: `1px solid ${theme.palette.common.black}`,
  },
}));

export const StyledImage = styled(
  CardMedia,
  { shouldForwardProp: (props) => props !== 'kioskMode' },
)<{ kioskMode?: boolean }>(({ kioskMode }) => ({
  maxHeight: kioskMode ? '300px' : '150px',
  maxWidth: '100%',
  objectFit: 'contain',
})) as typeof CardMedia | FC<{ kioskMode?: boolean }>;

export const StyledPriceBox = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: 'auto',
}));

export const StyledSkeletonSection = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.border.main}`,
  backgroundColor: theme.palette.common.white,
  borderRadius: '12px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all .15s ease-in-out',
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.getContrastText(theme.palette.primary.main),

  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.6),
  },
}));
