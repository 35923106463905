import { Typography } from '@mui/material';
import { TypographyVariant } from '@mui/material/styles';
import { FC } from 'react';

import { WeightUnit } from 'constants/enums';
import { WEIGHT_UNIT_LABELS } from 'constants/labels';

interface PriceProps {
  price?: string;
  fontWeight: number;
  variant: TypographyVariant;
  weight?: number | null;
  weightUnit?: WeightUnit;
  quantity?: number | null;
}

const Price :FC<PriceProps> = ({
  price = '--',
  fontWeight,
  variant,
  weight = null,
  weightUnit = WeightUnit.G,
  quantity = null,
}) => {
  const formattedPrice = quantity
    ? `${price} X ${quantity}`
    : price;

  return (
    <Typography variant={variant} fontWeight={fontWeight}>
      {weight
        ? `${price} / ${weight} ${WEIGHT_UNIT_LABELS[weightUnit]}`
        : formattedPrice}
    </Typography>
  );
};

export default Price;
