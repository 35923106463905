import { Box, Dialog, Typography } from '@mui/material';
import { FC, useMemo } from 'react';

import CloseButton from 'components/CloseButton';
import ProductShortPreview from 'components/ProductShortPreview';
import ProductPriceSection from 'containers/ProductPriceSection';

import { Bounty } from 'types/bounty.interface';
import { isFlwr } from 'utils/productUtils';

interface AddToCartDialogProps {
  bounty: Bounty;
  isOpen: boolean;
  onClose: () => void;
}

const AddToCartDialog:FC<AddToCartDialogProps> = ({
  bounty,
  isOpen,
  onClose,
}) => {
  const tierPrices = bounty?.product?.altPrices?.tierPrices;
  const showWeightModal = !tierPrices && !isFlwr(bounty?.product?.sortUnit);
  const modalTitle = useMemo(
    () => (
      // eslint-disable-next-line no-nested-ternary
      showWeightModal
        ? 'Select quantity'
        : isFlwr(bounty?.product?.sortUnit) ? 'Select weight' : 'Select quantity'),
    [showWeightModal, bounty?.product?.sortUnit],
  );

  return (
    <Dialog
      open={isOpen}
      fullWidth
      onClose={onClose}
      aria-describedby="select-tier-price"
      slotProps={{
        paper: { sx: { maxWidth: 560, borderRadius: '16px', p: 2.5 } },
      }}
    >
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Typography variant="h5" fontWeight={700}>
          {modalTitle}
        </Typography>
        <CloseButton onClose={onClose} />
      </Box>
      <ProductShortPreview bounty={bounty} sx={{ mb: 2 }} />
      <ProductPriceSection
        isLoading={false}
        bounty={bounty}
        onCancel={onClose}
        onSuccess={onClose}
      />
    </Dialog>
  );
};

export default AddToCartDialog;
