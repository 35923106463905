import { Box } from '@mui/material';
import { TypographyVariant } from '@mui/material/styles';
import { FC } from 'react';

import Price from 'components/Price';
import { StyledOldPrice } from 'components/ProductPrice/index.styled';

import { Price as PriceProps } from 'types/money.interface';
import { formatPrice, hasDiscount } from 'utils/priceUtils';

interface ProductPriceProps {
  price: PriceProps|null;
  msrp?: PriceProps|null;
  fontWeight: number;
  variant: TypographyVariant;
}

const ProductPrice:FC<ProductPriceProps> = ({
  price, msrp = null, fontWeight, variant,
}) => {
  const hasProductDiscount = hasDiscount(msrp, price);
  const productPrice = formatPrice(price);

  return (
    hasProductDiscount
      ? (
        <Box display="flex" alignItems="center">
          <StyledOldPrice variant={variant}>
            {formatPrice(msrp)}
          </StyledOldPrice>
          <Price variant={variant} fontWeight={fontWeight} price={productPrice || '--'} />
        </Box>
      ) : (
        <Price variant={variant} fontWeight={fontWeight} price={productPrice} />
      )
  );
};

export default ProductPrice;
