import {
  Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import CustomButton from 'components/CustomButton';
import { POSHeader } from 'components/POSHeader';
import { GenericTable } from 'components/Table';

import { deletePOSQueue, getPOSQueues } from 'services/POS';

import { POSQueue } from 'types/pos.interface';
import { handleApiErrors } from 'utils/errorUtils';
import { formatUserName } from 'utils/formatters';

import { columns } from './constants';

export const POSPage = () => {
  const [queues, setQueues] = useState<POSQueue[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteQueueModal, setShowDeleteQueueModal] = useState(false);
  const [selectedQueue, setSelectedQueue] = useState<POSQueue | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const getQueues = async () => {
    try {
      setIsLoading(true);
      const { data } = await getPOSQueues();

      setQueues(data);
    } catch (err) {
      handleApiErrors(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = (queue: POSQueue) => {
    setSelectedQueue(queue);
    setShowDeleteQueueModal(true);
  };

  const deleteQueueHandler = async () => {
    if (!selectedQueue) {
      toast.error('No queue selected');
      return;
    }

    try {
      setIsDeleting(true);
      await deletePOSQueue(selectedQueue.queueId);
      getQueues();
    } catch (err) {
      handleApiErrors(err);
    } finally {
      setIsDeleting(false);
      setShowDeleteQueueModal(false);
    }
  };

  useEffect(() => {
    getQueues();
  }, []);

  const handleCancel = () => setShowDeleteQueueModal(false);

  return (
    <Box>
      <POSHeader />

      <Dialog
        open={showDeleteQueueModal}
        onClose={() => setShowDeleteQueueModal(false)}
      >
        <DialogTitle>
          Delete Queue
        </DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this customer (
            {formatUserName(selectedQueue?.customer.firstName ?? '', selectedQueue?.customer.lastName ?? '')}
            ) from the queue?
          </Typography>
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleCancel} variant="outlined" id="button-deleteQueueCancel">
            Cancel
          </CustomButton>
          <CustomButton
            onClick={deleteQueueHandler}
            id="button-deleteQueue"
            loading={isDeleting}
            disabled={isDeleting}
          >
            Delete
          </CustomButton>
        </DialogActions>
      </Dialog>

      <GenericTable
        columns={columns(handleDelete)}
        rows={queues}
        hideFooter
        loading={isLoading}
        getRowId={(row) => row.queueId}
      />
    </Box>
  );
};

export default POSPage;
