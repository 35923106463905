import { styled } from '@mui/material/styles';
import MuiSwipeableDrawer from '@mui/material/SwipeableDrawer';

export const SwipeableDrawer = styled(MuiSwipeableDrawer)(() => ({
  '& > .MuiPaper-root': {
    width: '280px',
  },
}));

export const DrawerTitle = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2, 2, 1, 2),
  marginBottom: theme.spacing(2),
}));

export const CategoriesLabel = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: '12px',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  cursor: 'pointer',
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(2),
  margin: theme.spacing(2, 0),
}));

export const FiltersWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(1.5, 2, 0),
  borderTop: `1px solid ${theme.palette.border.main}`,
}));
