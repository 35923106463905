import { Box } from '@mui/material';
import { FC, SyntheticEvent } from 'react';

import FilterGroup from 'components/Filters/FilterGroup';

import { FilterType } from 'constants/enums';
import { Filter } from 'types/filter.interface';

interface FiltersProps {
  activeFilters: { [key: string]: string[] };
  filters: Filter[];
  onSelectFilter: (filterId: string, filterType: FilterType) => (e: SyntheticEvent, checked: boolean) => void;
  onRangeFilter?: (filterId: string, filterValue: string, filterType: FilterType) => void;
  isLoading: boolean;
}

const Filters:FC<FiltersProps> = ({
  filters,
  onSelectFilter,
  onRangeFilter,
  activeFilters,
  isLoading,
}) => (
  <Box display="flex" flexDirection="column" gap={3.5}>
    {filters.map((filter) => (
      <FilterGroup
        isLoading={isLoading}
        key={filter.id}
        activeFilters={activeFilters}
        filter={filter}
        onSelectFilter={onSelectFilter}
        onRangeFilter={onRangeFilter}
      />
    ))}
  </Box>
);

export default Filters;
