import { Typography } from '@mui/material';
import { TypographyVariant } from '@mui/material/styles';
import { FC } from 'react';

import { CustomTypographyVariant } from 'assets/themes/defaultTheme';

interface SectionTitleProps {
  title: string;
  fontWeight?: number;
  variant?: TypographyVariant | CustomTypographyVariant;
  sx?: { [key: string]: any };
}

const SectionTitle:FC<SectionTitleProps> = ({
  variant = 'body2', fontWeight = 600, sx, title,
}) => (
  <Typography variant={variant} sx={sx} fontWeight={fontWeight}>
    {title}
  </Typography>
);

export default SectionTitle;
