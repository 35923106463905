import { Typography } from '@mui/material';
import { FC, useContext, useMemo } from 'react';

import CartTotal from 'components/CartTotal';
import Coupon from 'components/Coupon';
import CustomButton from 'components/CustomButton';
import FailedSummaryMessage from 'components/FailedSummaryMessage';
import { Root, PriceWrapper, SummaryWrapper } from 'containers/CheckoutProviderPage/CheckoutSummary/index.styled';
import StoreDetails from 'containers/CheckoutProviderPage/StoreDetails';
import { getPaymentLabel } from 'containers/CheckoutProviderPage/utils';
import { CartContext } from 'context/CartContext';

import { PaymentMethod } from 'constants/enums';
import { EMPTY_STATE } from 'constants/general';
import { ORDER_DELIVERY_METHOD } from 'constants/labels';
import { CartPriceDetails } from 'types/cart.interface';
import { PlaceOrderInfo } from 'types/checkout.interface';
import { calculateTotalQuantity } from 'utils/cartUtils';
import { isPickupMethod } from 'utils/checkoutUtils';

interface CheckoutSummaryProps {
  checkoutForm: PlaceOrderInfo;
  handlePlaceOrder: () => void;
  cartPriceDetails: CartPriceDetails;
  isSubmitting: boolean;
  isSummaryLoading: boolean;
  hasSummaryFailed?: boolean;
  consentMessage?: string;
  onChangeSummary: (coupon: string) => void;
  scheduledTime: string;
  disabled?: boolean;
}

const CheckoutSummary: FC<CheckoutSummaryProps> = ({
  checkoutForm,
  handlePlaceOrder,
  cartPriceDetails,
  isSubmitting,
  consentMessage,
  isSummaryLoading,
  hasSummaryFailed = false,
  onChangeSummary,
  scheduledTime,
  disabled = false,
}) => {
  const cart = useContext(CartContext);
  const totalProductsQuantity = useMemo(() => calculateTotalQuantity(cart?.products), [cart?.products]);
  const isPickup = isPickupMethod(checkoutForm?.delivery?.method);

  return (
    <Root>
      <SummaryWrapper>
        <Typography variant="h4" mb={3.5}>
          Order Summary
        </Typography>

        <Typography variant="subtitle1" color="textSecondary">
          SCHEDULED TIME
        </Typography>
        <Typography variant="subtitle1" fontWeight={700} mb={5}>
          {scheduledTime}
        </Typography>

        <StoreDetails mainColor="textSecondary" secondaryColor="textPrimary" />

        <Typography variant="subtitle1" color="textSecondary" mt={5}>
          {isPickup ? 'PICKUP METHOD' : 'DELIVERY METHOD'}
        </Typography>
        <Typography variant="subtitle1" fontWeight={700}>
          {ORDER_DELIVERY_METHOD?.[checkoutForm?.delivery?.method] || EMPTY_STATE}
        </Typography>

        <Typography variant="subtitle1" color="textSecondary" mt={5}>
          PAYMENT
        </Typography>
        <Typography variant="subtitle1" fontWeight={700}>
          {getPaymentLabel(checkoutForm?.payment?.paymentMethod as PaymentMethod)}
        </Typography>
      </SummaryWrapper>

      <PriceWrapper>
        <Coupon
          title="PROMO CODE"
          variant="subtitle1"
          onChangeSummary={onChangeSummary}
        />

        <CartTotal
          cartPriceDetails={cartPriceDetails}
          isLoading={(isSummaryLoading && !isSubmitting)}
          hasFailed={hasSummaryFailed}
          productsCount={totalProductsQuantity}
          totalVariant="h4"
          totalLabel="Estimated total"
          variant="subtitle1"
        />

        <Typography variant="body2" mt={2} mb={7.5}>
          The tax amount we provide is an estimate and is subject to change. Final taxes will be adjusted when you pay
          for your order.
        </Typography>

        <CustomButton
          id="button-placeOrder"
          fullWidth
          variant="contained"
          onClick={handlePlaceOrder}
          isLoading={isSubmitting || isSummaryLoading}
          disabled={hasSummaryFailed || disabled}
          size="large"
        >
          Submit Order
        </CustomButton>

        {consentMessage && (
          <Typography variant="body2" fontSize={12} mt={3}>
            {consentMessage}
          </Typography>
        )}

        {hasSummaryFailed && <FailedSummaryMessage sx={{ mt: 3 }} />}
      </PriceWrapper>
    </Root>
  );
};

export default CheckoutSummary;
