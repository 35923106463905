import MuiSearchIcon from '@mui/icons-material/Search';
import { Badge, Dialog, DialogActions } from '@mui/material';
import {
  ChangeEvent, FC, FormEvent, SyntheticEvent, useState,
} from 'react';

import CustomButton from 'components/CustomButton';
import SearchForm from 'containers/SearchProducts/SearchForm';

import { StyledButton, DialogContent, StyledSearch } from '../index.styled';

interface MobileContentProps {
  search: string;
  setSearch: (search: string) => void;
  onSearch: (e: SyntheticEvent | FormEvent<HTMLFormElement>) => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
}
const MobileContent:FC<MobileContentProps> = ({
  search,
  setSearch,
  onSearch,
  onChange,
  onClear,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
    setSearch('');
  };

  const handleOpen = () => {
    setIsFocused(true);
    setIsOpen(true);
  };

  const handleSearch = (e: SyntheticEvent | FormEvent<HTMLFormElement>) => {
    setIsOpen(false);
    onSearch(e);
  };

  return (
    !isOpen ? (
      <StyledButton
        disableRipple
        aria-label="search"
      >
        <StyledSearch onClick={handleOpen}>
          <Badge color="primary">
            <MuiSearchIcon />
          </Badge>
        </StyledSearch>
      </StyledButton>
    ) : (
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-describedby="search-products"
        disableRestoreFocus
        slotProps={{
          paper: {
            sx: {
              borderRadius: 4, pt: 3, pb: 4, px: 2,
            },
          },
        }}
      >
        <DialogContent>
          <SearchForm
            search={search}
            isFocused={isFocused}
            onSearch={handleSearch}
            onChange={onChange}
            onClear={onClear}
          />
        </DialogContent>

        <DialogActions sx={{ p: '0 24px' }}>
          <CustomButton id="button-cancelSearch" variant="outlined" onClick={handleClose}>
            Cancel
          </CustomButton>
          <CustomButton id="button-submitSearch" variant="contained" onClick={handleSearch}>
            Search
          </CustomButton>
        </DialogActions>
      </Dialog>
    )
  );
};

export default MobileContent;
