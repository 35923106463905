import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { FC } from 'react';

import { InfoRow } from 'containers/ProductPriceSection/TierPrices/index.styled';

import { OnboardingUseType } from 'constants/enums';
import { EMPTY_STATE } from 'constants/general';
import { WEIGHT_UNIT_LABELS } from 'constants/labels';
import { TierPrice } from 'types/money.interface';
import { Product } from 'types/product.interface';
import {
  formatPrice, getPriceDiscount, getProductPrice, getTierPrice, getTopLevelVariant,
} from 'utils/priceUtils';

interface TierPricesProps {
  tierPrices: TierPrice[];
  useType: OnboardingUseType;
  product: Product;
  shouldUseProductWeight: boolean;
}

const TierPrices: FC<TierPricesProps> = ({
  tierPrices,
  useType,
  product,
  shouldUseProductWeight,
}) => {
  const { price: productPrice } = getProductPrice(product, useType);
  const { weight, weightUnit } = getTopLevelVariant(product) || {};
  const weightUnitLabel = weightUnit ? WEIGHT_UNIT_LABELS[weightUnit] : EMPTY_STATE;

  return (
    <Grid container>
      <InfoRow container size={12}>
        <Grid size={4}>
          <Typography fontWeight={600}>
            Quantity
          </Typography>
        </Grid>
        <Grid size={4}>
          <Typography fontWeight={600}>
            Price
          </Typography>
        </Grid>
        <Grid size={4}>
          <Typography fontWeight={600}>
            Discount
          </Typography>
        </Grid>
      </InfoRow>

      {tierPrices?.[0]?.weight !== weight && (
        <InfoRow container size={12}>
          <Grid size={4}>
            <Typography>
              {shouldUseProductWeight
                ? `${weight} ${weightUnitLabel} +`
                : '1+ items'}
            </Typography>
          </Grid>
          <Grid size={4}>
            <Typography>
              {`${formatPrice(productPrice)} /${shouldUseProductWeight ? weightUnitLabel : 'item'}`}
            </Typography>
          </Grid>
          <Grid size={4}>
            <Typography>
              -
            </Typography>
          </Grid>
        </InfoRow>
      )}

      {tierPrices?.map((tierPrice) => {
        const price = getTierPrice(useType, tierPrice);
        /**
         * The product can have the same weight as the first tier
         * in this case, the price from the tier is the new product price
         */
        const newProductPrice = tierPrices?.[0]?.weight === weight
          ? getTierPrice(useType, tierPrices?.[0])
          : productPrice;

        if (!price) {
          return null;
        }

        const discount = newProductPrice ? getPriceDiscount(newProductPrice, price) : 0;

        return (
          <InfoRow container size={12} key={tierPrice?.weight}>
            <Grid size={4}>
              <Typography>
                {shouldUseProductWeight
                  ? `${tierPrice?.weight} ${weightUnitLabel} +`
                  : `${tierPrice?.items}+ items`}
              </Typography>
            </Grid>
            <Grid size={4}>
              <Typography>
                {`${formatPrice(price)} /${shouldUseProductWeight ? weightUnitLabel : 'item'}`}
              </Typography>
            </Grid>
            <Grid size={4}>
              <Typography>
                {discount >= 1 ? `${Math.ceil(discount)}% OFF` : EMPTY_STATE}
              </Typography>
            </Grid>
          </InfoRow>
        );
      })}
    </Grid>
  );
};

export default TierPrices;
