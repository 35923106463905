export const extractNumberFromNumericFormat = (
  {
    value,
    hasSuffix = false,
    hasPrefix = false,
  }: { value?: string | null; hasSuffix?: boolean; hasPrefix?: boolean } = {},
) => {
  if (!value) {
    return 0;
  }

  let amount = value;

  if (hasSuffix) {
    amount = amount?.split(' ')?.[0];
  }

  if (hasPrefix) {
    amount = amount?.split(' ')?.[1];
  }

  amount = amount?.replaceAll(',', '');
  return amount ? +amount : 0;
};
