import {
  Box, Divider, Grid, Typography,
} from '@mui/material';
import { format } from 'date-fns';
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import CartTotal from 'components/CartTotal';
import Loader from 'components/Loader';
import OrderContactInfo from 'components/OrderContactInfo';
import OrderPaymentInfo from 'components/OrderPaymentInfo';
import OrderProductPreview from 'components/OrderProductPreview';
import OrderTypeInfo from 'components/OrderTypeInfo';
import QrCodeWrapper from 'components/QrCodeWrapper';
import { SystemContext } from 'context/SystemContext';
import { UserContext } from 'context/UserContext';

import { useOrderId } from 'hooks/useRouting';
import { getOrderDetails } from 'services/Order';

import { HOME_ROUTE } from 'constants/clientRoutes';
import { EXTENDED_ORDER_DATE_FORMAT } from 'constants/general';
import { ORDER_STATUS } from 'constants/statuses';
import { Bounty } from 'types/bounty.interface';
import { calculateTotalQuantity } from 'utils/cartUtils';
import { getCardDetails, isPickupMethod } from 'utils/checkoutUtils';
import { formatReward } from 'utils/currencyUtils';
import { handleApiErrors } from 'utils/errorUtils';
import { getLocalUsStates } from 'utils/storageUtils';

import {
  StyledBox, StyledStatusWrapper, Root,
} from './index.styled';

const OrderDigitalReceiptPage = () => {
  const orderId = useOrderId();
  const navigate = useNavigate();
  const [bounty, setBounty] = useState<Bounty|null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useContext(UserContext);
  const { locationOptions, clientInfo } = useContext(SystemContext);

  const { order } = bounty || {} as Bounty;
  const usStates = getLocalUsStates();
  const state = useMemo(() => (
    usStates?.find((states) => states?.key === order?.customerInfo?.customer?.address?.state)?.value
  ), [usStates, order]);
  const locationInfo = useMemo(() => (
    locationOptions.list.find(({ id }) => id === clientInfo?.id)
  ), [clientInfo, locationOptions]);
  const shortOrderId = order?.orderId ? order?.orderId?.split('-')?.[1] : '';
  const cartDetails = getCardDetails(order?.invoiceInfo?.summary?.sections?.IN_APP);

  useEffect(() => {
    if (orderId) {
      fetchOrder(orderId);
    }
  }, [orderId]);

  const fetchOrder = async (id: string) => {
    setIsLoading(true);
    try {
      const payload = { userId: user.uid, orderId: id, isOpened: true };
      const response = await getOrderDetails(payload);
      setBounty(response.data);
    } catch (error) {
      handleApiErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (order && !order?.posUrl) {
      toast.error('This order is missing important information!');
      navigate(HOME_ROUTE);
    }
  }, [order]);

  return (
    <Root>
      {order?.posUrl && (
        <QrCodeWrapper
          size={125}
          src={order?.posUrl}
          sx={{ mb: 2, p: 2, borderRadius: '4px' }}
          innerSx={{
            display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2,
          }}
          backgroundColor="general.lightGrey6"
        >
          <Typography textAlign="center">
            <b>Please present this QR code to the budtender at the pickup register.</b>
          </Typography>
        </QrCodeWrapper>
      )}
      <Typography
        fontWeight={600}
        my={3}
        textAlign="center"
      >
        {shortOrderId ? `Order # ${shortOrderId}` : 'Order'}
      </Typography>
      <StyledBox sx={{ clear: 'both' }}>
        <Typography>
          From:
        </Typography>
        <Typography fontWeight={600}>
          {format(order?.orderedAt || Date.now(), EXTENDED_ORDER_DATE_FORMAT)}
        </Typography>

        <Typography mt={1}>
          Total:
        </Typography>
        <Typography fontWeight={600}>
          {formatReward(order?.orderTotal, '0')}
        </Typography>
      </StyledBox>
      {order?.status && (
        <StyledStatusWrapper my={2}>
          <Typography>
            {`[${ORDER_STATUS[order?.status]}]`}
          </Typography>
        </StyledStatusWrapper>
      )}
      {order && (
        <Grid container spacing={2} mb={4}>
          <Grid size={{ xs: 12, md: 4 }}>
            <StyledBox hasBackground>
              <OrderContactInfo customer={order?.customerInfo?.customer} state={state} />
            </StyledBox>
          </Grid>

          <Grid size={{ xs: 12, md: 4 }}>
            <StyledBox hasBackground>
              <OrderTypeInfo
                delivery={order?.customerInfo?.delivery}
                address={isPickupMethod(order?.customerInfo?.delivery?.method)
                  ? locationInfo?.address
                  : order?.shippingAddress}
              />
            </StyledBox>
          </Grid>

          <Grid size={{ xs: 12, md: 4 }}>
            <StyledBox hasBackground>
              <OrderPaymentInfo order={order} cartDetails={cartDetails} />
            </StyledBox>
          </Grid>
        </Grid>
      )}
      {order?.productsInfo?.products && (
        <Box mt={3}>
          {Object.values(order?.productsInfo?.products)?.map((product) => (
            <Box width="100%" key={product?.id}>
              <OrderProductPreview product={product} />
              <Divider sx={{ mt: 1, mb: 3.5 }} />
            </Box>
          ))}
        </Box>
      )}
      <Box width="100%" display="flex" justifyContent="flex-end" my={2}>
        <CartTotal
          cartPriceDetails={cartDetails}
          sx={{ minWidth: '200px', opacity: 0.7, my: 2 }}
          productsCount={calculateTotalQuantity(order?.productsInfo?.products)}
        />
      </Box>
      {isLoading && <Loader />}
    </Root>
  );
};

export default OrderDigitalReceiptPage;
