import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import { ListItemIcon, Typography, MenuItem } from '@mui/material';
import { FC } from 'react';

import UnstyledLink from 'components/UnstyledLink';
import { LinkBox, Menu } from 'containers/User/UserMenu/index.styled';

import { getOrderRoute } from 'hooks/useRouting';

interface UserMenuProps {
  anchorEl: null | HTMLElement;
  onClose: () => void;
  onLogout: () => void;
  managementMode: boolean;
}

const UserMenu:FC<UserMenuProps> = ({
  anchorEl,
  onClose,
  onLogout,
  managementMode,
}) => {
  const open = Boolean(anchorEl);

  return (
    <Menu
      id="user-menu"
      anchorEl={anchorEl}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      keepMounted
      open={open}
      onClose={onClose}
    >
      {!managementMode && (
        <MenuItem onClick={onClose}>
          <UnstyledLink to={getOrderRoute()}>
            <LinkBox>
              <ListItemIcon>
                <ShoppingBagOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <Typography color="textSecondary" component="span">
                My orders
              </Typography>
            </LinkBox>
          </UnstyledLink>
        </MenuItem>
      )}
      <MenuItem onClick={onLogout}>
        <>
          <ListItemIcon>
            <LogoutOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <Typography color="textSecondary">
            Logout
          </Typography>
        </>
      </MenuItem>
    </Menu>
  );
};

export default UserMenu;
