import { Box } from '@mui/material';

import PageTitle from 'components/PageTitle';
import Item from 'containers/WelcomePage/Item';

import { HOME_ROUTE, POS_ROUTE, INVENTORY_ROUTE } from 'constants/clientRoutes';

const WelcomePage = () => (
  <Box mt={10}>
    <PageTitle title="Choose your vibe today" />
    <Box display="flex" justifyContent="center" gap={2}>
      <Item
        to={HOME_ROUTE}
        label="Kiosk"
      />
      <Item
        to={POS_ROUTE}
        label="POS"
      />
      <Item
        to={INVENTORY_ROUTE}
        label="Inventory"
      />
    </Box>
  </Box>
);

export default WelcomePage;
