import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserContext } from 'context/UserContext';

import { getCheckoutRoute } from 'hooks/useRouting';
import useSummary from 'hooks/useSummary';

import { reportAlert } from 'utils/utils';

const useCartListing = () => {
  const navigate = useNavigate();
  const { paymentAccount } = useContext(UserContext);
  const [summaryFailedDetails, setSummaryFailedDetails] = useState<{ hasFailed: boolean; productSkus: string[] }>({
    hasFailed: false,
    productSkus: [],
  });
  const {
    checkSummary,
    formattedCartDetails,
    isDisabled,
    updatedProducts,
    cartPriceDetails,
    isLoading: isSummaryLoading,
  } = useSummary();

  const showMedicalBalance = Boolean(!summaryFailedDetails?.hasFailed && !isSummaryLoading
    && (!paymentAccount?.alleavesCustomerId || formattedCartDetails.balances));

  const verifySummary = async () => {
    try {
      await checkSummary();
      setSummaryFailedDetails({
        hasFailed: false,
        productSkus: [],
      });
    } catch (e: any) {
      setSummaryFailedDetails({
        hasFailed: true,
        productSkus: e?.data?.productSkus || [],
      });
      reportAlert(`summary failed: ${JSON.stringify(e)}`);
    }
  };

  const goToCheckout = () => {
    navigate(getCheckoutRoute());
  };

  return {
    goToCheckout,
    verifySummary,
    summaryFailedDetails,
    showMedicalBalance,
    checkSummary,
    formattedCartDetails,
    isDisabled,
    updatedProducts,
    cartPriceDetails,
    isSummaryLoading,
  };
};

export default useCartListing;
