const config = {
  id: 'stash',
  title: 'Stash',
  name: 'Stash app',
  brandName: 'Stash',
  appLinkSchema: 'stash',
  appTld: 'mystash.co',
  TERMS_OF_SERVICE_URL: 'http://mystash.co/terms/',
  PRIVACY_STATEMENT_URL: 'http://mystash.co/privacy/',
  googleMapsKey: 'AIzaSyDklVnT0bZovfpKEKfX_rpg_jhsTm5O0XA',
  tags: ['all'],
};

export default config;
