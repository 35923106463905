import { Box, SxProps } from '@mui/material';
import { FC, ReactNode } from 'react';

import ShopContent from 'components/_ShopContent';

interface CustomContentProps {
  kioskMode?: boolean;
  children: ReactNode | ReactNode[];
  sx?: SxProps;
  className?: string;
}

const CustomContent:FC<CustomContentProps> = ({
  children,
  kioskMode,
  sx = {},
  ...remainingProps
}) => (
  kioskMode
    ? <Box p={3} sx={sx} {...remainingProps}>{children}</Box>
    : <ShopContent sx={sx}>{children}</ShopContent>
);

export default CustomContent;
