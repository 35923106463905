import { Skeleton } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';

export const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  height: '300px',
  [theme.breakpoints.down('md')]: {
    height: '200px',
  },
  [theme.breakpoints.down('sm')]: {
    height: '150px',
  },
})) as typeof Skeleton;

export const Drawer = styled(MuiDrawer)(({ theme }) => ({
  '& > .MuiPaper-root': {
    width: '700px',
    padding: theme.spacing(4),

    [theme.breakpoints.down(700)]: {
      width: '100%',
    },
  },
}));
