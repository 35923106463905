import { Box, SliderThumb } from '@mui/material';
import { HTMLAttributes } from 'react';

interface AirbnbSliderThumbProps extends HTMLAttributes<unknown> {}

const AirbnbSliderThumb = (props: AirbnbSliderThumbProps) => {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <Box display="flex" flexDirection="column" gap={0.25}>
        <span className="airbnb-bar" />
        <span className="airbnb-bar" />
      </Box>
    </SliderThumb>
  );
};

export default AirbnbSliderThumb;
