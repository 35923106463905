import { get } from 'lodash';
import { ChangeEvent, FC } from 'react';

import CustomRadioButton from 'components/CustomRadioButton';

import { PAYMENT_METHOD } from 'constants/fields';
import { PlaceOrderInfo } from 'types/checkout.interface';
import { Option } from 'types/option.interface';

interface PaymentFormProps {
  checkoutForm: PlaceOrderInfo;
  fieldErrors: Record<string, string>;
  onChange: (value: any, name: string) => void;
  paymentMethods: Option[];
}

const PaymentForm: FC<PaymentFormProps> = ({
  checkoutForm,
  onChange,
  fieldErrors,
  paymentMethods,
}) => {
  const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>, name: string) => {
    onChange(value, name);
  };

  return (
    <CustomRadioButton
      {...PAYMENT_METHOD}
      grid={{ xs: 6 }}
      variant="subtitle1"
      spacing={2}
      size="medium"
      onChange={handleChange}
      options={paymentMethods}
      value={checkoutForm?.payment?.paymentMethod}
      error={!!get(fieldErrors, PAYMENT_METHOD.name)}
      helperText={get(fieldErrors, PAYMENT_METHOD.name)}
    />
  );
};

export default PaymentForm;
