import { Typography, Box, Grid } from '@mui/material';
import { FC, useContext } from 'react';

import ProductRating from 'components/ProductRating';
import AddProductReview from 'containers/ProductReviews/AddProductReview';
import EmptyReviews from 'containers/ProductReviews/EmptyReviews';
import { StyledReviewSection } from 'containers/ProductReviews/index.styled';
import ReviewDetails from 'containers/ProductReviews/ReviewDetails';
import ReviewOverview from 'containers/ProductReviews/ReviewOverview';
import { UserContext } from 'context/UserContext';

import { MOCK_REVIEW_STATS } from 'constants/mockData';
import { Bounty } from 'types/bounty.interface';
import { Comment } from 'types/comment.interface';
import { getRatingScore } from 'utils/ratingUtils';

interface ProductReviewsProps {
  reviews: Comment[];
  bounty: Bounty;
}

const ProductReviews:FC<ProductReviewsProps> = ({ reviews, bounty }) => {
  const { user } = useContext(UserContext);

  return (
    <Box mt={3}>
      <ProductRating ratingStats={getRatingScore(bounty?.rating || {})} />
      {reviews.length > 0
        ? (
          <Grid container spacing={10}>
            <Grid size={{ xs: 12, md: 4 }}>
              <StyledReviewSection>
                <Typography variant="h6" fontWeight="500" mb={2}>
                  Customer Reviews
                </Typography>
                <ReviewOverview reviewStats={MOCK_REVIEW_STATS} />
                {!user?.isAnonymous && <AddProductReview fullWidth bounty={bounty} />}
              </StyledReviewSection>
            </Grid>
            <Grid size={{ xs: 12, md: 8 }}>
              {Object.values(reviews).map((review) => (
                <Box key={review.id} mb={2}>
                  <ReviewDetails review={review} />
                </Box>
              ))}
            </Grid>
          </Grid>
        )
        : <EmptyReviews bounty={bounty} canAddReview={!user?.isAnonymous} />}
    </Box>
  );
};

export default ProductReviews;
