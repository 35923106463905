import { SxProps, useTheme } from '@mui/material';
import { FC, useContext } from 'react';
import Slider from 'react-slick';

import ProductDetailsPage from 'containers/ProductDetailsPage';
import {
  Headline,
  HeadlineContainer, ImageWrapper, OverlayContainer, Root, StyledCardMedia,
} from 'containers/PromotionBanner/index.styled';
import { SystemContext } from 'context/SystemContext';

import useBountyDetails from 'hooks/useBountyDetails';

import { AttachmentActions } from 'constants/enums';
import { Bounty } from 'types/bounty.interface';
import { Promotion } from 'types/promotion.interface';
import { getBannerSettings } from 'utils/carousel';
import { getOverlayStyle, getPromotionRoute } from 'utils/promotion';
import { checkUrl } from 'utils/yupUtils';

interface PromotionBannerProps {
  promotions: Promotion[];
  sx?: SxProps;
}

const PromotionBanner:FC<PromotionBannerProps> = (props) => {
  const { promotions, sx } = props;
  const { selectedBounty, openBountyDetails, closeBountyDetails } = useBountyDetails();
  const theme = useTheme();
  const { detailedClientData } = useContext(SystemContext);
  const settings = getBannerSettings(theme, promotions?.length > 1);

  const handleClickPromotion = (promotion: Promotion): void => {
    const { actionUrl, action } = promotion;
    const baseUrl = `${detailedClientData?.baseUrl}${detailedClientData?.basePath}`;

    if (action?.actionType === AttachmentActions.OpenProduct && action?.bountyIds) {
      openBountyDetails(
        action?.bounties?.[0]
          ? action?.bounties?.[0]
          : { id: action?.bountyIds } as Bounty,
      );
      return;
    }

    if (action?.actionType === AttachmentActions.OpenUrl) {
      const url = checkUrl(actionUrl, '');
      if (url) {
        window.open(actionUrl, '_blank');
        return;
      }
    }

    const route = getPromotionRoute(promotion);
    window.location.href = `${baseUrl || window.location.origin}${route}`;
  };

  return (
    <Root sx={sx}>
      <Slider {...settings}>
        {promotions?.map((promotion, index) => (
          <ImageWrapper key={index} onClick={() => handleClickPromotion(promotion)}>
            <StyledCardMedia
              component="img"
              background={promotion.background}
              src={promotion?.cdnImageUrl || promotion?.imageUrl}
              alt={promotion?.alt || promotion?.imageUrl}
            />
            <OverlayContainer sx={getOverlayStyle(promotion.overlay)} />
            <HeadlineContainer>
              {promotion?.headline1?.text && (
                <Headline headline={promotion.headline1}>
                  {promotion.headline1.text}
                </Headline>
              )}
              {promotion?.headline2?.text && (
                <Headline headline={promotion.headline2}>
                  {promotion.headline2.text}
                </Headline>
              )}
            </HeadlineContainer>
          </ImageWrapper>
        ))}
      </Slider>
      <ProductDetailsPage
        bountyDetails={selectedBounty}
        onClose={closeBountyDetails}
        isOpen={!!selectedBounty}
      />
    </Root>
  );
};

export default PromotionBanner;
