import MuiCardMedia from '@mui/material/CardMedia';
import { alpha, styled } from '@mui/material/styles';

export const Root = styled('div')(({ theme }) => ({
  boxShadow: `${alpha(theme.palette.common.black, 0.05)} 0 0 20px inset`,
  borderRadius: '12px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.palette.secondary.main,
  padding: theme.spacing(0, 3),
  width: '100%',
  height: '100%',

  '.MuiSvgIcon-root': {
    height: '140px',
    width: '140px',
  },
}));

export const CardMedia = styled(MuiCardMedia)(({ theme }) => ({
  height: '140px',
  width: '140px',

  [theme.breakpoints.down('sm')]: {
    height: '100px',
    width: '100px',
  },
})) as typeof MuiCardMedia;
