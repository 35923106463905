import { Box } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

export const Wrapper = styled(Box)(({ theme }) => ({
  borderBottom: `2px solid ${theme.palette.general.lightGrey}`,
  padding: theme.spacing(2, 0),
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(2),
  fontSize: '14px',
}));

export const Step = styled('div')<{ isActive?: boolean }>(({ theme, isActive }) => ({
  border: `2px solid ${theme.palette.general.lightGrey}`,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.common.white,
  color: theme.palette.general.lightGrey,
  height: '25px',
  width: '25px',
  ...(isActive ? {
    border: 'none',
    backgroundColor: alpha(theme.palette.primary.main, 0.7),
    color: theme.palette.getContrastText(alpha(theme.palette.primary.main, 0.7)),
    height: '27px',
    width: '27px',
  } : {}),
}));
