import { FC, ReactElement } from 'react';

import Kiosk from 'layouts/AppLayout/Kiosk';
import Shop from 'layouts/AppLayout/Shop';

import { useKiosk } from 'hooks/useKiosk';

import { ClientInfoInterface } from 'types/clientInfo.interface';

interface AppLayoutProps {
  children: ReactElement;
  hasStores?: boolean;
  clientInfo?: ClientInfoInterface | null;
  hideFooter?: boolean;
  isClosed?: boolean;
  hideNavbarActions?: boolean;
}

const AppLayout:FC<AppLayoutProps> = ({
  clientInfo = null,
  children,
  hasStores,
  hideFooter = false,
  isClosed = false,
  hideNavbarActions = false,
}) => {
  const { kioskMode } = useKiosk();

  return (
    kioskMode
      ? (
        <Kiosk clientInfo={clientInfo}>
          {children}
        </Kiosk>
      ) : (
        <Shop
          clientInfo={clientInfo}
          hasStores={hasStores}
          hideFooter={hideFooter}
          isClosed={isClosed}
          hideNavbarActions={hideNavbarActions}
        >
          {children}
        </Shop>
      )
  );
};

export default AppLayout;
