import { useContext, useMemo } from 'react';

import CustomAccordionSection from 'components/CustomAccordionSection';
import CategoryIcon from 'containers/Categories/CategoryIcon';
import Subcategories from 'containers/Categories/Subcategories';
import { CategoryContext, CategoryDispatchContext } from 'context/CategoryContext';

const SubcategoriesSection = () => {
  const { categories, selectedCategories } = useContext(CategoryContext);
  const { onCategoryChanges } = useContext(CategoryDispatchContext);
  const mainCategory = useMemo(
    () => categories.find((category) => category.code === selectedCategories[0]),
    [selectedCategories[0]],
  );

  if (!mainCategory?.children) {
    return null;
  }

  return (
    <CustomAccordionSection
      sx={{ mb: 3 }}
      defaultExpanded
      name={mainCategory?.name || 'All categories'}
      icon={<CategoryIcon size={24} category={mainCategory} />}
    >
      <Subcategories
        onCategoryChanges={onCategoryChanges}
        categories={mainCategory?.children}
        selectedCategories={selectedCategories}
        level={1}
        startLevel={1}
      />
    </CustomAccordionSection>
  );
};

export default SubcategoriesSection;
