import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { IconButton } from '@mui/material';
import { FC } from 'react';

import { Chip } from 'containers/User/index.styled';

import { useKiosk } from 'hooks/useKiosk';

interface LoginButtonProps {
  onClick: () => void;
}

const LoginButton:FC<LoginButtonProps> = ({ onClick }) => {
  const { kioskMode } = useKiosk();
  return (
    kioskMode ? (
      <IconButton onClick={onClick} className="headerBackground">
        <AccountCircleOutlinedIcon />
      </IconButton>
    ) : (
      <Chip
        label="Account"
        variant="outlined"
        onClick={onClick}
        icon={<AccountCircleOutlinedIcon />}
      />
    )
  );
};

export default LoginButton;
