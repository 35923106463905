import MuiCancelIcon from '@mui/icons-material/Cancel';
import MuiSearchIcon from '@mui/icons-material/Search';
import { alpha, styled } from '@mui/material/styles';

import CustomInput from 'components/CustomInput';

export const CancelIcon = styled(
  MuiCancelIcon,
  { shouldForwardProp: (props) => props !== 'kioskMode' },
)<{ kioskMode: boolean }>(({ kioskMode, theme }) => ({
  cursor: 'pointer',
  fill: kioskMode
    ? theme.palette.getContrastText(theme.palette.headerBackground.main)
    : theme.palette.general.lightGrey2,
}));

export const SearchIcon = styled(
  MuiSearchIcon,
  { shouldForwardProp: (props) => props !== 'kioskMode' },
)<{ kioskMode: boolean }>(({ kioskMode, theme }) => ({
  fill: kioskMode
    ? theme.palette.getContrastText(theme.palette.headerBackground.main)
    : theme.palette.general.lightGrey2,
  cursor: 'pointer',
}));

export const SearchInput = styled(
  CustomInput,
  { shouldForwardProp: (props) => props !== 'kioskMode' },
)<{ kioskMode?: boolean }>(({ kioskMode = false, theme }) => ({
  '&.searchInput': {
    ...(kioskMode ? {
      marginRight: theme.spacing(1),

      '& input': {
        padding: theme.spacing(0.25, 3, 0.25, 1),
        color: theme.palette.getContrastText(theme.palette.headerBackground.main),

        '&::placeholder': {
          color: theme.palette.getContrastText(theme.palette.headerBackground.main),
          opacity: 0.6,
        },
      },

      '& .MuiInputBase-root': {
        paddingLeft: theme.spacing(1.75),
        borderRadius: '32px',
        backgroundColor: alpha(theme.palette.getContrastText(theme.palette.headerBackground.main), 0.3),
      },

      '& .MuiOutlinedInput-root:hover fieldset': {
        borderColor: theme.palette.getContrastText(theme.palette.headerBackground.main),
      },

      '& .Mui-focused fieldset, fieldset': {
        borderColor: theme.palette.getContrastText(theme.palette.headerBackground.main),
        borderWidth: '2px',
      },
    } : {
      '& input': {
        width: '100%',
        height: '38px',
        padding: theme.spacing(0.25, 3, 0.25, 1),

        '&::placeholder': {
          ...theme.typography.header,
        },
      },

      '& .MuiInputBase-root': {
        paddingLeft: theme.spacing('13px'),
        borderRadius: '6px',
      },

      '& .MuiOutlinedInput-root:hover fieldset': {
        borderColor: theme.palette.general.lightGrey10,
      },

      '& .Mui-focused fieldset, fieldset': {
        borderColor: theme.palette.general.lightGrey10,
        borderWidth: '2px',
      },
    }),
  },
}));
