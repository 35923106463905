import MuiSearchIcon from '@mui/icons-material/Search';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiFormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';

import CustomInput from 'components/CustomInput';

export const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: 0,
}));

export const FormControlLabel = styled(
  MuiFormControlLabel,
  { shouldForwardProp: (props) => props !== 'kioskMode' },
)<{ kioskMode?: boolean }>(({ theme, kioskMode }) => ({
  marginRight: 0,
  '&.MuiFormControlLabel-root': {
    alignItems: 'flex-start',
  },
  '& .MuiFormControlLabel-label': {
    paddingTop: kioskMode ? '12px' : '9px',
    color: theme.palette.text.primary,
  },
}));

export const SearchIcon = styled(MuiSearchIcon)(({ theme }) => ({
  fill: theme.palette.general.lightGrey12,
}));

export const SearchInput = styled(CustomInput)(({ theme }) => ({
  '&.searchInput': {
    margin: theme.spacing(1, 0),

    '& input': {
      width: '100%',
      padding: theme.spacing(1),

      '&::placeholder': {
        ...theme.typography.header,
      },
    },

    '& .MuiInputBase-root': {
      paddingLeft: theme.spacing(1.5),
      borderRadius: '6px',
    },

    '& .Mui-focused fieldset, fieldset': {
      borderWidth: '2px',
    },
  },
}));
