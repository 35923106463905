import CheckoutProviderPage from 'containers/CheckoutProviderPage';
import LandingPage from 'containers/LandingPage';
import OrderConfirmationPage from 'containers/OrderConfirmationPage';
import OrderDetailsPage from 'containers/OrderDetailsPage';
import OrderDigitalReceiptPage from 'containers/OrderDigitalReceiptPage';
import OrdersPage from 'containers/OrdersPage';
import PaymentWidgetPage from 'containers/PaymentWidgetPage';
import ProductsProviderPage from 'containers/ProductsProviderPage';
import { LocationContextProvider } from 'context/LocationContext';

import { useNavigationSearchParams } from 'hooks/useRouting';

import { ShopPages } from 'constants/enums';
import { getShopConfiguration } from 'utils/storeUtils';

const { asFullPage } = getShopConfiguration();

const MainShop = () => {
  const { page, order } = useNavigationSearchParams();

  const handleScroll = () => {
    if (!asFullPage) {
      const elem = document.getElementById('subheader');
      elem?.scrollIntoView();
    }
  };

  const renderContent = () => {
    handleScroll();

    switch (page) {
      case ShopPages.Products:
        return <ProductsProviderPage />;
      case ShopPages.Orders:
        return order ? <OrderDetailsPage /> : <OrdersPage />;
      case ShopPages.DigitalReceipt:
        return (
          <LocationContextProvider>
            <OrderDigitalReceiptPage />
          </LocationContextProvider>
        );
      case ShopPages.Checkout:
        return <CheckoutProviderPage />;
      case ShopPages.CheckoutComplete:
        return <OrderConfirmationPage />;
      case ShopPages.CheckoutPayment:
        return <PaymentWidgetPage />;
      default:
        return <LandingPage />;
    }
  };

  return renderContent();
};

export default MainShop;
