import { styled } from '@mui/material/styles';

export const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  padding: theme.spacing(2, 0),
  marginBottom: theme.spacing(6),

  '.MuiTabs-root': {
    alignItems: 'center',
  },

  '.MuiTab-root': {
    backgroundColor: 'transparent',
    border: 'none',
    padding: 0,
    minHeight: '0',
    marginRight: theme.spacing(1),
  },
}));
