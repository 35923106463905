import { Box, Typography } from '@mui/material';
import { FC, useContext, useMemo } from 'react';

import Brand from 'components/Brand';
import CertificateOfAuthenticity from 'components/CertificateOfAuthenticity';
import ChemicalInformation from 'components/ChemicalInformation';
import FreeTag from 'components/FreeTag';
import ImagesCarousel from 'components/ImagesCarousel';
import { StyledImage } from 'components/ProductCard/index.styled';
import ProductPrice from 'components/ProductPrice';
import ProductWeight from 'components/ProductWeight';
import ProductDetailsSkeleton from 'containers/ProductDetailsPage/ProductDetails/index.skeleton';
import StrainType from 'containers/ProductDetailsPage/StrainType';
import ProductPriceSection from 'containers/ProductPriceSection';
import { LocationContext } from 'context/LocationContext';

import { useKiosk } from 'hooks/useKiosk';

import { Bounty } from 'types/bounty.interface';
import { Cart } from 'types/cart.interface';
import { filterProductPicture } from 'utils/attachmentsUtils';
import { getProductPrice, getTopLevelVariant, isFree } from 'utils/priceUtils';
import {
  formatProductWeight,
  getManufacturer,
  getProductStrainType,
  getSortedChemicals,
} from 'utils/productUtils';
import { getPublicUrlForImage } from 'utils/publicUrl';

import Dot from 'assets/themes/styled/Dot';

interface ProductDetailsProps {
  bounty?: Bounty | null;
  isLoading: boolean;
  isCheckingSummary?: boolean;
  onClose: () => void;
  onSubmit?: (updatedCart: Cart) => void;
  className?: string;
}

const ProductDetails:FC<ProductDetailsProps> = ({
  bounty,
  isLoading,
  onClose,
  onSubmit,
  isCheckingSummary = false,
  className = '',
}) => {
  const { kioskMode } = useKiosk();
  const { product, tags } = bounty || {};
  const { chemicalComposition, certificateOfAuthenticityUrl } = product || {};
  const manufacturer = getManufacturer(product);
  const { onboardingInfo } = useContext(LocationContext);
  const { price, msrp } = getProductPrice(product, onboardingInfo?.useType);

  const { weightUnit, quantity } = getTopLevelVariant(product) || {};
  const productQuantity = Math.max(0, quantity || 0);
  const isOutOfStock = productQuantity <= 0;
  const isProductFree = isFree(price);
  const { allPictures } = bounty ? filterProductPicture(bounty) : { allPictures: [] };

  const strainType = tags?.tags ? getProductStrainType(tags?.tags) : null;

  const sortedChemicals = useMemo(() => (
    chemicalComposition
      ? getSortedChemicals(chemicalComposition)
      : []
  ), [chemicalComposition]);

  const renderPrice = () => {
    if (isOutOfStock) {
      return <Typography fontWeight={700} color="error">Out of stock</Typography>;
    }

    if (isProductFree) {
      return <FreeTag fontWeight={700} variant="body1" />;
    }

    return <ProductPrice price={price} msrp={msrp} fontWeight={500} variant="h5" />;
  };

  return (
    <Box className={className}>
      {isLoading
        ? <ProductDetailsSkeleton />
        : (
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box margin="0 auto" height={350}>
              {allPictures?.length ? (
                <ImagesCarousel
                  pictures={allPictures}
                />
              ) : (
                <StyledImage
                  component="img"
                  src={getPublicUrlForImage('images/placeholder_image.jpg')}
                  alt={product?.name}
                />
              )}
            </Box>
            <Box mb={1} textAlign="center">
              {manufacturer && (
                <Brand brand={manufacturer} variant="body3" />
              )}
              <Typography variant="h5" fontWeight="500" mt={2} mb={1}>
                {product?.name}
              </Typography>
              <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
                {renderPrice()}
                {product?.sortWeight && (
                  <>
                    <Dot />
                    <ProductWeight
                      fontWeight={500}
                      variant="h5"
                      weight={formatProductWeight(product.sortWeight)}
                      weightUnit={weightUnit}
                    />
                  </>
                )}
              </Box>
            </Box>

            {bounty && (
              <Box width="100%">
                <ProductPriceSection
                  fullWidth
                  isLoading={isCheckingSummary}
                  bounty={bounty}
                  onSuccess={onSubmit}
                  onCancel={onClose}
                />
              </Box>
            )}

            <Typography mt={4} mb={2}>
              {product?.description}
            </Typography>

            {certificateOfAuthenticityUrl && !kioskMode && (
              <CertificateOfAuthenticity url={certificateOfAuthenticityUrl} />
            )}

            <Box width="100%">
              {strainType && (
                <Box mb={1}>
                  <StrainType strainType={strainType} size={[12]} />
                </Box>
              )}
              {sortedChemicals && sortedChemicals.length > 0 && (
                <ChemicalInformation chemicalComposition={sortedChemicals} />
              )}
            </Box>
          </Box>
        )}
    </Box>
  );
};

export default ProductDetails;
