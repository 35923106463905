import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const UserInfo = styled('div')<{ managementMode: boolean }>(({ theme, managementMode }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  gap: theme.spacing(1),

  '& .MuiTypography-root': {
    marginRight: theme.spacing(1),
  },

  [theme.breakpoints.down('md')]: {
    '& .MuiTypography-root': {
      display: managementMode ? 'inherit' : 'none',
    },
  },
}));

export const NameTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.getContrastText(theme.palette.headerBackground.main),
}));
