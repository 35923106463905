import { Box, Typography } from '@mui/material';
import { FC, useContext, useState } from 'react';
import { toast } from 'react-toastify';

import IncrementWrapper from 'components/IncrementWrapper';
import ProductActions from 'containers/ProductDetailsPage/ProductActions';
import { CartContext, CartDispatchContext } from 'context/CartContext';

import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { useKiosk } from 'hooks/useKiosk';

import { Bounty } from 'types/bounty.interface';
import { Cart } from 'types/cart.interface';
import { getTopLevelVariant } from 'utils/priceUtils';
import { getRealQuantity } from 'utils/productUtils';

interface QuantitySelectorProps {
  bounty: Bounty;
  onSuccess?: (cart: Cart) => void;
  isLoading?: boolean;
  isSmall?: boolean;
  fullWidth?: boolean;
  submitLabel?: string;
  cancelLabel?: string;
  onCancel: () => void;
}

const QuantitySelector:FC<QuantitySelectorProps> = ({
  bounty,
  onSuccess,
  isLoading,
  isSmall = false,
  fullWidth = false,
  submitLabel = 'Add to Cart',
  cancelLabel = 'Cancel',
  onCancel,
}) => {
  const cart = useContext(CartContext);
  const { kioskMode } = useKiosk();
  const { addItem } = useContext(CartDispatchContext);
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const { product } = bounty;
  const { quantity, maxAllowedQty } = getTopLevelVariant(product) || {};
  const productQuantity = Math.max(0, quantity || 0);
  const realQuantity = getRealQuantity(quantity, bounty.id, cart);
  const allowableQuantity = Math.min(realQuantity, maxAllowedQty || Number.MAX_VALUE);
  const showRemainingStock = realQuantity > 0 && realQuantity <= 10;

  const { handleTrackAddItemToCart } = useGoogleAnalytics();

  const decreaseQuantity = () => {
    if (isLoading) {
      return;
    }

    setSelectedQuantity((prevQuantity) => (prevQuantity > 1 ? selectedQuantity - 1 : 1));
  };

  const increaseQuantity = () => {
    if (isLoading) {
      return;
    }

    setSelectedQuantity((prevQuantity) => (
      prevQuantity === allowableQuantity ? selectedQuantity : selectedQuantity + 1
    ));
  };

  const handleAddToCart = () => {
    addItem({
      bounty,
      quantity: selectedQuantity,
      onSuccess: (updatedCart) => {
        setSelectedQuantity(1);
        handleTrackAddItemToCart({ bounty, selectedQuantity });

        if (!kioskMode) toast.success('Product added to cart!');
        if (onSuccess) onSuccess(updatedCart);
      },
    });
  };

  return (
    <Box width="100%">
      {allowableQuantity > 0 && (
        <IncrementWrapper
          isSmall={isSmall}
          kioskMode={kioskMode}
          onDecrease={decreaseQuantity}
          onIncrease={increaseQuantity}
        >
          <Typography p={1} textAlign="center" flexGrow={1}>
            {selectedQuantity}
          </Typography>
        </IncrementWrapper>
      )}

      {showRemainingStock && (
        <Typography variant="body2" fontWeight={600} color="error" mt={2}>
          Only
          {' '}
          {allowableQuantity}
          {' '}
          left in stock!
        </Typography>
      )}

      <ProductActions
        fullWidth={fullWidth}
        onCancel={onCancel}
        onSubmit={handleAddToCart}
        disabled={isLoading || productQuantity <= 0}
        cancelLabel={cancelLabel}
        submitLabel={submitLabel}
      />
    </Box>
  );
};

export default QuantitySelector;
