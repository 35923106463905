import axios from 'axios';

import { getHeaders } from 'utils/apiUtils';

export const runGetApiRequest = async (endpoint: string, params: any = {}, enforceClient = false) => (
  axios.get(
    endpoint,
    {
      params,
      headers: getHeaders(enforceClient),
    },
  )
);

export const runPostApiRequest = async (endpoint: string, data?: any, params: any = {}) => (
  axios.post(
    endpoint,
    data,
    {
      params,
      headers: getHeaders(),
    },
  )
);

export const runUpdateApiRequest = async (endpoint: string, data: any, params: any = {}) => (
  axios.put(
    endpoint,
    data,
    {
      params,
      headers: getHeaders(),
    },
  )
);

export const runPatchApiRequest = async (endpoint: string, data: any, params: any = {}) => (
  axios.patch(
    endpoint,
    data,
    {
      params,
      headers: getHeaders(),
    },
  )
);

export const runDeleteApiRequest = async (endpoint: string, params: any = {}) => (
  axios.delete(
    endpoint,
    {
      params,
      headers: getHeaders(),
    },
  )
);
