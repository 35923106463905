import { Box, Typography } from '@mui/material';
import { FieldErrors, UseFormRegister } from 'react-hook-form';

import CustomInput from 'components/CustomInput';

import { NewCustomerFormData } from 'validation/newCustomerSchema';

interface PersonalInformationSectionProps {
  register: UseFormRegister<NewCustomerFormData>;
  errors: FieldErrors<NewCustomerFormData>;
}

const PersonalInformationSection = ({ register, errors }: PersonalInformationSectionProps) => (
  <Box sx={{ flex: 1 }}>
    <Typography variant="h6" mb={2}>Personal Information</Typography>
    <Box display="flex" flexDirection="column" gap={2}>
      <CustomInput
        {...register('firstName')}
        size="small"
        label="First Name"
        fullWidth
        error={!!errors.firstName}
        helperText={errors.firstName?.message}
      />
      <CustomInput
        {...register('lastName')}
        size="small"
        label="Last Name"
        fullWidth
        error={!!errors.lastName}
        helperText={errors.lastName?.message}
      />
      <CustomInput
        {...register('email')}
        size="small"
        label="Email"
        fullWidth
        error={!!errors.email}
        helperText={errors.email?.message}
      />
      <CustomInput
        {...register('phone')}
        size="small"
        label="Phone"
        fullWidth
        error={!!errors.phone}
        helperText={errors.phone?.message}
      />
      <CustomInput
        {...register('dateOfBirth')}
        size="small"
        label="Date of Birth"
        type="date"
        fullWidth
        InputLabelProps={{ shrink: true }}
        error={!!errors.dateOfBirth}
        helperText={errors.dateOfBirth?.message}
      />
    </Box>
  </Box>
);

export default PersonalInformationSection;
