import MuiIconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

export const IconButton = styled(MuiIconButton)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: theme.palette.general.lightGrey6,
  color: theme.palette.text.primary,
  borderRadius: 8,
  padding: theme.spacing(1.5),
}));
