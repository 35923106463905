import { Grid, SelectChangeEvent } from '@mui/material';
import { get } from 'lodash';
import { ChangeEvent, FC } from 'react';

import CustomInput from 'components/CustomInput';
import PlacesAutocomplete from 'components/PlacesAutocomplete';

import { CUSTOMER_ADDRESS } from 'constants/address';
import {
  CUSTOMER_CITY,
  CUSTOMER_ADDRESS_LINE1,
  CUSTOMER_STATE,
  CUSTOMER_ZIP_CODE,
} from 'constants/fields';
import { Address } from 'types/address.interface';
import { isAddressAutocomplete } from 'utils/checkoutUtils';

interface CustomerAddressFormProps {
  onChange: (e: ChangeEvent<HTMLInputElement> | SelectChangeEvent) => void;
  onValueChange: (value: any, name: string) => void;
  address?: Address;
  fieldErrors: Record<string, string>;
  required?: boolean;
}

const CustomerAddressForm :FC<CustomerAddressFormProps> = ({
  onChange,
  onValueChange,
  address,
  fieldErrors,
  required = false,
}) => (
  <Grid container spacing={4}>
    <Grid size={{ xs: 12 }}>
      {isAddressAutocomplete() ? (
        <PlacesAutocomplete
          {...CUSTOMER_ADDRESS_LINE1}
          required={required}
          onValueChange={onValueChange}
          defaultValue={address?.line1 || ''}
          error={!!get(fieldErrors, CUSTOMER_ADDRESS_LINE1.name)}
          helperText={get(fieldErrors, CUSTOMER_ADDRESS_LINE1.name)}
          fieldsConfig={CUSTOMER_ADDRESS}
        />
      ) : (
        <CustomInput
          {...CUSTOMER_ADDRESS_LINE1}
          required={required}
          label="Address"
          size="small"
          onChange={(e) => onChange(e as ChangeEvent<HTMLInputElement>)}
          value={address?.line1 || ''}
          error={!!get(fieldErrors, CUSTOMER_ADDRESS_LINE1.name)}
          helperText={get(fieldErrors, CUSTOMER_ADDRESS_LINE1.name)}
        />
      )}
    </Grid>
    <Grid size={{ xs: 12, sm: 6 }}>
      <CustomInput
        {...CUSTOMER_CITY}
        required={required}
        label="City"
        size="small"
        onChange={(e) => onChange(e as ChangeEvent<HTMLInputElement>)}
        value={address?.city || ''}
        error={!!get(fieldErrors, CUSTOMER_CITY.name)}
        helperText={get(fieldErrors, CUSTOMER_CITY.name)}
      />
    </Grid>
    <Grid size={{ xs: 12, sm: 3 }}>
      <CustomInput
        {...CUSTOMER_ZIP_CODE}
        required={required}
        label="Zipcode"
        size="small"
        onChange={(e) => onChange(e as ChangeEvent<HTMLInputElement>)}
        value={address?.postalCode || ''}
        error={!!get(fieldErrors, CUSTOMER_ZIP_CODE.name)}
        helperText={get(fieldErrors, CUSTOMER_ZIP_CODE.name)}
      />
    </Grid>
    <Grid size={{ xs: 12, sm: 3 }}>
      <CustomInput
        {...CUSTOMER_STATE}
        required={required}
        size="small"
        onChange={(e) => onChange(e as ChangeEvent<HTMLInputElement>)}
        value={address?.state || ''}
        error={!!get(fieldErrors, CUSTOMER_STATE.name)}
        helperText={get(fieldErrors, CUSTOMER_STATE.name)}
      />
    </Grid>
  </Grid>
);

export default CustomerAddressForm;
