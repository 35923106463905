import { AxiosResponse } from 'axios';
import { ref } from 'firebase/database';

import { runGetApiRequest, runPostApiRequest } from 'services/Api';

import { db } from 'config/firebase';
import {
  CREATE_ALLEAVES_CUSTOMER_ROUTE,
  REQUESTS_ROUTE,
  USER_DATA_ROUTE, USER_INFO_ROUTE,
  USER_PAYMENT_ACCOUNT_ROUTE,
} from 'constants/apiRoutes';
import { QueueActions } from 'constants/enums';
import { AlleavesUser } from 'types/alleavesCustomer.interface';
import { UserInfo } from 'types/user.interface';
import { formatRoute } from 'utils/formatters';

export const updateUserInfo = async (userInfo: any) => {
  const endpoint = formatRoute(REQUESTS_ROUTE, { action: QueueActions.UpdateUser });
  return runPostApiRequest(endpoint, userInfo);
};

export const createAlleavesCustomer = async (userInfo: AlleavesUser, merchantId: string) => (
  runPostApiRequest(CREATE_ALLEAVES_CUSTOMER_ROUTE, userInfo, { merchantId })
);

export const getUserPaymentAccount = async (merchantId?: string) => (
  runGetApiRequest(USER_PAYMENT_ACCOUNT_ROUTE, { merchantId })
);

export const getUserData = async (userUid: string) => {
  const endpoint = formatRoute(USER_DATA_ROUTE, { userUid });
  return runGetApiRequest(endpoint);
};

export const getUserInfo = async (): Promise<AxiosResponse<UserInfo>> => runGetApiRequest(USER_INFO_ROUTE);

export const getClientsRef = (userPin: string) => ref(db, `clients/${userPin}`);
