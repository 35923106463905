import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import { Box, Grid, Typography } from '@mui/material';
import React, {
  FC, useContext, useEffect, useMemo, useState,
} from 'react';
import { ObjectSchema } from 'yup';

import EmptyPreviewCart from 'components/EmptyPreviewCart';
import PageTitle from 'components/PageTitle';
import PreviewCartProduct from 'components/PreviewCartProduct';
import {
  DeliveryCard,
  DeliveryWrapper,
  EstimationWrapper,
  Paper,
} from 'containers/CheckoutProviderPage/Checkout/index.styled';
import CheckoutSection from 'containers/CheckoutProviderPage/CheckoutSection';
import CheckoutSummary from 'containers/CheckoutProviderPage/CheckoutSummary';
import ContactInfo from 'containers/CheckoutProviderPage/ContactInfo';
import ContactInfoForm from 'containers/CheckoutProviderPage/ContactInfoForm';
import useCheckoutConfiguration from 'containers/CheckoutProviderPage/KioskCheckoutForm/useCheckoutConfiguration';
import PaymentForm from 'containers/CheckoutProviderPage/PaymentForm';
import ReservationForm from 'containers/CheckoutProviderPage/ReservationForm';
import {
  checkDeliveryMethods,
  getNextSection,
  getPaymentLabel,
  getScheduleTime,
} from 'containers/CheckoutProviderPage/utils';
import { CartContext } from 'context/CartContext';
import { LocationContext } from 'context/LocationContext';
import { SystemContext } from 'context/SystemContext';

import { CheckoutSections, DeliveryMethod, PaymentMethod } from 'constants/enums';
import { DELIVERY_METHOD } from 'constants/fields';
import { CartPriceDetails } from 'types/cart.interface';
import { OrderDateRestrictions, PlaceOrderInfo } from 'types/checkout.interface';
import { isPickupMethod } from 'utils/checkoutUtils';

import { CenteredContent } from 'assets/themes/styled/CenteredContent';

interface CheckoutProps {
  checkoutForm: PlaceOrderInfo;
  fieldErrors: Record<string, string>;
  formattedCartDetails: CartPriceDetails;
  orderDateRestrictions: OrderDateRestrictions | null;
  onOrder: ({ ignoreDialog, shouldRedirect }: { ignoreDialog: boolean; shouldRedirect: boolean }) => Promise<string>;
  onChange: (value: any, name: string) => void;
  onCheckSummary: () => void;
  isSubmitting: boolean;
  isLoadingDateRestrictions: boolean;
  isSummaryLoading: boolean;
  hasSummaryFailed: boolean;
  isSectionValid: (section: CheckoutSections) => Promise<boolean>;
  checkoutFormSchema: ObjectSchema<any>;
}

interface Section {
  isCompleted: boolean;
  isOpen: boolean;
}

const Checkout: FC<CheckoutProps> = ({
  checkoutForm,
  onOrder,
  onChange,
  orderDateRestrictions,
  fieldErrors,
  formattedCartDetails,
  onCheckSummary,
  isSubmitting,
  isLoadingDateRestrictions,
  isSummaryLoading,
  hasSummaryFailed,
  isSectionValid,
  checkoutFormSchema,
}) => {
  const cart = useContext(CartContext);
  const { onboardingInfo } = useContext(LocationContext);
  const { shopSettings } = useContext(SystemContext);
  const [isCompleted, setIsCompleted] = useState(false);
  const [sectionsDetails, setSectionsDetails] = useState<Record<CheckoutSections, Section>>({
    [CheckoutSections.ReservationDetails]: {
      isOpen: true,
      isCompleted: false,
    },
    [CheckoutSections.ContactInfo]: {
      isOpen: false,
      isCompleted: false,
    },
    [CheckoutSections.PaymentMethod]: {
      isOpen: false,
      isCompleted: false,
    },
  });

  const {
    deliveryMethods,
    paymentMethods,
  } = useCheckoutConfiguration({ deliveryMethod: checkoutForm?.delivery?.method });
  const { hasPickup, hasDelivery } = checkDeliveryMethods(deliveryMethods);
  const isPickup = isPickupMethod(checkoutForm?.delivery?.method);
  const products = useMemo(() => Object.values(cart?.products) || [], [cart?.products]);
  const scheduledTime = getScheduleTime(checkoutForm, isPickup);

  useEffect(() => {
    const validateCheckout = async () => {
      try {
        await checkoutFormSchema.validate(checkoutForm, { abortEarly: false });
        setIsCompleted(true);
      } catch (e) {
        setIsCompleted(false);
      }
    };

    validateCheckout();
  }, [checkoutForm, shopSettings.effectiveShopOperation?.hoursOfOperation]);

  const isSectionDisabled = (section: CheckoutSections) => (
    !sectionsDetails[section].isCompleted && !sectionsDetails[section].isOpen
  );

  const handleContinue = async (section: CheckoutSections) => {
    const isValid = await isSectionValid(section);

    if (isValid) {
      const nextSection = getNextSection(section);

      setSectionsDetails((prevState) => ({
        ...prevState,
        [section]: {
          isCompleted: true,
          isOpen: false,
        },
        [nextSection]: {
          ...prevState[nextSection],
          isOpen: true,
        },
      }));
    }
  };

  const handlePlaceOrder = () => onOrder({ ignoreDialog: false, shouldRedirect: true });

  const handleEditSection = (section: CheckoutSections) => {
    setSectionsDetails((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        isOpen: true,
      },
    }));
  };

  return (
    <CenteredContent>
      <PageTitle hasBackButton title="Checkout" />
      {products?.length > 0 ? (
        <>
          <DeliveryWrapper>
            {hasPickup && (
              <DeliveryCard
                isActive={isPickupMethod(checkoutForm?.delivery?.method)}
                onClick={() => onChange(DeliveryMethod.PICKUP, DELIVERY_METHOD.name)}
              >
                <ShoppingBagOutlinedIcon sx={{ height: '20px', width: '20px' }} />
                <Typography fontWeight={600} variant="subtitle1">
                  Pickup
                </Typography>
              </DeliveryCard>
            )}
            {hasDelivery && (
              <DeliveryCard
                isActive={checkoutForm?.delivery?.method === DeliveryMethod.DELIVERY}
                onClick={() => onChange(DeliveryMethod.DELIVERY, DELIVERY_METHOD.name)}
              >
                <LocalShippingOutlinedIcon sx={{ height: '20px', width: '20px' }} />
                <Typography fontWeight={600} variant="subtitle1">
                  Delivery
                </Typography>
              </DeliveryCard>
            )}
          </DeliveryWrapper>
          <Grid container spacing={5}>
            <Grid size={{ xs: 12, md: 6 }}>
              <Box display="flex" flexDirection="column" gap={5}>
                <CheckoutSection
                  title={isPickup ? 'Reservation Details' : 'Delivery Details'}
                  isDisabled={isSectionDisabled(CheckoutSections.ReservationDetails)}
                  onEdit={() => handleEditSection(CheckoutSections.ReservationDetails)}
                  sectionDetails={sectionsDetails[CheckoutSections.ReservationDetails]}
                  FormComponent={(
                    <ReservationForm
                      checkoutForm={checkoutForm}
                      onContinue={() => handleContinue(CheckoutSections.ReservationDetails)}
                      onChange={onChange}
                      fieldErrors={fieldErrors}
                      orderDateRestrictions={orderDateRestrictions}
                      isLoadingDateRestrictions={isLoadingDateRestrictions}
                      checkoutFields={shopSettings?.checkoutFields}
                      deliveryMethods={deliveryMethods}
                    />
                  )}
                  DetailsComponent={(
                    <Typography variant="subtitle1" fontWeight={600}>
                      {scheduledTime}
                    </Typography>
                  )}
                />
                <CheckoutSection
                  title="Contact Info"
                  isDisabled={isSectionDisabled(CheckoutSections.ContactInfo)}
                  onEdit={() => handleEditSection(CheckoutSections.ContactInfo)}
                  sectionDetails={sectionsDetails[CheckoutSections.ContactInfo]}
                  FormComponent={(
                    <ContactInfoForm
                      checkoutForm={checkoutForm}
                      onContinue={() => handleContinue(CheckoutSections.ContactInfo)}
                      checkoutFields={shopSettings?.checkoutFields}
                      onChange={onChange}
                      fieldErrors={fieldErrors}
                      useType={onboardingInfo?.useType}
                    />
                  )}
                  DetailsComponent={<ContactInfo checkoutForm={checkoutForm} />}
                />
                <CheckoutSection
                  title="Payment Method"
                  isDisabled={isSectionDisabled(CheckoutSections.PaymentMethod)}
                  onEdit={() => handleEditSection(CheckoutSections.PaymentMethod)}
                  sectionDetails={sectionsDetails[CheckoutSections.PaymentMethod]}
                  FormComponent={(
                    <PaymentForm
                      paymentMethods={paymentMethods}
                      checkoutForm={checkoutForm}
                      onChange={onChange}
                      fieldErrors={fieldErrors}
                    />
                  )}
                  DetailsComponent={(
                    <Typography variant="subtitle1" fontWeight={600}>
                      {getPaymentLabel(checkoutForm?.payment?.paymentMethod as PaymentMethod)}
                    </Typography>
                  )}
                />
                <Paper elevation={0}>
                  <Typography variant="h4">
                    Review Items
                  </Typography>
                  {products.map((product) => (
                    <PreviewCartProduct
                      product={product}
                      key={product?.id}
                      useType={onboardingInfo?.useType}
                      size="medium"
                    />
                  ))}
                  <EstimationWrapper>
                    <Typography variant="h4">
                      Estimated total
                    </Typography>
                    <Typography variant="h4">
                      {formattedCartDetails?.formattedTotal}
                    </Typography>
                  </EstimationWrapper>
                </Paper>
              </Box>
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <CheckoutSummary
                checkoutForm={checkoutForm}
                handlePlaceOrder={handlePlaceOrder}
                cartPriceDetails={formattedCartDetails}
                isSubmitting={isSubmitting}
                isSummaryLoading={isSummaryLoading}
                hasSummaryFailed={hasSummaryFailed}
                onChangeSummary={onCheckSummary}
                consentMessage={shopSettings?.consentMessage}
                scheduledTime={scheduledTime}
                disabled={!isCompleted}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <Box mt={12}>
          <EmptyPreviewCart hasButton fontSize="large" variant="h6" />
        </Box>
      )}
    </CenteredContent>
  );
};

export default Checkout;
