import Delete from '@mui/icons-material/Delete';
import {
  Box, SelectChangeEvent,
} from '@mui/material';
import {
  FC, KeyboardEventHandler, memo, MouseEventHandler,
} from 'react';

import CustomButton from 'components/CustomButton';
import CustomDropdown from 'components/CustomDropdown';
import CustomInput from 'components/CustomInput';
import { useInventoryContext } from 'context/InventoryContext';

import {
  InventoryConditionItemName,
  InventoryConditionItemType,
  InventoryConditionOperatorName,
} from 'constants/enums';
import {
  InventoryArea,
  InventoryBrand,
  InventoryCategory,
  InventoryStrain,
  InventoryTreeNode,
  InventoryUOM,
  InventoryVendor,
} from 'types/customInventoryFilters.interface';
import { Option } from 'types/option.interface';

import {
  BOOLEAN_CONDITION_OPTIONS,
  CONDITION_ITEMS,
  CONDITION_OPERATORS,
  INVENTORY_CONDITION_OPERATORS_WITHOUT_VALUE,
  INVENTORY_STATUS_OPTIONS,
} from '../constants';

interface ConditionNodeProps {
  node: InventoryTreeNode;
  onDelete: () => void;
  onUpdateNode: (nodeId: string, updates: Partial<InventoryTreeNode>) => void;
}

const ConditionNode: FC<ConditionNodeProps> = ({
  node, onDelete, onUpdateNode,
}) => {
  const {
    brands,
    strains,
    areas,
    uoms,
    categories,
    vendors,
  } = useInventoryContext();
  const type = node.conditionItem?.type;

  const getInventoryBrands = (brands: InventoryBrand[]) => brands.filter((brand) => !brand.deleted).map((brand) => (
    { key: brand.brandName, value: brand.brandName }
  ));

  const getInventoryStrains = (strains: InventoryStrain[]) => strains.map((strain) => (
    { key: strain.strainName, value: strain.strainName }
  ));

  const getInventoryAreas = (areas: InventoryArea[]) => areas.map((area) => (
    { key: area.areaPath, value: area.areaName }
  ));

  const getInventoryVendors = (vendors: InventoryVendor[]) => vendors.map((vendor) => (
    { key: vendor.vendorName, value: vendor.vendorName }
  ));

  const getInventoryUOMs = (uoms: InventoryUOM[]) => uoms.map((uom) => (
    { key: uom.uomName, value: uom.uomName }
  ));

  const getInventoryCategories = (categories: InventoryCategory[]) => categories.filter(
    (category) => category.categoryPath,
  ).map((category) => (
    { key: category.categoryPath, value: category.categoryPath }
  ));

  const optionsMap: Partial<Record<InventoryConditionItemName, Option[]>> = {
    [InventoryConditionItemName.AREA]: getInventoryAreas(areas),
    [InventoryConditionItemName.STATUS]: INVENTORY_STATUS_OPTIONS,
    [InventoryConditionItemName.CATEGORY]: getInventoryCategories(categories),
    [InventoryConditionItemName.BRAND]: getInventoryBrands(brands),
    [InventoryConditionItemName.VENDOR]: getInventoryVendors(vendors),
    [InventoryConditionItemName.STRAIN]: getInventoryStrains(strains),
    [InventoryConditionItemName.STORED_UOM]: getInventoryUOMs(uoms),
  };

  const getOptionsForItem = (name: InventoryConditionItemName) => optionsMap[name] || [];

  const renderConditionValueField = () => {
    if (!type) return null;
    if (INVENTORY_CONDITION_OPERATORS_WITHOUT_VALUE.includes(
        node.conditionOperator?.key as InventoryConditionOperatorName,
    )) return null;

    if (type === InventoryConditionItemType.STRING) {
      return (
        <CustomInput
          placeholder="Please add a value!"
          sx={{
            width: 300,
          }}
          size="small"
          value={node.conditionValue}
          onChange={(e) => {
            onUpdateNode(node.id, { conditionValue: e.target.value as string });
          }}
        />
      );
    }

    if (type === InventoryConditionItemType.BOOLEAN) {
      return (
        <CustomDropdown
          size="small"
          value={node.conditionValue}
          options={BOOLEAN_CONDITION_OPTIONS}
          onChange={(e) => onUpdateNode(node.id, { conditionValue: e.target.value as string })}
        />
      );
    }

    if (type === InventoryConditionItemType.OPTIONS) {
      const options = getOptionsForItem(node.conditionItem!.key);
      const values = Array.isArray(node.conditionValue) ? node.conditionValue : [];
      return (
        <CustomDropdown
          size="small"
          value={values}
          options={options}
          multiple
          onChange={(e) => onUpdateNode(node.id, { conditionValue: e.target.value as string[] })}
        />
      );
    }

    return null;
  };

  const handleConditionItemChange = (e: SelectChangeEvent) => {
    const selectedItem = CONDITION_ITEMS.find((item) => item.key === e.target.value);
    const conditionValue = selectedItem?.type === InventoryConditionItemType.BOOLEAN ? 'true' : '';
    onUpdateNode(node.id, {
      conditionItem: selectedItem,
      conditionValue,
    });
  };

  const handleOperatorChange = (e: SelectChangeEvent) => {
    const selectedOperator = CONDITION_OPERATORS.find(
      (op) => op.key === e.target.value as InventoryConditionOperatorName,
    );
    onUpdateNode(node.id, { conditionOperator: selectedOperator });
  };

  const isBooleanType = node.conditionItem?.type === InventoryConditionItemType.BOOLEAN;
  const filteredOperators = isBooleanType
    ? CONDITION_OPERATORS.filter(
      (op) => [InventoryConditionOperatorName.EQ, InventoryConditionOperatorName.NOT_EQ].includes(op.key),
    )
    : CONDITION_OPERATORS;

  const stopPropagation: MouseEventHandler<HTMLElement> & KeyboardEventHandler<HTMLElement> = (e) => {
    e.stopPropagation();
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      onClick={stopPropagation}
      onMouseDown={stopPropagation}
      onKeyDown={stopPropagation}
      gap={1}
    >
      <CustomDropdown
        size="small"
        value={node.conditionItem?.key ?? InventoryConditionItemName.ITEM}
        options={CONDITION_ITEMS}
        onChange={(e) => {
          handleConditionItemChange(e as SelectChangeEvent);
        }}
      />

      <CustomDropdown
        size="small"
        value={node.conditionOperator?.key ?? InventoryConditionOperatorName.NOT_EQ}
        options={filteredOperators}
        onChange={(e) => {
          handleOperatorChange(e as SelectChangeEvent);
        }}
      />
      <Box>
        {renderConditionValueField()}
      </Box>

      <CustomButton id="button-deleteCondition" size="small" variant="outlined" onClick={onDelete}>
        <Delete />
      </CustomButton>
    </Box>
  );
};

export default memo(ConditionNode);
