import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { Avatar } from '@mui/material';
import { User } from 'firebase/auth';
import { FC, useState, MouseEvent } from 'react';

import { Chip } from 'containers/User/index.styled';
import UserMenu from 'containers/User/UserMenu';
import { NameTypography, UserInfo } from 'containers/User/Username/index.styled';

import { useKiosk } from 'hooks/useKiosk';

import { textTruncate } from 'utils/formatters';

interface UsernameProps {
  onLogout: () => void;
  user: User;
  managementMode: boolean;
}

const Username:FC<UsernameProps> = ({ onLogout, user, managementMode }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const username = user?.displayName || user.email || '';
  const { kioskMode } = useKiosk();

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {kioskMode ? (
        <UserInfo managementMode={managementMode} onClick={handleMenu}>
          <Avatar
            alt={user?.displayName || user.email || ''}
            src={user?.photoURL || '/'}
            sx={{ width: 34, height: 34 }}
          />
          <NameTypography fontSize="16px" noWrap>
            {textTruncate(username, 15)}
          </NameTypography>
        </UserInfo>
      ) : (
        <Chip
          label={textTruncate(username, 15)}
          variant="outlined"
          onClick={handleMenu}
          icon={<AccountCircleOutlinedIcon />}
        />
      )}
      <UserMenu
        managementMode={managementMode}
        anchorEl={anchorEl}
        onClose={handleClose}
        onLogout={onLogout}
      />
    </div>
  );
};

export default Username;
