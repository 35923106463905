import { createTheme } from '@mui/material/styles';

import palette from 'assets/themes/palettes/defaultPalette';
import { kioskVariants, variants } from "assets/themes/variants";
import { getShadowDomRoot } from "utils/utils";
import { CSSProperties } from 'react';

const node = getShadowDomRoot();

const getAppTheme = (customPalette: CustomPaletteProps = {} as CustomPaletteProps, kioskMode?: boolean) => {
  const theme = createTheme({
    palette: {
      ...palette,
      ...customPalette,
    },
    typography: {
      ...(kioskMode ? kioskVariants : variants),
      button: {
        textTransform: 'none',
        ...(kioskMode ? kioskVariants.button : variants.button),
      },
    },
  });

  return {
    ...theme,
    components: {
      MuiPopover: {
        styleOverrides: {
          paper: {
            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 40px'
          }
        },
        defaultProps: {
          container: node,
        },
      },
      MuiPopper: {
        defaultProps: {
          container: node,
        },
      },
      MuiModal: {
        defaultProps: {
          container: node,
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: kioskMode ? '1.2rem' : '1rem',
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: kioskMode ? '2.2rem' : '1.5rem',
            width: kioskMode ? '1em' : '22px',
            height: kioskMode ? '1em' : '22px',
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: kioskMode ? '10px' : '8px',

            '&.headerBackground': {
              color: theme.palette.getContrastText(theme.palette.headerBackground.main),
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontSize: kioskMode ? '1.2rem' : '1rem',
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.common.white,
            borderRadius: kioskMode ? '8px': '6px',

            '& .MuiSelect-select': {
              padding: kioskMode ? '15px 14px' : '12.5px 14px',
            }
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: kioskMode ? '8px': '6px',
          },
          notchedOutline: {
            borderColor: theme.palette.border.main,

            '& legend': {
              lineHeight: '10px',
            },
          },
          input: {
            fontSize: kioskMode ? '1.2rem' : '1rem',
            height: kioskMode ? '40px': '31px',
          }
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            ...(!kioskMode
              ? {
                marginBottom: theme.spacing(1.5),
                '& .MuiTypography-root':{
                  ...theme.typography.subtitle1,
                  fontWeight: 700,
                  textTransform: 'uppercase',
                }
              } : {}),
          },
          asterisk: {
            color: theme.palette.error.main,
          },
        }
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginLeft: 0,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            border: `2px solid ${theme.palette.general.lightGrey3}`,
            backgroundColor: theme.palette.general.lightGrey3,
            color: theme.palette.text.primary,
            marginRight: theme.spacing(2),
            borderRadius: '4px',

            '&.Mui-selected': {
              borderColor: theme.palette.primary.main,
              backgroundColor: 'transparent',
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: () => ({
            display: 'none',
          }),
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
            borderRadius: '8px',
            '&:hover': {
              boxShadow: 'none',
            },

            '&:disabled': {
              opacity: 0.35,
            },
          },
          containedSizeLarge: {
            padding: theme.spacing(1.5),
            fontSize: '1.5rem',
          },
          containedPrimary: {
            color: theme.palette.getContrastText(theme.palette.primary.main),

            '&:disabled': {
              color: theme.palette.getContrastText(theme.palette.primary.main),
              backgroundColor: theme.palette.primary.main,
            },
          },
          containedSecondary: {
            backgroundColor: theme.palette.secondary.main,

            '&:disabled': {
              backgroundColor: theme.palette.secondary.main,
            },
          },
          outlinedPrimary: {
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.common.white,

            '&:hover': {
              borderColor: theme.palette.primary.main,
            },
          },
          outlinedSecondary: {
            borderColor: theme.palette.secondary.main,
            color: theme.palette.secondary.main,

            '&:hover': {
              borderColor: theme.palette.secondary.main,
            },
          },
        },
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            '@media (min-width: 600px)': {
              width: '90%',
            }
          }
        }
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            '& .MuiSvgIcon-root': {
              fill: theme.palette.common.black,
            }
          }
        }
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            color: palette.text.primary,
          }
        }
      },
      MuiChip: {
        styleOverrides: {
          root: {
            borderColor: theme.palette.border.main,
            color: palette.text.secondary,
          },
          sizeMedium: {
            height: kioskMode ? '50px' : '42px',
            fontSize: kioskMode ? '1.3rem' : '1rem',
          }
        }
      },
      MuiDialog: {
        defaultProps: {
          container: node,
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontSize: '1.5rem',
          }
        }
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            '&.headerBackground': {
              color: theme.palette.getContrastText(theme.palette.headerBackground.main),
            },
          }
        },
        variants: [
          {
            props: { variant: 'subheader' },
            style: {
              color: theme.palette.getContrastText(theme.palette.subheaderBackground.main),
              ...theme.typography.body2,
            }
          },
        ]
      },
      MuiBreadcrumbs: {
        styleOverrides: {
          separator: {
            color: theme.palette.getContrastText(theme.palette.subheaderBackground.main),
          }
        }
      },
    },
  }
};

export default getAppTheme;

declare module '@mui/material/styles' {
  interface Palette {
    border: Palette['primary'];
    avatar: Palette['primary'];
    subheaderBackground: Palette['primary'];
    link: Palette['primary'];
    headerBackground: Palette['primary'];
    highlightColor: Palette['primary'];
    input: Palette['primary'];
    general: Palette['primary'];
  }

  interface PaletteColor {
    lightGrey: string;
    lightGrey1: string;
    lightGrey2: string;
    lightGrey3: string;
    lightGrey4: string;
    lightGrey5: string;
    lightGrey6: string;
    lightGrey7: string;
    lightGrey8: string;
    lightGrey9: string;
    lightGrey10: string;
    lightGrey11: string;
    lightGrey12: string;
    lightGrey13: string;
    darkYellow1: string;
    lightYellow1: string;
    lightYellow2: string;
    darkGreen1: string;
    darkGreen2: string;
    lightGreen: string;
    lightGreen1: string;
    lightBlue: string;
    lightBlue1: string;
    lightBlue3: string;
    lightOrange: string;
    messageBubble: string;
    alleavesBackground: string;
  }

  interface TypeBackground {
    main: string;
    secondary: string;
  }

  interface TypographyVariants {
    subheader: CSSProperties;
    header: CSSProperties;
    body3: CSSProperties;
  }

}

export interface CustomPaletteProps {
  primary: {
    main: string;
    secondary: string;
    light: string;
  };
  secondary: {
    main: string;
    secondary: string;
    light: string;
  };
  headerBackground: {
    main: string;
  };
  subheaderBackground: {
    main: string;
  };
  highlightColor: {
    main: string;
  };
  link: {
    main: string;
  };
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    subheader: true;
    header: true;
    body3: true;
    body4: true;
  }
}

export type CustomTypographyVariant = 'subheader' | 'header';
