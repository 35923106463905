import { styled } from '@mui/material/styles';

export const Root = styled('div')<{ allowStickyFooter: boolean }>(({ theme, allowStickyFooter }) => ({
  backgroundColor: theme.palette.general.lightGrey8,
  opacity: 1,
  padding: theme.spacing(3),
  position: allowStickyFooter ? 'sticky' : 'relative',
  bottom: 0,
  display: 'flex',
  alignItems: 'flex-end',
  flexDirection: 'column',

  '& .coupon, & .continueShopping': {
    backgroundColor: theme.palette.general.lightGrey8,
  },
  marginTop: theme.spacing(2),
}));

export const Content = styled('div')(() => ({
  width: '50%',
  position: 'relative',
}));
