import {
  Container, Grid, useTheme,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Suspense, useContext, useEffect, useState,
} from 'react';

import Filters from 'components/Filters';
import FiltersMobile from 'components/FiltersMobile';
import ProductCard from 'components/ProductCard';
import ProductCardSkeleton from 'components/ProductCard/index.skeleton';
import ProductDetailsPage from 'containers/ProductDetailsPage';
import { PreviewContext } from 'context/PreviewProvider';
import PreviewLayout from 'layouts/PreviewLayout';

import useBountyDetails from 'hooks/useBountyDetails';

import { LandingPageType } from 'constants/enums';
import {
  MOCK_PRODUCT,
  MOCK_PRODUCT_FILTER_1,
} from 'constants/mockData';
import { getPreviewChemicals } from 'utils/productUtils';

const PreviewStoreChemicalsPage = () => {
  const theme = useTheme();
  const { gistContent } = useContext(PreviewContext);
  const matchesScreenSizeMd = useMediaQuery(theme.breakpoints.down('md'));
  const chemicalKeys = gistContent?.keys || '';
  const [bounty, setBounty] = useState(MOCK_PRODUCT);
  const { selectedBounty, openBountyDetails, closeBountyDetails } = useBountyDetails();
  const isDashboard = gistContent?.landingPage === LandingPageType.Default;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleSelectFilter = () => (): void => {};

  useEffect(() => {
    if (chemicalKeys) {
      const keys = chemicalKeys?.split(',');
      const chemicals = getPreviewChemicals(gistContent, keys);

      setBounty({ ...MOCK_PRODUCT, product: { ...MOCK_PRODUCT.product, chemicalComposition: chemicals } });
    }
  }, [chemicalKeys]);

  return (
    <PreviewLayout hideFooter hideNavbarActions>
      <div>
        <Container>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, md: 3 }}>
              {matchesScreenSizeMd ? (
                <FiltersMobile
                  isLoading={false}
                  filters={MOCK_PRODUCT_FILTER_1}
                  activeFilters={{}}
                  onSelectFilter={handleSelectFilter}
                />
              ) : (
                <Filters
                  isLoading={false}
                  filters={MOCK_PRODUCT_FILTER_1}
                  activeFilters={{}}
                  onSelectFilter={handleSelectFilter}
                />
              )}
            </Grid>
            <Grid
              container
              spacing={2}
              size={{ xs: 12, md: isDashboard ? 12 : 9 }}
            >
              <Grid size={{
                xs: 12, sm: 6, md: 6, lg: 4,
              }}
              >
                <Suspense fallback={<ProductCardSkeleton />}>
                  <ProductCard
                    openDetails={() => openBountyDetails(bounty)}
                    bounty={bounty}
                    hasAddButton={false}
                    isBrandDisabled
                    isDescriptionVisible
                  />
                </Suspense>
              </Grid>
            </Grid>
          </Grid>
        </Container>

        <ProductDetailsPage
          skipCall
          bountyDetails={selectedBounty}
          onClose={closeBountyDetails}
          isOpen={!!selectedBounty}
        />
      </div>
    </PreviewLayout>
  );
};

export default PreviewStoreChemicalsPage;
