import { ALL_CATEGORIES } from 'constants/general';
import { Category } from 'types/category.interface';

export const findNode = (code: string, arr: Category[]): Category | undefined | null => (
  arr.reduce((foundValue: Category | null | undefined, currentValue: Category) => {
    if (foundValue) {
      return foundValue;
    }

    if (currentValue.code === code) {
      return currentValue;
    }

    if (currentValue.children) {
      return findNode(code, currentValue.children);
    }

    return null;
  }, null)
);

export const findRoot = (code: string, arr: Category[]): Category|undefined => (
  arr.find((item) => {
    if (item.code === code) {
      return item;
    }

    if (item.children) {
      return findNode(code, item.children);
    }

    return null;
  })
);

export const getCategoryLeaf = (category: string|null) => {
  const codes = category?.split(',') || [];
  return codes[codes.length - 1];
};

export const getCategoryName = (leaf: string, arr: Category[]) => {
  if (leaf === ALL_CATEGORIES) {
    return '';
  }

  const category = findNode(leaf, arr);
  return category?.name || '';
};
