import { Box } from '@mui/material';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { FC, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import CustomButton from 'components/CustomButton';

import {
  InventoryConditionItemName,
  InventoryConditionOperatorName,
  InventoryFiltersType,
  InventoryGroupTypeOperator,
} from 'constants/enums';
import {
  InventoryConditionItemOption, InventoryConditionOperatorOption, InventoryFilter, InventoryTreeNode,
} from 'types/customInventoryFilters.interface';

import ConditionNode from './ConditionNode';
import { CONDITION_OPERATORS, CONDITION_ITEMS } from './constants';
import GroupNode from './GroupNode';

interface CustomInventoryFiltersProps {
  onApplyFilters: (filters: InventoryFilter) => void;
}

const CustomInventoryFilters: FC<CustomInventoryFiltersProps> = ({ onApplyFilters }) => {
  const conditionOperatorsMap = CONDITION_OPERATORS.reduce((acc: {
    [key: string]: InventoryConditionOperatorOption; }, operator: InventoryConditionOperatorOption) => {
    acc[operator.key] = operator;
    return acc;
  }, {});

  const conditionItemsMap = CONDITION_ITEMS.reduce((
    acc: { [key: string]: InventoryConditionItemOption },
    item: InventoryConditionItemOption,
  ) => {
    acc[item.key] = item;
    return acc;
  }, {});

  const [rootNode, setRootNode] = useState<InventoryTreeNode>({
    id: uuidv4(),
    type: InventoryFiltersType.GROUP,
    groupOperator: InventoryGroupTypeOperator.AND,
    children: [],
  });

  const updateNode = (node: InventoryTreeNode, nodeId: string, updates: Partial<InventoryTreeNode>)
  : InventoryTreeNode => {
    if (node.id === nodeId) {
      return { ...node, ...updates };
    }
    if (node.children) {
      return {
        ...node,
        children: node.children.map((child) => updateNode(child, nodeId, updates)),
      };
    }
    return node;
  };

  const addCondition = (parentId: string) => {
    const newNode: InventoryTreeNode = {
      id: uuidv4(),
      type: InventoryFiltersType.CONDITION,
      conditionItem: conditionItemsMap[InventoryConditionItemName.ITEM],
      conditionOperator: conditionOperatorsMap[InventoryConditionOperatorName.EQ],
      conditionValue: '',
    };

    const addToTree = (node: InventoryTreeNode): InventoryTreeNode => {
      if (node.id === parentId) {
        return {
          ...node,
          children: [...(node.children || []), newNode],
        };
      }
      if (node.children) {
        return {
          ...node,
          children: node.children.map(addToTree),
        };
      }
      return node;
    };

    setRootNode(addToTree(rootNode));
  };

  const addGroup = (parentId: string) => {
    const newGroup: InventoryTreeNode = {
      id: uuidv4(),
      type: InventoryFiltersType.GROUP,
      groupOperator: InventoryGroupTypeOperator.AND,
      children: [],
    };

    const addToTree = (node: InventoryTreeNode): InventoryTreeNode => {
      if (node.id === parentId) {
        return {
          ...node,
          children: [...(node.children || []), newGroup],
        };
      }
      if (node.children) {
        return {
          ...node,
          children: node.children.map(addToTree),
        };
      }
      return node;
    };

    setRootNode(addToTree(rootNode));
  };

  const deleteNode = (node: InventoryTreeNode, nodeId: string): InventoryTreeNode | null => {
    if (node.id === nodeId) return null;
    if (node.children) {
      const filteredChildren = node.children
        .map((child) => deleteNode(child, nodeId))
        .filter((child): child is InventoryTreeNode => child !== null);
      return { ...node, children: filteredChildren };
    }
    return node;
  };

  const handleDeleteNode = (nodeId: string) => {
    if (rootNode.id === nodeId) return;
    const updated = deleteNode(rootNode, nodeId);
    if (updated) setRootNode(updated);
  };

  const deleteAllFilters = () => {
    setRootNode({
      id: uuidv4(),
      type: InventoryFiltersType.GROUP,
      groupOperator: InventoryGroupTypeOperator.AND,
      children: [],
    });
    onApplyFilters({});
  };

  const getFormattedFilters = (node: InventoryTreeNode): InventoryFilter => {
    if (node.type === InventoryFiltersType.CONDITION) {
      return {
        field: node.conditionItem?.key,
        operator: node.conditionOperator?.key,
        value: Array.isArray(node.conditionValue) && node.conditionValue.length === 1
          ? node.conditionValue[0]
          : node.conditionValue,
      };
    }

    if (node.type === InventoryFiltersType.GROUP) {
      return {
        logic: node.groupOperator,
        filters: node.children?.map((child) => getFormattedFilters(child)),
      };
    }

    return {};
  };

  const applyFilters = () => {
    const filters = getFormattedFilters(rootNode);
    onApplyFilters(filters);
  };

  const renderTree = (node: InventoryTreeNode, isRoot = false) => (
    <TreeItem
      key={node.id}
      itemId={node.id}
      label={
        node.type === InventoryFiltersType.GROUP ? (
          <GroupNode
            node={node}
            isRoot={isRoot}
            onAddCondition={() => addCondition(node.id)}
            onAddGroup={() => addGroup(node.id)}
            onDelete={() => handleDeleteNode(node.id)}
            onUpdateNode={(id, updates) => setRootNode(updateNode(rootNode, id, updates))}
          />
        ) : (
          <ConditionNode
            node={node}
            onDelete={() => handleDeleteNode(node.id)}
            onUpdateNode={(id, updates) => setRootNode(updateNode(rootNode, id, updates))}
          />
        )
      }
    >
      {node.children && node.children.map((child) => renderTree(child))}
    </TreeItem>
  );

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <SimpleTreeView
        disableSelection
        sx={{ maxHeight: 500, overflowY: 'auto' }}
      >
        {renderTree(rootNode, true)}
      </SimpleTreeView>
      <Box display="flex" justifyContent="flex-start" gap={2} marginTop={2}>
        <CustomButton
          id="button-applyFilters"
          variant="contained"
          color="primary"
          onClick={applyFilters}
        >
          Apply Filters
        </CustomButton>
        <CustomButton
          id="button-deleteAllFilters"
          variant="outlined"
          onClick={deleteAllFilters}
        >
          Delete Filters
        </CustomButton>
      </Box>
    </Box>
  );
};

export default CustomInventoryFilters;
