import { Box } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import ShopContent from 'components/_ShopContent';
import User from 'containers/User';
import { CardMedia, Root } from 'layouts/ManagementLayout/Navbar/index.styled';

import { WELCOME_ROUTE } from 'constants/clientRoutes';
import { ClientInfoInterface } from 'types/clientInfo.interface';
import { getPublicUrlForImage } from 'utils/publicUrl';

interface ManagementNavbarProps {
  clientInfo?: ClientInfoInterface | null;
}

const ManagementNavbar:FC<ManagementNavbarProps> = ({ clientInfo = null }) => {
  const { name, branding } = clientInfo || {};

  return (
    <Root>
      <ShopContent>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Box display="flex" alignItems="center" gap={2}>
            <Box>
              <Link to={WELCOME_ROUTE}>
                <CardMedia
                  component="img"
                  src={branding?.logoUrl || getPublicUrlForImage('images/appLogoText.png')}
                  alt={name}
                />
              </Link>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" gap={2}>
            <User />
          </Box>
        </Box>
      </ShopContent>
    </Root>
  );
};

export default ManagementNavbar;
