import { Theme } from '@mui/material';

export const getCategoriesCarouselSettings = (theme: Theme, noOfItems: number) => ({
  infinite: noOfItems > 7,
  speed: 500,
  slidesToShow: 7,
  slidesToScroll: 7,
  arrows: false,
  responsive: [
    {
      breakpoint: theme.breakpoints.values.xl,
      settings: {
        infinite: noOfItems > 7,
        slidesToShow: 7,
        slidesToScroll: 7,
      },
    },
    {
      breakpoint: theme.breakpoints.values.lg,
      settings: {
        infinite: noOfItems > 5,
        slidesToShow: 5,
        slidesToScroll: 5,
      },
    },
    {
      breakpoint: theme.breakpoints.values.md,
      settings: {
        infinite: noOfItems > 3,
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: theme.breakpoints.values.sm,
      settings: {
        infinite: noOfItems > 2,
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
});

export const getProductPicturesCarouselSettings = (theme: Theme) => ({
  dots: true,
  infinite: false,
  arrows: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 5,
  responsive: [
    {
      breakpoint: theme.breakpoints.values.xl,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
      },
    },
    {
      breakpoint: theme.breakpoints.values.lg,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: theme.breakpoints.values.md,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 6,
      },
    },
    {
      breakpoint: theme.breakpoints.values.sm,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
  ],
});

export const getProductsCarouselSettings = (theme: Theme) => ({
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 6,
  arrows: false,
  responsive: [
    {
      breakpoint: theme.breakpoints.values.xl,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
      },
    },
    {
      breakpoint: theme.breakpoints.values.lg,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: theme.breakpoints.values.md,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: theme.breakpoints.values.sm,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: theme.breakpoints.values.xs,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
});

export const getSpecialsCarouselSettings = (theme: Theme) => ({
  dots: true,
  infinite: false,
  arrows: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: theme.breakpoints.values.xl,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: theme.breakpoints.values.lg,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: theme.breakpoints.values.md,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: theme.breakpoints.values.sm,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
});

export const getBannerSettings = (theme: Theme, infinite: boolean) => ({
  dots: true,
  infinite,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
});
