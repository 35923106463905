import { Grid, Typography } from '@mui/material';
import { TypographyVariant } from '@mui/material/styles';
import {
  ChangeEvent, FC, FormEvent, useState,
} from 'react';

import { BoxWrapper, GridInput } from 'components/Coupon/index.styled';
import CustomButton from 'components/CustomButton';
import CustomInput from 'components/CustomInput';

import { CustomTypographyVariant } from 'assets/themes/defaultTheme';

interface CouponProps {
  onChangeSummary: (coupon: string) => void;
  inline?: boolean;
  variant?: TypographyVariant | CustomTypographyVariant;
  title?: string;
}

const Coupon: FC<CouponProps> = ({
  onChangeSummary,
  inline,
  variant = 'body1',
  title = 'Do you have a Promo Code?',
}) => {
  const [coupon, setCoupon] = useState('');
  const handleChanges = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setCoupon(value);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (coupon) {
      onChangeSummary(coupon);
    }
  };

  return (
    <BoxWrapper className="coupon">
      <Typography variant={variant} fontWeight={700} mb={1}>
        {title}
      </Typography>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <GridInput size={[9, 9, inline ? 9 : 12, 9]}>
            <CustomInput
              name="coupon"
              size="small"
              fullWidth
              placeholder="Type Promo Code…"
              onChange={handleChanges}
              value={coupon}
              // error={!!get(fieldErrors, LAST_NAME.name)}
              // helperText={get(fieldErrors, LAST_NAME.name)}
            />
          </GridInput>
          <Grid size={[3, 3, inline ? 3 : 12, 3]}>
            <CustomButton
              id="button-applyCoupon"
              variant="contained"
              fullWidth
              type="submit"
            >
              Apply
            </CustomButton>
          </Grid>
        </Grid>
      </form>
    </BoxWrapper>
  );
};

export default Coupon;
