import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const Dot = styled(Box)(({ theme }) => ({
  height: '4px',
  width: '4px',
  borderRadius: '50%',
  backgroundColor: theme.palette.border.main,
})) as typeof Box;

export default Dot;