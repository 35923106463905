import { Box, useTheme } from '@mui/material';
import {
  FC, useContext, useEffect, useState,
} from 'react';
import { forceCheck } from 'react-lazyload';
import { useSearchParams } from 'react-router-dom';
import Slider from 'react-slick';

import ProductCard from 'components/ProductCard';
import SectionHeader from 'components/SectionHeader';
import ProductsCarouselSkeleton from 'containers/Dashboard/ProductsCarousel/index.skeleton';
import ProductDetailsPage from 'containers/ProductDetailsPage';
import { CategoryDispatchContext } from 'context/CategoryContext';
import { LocationContext } from 'context/LocationContext';
import { SystemContext } from 'context/SystemContext';

import useBountyDetails from 'hooks/useBountyDetails';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import useSlickNavigation from 'hooks/useSlickNavigation';
import { getProducts } from 'services/Product';

import { ViewMode } from 'constants/enums';
import { Bounty } from 'types/bounty.interface';
import { Category } from 'types/category.interface';
import { getProductsCarouselSettings } from 'utils/carousel';
import { formatSearchParams } from 'utils/formatters';

interface ProductsCarouselProps {
  category: Category;
}

const ProductsCarousel:FC<ProductsCarouselProps> = ({ category }) => {
  const [products, setProducts] = useState<Bounty[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const { shopSettings } = useContext(SystemContext);
  const { removeCategory } = useContext(CategoryDispatchContext);
  const { onboardingInfo } = useContext(LocationContext);

  const { handleTrackViewItems } = useGoogleAnalytics();
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const settings = getProductsCarouselSettings(theme);
  const { selectedBounty, openBountyDetails, closeBountyDetails } = useBountyDetails();
  const {
    sliderRef,
    canNavigate,
    onNext,
    onPrevious,
  } = useSlickNavigation(settings);

  const { storeId, useType } = onboardingInfo;

  useEffect(() => {
    fetchProducts();
  }, [storeId, useType]);

  useEffect(() => {
    if (products.length > 0) {
      handleTrackViewItems(category, products);
    }
  }, [products]);

  const fetchProducts = async () => {
    try {
      setIsLoading(true);

      const params = formatSearchParams(searchParams);
      params.set('category', category.code);

      const { data } = await getProducts(params);

      if (!data?.list?.length) {
        handleEmptyProductsList();
      } else {
        setProducts(data?.list || []);
      }
    } catch (e) {
      handleEmptyProductsList();
    } finally {
      setIsLoading(false);
    }
  };

  const handleEmptyProductsList = () => {
    removeCategory(category.code);
    setTimeout(() => forceCheck());
  };

  if (isLoading) {
    return <ProductsCarouselSkeleton />;
  }

  return (
    <Box mb={10}>
      <SectionHeader
        canNavigate={canNavigate}
        title={category.name}
        onNext={onNext}
        onPrevious={onPrevious}
      />
      <Slider {...settings} ref={sliderRef}>
        {products?.map((bounty) => (
          <ProductCard
            key={bounty.id}
            bounty={bounty}
            openDetails={() => openBountyDetails(bounty)}
            hasAddButton
            isDescriptionVisible={shopSettings?.viewConfigs?.PRODUCT_DESCRIPTION?.viewMode === ViewMode.Full}
            useType={onboardingInfo?.useType}
          />
        ))}
      </Slider>

      <ProductDetailsPage
        bountyDetails={selectedBounty}
        onClose={closeBountyDetails}
        isOpen={!!selectedBounty}
      />
    </Box>
  );
};

export default ProductsCarousel;
