import { Box } from '@mui/material';
import {
  GridColDef, GridRowSelectionModel, GridRowsProp, GridToolbar,
} from '@mui/x-data-grid';
import { FC, useEffect, useState } from 'react';

import CustomInventoryFilters from 'containers/CustomInventoryFilters';
import { InventoryContextProvider } from 'context/InventoryContext';

import { postInventorySearch } from 'services/Inventory';

import { DEFAULT_PAGINATION_LIMIT } from 'constants/general';
import { InventoryItem } from 'types/inventory.interface';
import { handleApiErrors } from 'utils/errorUtils';
import { getStoreId } from 'utils/storageUtils';

import { DEFAULT_INVENTORY_PAGE_SIZE_OPTIONS, INVETORY_GRID_COLUMNS } from './constants';
import { StyledInventoryDataGrid, StyledInventoryFiltersBox, StyledInventoryPaper } from './index.styled';

const Inventory: FC = () => {
  const [inventoryItems, setInventoryItems] = useState<GridRowsProp>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [finalRawCount, setFinalRawCount] = useState(0);
  const [inventoryFilters, setInventoryFilters] = useState({});

  const columns: GridColDef[] = INVETORY_GRID_COLUMNS;

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: DEFAULT_PAGINATION_LIMIT,
  });

  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

  const getInventory = async (page: number, pageSize: number) => {
    setIsLoading(true);
    try {
      const clientId = getStoreId();
      const payload = {
        start: page * pageSize,
        length: pageSize,
        filter: inventoryFilters,
      };

      const { data } = await postInventorySearch(clientId!, payload);

      if (!data.list) {
        setInventoryItems([]);
        return;
      }
      if (!data.hasNextPage) {
        setFinalRawCount(data.list.length + pageSize * page);
      }

      const items = data.list.map((item: InventoryItem, index: number) => ({
        ...item,
        // TODO:: Should be removed when we can confirm that production data have unique ids
        itemId: `${item.itemId}-${index}-${page}`,
      }));

      setInventoryItems(items);
      setHasNextPage(data.hasNextPage);
    } catch (e) {
      handleApiErrors(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getInventory(paginationModel.page, paginationModel.pageSize);
  }, [paginationModel.page, paginationModel.pageSize, inventoryFilters]);

  return (
    <Box>
      <StyledInventoryFiltersBox>
        <InventoryContextProvider>
          <CustomInventoryFilters onApplyFilters={(filters: any) => setInventoryFilters(filters)} />
        </InventoryContextProvider>
      </StyledInventoryFiltersBox>
      <StyledInventoryPaper>
        <StyledInventoryDataGrid
          loading={isLoading}
          rows={[...inventoryItems]}
          getRowId={(row) => row.itemId}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              printOptions: { disableToolbarButton: true },
              csvOptions: { disableToolbarButton: true },
            },
          }}
          // filtering
          ignoreDiacritics
          disableColumnFilter
          resetPageOnSortFilter
          // selection
          checkboxSelection
          keepNonExistentRowsSelected
          onRowSelectionModelChange={setRowSelectionModel}
          rowSelectionModel={rowSelectionModel}
          // pagination
          paginationMeta={{ hasNextPage }}
          paginationModel={paginationModel}
          paginationMode="server"
          rowCount={hasNextPage ? -1 : finalRawCount}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={DEFAULT_INVENTORY_PAGE_SIZE_OPTIONS}
        />
      </StyledInventoryPaper>
    </Box>
  );
};

export default Inventory;
