export const isPartialMatch = (name: string, search: string) => {
  if (!name) {
    return false;
  }

  const nameLower = name.toLowerCase();
  const searchLower = search.toLowerCase();

  if (nameLower.includes(searchLower)) {
    return true;
  }

  const nameWords = nameLower.split(' ');

  return nameWords.some((word) => word.includes(searchLower));
};
