import { Box } from '@mui/material';
import { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import KioskUseTypeSelector from 'components/KioskUseTypeSelector/index';
import { Content, Root } from 'containers/Categories/KioskCategories/index.styled';
import CategoryCard from 'containers/Categories/KioskCategoryCard';
import { SystemContext } from 'context/SystemContext';

import { PRODUCTS_ROUTE } from 'constants/clientRoutes';
import { Category } from 'types/category.interface';

interface KioskCategoriesProps {
  categories: Category[];
  selectedCategories: string[];
  onCategoryChanges: (category: string, index: number) => void;
  level: number;
}

const KioskCategories:FC<KioskCategoriesProps> = ({
  categories,
  selectedCategories,
  onCategoryChanges,
  level,
}) => {
  const navigate = useNavigate();
  const { hasStores } = useContext(SystemContext);

  const removeCategory = (category: string) => {
    let newCategories = [...selectedCategories];
    const position = newCategories.findIndex((cat) => cat === category);

    if (position > -1) {
      newCategories = newCategories.slice(0, position);
      navigate({
        pathname: PRODUCTS_ROUTE,
        search: newCategories?.length ? `?category=${newCategories.join(',')}` : '',
      });
    }
  };

  const handleSelect = (categoryCode: string, index: number, children?: Category[]) => {
    if (selectedCategories.includes(categoryCode) && children) {
      removeCategory(categoryCode);
    } else {
      onCategoryChanges(categoryCode, index);
    }
  };

  return (
    <Root level={level}>
      <Content level={level} className="hidden-scroll">
        {!level && hasStores && (
          <Box pt={4.5} pb={4}>
            <KioskUseTypeSelector />
          </Box>
        )}
        {categories.map((category) => (
          <div key={category.code}>
            <CategoryCard
              category={category}
              onSelect={() => handleSelect(category.code, level, category.children)}
              isActive={selectedCategories.includes(category.code)}
            />
            {selectedCategories.includes(category.code) && category.children && (
              <KioskCategories
                categories={category.children}
                selectedCategories={selectedCategories}
                onCategoryChanges={onCategoryChanges}
                level={level + 1}
              />
            )}
          </div>
        ))}
      </Content>
    </Root>
  );
};

export default KioskCategories;
