import {
  get, isEmpty, omit, set,
} from 'lodash';
import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ValidationError } from 'yup';

import Checkout from 'containers/CheckoutProviderPage/Checkout';
import KioskCheckout from 'containers/CheckoutProviderPage/KioskCheckout';
import { CartContext, CartDispatchContext } from 'context/CartContext';
import { LocationContext } from 'context/LocationContext';
import { PaymentContext, PaymentDispatchContext } from 'context/PaymentContext';
import { SystemContext } from 'context/SystemContext';

import useCheckoutState from 'hooks/useCheckoutState';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { useKiosk } from 'hooks/useKiosk';
import { getCheckoutCompleteRoute, getPaymentWidgetRoute } from 'hooks/useRouting';
import useSummary from 'hooks/useSummary';
import { placeOrder } from 'services/Order';

import { CheckoutSections, PaymentMethod } from 'constants/enums';
import {
  DELIVERY_PHONE_NUMBER,
  MEDICAL_ID,
  MEDICAL_ID_EXP,
  PHONE_NUMBER,
  PICK_UP_DATE,
  PICK_UP_TIME,
} from 'constants/fields';
import { PlaceOrderInfo } from 'types/checkout.interface';
import { getPaymentMethodBasedOnEnv } from 'utils/bmbUtils';
import { generateUID } from 'utils/cartUtils';
import {
  formatCheckoutValues,
  getDayLimitBasedOnDelivery,
  isIntegratedPayment,
  isPickupMethod,
} from 'utils/checkoutUtils';
import { handleApiErrors } from 'utils/errorUtils';
import { isValidHourForPlacingOrder } from 'utils/shopOperation';
import {
  getSavedUid, saveCheckoutDetailsToStorage, saveUIDToStorage,
} from 'utils/storageUtils';
import { reportAlert } from 'utils/utils';
import { getCheckoutFormSchema, getSectionSchema } from 'validation/checkoutFormSchema';

const CheckoutProviderPage = () => {
  const navigate = useNavigate();
  const cart = useContext(CartContext);
  const { removeItems } = useContext(CartDispatchContext);
  const { paymentFlow } = useContext(PaymentContext);
  const { savePayment, updatePaymentFlow, resetPaymentFlow } = useContext(PaymentDispatchContext);
  const { onboardingInfo } = useContext(LocationContext);
  const { shopSettings, detailedClientData } = useContext(SystemContext);

  const [fieldErrors, setFieldErrors] = useState<Record<string, string>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSummaryFailed, setHasSummaryFailed] = useState<boolean>(false);
  const products = useMemo(() => Object.values(cart?.products) || [], [cart?.products]);
  const kioskSettings = detailedClientData?.kioskId
    ? shopSettings?.kioskSettings?.[detailedClientData?.kioskId]
    : null;

  const {
    checkSummary,
    cartPriceDetails,
    isLoading: isSummaryLoading,
    formattedCartDetails,
  } = useSummary();
  const { handleTrackPurchase, handleTrackBeginCheckout } = useGoogleAnalytics();
  const {
    checkoutForm,
    setCheckoutForm,
    orderDateRestrictions,
    isLoadingDateRestrictions,
  } = useCheckoutState();
  const { kioskMode } = useKiosk();

  const checkoutFormSchema = useMemo(() => {
    const hoursOfOperation = shopSettings.effectiveShopOperation?.hoursOfOperation;
    const dayCap = hoursOfOperation
      ? getDayLimitBasedOnDelivery(hoursOfOperation, checkoutForm.delivery.method)
      : 0;

    return getCheckoutFormSchema(
      dayCap,
      checkoutForm?.delivery?.method,
      shopSettings?.checkoutFields,
      kioskMode,
    );
  }, [checkoutForm, shopSettings.effectiveShopOperation?.hoursOfOperation]);

  useDocumentTitle({ title: 'Checkout' });

  useEffect(() => {
    if (!isEmpty(cart?.products)) {
      handleCreateSummary();
    }
  }, [cart?.products]);

  useEffect(() => {
    if (products?.length > 0 && !isEmpty(cartPriceDetails)) {
      handleTrackBeginCheckout(cartPriceDetails, products);
    }
  }, [cartPriceDetails, products]);

  useEffect(() => () => {
    resetPaymentFlow();
  }, []);

  const saveCheckoutDetails = (data: PlaceOrderInfo) => {
    const omitFields = [
      MEDICAL_ID.name, MEDICAL_ID_EXP.name, PICK_UP_DATE.name, PICK_UP_TIME.name, DELIVERY_PHONE_NUMBER.name,
    ];
    saveCheckoutDetailsToStorage(omit(data, omitFields) as PlaceOrderInfo);
  };

  const handleCreateSummary = async () => {
    try {
      const { products } = await checkSummary();

      if (!isEmpty(products)) {
        toast.error('Some of the products in your cart are no longer available. Please review your cart.');
        return { hasProductsChanged: true };
      }

      setHasSummaryFailed(false);
      return { hasProductsChanged: false };
    } catch (e) {
      setHasSummaryFailed(true);
      return { hasProductsChanged: false };
    }
  };

  const clearErrors = (name: string) => {
    setFieldErrors((prevState) => ({ ...prevState, [name]: '' }));
  };

  const handleChangeValue = (value: any, name: string) => {
    setCheckoutForm((prev) => {
      const newCheckoutForm = { ...prev };

      if ([PHONE_NUMBER.name, DELIVERY_PHONE_NUMBER.name].includes(name)) {
        const isCustomerPhone = name === PHONE_NUMBER.name;
        const fieldPath = isCustomerPhone ? 'customer.address' : 'address';
        set(newCheckoutForm, fieldPath, { ...get(prev, fieldPath), ...value });

        if (isCustomerPhone) {
          clearErrors(DELIVERY_PHONE_NUMBER.name);
        }
      } else {
        set(newCheckoutForm, name, value);
      }

      if (!kioskMode) {
        saveCheckoutDetails(newCheckoutForm);
      }

      return newCheckoutForm;
    });

    clearErrors(name);
  };

  const handlePlaceOrder = async ({ shouldRedirect } = { shouldRedirect: false }) => {
    const { hasProductsChanged } = await handleCreateSummary();

    if (!hasProductsChanged) {
      const formattedCheckoutForm = formatCheckoutValues(
        checkoutForm,
        onboardingInfo,
        shopSettings?.checkoutFields,
        kioskMode,
      );
      const currentPaymentMethod = formattedCheckoutForm?.payment?.paymentMethod as PaymentMethod;
      let uid = getSavedUid();

      if (!uid) {
        uid = generateUID();
        saveUIDToStorage(uid);
      }

      const order = {
        products: cart?.products,
        ...formattedCheckoutForm,
        uid,
        payment: {
          ...formattedCheckoutForm?.payment,
          paymentMethod: getPaymentMethodBasedOnEnv(currentPaymentMethod),
        },
      };

      const { data } = await placeOrder(order);

      setFieldErrors({});
      handleTrackPurchase(cartPriceDetails, order, products, data?.id);
      removeItems();

      savePayment({
        orderAmount: formattedCartDetails?.total || '0',
        orderId: data?.bountyId,
        alleavesOrderId: data?.alleavesOrderId,
        orderShortId: data?.bountyShortId,
        bmbAuthToken: data?.bmbAuthToken,
        bmbEnv: data?.bmbEnv,
        paymentMethod: currentPaymentMethod,
        deliveryMethod: order?.delivery?.method,
        receiptUrl: data?.receiptUrl,
      });

      if (!data?.bountyId) {
        toast.error('Missing information about the order!');
        return '';
      }

      if (shouldRedirect) {
        if (isIntegratedPayment(currentPaymentMethod)) {
          navigate(getPaymentWidgetRoute(data.bountyId, `alleavesOrderId=${data?.alleavesOrderId || ''}`));
        } else {
          navigate(getCheckoutCompleteRoute(data.bountyId, `alleavesOrderId=${data?.alleavesOrderId || ''}`));
        }
      }

      return data?.bountyId;
    }

    return '';
  };

  const handleOrder = async ({ ignoreDialog, shouldRedirect } = { ignoreDialog: false, shouldRedirect: false }) => {
    if (isEmpty(cart?.products)) {
      toast.error('Your cart is empty!');
    } else {
      try {
        setIsSubmitting(true);
        await checkoutFormSchema.validate(checkoutForm, { abortEarly: false });

        const { pickupTime, method } = checkoutForm.delivery;
        const isPickup = isPickupMethod(method);

        if (!kioskMode && pickupTime && isPickup
          && !isValidHourForPlacingOrder(pickupTime, orderDateRestrictions)
        ) {
          setFieldErrors({ ...fieldErrors, [PICK_UP_TIME.name]: 'We have too many pickups for this hour.' });
          return '';
        }

        if (detailedClientData?.kioskId && !ignoreDialog && !!kioskSettings?.registerId) {
          updatePaymentFlow({ showKioskPaymentMethods: true });
          return '';
        }

        const id = await handlePlaceOrder({ shouldRedirect });
        return id;
      } catch (err: any) {
        if (!(err instanceof ValidationError) || !ValidationError.isError(err)) {
          reportAlert(`place order failed: ${JSON.stringify(err)}`);
        }

        handleApiErrors(err, setFieldErrors);
      } finally {
        setIsSubmitting(false);
        saveUIDToStorage();
      }
    }

    return '';
  };

  useEffect(() => {
    if (paymentFlow.showKioskPaymentMethods && !kioskSettings) {
      toast.error('Missing kiosk settings!');
    }
  }, [paymentFlow.showKioskPaymentMethods, kioskSettings]);

  const isSectionValid = async (section: CheckoutSections) => {
    const hoursOfOperation = shopSettings.effectiveShopOperation?.hoursOfOperation;
    const dayCap = hoursOfOperation
      ? getDayLimitBasedOnDelivery(hoursOfOperation, checkoutForm.delivery.method)
      : 0;
    const sectionSchema = getSectionSchema(
      section,
      dayCap,
      checkoutForm?.delivery?.method,
      shopSettings?.checkoutFields,
    );

    try {
      await sectionSchema.validate(checkoutForm, { abortEarly: false });
      return true;
    } catch (err: any) {
      handleApiErrors(err, setFieldErrors);
      return false;
    }
  };

  const CheckoutComponent = kioskMode ? KioskCheckout : Checkout;

  return (
    <CheckoutComponent
      checkoutForm={checkoutForm}
      onOrder={handleOrder}
      onChange={handleChangeValue}
      orderDateRestrictions={orderDateRestrictions}
      fieldErrors={fieldErrors}
      formattedCartDetails={formattedCartDetails}
      cartPriceDetails={cartPriceDetails}
      onCheckSummary={checkSummary}
      isSubmitting={isSubmitting}
      isLoadingDateRestrictions={isLoadingDateRestrictions}
      isSummaryLoading={isSummaryLoading}
      hasSummaryFailed={hasSummaryFailed}
      isSectionValid={isSectionValid}
      checkoutFormSchema={checkoutFormSchema}
    />
  );
};

export default CheckoutProviderPage;
