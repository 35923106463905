import MuiCardMedia from '@mui/material/CardMedia';
import { styled } from '@mui/material/styles';

import { HEADER_HEIGHT, KIOSK_HEADER_HEIGHT } from 'constants/general';

export const Root = styled('div')(({ theme }) => ({
  height: `${KIOSK_HEADER_HEIGHT}px`,
  top: 0,
  backgroundColor: theme.palette.headerBackground.main,
  position: 'fixed',
  zIndex: theme.zIndex.drawer - 2,
  display: 'flex',
  alignItems: 'center',
  width: '100%',
}));

export const CardMedia = styled(MuiCardMedia)(({ theme }) => ({
  objectFit: 'contain',
  maxWidth: '200px',
  maxHeight: `calc(${HEADER_HEIGHT}px - ${theme.spacing(1.2)})`,

  [theme.breakpoints.down('md')]: {
    maxWidth: '120px',
  },
})) as typeof MuiCardMedia;
