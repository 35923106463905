import { DialogActions, DialogContent } from '@mui/material';
import { get } from 'lodash';
import { ChangeEvent, FC, useState } from 'react';

import CustomButton from 'components/CustomButton';
import DialogTitle from 'containers/Onboarding/DialogTitle';
import { DatePickerField, InputField, Wrapper } from 'containers/Onboarding/MedicalInfo/index.styled';
import Stepper from 'containers/Onboarding/Stepper';

import { useKiosk } from 'hooks/useKiosk';

import { OnboardingSteps } from 'constants/enums';
import { MEDICAL_ID, MEDICAL_ID_EXP } from 'constants/fields';
import { OnboardingInfo } from 'types/onboarding.interface';
import { handleApiErrors } from 'utils/errorUtils';
import { getCompanyLocationsData } from 'utils/storageUtils';
import { medicalSchema } from 'validation/checkoutFormSchema';

interface MedicalInfoProps {
  setup: OnboardingInfo;
  updateSettings: (data: Partial<OnboardingInfo>) => void;
  setStep: (step: OnboardingSteps) => void;
  endFlow: () => Promise<void>;
  hasSteps?: boolean;
  step?: OnboardingSteps;
}

const MedicalInfo:FC<MedicalInfoProps> = ({
  setup,
  updateSettings,
  setStep,
  endFlow,
  hasSteps,
  step,
}) => {
  const { list } = getCompanyLocationsData() || {};
  const isDisabled = !setup?.medical?.medicalId || !setup?.medical?.medicalIdExp;
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<Record<string, string>>({});
  const { kioskMode } = useKiosk();

  const handleMedicalIdExpiration = (value: any) => {
    updateSettings({
      medical: {
        ...setup.medical,
        medicalIdExp: value,
      },
    });
  };

  const handleInputChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    updateSettings({
      medical: {
        ...setup.medical,
        medicalId: value,
      },
    });
    setFieldErrors({ ...fieldErrors, [name]: '' });
  };

  const handleBackButton = () => {
    if (setup?.searchResults?.length === 1 || list?.length === 1) {
      const selectedStore = list?.find(({ id }) => id === setup?.storeId);
      setStep(!selectedStore?.adultUse ? OnboardingSteps.DeliveryType : OnboardingSteps.UseType);
    } else {
      setStep(OnboardingSteps.UseType);
    }
  };

  const handleSubmit = async () => {
    try {
      await medicalSchema.validate(setup, { abortEarly: false });
      setIsSubmitDisabled(true);
      endFlow();
    } catch (err) {
      handleApiErrors(err, setFieldErrors);
    }
  };

  return (
    <>
      <DialogTitle>
        {hasSteps && <Stepper activeStep={2} sx={{ mb: 3 }} />}
        Please provide
        <br />
        Medical ID and Date of Expiration info
      </DialogTitle>
      <DialogContent sx={{ py: 3, px: 8 }}>
        <Wrapper>
          <div>
            <InputField
              {...MEDICAL_ID}
              label=""
              value={setup?.medical?.medicalId || ''}
              placeholder={MEDICAL_ID.label}
              onChange={handleInputChange}
              error={!!get(fieldErrors, MEDICAL_ID.name)}
              helperText={get(fieldErrors, MEDICAL_ID.name)}
            />
          </div>
          <div>
            <DatePickerField
              {...MEDICAL_ID_EXP}
              label=""
              disableOpenPicker={kioskMode}
              value={setup?.medical?.medicalIdExp || null}
              onChange={handleMedicalIdExpiration}
              error={!!get(fieldErrors, MEDICAL_ID_EXP.name)}
              helperText={get(fieldErrors, MEDICAL_ID_EXP.name)}
            />
          </div>
        </Wrapper>
      </DialogContent>
      <DialogActions sx={{ pb: 3, px: 8 }}>
        {step !== OnboardingSteps.OnlyMedicalInfo && (
          <CustomButton
            id="button-goBack"
            variant="outlined"
            onClick={handleBackButton}
          >
            Back
          </CustomButton>
        )}
        <CustomButton
          id="button-continue"
          variant="contained"
          onClick={handleSubmit}
          disabled={isDisabled || isSubmitDisabled}
        >
          Continue
        </CustomButton>
      </DialogActions>
    </>
  );
};

export default MedicalInfo;
