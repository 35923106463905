import {
  Box, Divider, Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import {
  FC, useContext, useEffect, useMemo,
} from 'react';

import CloseButton from 'components/CloseButton';
import EmptyPreviewCart from 'components/EmptyPreviewCart';
import OrderWarningMessage from 'components/OrderWarningMessage';
import { Drawer } from 'containers/CartListingPage/index.styled';
import Products from 'containers/CartListingPage/Products';
import Summary from 'containers/CartListingPage/Summary';
import useCartListing from 'containers/CartListingPage/useCartListing';
import { CartContext } from 'context/CartContext';
import { SystemContext } from 'context/SystemContext';

import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';

import { calculateTotalQuantity } from 'utils/cartUtils';

interface CartListingProps {
  isOpen: boolean;
  onClose: () => void;
}

const CartListing:FC<CartListingProps> = ({ isOpen, onClose }) => {
  const { handleTrackViewCart } = useGoogleAnalytics();
  const { shopSettings } = useContext(SystemContext);
  const cart = useContext(CartContext);
  const products = useMemo(() => Object.values(cart?.products) || [], [cart?.products]);
  const {
    goToCheckout,
    verifySummary,
    summaryFailedDetails,
    showMedicalBalance,
    checkSummary,
    formattedCartDetails,
    isDisabled,
    updatedProducts,
    isSummaryLoading,
    cartPriceDetails,
  } = useCartListing();

  useDocumentTitle({ title: 'Cart summary' });

  useEffect(() => {
    if (!isEmpty(cart?.products)) {
      verifySummary();
    }
  }, [cart?.products]);

  useEffect(() => {
    if (!isEmpty(cartPriceDetails)) {
      handleTrackViewCart(cartPriceDetails, products);
    }
  }, [cartPriceDetails]);

  const totalProductsQuantity = useMemo(() => calculateTotalQuantity(cart?.products), [cart?.products]);

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
    >
      <Box className="hidden-scroll" position="relative" height="100%">
        <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
          <Box mx={3}>
            <CloseButton onClose={onClose} sx={{ right: 28, top: 0, p: 1.5 }} />
            <Typography variant="h4" fontWeight={700} textAlign="center">
              Your cart
            </Typography>
            <Divider sx={{ my: 3 }} />
            {products?.length > 0
              ? (
                <>
                  <Products
                    updatedProducts={updatedProducts}
                    summaryFailedDetails={summaryFailedDetails}
                  />
                  {shopSettings?.orderWarningMessage && (
                    <Box mt={2.5}>
                      <OrderWarningMessage message={shopSettings?.orderWarningMessage} />
                    </Box>
                  )}
                </>
              ) : (
                <Box mt={12} mx={6}>
                  <EmptyPreviewCart hasButton fontSize="large" variant="h6" onClose={onClose} />
                </Box>
              )}
          </Box>

          {products?.length > 0 && (
            <Summary
              onSubmit={goToCheckout}
              disabled={isDisabled || summaryFailedDetails?.hasFailed}
              isSummaryLoading={isSummaryLoading}
              hasSummaryFailed={summaryFailedDetails?.hasFailed}
              cartPriceDetails={formattedCartDetails}
              onChangeSummary={checkSummary}
              showMedicalBalance={showMedicalBalance}
              productsCount={totalProductsQuantity}
              orderDisclaimerMessage={shopSettings?.orderDisclaimerMessage}
            />
          )}
        </Box>
      </Box>
    </Drawer>
  );
};

export default CartListing;
