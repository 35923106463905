import { Grid, Box } from '@mui/material';
import { isNil } from 'lodash';
import {
  Suspense, lazy, FC, useContext,
} from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import KioskFooter from 'components/_Footer/index.kiosk';
import CustomContent from 'components/CustomContent';
import EmptyState from 'components/EmptyState';
import ProductCardSkeleton from 'components/ProductCard/index.skeleton';
import Categories from 'containers/Categories/index.kiosk';
import KioskCategoriesSkeleton from 'containers/Categories/KioskCategories/index.skeleton';
import HighlightsBanner from 'containers/HighlightsBanner';
import { ActionsWrapper, Content } from 'containers/KioskProducts/index.styled';
import ProductFilterFlyout from 'containers/KioskProducts/ProductFilterFlyout';
import ProductSortFlyout from 'containers/KioskProducts/ProductSortFlyout';
import RecommendationsBanner from 'containers/KioskProducts/RecommendationsBanner';
import ProductDetailsPage from 'containers/ProductDetailsPage/index.kiosk';
import PromotionBanner from 'containers/PromotionBanner';
import { CategoryContext } from 'context/CategoryContext';
import { LocationContext } from 'context/LocationContext';
import { SystemContext } from 'context/SystemContext';

import useBountyDetails from 'hooks/useBountyDetails';

import { BannerType, ProductSortOptions, ViewMode } from 'constants/enums';
import { KIOSK_HEADER_HEIGHT } from 'constants/general';
import { Bounty } from 'types/bounty.interface';
import { Promotion } from 'types/promotion.interface';

const ProductCard = lazy(() => import('components/ProductCard'));

interface KioskProductsProps {
  promotions?: Record<BannerType, Promotion[]>;
  onLoadProducts: () => void;
  skeletonProducts: number[];
  products: Bounty[] | null;
  hasMore: boolean;
  isLoading: boolean;
  isLoadingMore: boolean;
  onSort: (newSort: ProductSortOptions) => void;
  sortOption: ProductSortOptions;
}

const KioskProducts: FC<KioskProductsProps> = ({
  promotions,
  onLoadProducts,
  skeletonProducts,
  products,
  hasMore,
  isLoadingMore,
  isLoading,
  onSort,
  sortOption,
}) => {
  const { categories, isLoading: isCategoriesLoading } = useContext(CategoryContext);
  const { shopSettings } = useContext(SystemContext);
  const { onboardingInfo } = useContext(LocationContext);
  const hasCategories = categories?.length > 0;
  const isDescriptionVisible = shopSettings?.viewConfigs?.PRODUCT_DESCRIPTION?.viewMode === ViewMode.Full;
  const {
    [BannerType.Hero]: heroes = [],
    [BannerType.Recommendations]: recommendations = [],
    [BannerType.Highlights]: highlights = [],
  } = promotions || {};
  const isListVisible = !(highlights?.length > 0 && recommendations?.length > 0);

  const { selectedBounty, openBountyDetails, closeBountyDetails } = useBountyDetails();

  return (
    <Box display="flex" minHeight={`calc(100vh - ${KIOSK_HEADER_HEIGHT}px)`}>
      {isCategoriesLoading
        ? <KioskCategoriesSkeleton />
        : hasCategories && <Categories />}
      <Content>
        <CustomContent kioskMode sx={{ flexGrow: 1 }}>
          <Box maxHeight="calc(100vh - 288px)" className="hidden-scroll">
            {heroes?.length > 0 && (
              <PromotionBanner
                sx={{ px: heroes?.length > 1 ? 6 : 0, mt: 0 }}
                promotions={heroes}
              />
            )}
            {highlights?.length > 0 && <HighlightsBanner highlights={highlights} sx={{ mb: 6 }} /> }
            {recommendations?.length > 0 && (
              <RecommendationsBanner
                recommendations={recommendations}
                openDetails={openBountyDetails}
              />
            )}
          </Box>

          {isListVisible && (
            <div>
              {!isLoading && isNil(products)
                ? <EmptyState message="No products found!" />
                : (
                  <>
                    <ActionsWrapper>
                      <ProductSortFlyout sortOption={sortOption} onChange={onSort} />
                      <ProductFilterFlyout />
                    </ActionsWrapper>

                    <Box
                      maxHeight="calc(100vh - 390px)"
                      id="products"
                      className="hidden-scroll"
                    >
                      <InfiniteScroll
                        useWindow={false}
                        pageStart={0}
                        loadMore={onLoadProducts}
                        hasMore={hasMore}
                        getScrollParent={() => document.getElementById('products')}
                      >
                        <Grid
                          container
                          spacing={2}
                        >
                          {!isLoading && products && products.map((bounty, index) => (
                            <Grid key={`${index}-${bounty.id}`} size={[12, 12, 4, 3]}>
                              <Suspense fallback={<ProductCardSkeleton />}>
                                <ProductCard
                                  openDetails={() => openBountyDetails(bounty)}
                                  bounty={bounty}
                                  isDescriptionVisible={isDescriptionVisible}
                                  useType={onboardingInfo?.useType}
                                />
                              </Suspense>
                            </Grid>
                          ))}

                          {(isLoading || isLoadingMore) && skeletonProducts.map((loader) => (
                            <Grid key={`productSkeleton-${loader}`} size={[12, 12, 4, 3]}>
                              <ProductCardSkeleton />
                            </Grid>
                          ))}
                        </Grid>
                      </InfiniteScroll>
                    </Box>
                  </>
                )}
            </div>
          )}
          {!!selectedBounty && (
            <ProductDetailsPage
              isOpen
              bountyDetails={selectedBounty}
              onCancel={closeBountyDetails}
            />
          )}
        </CustomContent>

        <KioskFooter />
      </Content>
    </Box>
  );
};

export default KioskProducts;
