export const HOME_ROUTE = '/';
export const ADMIN_LOGIN_ROUTE = '/adminLogin';
export const WELCOME_ROUTE = '/welcome';
export const DASHBOARD_ROUTE = '/dashboard';
export const SHOP_CLOSED_ROUTE = '/closed';
export const PRODUCTS_ROUTE = '/products';
export const PRODUCT_DETAILS_ROUTE = '/products/:productId';
export const CART_ROUTE = '/cart';
export const CHECKOUT_ROUTE = '/checkout';
export const CHECKOUT_COMPLETE_ROUTE = '/checkout/:orderId/complete';
export const CHECKOUT_PAYMENT_WIDGET_ROUTE = '/checkout/:orderId/paymentWidget';
export const ORDER_DETAILS_ROUTE = '/orders/:orderId';
export const ORDER_DIGITAL_RECEIPT_ROUTE = '/orders/:orderId/receipt';
export const ORDERS_ROUTE = '/orders';

export const PREVIEW_STORE = '/previewStore';
export const PREVIEW_CHEMICALS_IN_STORE = `${PREVIEW_STORE}/chemicals`;
export const PREVIEW_CART = '/previewCart';
export const PREVIEW_ONBOARDING = '/previewOnboarding';
export const PREVIEW_PRODUCT = '/previewProduct';
export const STORE_ROUTE = '/store';
export const INVENTORY_ROUTE = '/inventory';
export const POS_ROUTE = '/pos';
export const PRODUCT_PROFILE_ROUTE = '/productProfile';
export const NEW_CUSTOMER_ROUTE = '/newCustomer';

export const CHAT_RESTRICTED_ROUTES = [
  PREVIEW_STORE,
  PREVIEW_ONBOARDING,
  PREVIEW_CHEMICALS_IN_STORE,
  PREVIEW_CART,
  PREVIEW_PRODUCT,
  WELCOME_ROUTE,
  INVENTORY_ROUTE,
  POS_ROUTE,
  PRODUCT_PROFILE_ROUTE,
];
