import { useState } from 'react';

import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { useKiosk } from 'hooks/useKiosk';

import { Bounty } from 'types/bounty.interface';

const useBountyDetails = () => {
  const [selectedBounty, setSelectedBounty] = useState<Bounty|null>(null);
  const { handleTrackViewItem } = useGoogleAnalytics();
  const { kioskMode } = useKiosk();

  const openBountyDetails = (bounty: Bounty) => {
    setSelectedBounty(bounty);

    if (!kioskMode) {
      handleTrackViewItem(bounty);
    }
  };

  const closeBountyDetails = () => {
    setSelectedBounty(null);
  };

  return {
    selectedBounty,
    openBountyDetails,
    closeBountyDetails,
  };
};

export default useBountyDetails;
