import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import {
  Box, Fab, Popover, PopoverOrigin,
} from '@mui/material';
import { off, onValue, query } from 'firebase/database';
import { isEmpty } from 'lodash';
import {
  useState, MouseEvent, useContext, useEffect,
} from 'react';
import { toast } from 'react-toastify';

import ChatWindow from 'containers/SupportChat/ChatWindow';
import { Badge } from 'containers/SupportChat/index.styled';
import { SystemContext } from 'context/SystemContext';
import { UserContext, UserDispatchContext } from 'context/UserContext';

import { useOrderId } from 'hooks/useRouting';
import { createPsgChat, getUserChatDetailsRef } from 'services/Chat';

import { ChatSupportState } from 'constants/enums';
import { UserChat } from 'types/chat.interface';
import { hasUnreadMessages } from 'utils/chatUtils';
import { handleApiErrors } from 'utils/errorUtils';
import { formatPhoneNumber } from 'utils/formatters';

const anchorOrigin:PopoverOrigin = {
  vertical: 'top',
  horizontal: 'left',
};

const transformOrigin:PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};

const ChatButton = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [showBadge, setShowBadge] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { user, userData, isUpdating } = useContext(UserContext);
  const { shopSettings } = useContext(SystemContext);
  const { setShowLoginFlow } = useContext(UserDispatchContext);
  const orderId = useOrderId();
  const [roomId, setRoomId] = useState('');

  const handleOpenChatWindow = (event: MouseEvent<HTMLButtonElement>) => {
    if (shopSettings?.shopChatSupportState === ChatSupportState.Disabled) {
      const message = shopSettings?.supportPhoneNumber
        ? `Please reach out to ${formatPhoneNumber(shopSettings?.supportPhoneNumber)}`
        : 'Please reach out to our support team.';
      toast.info(message);
      return;
    }

    if (user.isAnonymous) {
      setShowLoginFlow(true);
    } else {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => !prev);

      if (!roomId) {
        initChat({ existingOnly: false });
      }
    }
  };

  const handleCloseChatWindow = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  useEffect(() => {
    if (roomId) {
      subscribeToChatDetails();
    }

    return () => unsubscribeFromChatDetails();
  }, [roomId]);

  useEffect(() => {
    if (!isEmpty(userData) && !roomId && !isUpdating) {
      initChat({ existingOnly: true });
    } else if (user.isAnonymous && roomId) {
      unsubscribeFromChatDetails();
      setRoomId('');
    }
  }, [user.isAnonymous, userData, isUpdating]);

  const unsubscribeFromChatDetails = () => off(getUserChatDetailsRef(user.uid, roomId));

  const subscribeToChatDetails = () => {
    const dbQuery = query(getUserChatDetailsRef(user.uid, roomId));

    onValue(dbQuery, (snapshot) => {
      const chat = snapshot.val() as UserChat;

      if (chat) {
        const hasAtLeastOneUnreadMessage = hasUnreadMessages(chat);
        setShowBadge(hasAtLeastOneUnreadMessage);
      }
    });
  };

  const initChat = async ({ existingOnly }: { existingOnly: boolean }) => {
    try {
      let params: { orderId?: string; existingOnly: boolean } = { existingOnly };

      if (orderId) {
        params = { ...params, orderId };
      }

      const { data } = await createPsgChat(params);
      setRoomId(data.roomId);
    } catch (err: any) {
      setIsDisabled(true);

      if (!existingOnly) {
        if (err.status === 401) {
          toast.warning('Unable to connect to the chat. Please try connecting again later!');
        } else {
          handleApiErrors(err);
        }
      }
    }
  };

  return (
    <div>
      <Box position="fixed" bottom="24px" right="40px">
        <Fab
          aria-label="support-chat"
          color="primary"
          size="medium"
          onClick={handleOpenChatWindow}
          disabled={isUpdating}
        >
          <Badge color="error" variant="dot" invisible={!showBadge}>
            {open ? <ExpandMoreIcon /> : <QuestionAnswerIcon />}
          </Badge>
        </Fab>
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseChatWindow}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <ChatWindow roomId={roomId} onClose={handleCloseChatWindow} isDisabled={isDisabled} />
      </Popover>
    </div>
  );
};

export default ChatButton;
