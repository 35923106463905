import { Box } from '@mui/material';
import { useContext } from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';

import { SystemContext } from 'context/SystemContext';
import AppLayout from 'layouts/AppLayout';

import { getShopConfiguration } from 'utils/storeUtils';

const { asFullPage } = getShopConfiguration();

const MainLayout = () => {
  const {
    hasStores,
    clientInfo,
    isClosed,
  } = useContext(SystemContext);

  return (
    <AppLayout
      isClosed={isClosed}
      hasStores={hasStores}
      clientInfo={clientInfo}
    >
      <Box height="100%">
        <Outlet />
        {asFullPage && <ScrollRestoration />}
      </Box>
    </AppLayout>
  );
};

export default MainLayout;
