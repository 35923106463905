import { Box } from '@mui/material';
import MuiPaper from '@mui/material/Paper';
import { alpha, styled } from '@mui/material/styles';

export const DeliveryWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.common.black, 0.05),
  padding: theme.spacing(3.5, 5),
  borderTop: `1px solid ${alpha(theme.palette.common.black, 0.2)}`,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2.5),
  marginBottom: theme.spacing(6),

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3),
  },
}));

export const DeliveryCard = styled('div')<{ isActive: boolean }>(({ theme, isActive }) => ({
  borderRadius: '6px',
  backgroundColor: isActive ? theme.palette.common.black : theme.palette.common.white,
  color: isActive ? theme.palette.common.white : alpha(theme.palette.common.black, 0.6),
  padding: theme.spacing(1, 2.5),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.5),
  cursor: 'pointer',

  '& .MuiSvgIcon-root': {
    color: isActive ? theme.palette.general.lightGrey12 : alpha(theme.palette.common.black, 0.6),
  },

  '&:hover': {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,

    '& .MuiSvgIcon-root': {
      color: theme.palette.general.lightGrey12,
    },
  },
}));

export const Paper = styled(MuiPaper)(({ theme }) => ({
  padding: theme.spacing(4.5, 5),
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: '12px !important',

  '&:before': {
    display: 'none',
  },

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3),
  },
}));

export const EstimationWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: theme.spacing(3.5),

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));
