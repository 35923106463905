import MuiChip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

export const Chip = styled(MuiChip)(({ theme }) => ({
  padding: theme.spacing(1, 0.5),
  marginLeft: theme.spacing(2),
  borderRadius: '6px',
  border: 'none',
  backgroundColor: theme.palette.common.white,
  ...theme.typography.header,

  '&.MuiButtonBase-root.MuiChip-root.Mui-disabled': {
    opacity: 1,
  },

  '&.MuiButtonBase-root.MuiChip-root.MuiChip-clickable:hover': {
    backgroundColor: theme.palette.background.secondary,
  },

  [theme.breakpoints.down('md')]: {
    paddingLeft: 0,
    paddingRight: 0,

    '& .MuiChip-label': {
      display: 'none',
    },

    '& .MuiChip-icon': {
      marginLeft: theme.spacing(1.5),
      marginRight: theme.spacing(1.5),
    },
  },

  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(1),

    '& .MuiChip-icon': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
})) as typeof MuiChip;
