import { FC, useContext, useState } from 'react';

import MedicalInfo from 'containers/OnboardingUseType/MedicalInfo';
import UseTypePicker from 'containers/OnboardingUseType/UseTypePicker';
import { LocationContext, LocationContextDispatchContext } from 'context/LocationContext';
import { SystemContext } from 'context/SystemContext';

import { MedicalIdPromptType, OnboardingSteps, OnboardingUseType as OnboardingUseTypeEnum } from 'constants/enums';
import { OnboardingInfo } from 'types/onboarding.interface';

interface OnboardingUseTypeProps {
  onClose: () => void;
}

const OnboardingUseType:FC<OnboardingUseTypeProps> = ({ onClose }) => {
  const { saveOnboardingInfo } = useContext(LocationContextDispatchContext);
  const { onboardingInfo } = useContext(LocationContext);
  const { shopSettings } = useContext(SystemContext);
  const [step, setStep] = useState<OnboardingSteps>(OnboardingSteps.UseType);
  const [setup, setSetup] = useState(onboardingInfo);

  const handleUseTypeChanges = (useType: OnboardingUseTypeEnum) => {
    if (
      useType === OnboardingUseTypeEnum.is_medical_use
      && shopSettings.medicalIdPrompt === MedicalIdPromptType.AtStart
    ) {
      setStep(OnboardingSteps.MedicalInfo);
      setSetup({
        ...onboardingInfo,
        useType,
      });
    } else {
      saveOnboardingInfo({
        ...onboardingInfo,
        useType,
      });
      onClose();
    }
  };

  const handleMedicalChanges = (data: Partial<OnboardingInfo>) => {
    setSetup((prevState) => ({ ...prevState, ...data }));
  };

  const submitMedicalInfo = () => {
    saveOnboardingInfo({
      ...onboardingInfo,
      ...setup,
    });
    onClose();
  };

  const showUseType = () => setStep(OnboardingSteps.UseType);

  return (
    step === OnboardingSteps.UseType
      ? <UseTypePicker onChange={handleUseTypeChanges} />
      : (
        <MedicalInfo
          setup={setup}
          onChange={handleMedicalChanges}
          onBack={showUseType}
          onSubmit={submitMedicalInfo}
        />
      )
  );
};

export default OnboardingUseType;
