import { Box } from '@mui/material';
import { FC, ReactElement, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import Footer from 'components/_Footer';
import Navbar from 'components/_Navbar';
import SupportChat from 'containers/SupportChat';
import { SystemContext } from 'context/SystemContext';

import { CHAT_RESTRICTED_ROUTES } from 'constants/clientRoutes';
import { HEADER_HEIGHT } from 'constants/general';
import { ClientInfoInterface } from 'types/clientInfo.interface';
import { getShopConfiguration } from 'utils/storeUtils';

import { ContentWrapper } from './index.styled';

const { asFullPage, hideLogo } = getShopConfiguration();

interface ShopProps {
  children: ReactElement;
  hasStores?: boolean;
  clientInfo?: ClientInfoInterface | null;
  hideFooter?: boolean;
  isClosed?: boolean;
  hideNavbarActions?: boolean;
}

const Shop:FC<ShopProps> = ({
  clientInfo = null,
  children,
  hasStores,
  hideFooter = false,
  isClosed = false,
  hideNavbarActions = false,
}) => {
  const location = useLocation();
  const { layoutType } = useContext(SystemContext);
  const isChatRestrictedRoute = CHAT_RESTRICTED_ROUTES.includes(location.pathname);
  const hideChat = isChatRestrictedRoute || !asFullPage;

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Navbar
        hideLogo={hideLogo}
        hasShoppingCart={!isClosed}
        hasUser={!isClosed}
        clientInfo={clientInfo}
        hasStores={hasStores}
        hideActions={hideNavbarActions}
      />
      <ContentWrapper pt={asFullPage ? `${HEADER_HEIGHT}px` : 0} pb={hideFooter ? 0 : 4} layoutType={layoutType}>
        {children}
      </ContentWrapper>
      {!hideChat && <SupportChat />}
      {!hideFooter && <Footer />}
    </Box>
  );
};

export default Shop;
