import {
  createContext, FC, ReactNode, useContext, useEffect, useMemo, useState,
} from 'react';

import { UserContext } from 'context/UserContext';

import { getClientInformation, getCompanyLocations } from 'services/Client';

import { UserType } from 'constants/enums';
import { ApiLocation } from 'types/companyLocations.interface';
import { UserInfo } from 'types/user.interface';
import { handleApiErrors } from 'utils/errorUtils';

const UserLocationAccessContext = createContext({
  shopOptions: [],
  isLoading: false,
});

interface UserLocationAccessProviderProps {
  children: ReactNode;
}

const UserLocationAccessProvider:FC<UserLocationAccessProviderProps> = ({ children }) => {
  const { userInfo } = useContext(UserContext);
  const [shopOptions, setShopOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const store = useMemo(() => ({
    shopOptions,
    isLoading,
  }), [shopOptions, isLoading]);

  useEffect(() => {
    if (userInfo?.userType !== UserType.Individual && userInfo.clientId) {
      fetchLocations(userInfo);
    }
  }, [userInfo?.clientId]);

  const fetchLocations = async (userInfo: UserInfo) => {
    try {
      setIsLoading(true);
      const { data: clientDetails } = await getClientInformation(userInfo.clientId);

      if (clientDetails.hasLocations) {
        const { data } = await getCompanyLocations(userInfo.clientId);
        setShopOptions(data.list.map(({ locationId, id, name }: ApiLocation) => ({
          key: `${locationId}/${id}`,
          value: name,
        })));
      } else {
        setShopOptions([]);
      }
    } catch (e) {
      handleApiErrors(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <UserLocationAccessContext.Provider value={store}>
      {children}
    </UserLocationAccessContext.Provider>
  );
};

export {
  UserLocationAccessProvider,
  UserLocationAccessContext,
};
