import { isNil } from 'lodash';

import { PRODUCTS_ROUTE } from 'constants/clientRoutes';
import { FontSize, AttachmentActions } from 'constants/enums';
import { ALL_CATEGORIES, FILTER_CODE_SEPARATOR } from 'constants/general';
import {
  Overlay, Promotion, TextLine,
} from 'types/promotion.interface';

export const getPromotionRoute = (promotion: Promotion) => {
  const { action } = promotion;
  let route = '';

  if (action?.actionType === AttachmentActions.Search) {
    const queryParams = [];

    if (action?.sort) {
      queryParams.push(`sort=${action?.sort}`);
    }

    if (action?.categories) {
      const category = action?.categories?.includes('root') ? ALL_CATEGORIES : action?.categories;
      queryParams.push(`category=${category}`);
    }

    if (action?.tags) {
      const filters = action?.tags ? action?.tags?.split(',') : [];
      filters.forEach((filter) => {
        const [filterId, filterCode] = filter.split(FILTER_CODE_SEPARATOR);
        queryParams.push(`filter.${filterId}=${filterCode}`);
      });
    }

    route = `${PRODUCTS_ROUTE}?${queryParams?.join('&')}`;
  }

  return route;
};

export const getFontSizeEquivalent = (fontSize: FontSize) => {
  if (fontSize === FontSize.Small) {
    return {
      fontSize: '16px',
      lineHeight: '21px',
    };
  }

  if (fontSize === FontSize.Medium) {
    return {
      fontSize: '24px',
      lineHeight: '34px',
    };
  }

  if (fontSize === FontSize.Large) {
    return {
      fontSize: '40px',
      lineHeight: '52px',
    };
  }

  return {
    fontSize: '16px',
    lineHeight: '21px',
  };
};

export const getHeadlineStyle = (headline: TextLine) => ({
  ...getFontSizeEquivalent(headline.fontSize),
  fontWeight: headline?.fontWeight,
  textAlign: headline?.alignment,
});

export const getOverlayStyle = (overlay?: Overlay) => ({
  backgroundColor: overlay?.color,
  opacity: !isNil(overlay?.opacity) ? +overlay.opacity : 0,
});
