import { ButtonBase } from '@mui/material';
import MuiDialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';

export const StyledButton = styled(ButtonBase)(({ theme }) => ({
  color: theme.palette.general.lightGrey2,
  height: '100%',
}));

export const StyledSearch = styled('div')(({ theme }) => ({
  border: `2px solid ${theme.palette.border.main}`,
  borderRadius: '6px',
  padding: theme.spacing(0.5, 1),
  display: 'flex',
  alignItems: 'center',
  height: '42px',
  backgroundColor: theme.palette.common.white,

  '&:hover': {
    backgroundColor: theme.palette.background.secondary,
  },
}));

export const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  minWidth: '500px',
  mb: theme.spacing(2),
  pt: theme.spacing(2),

  [theme.breakpoints.down('sm')]: {
    minWidth: '100%',
  },
}));

export const Root = styled('div')<{ kioskMode: boolean }>(({ theme, kioskMode }) => ({
  flexGrow: kioskMode ? 0 : 1,

  [theme.breakpoints.down('md')]: {
    flexGrow: 0,
  },
}));
