import { Box, SelectChangeEvent, Typography } from '@mui/material';
import { FC, useState } from 'react';

import CustomButton from 'components/CustomButton';
import CustomDropdown from 'components/CustomDropdown';
import Loader from 'components/Loader';

import { STORE_WITH_PLACEHOLDER } from 'constants/fields';
import { Option } from 'types/option.interface';

interface StorePickerProps {
  onClose: () => void;
  shopOptions: Option[];
  isLoading: boolean;
}

const StorePicker:FC<StorePickerProps> = ({
  shopOptions,
  onClose,
  isLoading,
}) => {
  const [selectedShop, setSelectedShop] = useState('');

  const selectShop = ({ target: { value } }: SelectChangeEvent<unknown>) => {
    setSelectedShop(value as string);
  };

  const visitShop = () => {
    window.location.replace(`${window.location.origin}/${selectedShop}/welcome`);
    onClose();
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Box py={2}>
      <Typography variant="h6" fontWeight={700}>
        Choose the store you want to use
      </Typography>
      <Box my={3}>
        <CustomDropdown
          {...STORE_WITH_PLACEHOLDER}
          value={selectedShop || ''}
          onChange={selectShop}
          options={shopOptions}
        />
      </Box>
      <CustomButton
        fullWidth
        id="button-continue"
        variant="contained"
        onClick={visitShop}
        disabled={!selectedShop}
      >
        Continue
      </CustomButton>
    </Box>
  );
};

export default StorePicker;
