import { Typography } from '@mui/material';
import { FC } from 'react';
import ReactPlayer from 'react-player';
import { useNavigate } from 'react-router-dom';

import {
  Root,
  BorderContainer,
  BuyButton,
  HeadlineContainer,
  ImageWrapper,
  OverlayContainer,
} from 'containers/Specials/SpecialCard/index.styled';

import { getProductsRoute } from 'hooks/useRouting';

import { Promotion } from 'types/promotion.interface';
import { getHeadlineStyle } from 'utils/promotion';

interface SpecialCardProps {
  special: Promotion;
}

const SpecialCard:FC<SpecialCardProps> = ({ special }) => {
  const navigate = useNavigate();

  const getSpecialDetails = () => {
    if (special?.action?.categories) {
      navigate(getProductsRoute(`category=${special.action.categories}`));
    } else if (special?.action?.bountyIds) {
      const queryParams = special.action.bountyIds
        .split(',')
        .map((id) => `bountyIds=${id.trim()}`)
        .join('&');
      navigate(getProductsRoute(queryParams));
    } else {
      navigate(getProductsRoute());
    }
  };

  return (
    <Root>
      <ImageWrapper>
        <ReactPlayer
          loop
          playing
          controls={false}
          width="100%"
          height="100%"
          muted
          playsinline
          url={special.videoUrl}
        />
        <OverlayContainer />
        <BorderContainer />
        <HeadlineContainer>
          {special?.headline1?.text && (
            <Typography style={{ ...getHeadlineStyle(special.headline1), textAlign: 'center' }}>
              {special.headline1.text}
            </Typography>
          )}
          {special?.headline2?.text && (
            <Typography style={{ ...getHeadlineStyle(special.headline2), textAlign: 'center' }}>
              {special.headline2.text}
            </Typography>
          )}
        </HeadlineContainer>
        <BuyButton
          id="btn-buyNow"
          onClick={getSpecialDetails}
        >
          {special?.action?.btnLabel || 'Buy Now'}
        </BuyButton>
      </ImageWrapper>
    </Root>
  );
};

export default SpecialCard;
