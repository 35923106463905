import { Box } from '@mui/material';
import { isArray } from 'lodash';
import {
  ChangeEvent, KeyboardEvent, FC, useState, useEffect,
} from 'react';

import AirbnbSliderThumb from 'components/AirbnbSliderThumb';
import {
  Divider,
  NumberField,
  Slider,
} from 'components/Filters/FilterSlider/index.styled';

import { FilterType } from 'constants/enums';
import { prefixByCriteriaType } from 'constants/general';
import { Filter } from 'types/filter.interface';
import { extractNumberFromNumericFormat } from 'utils/numbers';

interface FilterSliderProps {
  activeFilters: { [key: string]: string[] };
  filter: Filter;
  onRangeFilter?: (filterId: string, filterCode: string, filterType: FilterType) => void;
}

const FilterSlider:FC<FilterSliderProps> = ({
  activeFilters,
  filter,
  onRangeFilter,
}) => {
  const min = filter.options[0];
  const max = filter.options[1];
  const [sliderValues, setSliderValues] = useState<number[]>([+min.name, +max.name]);
  const isDisabled = min.name === max.name;

  useEffect(() => {
    if (activeFilters[min.code] || activeFilters[max.code]) {
      setSliderValues([
        activeFilters[min.code] ? +activeFilters[min.code][0] : +min.name,
        activeFilters[max.code] ? +activeFilters[max.code][0] : +max.name,
      ]);
    }
  }, [activeFilters, min, max]);

  const handleSlideChanges = (_: Event, newValue: number|number[]) => {
    setSliderValues((prevState) => {
      if (Array.isArray(newValue)) {
        if (newValue[0] < +min.name || newValue[1] > +max.name) {
          return prevState;
        }
      }

      return Array.isArray(newValue) ? newValue : [newValue];
    });
  };

  const handleMinSlideFieldChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setSliderValues((prevState) => [
      extractNumberFromNumericFormat({ value, hasPrefix: true }),
      prevState[1],
    ]);
  };

  const handleMaxSlideFieldChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setSliderValues((prevState) => [
      prevState[0],
      extractNumberFromNumericFormat({ value, hasPrefix: true }),
    ]);
  };

  const handleMinSlideFieldKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && onRangeFilter) {
      onRangeFilter(min.code, sliderValues[0].toString(), filter.type);
    }
  };

  const handleMaxSlideFieldKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && onRangeFilter) {
      onRangeFilter(max.code, sliderValues[1].toString(), filter.type);
    }
  };

  const handleSlideCommitted = (_: any, value: number | number[]) => {
    if (!isArray(value) || !onRangeFilter) {
      return;
    }

    if ((activeFilters[min.code] && value[0] !== +activeFilters[min.code]) || value[0] !== +min.name) {
      onRangeFilter(min.code, value[0].toString(), filter.type);
    } else if ((activeFilters[max.code] && value[1] !== +activeFilters[max.code]) || value[1] !== +max.name) {
      onRangeFilter(max.code, value[1].toString(), filter.type);
    }
  };

  return (
    <>
      <Slider
        min={+min.name}
        max={+max.name}
        value={sliderValues}
        onChange={handleSlideChanges}
        onChangeCommitted={handleSlideCommitted}
        disableSwap
        slots={{ thumb: AirbnbSliderThumb }}
      />
      <Box display="flex" alignItems="center" gap={2} justifyContent="space-between" mt={0.5}>
        <NumberField
          name={min.code}
          value={sliderValues[0]}
          disabled={isDisabled}
          onChange={handleMinSlideFieldChange}
          onKeyDown={handleMinSlideFieldKeyDown}
          decimalScale={0}
          size="small"
          prefix={prefixByCriteriaType[filter.criteriaType] || ''}
        />
        <Divider />
        <NumberField
          name={max.code}
          value={sliderValues[1]}
          disabled={isDisabled}
          onChange={handleMaxSlideFieldChange}
          onKeyDown={handleMaxSlideFieldKeyDown}
          decimalScale={0}
          size="small"
          prefix={prefixByCriteriaType[filter.criteriaType] || ''}
        />
      </Box>
    </>
  );
};

export default FilterSlider;
