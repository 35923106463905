import {
  Container,
  Label,
  Row,
  Value,
} from './index.styles';

type CustomerInfoCellProps = {
  name: string;
  dateOfBirth: string;
  patientNumber: string;
};

export const CustomerInfoCell = ({
  name,
  dateOfBirth,
  patientNumber,
}: CustomerInfoCellProps) => (
  <Container>
    <Row>
      <Label>Name:</Label>
      <Value>{name}</Value>
    </Row>

    <Row>
      <Label>DOB:</Label>
      <Value>{dateOfBirth}</Value>
    </Row>

    <Row>
      <Label>Patient #:</Label>
      <Value>{patientNumber}</Value>
    </Row>
  </Container>
);
