import { formatDate } from 'date-fns';

import { CheckoutSections, DeliveryMethod, PaymentMethod } from 'constants/enums';
import { DETAILED_DAY_DATE_FORMAT, EMPTY_STATE, EXTENDED_ORDER_DATE_FORMAT } from 'constants/general';
import { PAYMENT_METHOD_LABELS } from 'constants/labels';
import { PlaceOrderInfo } from 'types/checkout.interface';
import { Option } from 'types/option.interface';

export const getNextSection = (currentSection: CheckoutSections) => {
  if (currentSection === CheckoutSections.ReservationDetails) {
    return CheckoutSections.ContactInfo;
  }

  return CheckoutSections.PaymentMethod;
};

export const formatPickupDateAndTime = (pickupDate?: Date | null, pickupTime?: Date | null) => {
  if (!pickupDate) {
    return EMPTY_STATE;
  }

  if (!pickupTime) {
    return formatDate(pickupDate, DETAILED_DAY_DATE_FORMAT);
  }

  const newDate = new Date(pickupDate);
  newDate.setTime(pickupTime.getTime());
  return formatDate(newDate, EXTENDED_ORDER_DATE_FORMAT);
};

export const getPaymentLabel = (payment?: PaymentMethod) => {
  const onlineMethods = [
    PaymentMethod.BILLMYBANK,
    `${PaymentMethod.BILLMYBANK}_SANDBOX`,
    PaymentMethod.CHARGEE,
    `${PaymentMethod.CHARGEE}_SANDBOX`,
  ];

  if (!payment) {
    return EMPTY_STATE;
  }

  if (onlineMethods.includes(payment)) {
    return `Online with ${PAYMENT_METHOD_LABELS[payment]}`;
  }

  return `At store with ${PAYMENT_METHOD_LABELS[payment]}`;
};

export const getScheduleTime = (checkoutForm: PlaceOrderInfo, isPickup: boolean) => {
  const deliveryDate = checkoutForm?.delivery?.pickupDate
    ? formatDate(checkoutForm?.delivery?.pickupDate, DETAILED_DAY_DATE_FORMAT)
    : EMPTY_STATE;
  return isPickup
    ? formatPickupDateAndTime(checkoutForm?.delivery?.pickupDate, checkoutForm?.delivery?.pickupTime)
    : deliveryDate;
};

export const checkDeliveryMethods = (deliveryMethods: Option[]) => {
  let hasPickup = false;
  let hasDelivery = false;

  deliveryMethods?.forEach(({ key }) => {
    if ([DeliveryMethod.PICKUP, DeliveryMethod.CURBSIDE].includes(key as DeliveryMethod)) {
      hasPickup = true;
    }

    if (DeliveryMethod.DELIVERY === key) {
      hasDelivery = true;
    }
  });

  return { hasPickup, hasDelivery };
};
