import CloseIcon from '@mui/icons-material/Close';
import { Box, Skeleton, Typography } from '@mui/material';
import { FC, useContext, useMemo } from 'react';

import { Chip, SummaryContainer } from 'containers/Products/FiltersSummary/index.styled';
import { CategoryContext } from 'context/CategoryContext';

import { FilterType } from 'constants/enums';
import { ALL_CATEGORIES, prefixByCriteriaType } from 'constants/general';
import { Filter } from 'types/filter.interface';
import { getCategoryName } from 'utils/categoriesUtils';
import { getFilterName } from 'utils/filterUtils';
import { capitalize } from 'utils/text';

interface FiltersSummaryProps {
  isLoading: boolean;
  activeFilters: Record<string, string[]>;
  filters: Filter[];
  removeFilter: (filterId: string, filterCode: string) => void;
}
const FiltersSummary:FC<FiltersSummaryProps> = ({
  isLoading,
  activeFilters,
  filters,
  removeFilter,
}) => {
  const { categories, selectedCategories } = useContext(CategoryContext);
  const rangeFilters = useMemo(
    () => filters
      .filter(({ type }) => type === FilterType.RANGE)
      .reduce((acc, currentValue) => {
        currentValue?.options.forEach((option, index) => {
          acc[option.code] = {
            label: `${!index ? 'Min' : 'Max'} ${currentValue.name}:`,
            prefix: prefixByCriteriaType[currentValue.criteriaType],
          };
        });
        return acc;
      }, {} as Record<string, { label: string; prefix: string }>),
    [filters],
  );

  const leaf = selectedCategories[selectedCategories.length - 1];
  const leafName = leaf && leaf !== ALL_CATEGORIES
    ? getCategoryName(leaf, categories)
    : 'All Categories';
  const noOfActiveFilters = Object.keys(activeFilters)?.length;

  const getFilterLabel = (filterType: string) => {
    if (rangeFilters[filterType]) {
      return rangeFilters[filterType].label;
    }

    return `${capitalize(filterType?.split('_').join(' '))}:`;
  };

  const getFilterValue = (filterType: string, filterKey: string) => {
    if (rangeFilters[filterType]) {
      return `${rangeFilters[filterType].prefix}${filterKey}`;
    }

    return getFilterName(filterType, filterKey, filters);
  };

  return (
    <SummaryContainer>
      {noOfActiveFilters > 0 && (
        <Typography fontWeight="700" color="textSecondary" mb={1}>
          {`${leafName} - ${noOfActiveFilters} ${noOfActiveFilters === 1 ? 'Filter' : 'Filters'} Applied`}
        </Typography>
      )}
      {Object.keys(activeFilters)?.map((filterType) => (
        <Box display="flex" alignItems="center" gap={1} key={filterType} mb={0.75}>
          <Typography color="textSecondary">
            {getFilterLabel(filterType)}
          </Typography>
          {isLoading && !filters?.length
            ? <Skeleton animation="wave" variant="rectangular" width="70px" height={33} sx={{ borderRadius: '6px' }} />
            : (
              activeFilters[filterType].map((filterKey) => (
                <Chip
                  key={filterKey}
                  label={getFilterValue(filterType, filterKey)}
                  onDelete={() => removeFilter(filterType, filterKey)}
                  deleteIcon={<CloseIcon />}
                />
              ))
            )}
        </Box>
      ))}
    </SummaryContainer>
  );
};

export default FiltersSummary;
