import { Box, Typography } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import { styled } from '@mui/material/styles';
import { FC } from 'react';

import { FontSize } from 'constants/enums';
import { Background, TextLine } from 'types/promotion.interface';
import { getFontSizeEquivalent, getHeadlineStyle } from 'utils/promotion';

export const Root = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(5),
  width: '100%',

  [theme.breakpoints.down('md')]: {
    marginTop: 0,
    marginBottom: theme.spacing(4),
  },
}));

export const ImageWrapper = styled('div')(({ theme }) => ({
  height: '350px',
  borderRadius: '12px',
  position: 'relative',
  cursor: 'pointer',

  [theme.breakpoints.down('md')]: {
    height: '200px',
  },

  [theme.breakpoints.down('sm')]: {
    height: '150px',
  },
}));

export const StyledCardMedia = styled(CardMedia)<{ background?: Background }>(({ theme, background }) => ({
  borderRadius: '8px',
  height: '350px',
  cursor: 'pointer',
  objectFit: background?.objectFit || 'contain',
  backgroundColor: background?.color || 'transparent',

  [theme.breakpoints.down('md')]: {
    height: '200px',
  },

  [theme.breakpoints.down('sm')]: {
    height: '150px',
  },
})) as typeof CardMedia | FC<{ background?: Background }>;

export const OverlayContainer = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 1,
  width: '100%',
  height: '100%',
  borderRadius: '12px',
}));

export const HeadlineContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  bottom: 0,
  left: 0,
  transform: 'translateY(-50%)',
  zIndex: 2,
  paddingLeft: '80px',
  width: '100%',
  height: 'fit-content',
  color: theme.palette.common.white,

  [theme.breakpoints.down('md')]: {
    paddingLeft: '50px',
  },

  [theme.breakpoints.down('sm')]: {
    paddingLeft: '20px',
  },
}));

export const Headline = styled(
  Typography,
  { shouldForwardProp: (props) => props !== 'headline' },
)<{ headline: TextLine }>(({ headline, theme }) => ({
  ...getHeadlineStyle(headline),

  [theme.breakpoints.down('sm')]: {
    ...getFontSizeEquivalent(FontSize.Medium),
  },

  [theme.breakpoints.down(375)]: {
    ...getFontSizeEquivalent(FontSize.Small),
  },
}));
