import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import { Dialog } from '@mui/material';
import {
  FC, useContext, useMemo, useState,
} from 'react';

import { Chip } from 'components/_Navbar/StoreLocation/index.styled';
import OnboardingFlow from 'containers/Onboarding';
import OnboardingUseType from 'containers/OnboardingUseType';
import { LocationContext } from 'context/LocationContext';
import { OnboardingContextProvider } from 'context/OnboardingContext';
import { SystemContext } from 'context/SystemContext';

import { isRestrictedToOneLocation } from 'hooks/useRouting';

import { USE_TYPE_OPTIONS } from 'constants/options';
import { textTruncate } from 'utils/formatters';
import { getCompanyLocationsData, getOnboardingInfo } from 'utils/storageUtils';

const StoreLocation: FC = () => {
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [showUseTypeOnboarding, setShowUseTypeOnboarding] = useState(false);
  const { storeName } = getOnboardingInfo() || {};
  const { list } = getCompanyLocationsData() || {};
  const hasMultipleLocations = Boolean(list && list.length > 1);
  const { onboardingInfo } = useContext(LocationContext);
  const { clientInfo } = useContext(SystemContext);
  const { useType } = onboardingInfo || {};
  const hasMultipleUseTypes = useMemo(() => {
    const store = list?.find(({ id }) => id === clientInfo?.id);
    return store && store?.adultUse && store?.medicalUse;
  }, [clientInfo, list]);

  const locationName = useMemo(() => (
    storeName ? textTruncate(storeName, 25) : 'Store location'
  ), [storeName]);

  const useTypeLabel = useMemo(() => (
    useType === USE_TYPE_OPTIONS[1].key
      ? USE_TYPE_OPTIONS[1].value
      : USE_TYPE_OPTIONS[0].value
  ), [useType]);

  const handleOpenOnboarding = () => {
    setShowOnboarding(true);
  };

  const handleCloseOnboarding = () => {
    setShowOnboarding(false);
  };

  const handleOpenUseTypeOnboarding = () => {
    setShowUseTypeOnboarding(true);
  };

  const handleCloseUseTypeOnboarding = () => {
    setShowUseTypeOnboarding(false);
  };

  return (
    <>
      {!isRestrictedToOneLocation() && (
        <Chip
          disabled={!hasMultipleLocations}
          icon={<LocationOnOutlinedIcon />}
          label={locationName}
          variant="outlined"
          onClick={handleOpenOnboarding}
        />
      )}

      <Chip
        disabled={!hasMultipleUseTypes}
        icon={useType === USE_TYPE_OPTIONS[1].key ? <MedicalServicesOutlinedIcon /> : <SelfImprovementIcon />}
        label={useTypeLabel}
        variant="outlined"
        onClick={handleOpenUseTypeOnboarding}
      />

      {showUseTypeOnboarding && (
        <Dialog
          open
          aria-describedby="use-type-picker"
          onClose={handleCloseUseTypeOnboarding}
          slotProps={{
            paper: { sx: { width: '480px', borderRadius: '16px', py: 3 } },
          }}
        >
          <OnboardingUseType onClose={handleCloseUseTypeOnboarding} />
        </Dialog>
      )}

      <OnboardingContextProvider closeModal={handleCloseOnboarding} isModalOpen={showOnboarding}>
        {showOnboarding && <OnboardingFlow />}
      </OnboardingContextProvider>
    </>
  );
};

export default StoreLocation;
