import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: '12px',
}));

export const PriceWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.secondary,
  padding: theme.spacing(5),
  borderBottomLeftRadius: '12px',
  borderBottomRightRadius: '12px',

  '& .coupon': {
    padding: theme.spacing(0, 0, 5),
  },

  '& .totalSection': {
    marginTop: theme.spacing(5),
  },

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3),
  },
}));

export const SummaryWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(5),

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3),
  },
}));
