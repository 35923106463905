import { MenuItem, SelectChangeEvent, Typography } from '@mui/material';
import { FC } from 'react';

import { Dropdown, SortWrapper } from 'containers/Products/ProductSort/index.styled';

import { ProductSortOptions } from 'constants/enums';
import { PRODUCT_SORT_OPTIONS } from 'constants/options';

interface ProductSortProps {
  value: ProductSortOptions;
  onChange: (value: ProductSortOptions) => void;
}

const ProductSort:FC<ProductSortProps> = ({ value, onChange }) => {
  const handleSortChanges = ({ target: { value } }: SelectChangeEvent<unknown>) => {
    onChange(value as ProductSortOptions);
  };

  return (
    <SortWrapper>
      <Typography>
        Sort By:
      </Typography>
      <Dropdown
        displayEmpty
        size="small"
        value={value}
        onChange={handleSortChanges}
      >
        {PRODUCT_SORT_OPTIONS.map(({ value, key }) => (
          <MenuItem value={key} key={key}>{value}</MenuItem>
        ))}
      </Dropdown>
    </SortWrapper>
  );
};

export default ProductSort;
