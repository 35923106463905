export const INVETORY_GRID_COLUMNS = [
  {
    field: 'itemName',
    headerName: 'Item',
    flex: 1.5,
    minWidth: 150,
  },
  {
    field: 'batchName',
    headerName: 'Batch Name',
    flex: 1,
    minWidth: 150,
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    minWidth: 150,
  },
  {
    field: 'brand',
    headerName: 'Brand',
    flex: 1,
    minWidth: 150,
  },
  {
    field: 'barcodeId',
    headerName: 'Barcode Id',
    flex: 1,
    minWidth: 150,
  },
  {
    field: 'concentrationPercentage',
    headerName: 'THC%/CBG%',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    flex: 1,
    minWidth: 150,
    valueGetter: (_value: string, row: { thc: string; cbd: string }) => `${row.thc || ''}% / ${row.cbd || ''}%`,
  },
  {
    field: 'concentrationMilligrams',
    headerName: 'THC mg/CBD mg',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    flex: 1,
    minWidth: 150,
    valueGetter:
        (_value: string, row: { thcMg: string; cbdMg: string }) => `${row.thcMg || ''}mg / ${row.cbdMg || ''}mg`,
  },
  {
    field: 'areaName',
    headerName: 'Area',
    flex: 1,
    minWidth: 150,
  },
  {
    field: 'onHand',
    headerName: 'On Hand',
    flex: 1,
    minWidth: 150,
    valueGetter: (value: string, row: { uom: string }) => `${value || 0} ${row.uom || ''}`,
  },
  {
    field: 'available',
    headerName: 'Available',
    flex: 1,
    minWidth: 150,
    valueGetter: (value: string, row: { uom: string }) => `${value || 0} ${row.uom || ''}`,
  },
];

export const DEFAULT_INVENTORY_PAGE_SIZE_OPTIONS = [5, 10, 25, 50];
