import {
  Box, Typography, Grid, SelectChangeEvent,
} from '@mui/material';
import { get } from 'lodash';
import { ChangeEvent, FC } from 'react';

import CustomButton from 'components/CustomButton';
import CustomPhoneInput from 'components/CustomPhoneInput';
import CustomRadioButton from 'components/CustomRadioButton';
import DeliveryAddressForm from 'components/DeliveryAddressForm';
import PickupInformationForm from 'components/PickupInformationForm';
import StoreDetails from 'containers/CheckoutProviderPage/StoreDetails';

import { DeliveryMethod, FieldRuleType } from 'constants/enums';
import { DELIVERY_PHONE_NUMBER, DELIVERY_METHOD } from 'constants/fields';
import { OrderDateRestrictions, PlaceOrderInfo } from 'types/checkout.interface';
import { Option } from 'types/option.interface';
import { getPickupDeliveryOptions, isPickupMethod } from 'utils/checkoutUtils';
import { getPhoneFromAddress } from 'utils/formatters';

interface ReservationFormProps {
  checkoutForm: PlaceOrderInfo;
  fieldErrors: Record<string, string>;
  onChange: (value: any, name: string) => void;
  onContinue: () => void;
  orderDateRestrictions: OrderDateRestrictions | null;
  isLoadingDateRestrictions: boolean;
  checkoutFields?: Record<string, FieldRuleType>;
  deliveryMethods: Option[];
}

const ReservationForm: FC<ReservationFormProps> = ({
  checkoutForm,
  onChange,
  fieldErrors,
  onContinue,
  orderDateRestrictions,
  isLoadingDateRestrictions,
  checkoutFields = {},
  deliveryMethods,
}) => {
  const { address, delivery } = checkoutForm;
  const isPickup = isPickupMethod(checkoutForm?.delivery?.method);
  const options = getPickupDeliveryOptions(deliveryMethods);

  const handleChange = (
    { target: { value, name } }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent,
  ) => {
    onChange(value, name);
  };

  return (
    <>
      <StoreDetails sx={{ mb: 5 }} isBold />

      <Grid container spacing={5}>
        <Grid size={{ xs: 6 }}>
          <Typography variant="subtitle1" fontWeight={600} mb={1.5}>
            {isPickup ? 'PICKUP DATE & TIME' : 'DELIVERY DATE'}
          </Typography>
          <Box display="flex" flexDirection="column" gap={1.5}>
            <PickupInformationForm
              delivery={delivery}
              onValueChange={onChange}
              fieldErrors={fieldErrors}
              orderDateRestrictions={orderDateRestrictions}
              fieldsRules={checkoutFields}
              isLoadingDateRestrictions={isLoadingDateRestrictions}
            />
          </Box>
        </Grid>
        {isPickup && (
          <Grid size={{ xs: 6 }}>
            <CustomRadioButton
              {...DELIVERY_METHOD}
              label="PICKUP METHOD"
              size="medium"
              onChange={handleChange}
              value={delivery?.method}
              options={options}
              spacing={1.5}
              grid={{ xs: 12 }}
              error={!!get(fieldErrors, DELIVERY_METHOD.name)}
              helperText={get(fieldErrors, DELIVERY_METHOD.name)}
            />
          </Grid>
        )}
      </Grid>

      {!isPickup && address && (
        <DeliveryAddressForm
          onChange={handleChange}
          onValueChange={onChange}
          address={address}
          fieldErrors={fieldErrors}
          sx={{ mt: 5 }}
        />
      )}

      {delivery?.method === DeliveryMethod.CURBSIDE && (
        <CustomPhoneInput
          {...DELIVERY_PHONE_NUMBER}
          onChange={onChange}
          phoneNumber={getPhoneFromAddress(address)}
          error={!!get(fieldErrors, DELIVERY_PHONE_NUMBER.name)}
          helperText={get(fieldErrors, DELIVERY_PHONE_NUMBER.name)}
          sx={{ mt: 3.5 }}
        />
      )}

      <CustomButton
        id="button-continueReservationForm"
        onClick={onContinue}
        fullWidth
        sx={{ mt: 7.5 }}
      >
        Continue
      </CustomButton>
    </>
  );
};

export default ReservationForm;
