import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { FC, ReactNode } from 'react';

import { Paper, EditWrapper } from 'containers/CheckoutProviderPage/CheckoutSection/index.styled';

interface CheckoutSectionProps {
  title: string;
  FormComponent: ReactNode;
  DetailsComponent: ReactNode;
  isDisabled?: boolean;
  onEdit: () => void;
  sectionDetails: { isOpen: boolean; isCompleted: boolean };
}

const CheckoutSection: FC<CheckoutSectionProps> = ({
  title,
  isDisabled = false,
  onEdit,
  FormComponent,
  DetailsComponent,
  sectionDetails,
}) => {
  const { isOpen, isCompleted } = sectionDetails;

  return (
    <Paper elevation={0}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        minHeight="46px"
        gap={1}
        mb={isOpen || isCompleted ? 3.5 : 0}
      >
        <Typography variant="h4">
          {title}
        </Typography>
        {!isOpen && (
          <EditWrapper isDisabled={isDisabled} onClick={isDisabled ? undefined : onEdit}>
            <EditOutlinedIcon />
          </EditWrapper>
        )}
      </Box>
      {isOpen && FormComponent}
      {!isOpen && isCompleted && DetailsComponent}
    </Paper>
  );
};

export default CheckoutSection;
