import { Typography } from '@mui/material';
import { FC } from 'react';

import UnstyledLink from 'components/UnstyledLink';
import { Card } from 'containers/WelcomePage/Item/index.styled';

interface ItemProps {
  to: string;
  label: string;
}

const Item:FC<ItemProps> = ({ label, to }) => (
  <UnstyledLink to={to}>
    <Card>
      <Typography fontWeight={700}>
        {label}
      </Typography>
    </Card>
  </UnstyledLink>
);

export default Item;
