import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Typography } from '@mui/material';
import { GridColDef, GridDeleteIcon } from '@mui/x-data-grid';
import { format } from 'date-fns';

import CustomButton from 'components/CustomButton';
import { CustomerInfoCell } from 'components/CustomerInfoCell';

import { FULL_DATE_FORMAT } from 'constants/general';
import { POSQueue } from 'types/pos.interface';
import { formatUserName } from 'utils/formatters';

import { CellWrapper, OrderWrapper } from './index.styled';

const commonCellProps = {
  resizable: false,
  disableColumnMenu: true,
  sortable: false,
};

export const columns = (handleDelete: (queue: POSQueue) => void): GridColDef<POSQueue>[] => [{
  field: 'remove',
  headerName: 'Remove',
  width: 75,
  ...commonCellProps,
  renderCell: ({ row }) => (
    <CellWrapper onClick={() => handleDelete(row)}>
      <GridDeleteIcon />
    </CellWrapper>
  ),
}, {
  field: 'customer',
  headerName: 'Customer Info',
  width: 300,
  ...commonCellProps,
  renderCell: ({
    row: {
      lastName,
      firstName,
      dateOfBirth,
      patientNumber,
    },
  }) => (
    <CustomerInfoCell
      name={formatUserName(firstName, lastName)}
      dateOfBirth={dateOfBirth}
      patientNumber={patientNumber}
    />
  ),
}, {
  field: 'orders',
  headerName: 'Open Orders',
  width: 150,
  ...commonCellProps,
  renderCell: ({ row: { orders } }) => (
    <OrderWrapper sx={{ padding: 1.2 }}>
      {orders?.map((order) => (
        <CustomButton
          key={order.id}
          size="small"
          sx={{ marginBottom: 0.5 }}
          variant="contained"
          color="inherit"
          id={`order-${order.id}`}
        >
          {`Order # ${order.id}`}
        </CustomButton>
      ))}
    </OrderWrapper>
  ),
}, {
  field: 'ordersDuplicate',
  headerName: 'Sale Type',
  width: 100,
  ...commonCellProps,
  renderCell: ({ row: { orders } }) => (
    <OrderWrapper>
      {orders?.map((order) => (
        <CellWrapper key={order.id} sx={{ height: '35px' }}>
          <Typography variant="body4">
            {order.type}
          </Typography>
        </CellWrapper>
      ))}
    </OrderWrapper>
  ),
}, {
  field: 'status',
  headerName: '# Items',
  ...commonCellProps,
  renderCell: ({ row: { orders } }) => (
    <CellWrapper>
      <ShoppingCartIcon />
      {orders?.length ?? 0}
    </CellWrapper>
  ),
}, {
  field: 'total',
  headerName: 'Total',
  width: 120,
  ...commonCellProps,
  renderCell: ({ row: { orders } }) => (
    <CellWrapper>
      $
      {orders?.reduce((sum, order) => sum + order.total, 0).toFixed(2) ?? '0.00'}
    </CellWrapper>
  ),
}, {
  field: 'timeIn',
  headerName: 'Time In',
  width: 180,
  ...commonCellProps,
  renderCell: ({ row: { timeIn } }) => (
    <CellWrapper>
      {format(new Date(timeIn), FULL_DATE_FORMAT)}
    </CellWrapper>
  ),
}, {
  field: 'actions',
  headerName: '',
  width: 100,
  ...commonCellProps,
  renderCell: () => (
    <CellWrapper>
      <CustomButton size="small" id="button-selectQueue" variant="contained" color="inherit">
        Select
      </CustomButton>
    </CellWrapper>
  ),
}];
