import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { IconButton } from 'components/PageTitle/index.styled';

interface PageTitleProps {
  hasBackButton?: boolean;
  href?: string;
  title: string;
}

const PageTitle:FC<PageTitleProps> = ({ hasBackButton = false, href, title }) => {
  const navigate = useNavigate();

  const goBack = () => {
    if (href) {
      navigate(href);
    } else {
      navigate(-1);
    }
  };

  return (
    <Box display="flex" alignItems="center" gap={2} py={3}>
      {hasBackButton && (
        <IconButton onClick={goBack}>
          <ArrowBackIcon fontSize="small" />
        </IconButton>
      )}
      <Typography variant="h4" fontWeight={700} textAlign="center" flexGrow={1}>
        {title}
      </Typography>
    </Box>
  );
};

export default PageTitle;
