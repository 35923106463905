import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const POSHeaderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  padding: theme.spacing(3),
  backgroundColor: theme.palette.common.white,
}));

export const POSHeaderBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  flex: 1,
  justifyContent: 'space-evenly',
}));
