import { Box } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

export const CategoryCard = styled(
  Box,
  { shouldForwardProp: (props) => props !== 'isActive' && props !== 'disabled' },
)<{ isActive?: boolean; disabled?: boolean }>(({ theme, isActive, disabled }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  width: '100%',
  padding: theme.spacing(0.5, 2),
  borderRadius: '6px',
  // eslint-disable-next-line no-nested-ternary
  border: isActive
    ? `1px solid ${theme.palette.common.black}`
    : disabled ? '1px solid transparent' : `1px solid ${alpha(theme.palette.common.black, 0.1)}`,
  // eslint-disable-next-line no-nested-ternary
  backgroundColor: isActive
    ? theme.palette.common.black
    : disabled ? theme.palette.common.white : theme.palette.highlightColor.main,
  // eslint-disable-next-line no-nested-ternary
  color: isActive
    ? theme.palette.common.white
    : disabled ? theme.palette.text.secondary : theme.palette.text.primary,
  cursor: 'pointer',
}));

export const CategoryWrapper = styled(
  Box,
  { shouldForwardProp: (props) => props !== 'isActive' && props !== 'disabled' },
)<{ isActive?: boolean; disabled?: boolean }>(({ theme, isActive, disabled }) => ({
  borderRadius: '6px',
  border: isActive || !disabled ? `3px solid ${theme.palette.common.white}` : '3px solid transparent',
}));
