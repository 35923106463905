import { Grid, SelectChangeEvent, SxProps } from '@mui/material';
import { get } from 'lodash';
import { ChangeEvent, FC } from 'react';

import CustomDatePicker from 'components/CustomDatePicker';
import CustomInput from 'components/CustomInput';

import {
  MEDICAL_ID,
  MEDICAL_ID_EXP,
} from 'constants/fields';
import { MedicalInfo } from 'types/checkout.interface';

interface MedicalInfoFormProps {
  onChange: (e: ChangeEvent<HTMLInputElement> | SelectChangeEvent) => void;
  onValueChange: (value: any, name: string) => void;
  medical: MedicalInfo;
  fieldErrors: Record<string, string>;
  kioskMode?: boolean;
  sx?: SxProps;
  spacing?: number;
}

const MedicalInfoForm :FC<MedicalInfoFormProps> = ({
  onChange,
  onValueChange,
  fieldErrors,
  medical,
  kioskMode = false,
  sx = {},
  spacing = 4,
}) => (
  <Grid container spacing={spacing} sx={sx}>
    <Grid size={{ xs: 12, sm: 6 }}>
      <CustomInput
        {...MEDICAL_ID}
        size="small"
        onChange={(e) => onChange(e as ChangeEvent<HTMLInputElement>)}
        value={medical?.medicalId || ''}
        error={!!get(fieldErrors, MEDICAL_ID.name)}
        helperText={get(fieldErrors, MEDICAL_ID.name)}
      />
    </Grid>

    <Grid size={{ xs: 12, sm: 6 }}>
      <CustomDatePicker
        {...MEDICAL_ID_EXP}
        onChange={onValueChange}
        size="small"
        disableOpenPicker={kioskMode}
        value={medical?.medicalIdExp || null}
        error={!!get(fieldErrors, MEDICAL_ID_EXP.name)}
        helperText={get(fieldErrors, MEDICAL_ID_EXP.name)}
      />
    </Grid>
  </Grid>
);

export default MedicalInfoForm;
