import { Box, Checkbox, FormGroup } from '@mui/material';
import { isNil } from 'lodash';
import {
  ChangeEvent, FC, SyntheticEvent, useEffect, useMemo, useState,
} from 'react';

import CustomAccordionSection from 'components/CustomAccordionSection';
import {
  AccordionDetails,
  FormControlLabel,
  SearchIcon,
  SearchInput,
} from 'components/Filters/FilterGroup/index.styled';
import FilterSlider from 'components/Filters/FilterSlider';
import ShowMore from 'components/Filters/ShowMore';

import { useDebounce } from 'hooks/useDebounce';
import { useKiosk } from 'hooks/useKiosk';

import { FilterType } from 'constants/enums';
import { Filter, FilterOption } from 'types/filter.interface';
import { isPartialMatch } from 'utils/search';

interface FilterGroupProps {
  activeFilters: { [key: string]: string[] };
  filter: Filter;
  onSelectFilter?: (filterId: string, filterType: FilterType) => (e: SyntheticEvent, checked: boolean) => void;
  onRangeFilter?: (filterId: string, filterCode: string, filterType: FilterType) => void;
  isLoading: boolean;
}

export const MAX_SHOWN_FILTER_OPTIONS = 5;

const FilterGroup:FC<FilterGroupProps> = ({
  filter,
  onSelectFilter,
  onRangeFilter,
  activeFilters = {},
  isLoading,
}) => {
  const {
    id, name, options = [], type,
  } = filter;
  const { kioskMode } = useKiosk();
  const [search, setSearch] = useState<string>('');
  const [showMore, toggleShow] = useState(true);
  const [filteredOptions, setFilteredOptions] = useState<FilterOption[]>([]);
  const [optionsToShow, setOptionsToShow] = useState<FilterOption[]>([]);
  const hasMoreItems = useMemo(() => filteredOptions?.length > 5, [filteredOptions?.length]);
  const debounceSearch = useDebounce([search], 1000);
  const activeFiltersValue = activeFilters[filter.id] || [];

  useEffect(() => {
    const newFilteredOptions = search
      ? options?.filter(({ name, code }) => (
        isPartialMatch(name, search) || activeFiltersValue?.includes(code)
      )) : options;
    setFilteredOptions(newFilteredOptions);
  }, [options]);

  useEffect(() => {
    toggleShow(true);
  }, [hasMoreItems]);

  useEffect(() => {
    setOptionsToShow(options?.length > MAX_SHOWN_FILTER_OPTIONS && showMore
      ? filteredOptions.slice(0, 4)
      : filteredOptions);
  }, [filteredOptions]);

  useEffect(() => {
    const newFilteredOptions = search
      ? options?.filter(({ name, code }) => (
        isPartialMatch(name, search) || activeFiltersValue?.includes(code)
      )) : options;

    setFilteredOptions(newFilteredOptions);
  }, [debounceSearch]);

  useEffect(() => {
    if (activeFiltersValue.length > 0) {
      let lastActiveOptionIndex = -1;

      filteredOptions?.forEach((option, index) => {
        if (activeFiltersValue?.includes(option.code)) {
          lastActiveOptionIndex = index;
        }
      });

      if (hasMoreItems && lastActiveOptionIndex + 1 > MAX_SHOWN_FILTER_OPTIONS) {
        setOptionsToShow(filteredOptions);
        toggleShow(false);
      }
    }
  }, [filteredOptions, activeFilters]);

  const toggleShowMore = () => {
    if (showMore) {
      setOptionsToShow(filteredOptions);
    } else {
      setOptionsToShow(filteredOptions.slice(0, 4));
    }

    toggleShow((prevState) => !prevState);
  };

  const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setSearch(value);
  };

  return (
    <CustomAccordionSection
      defaultExpanded
      name={name}
    >
      <AccordionDetails>
        {[FilterType.SELECT, FilterType.MULTI_SELECT].includes(filter?.type) && (
          <>
            <SearchInput
              name="search-filter"
              size="small"
              fullWidth
              placeholder={`Search in ${name}`}
              onChange={handleChange}
              value={search}
              className="searchInput"
              slotProps={{
                input: {
                  startAdornment: <SearchIcon />,
                },
              }}
            />
            <FormGroup>
              {optionsToShow?.map((filter) => (
                <FormControlLabel
                  kioskMode={kioskMode}
                  key={filter.code}
                  label={`${filter.name} ${!isNil(filter.itemsCount) && `(${filter.itemsCount})`}`}
                  value={filter.code}
                  disabled={!onSelectFilter || isLoading}
                  onChange={onSelectFilter && onSelectFilter(id, type)}
                  checked={!!activeFiltersValue?.includes(filter.code)}
                  control={<Checkbox />}
                />
              ))}
            </FormGroup>
          </>
        )}
        {filter?.type === FilterType.RANGE && (
          <Box mt={1.5}>
            <FilterSlider
              activeFilters={activeFilters}
              filter={filter}
              onRangeFilter={onRangeFilter}
            />
          </Box>
        )}
      </AccordionDetails>
      {hasMoreItems && (
        <ShowMore
          showMore={showMore}
          toggleShowMore={toggleShowMore}
        />
      )}
    </CustomAccordionSection>
  );
};

export default FilterGroup;
