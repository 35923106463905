import * as yup from 'yup';

import { checkValidPhone } from 'utils/phoneUtils';
import { driverLicenseNumberRule } from 'validation/genericRules';
import { zipcodeRegex } from 'validation/regex';

export const newCustomerSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  phone: yup.string()
    .test('checkValidPhone', () => 'Invalid phone number', checkValidPhone)
    .required('Number is required'),
  email: yup.string()
    .email('Invalid email format')
    .required('Email is required'),
  dateOfBirth: yup.string()
    .required('Date of birth is required')
    .test('isValidDate', 'Date cannot be in the future', (value) => {
      if (!value) return false;
      const date = new Date(value);
      return date <= new Date();
    }),
  address1: yup.string().required('Address is required'),
  address2: yup.string(),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  zip: yup.string()
    .matches(zipcodeRegex, 'ZIP code must be 5 digits or ZIP+4 format')
    .required('ZIP code is required'),
  driverLicense: driverLicenseNumberRule,
  driversLicenseSt: yup.string().required('Driver\'s license state is required'),
  driverLicenseExpires: yup.string()
    .required('Driver\'s license expiration date is required')
    .test('isValidDate', 'Driver\'s license must not be expired', (value) => {
      if (!value) return false;
      const date = new Date(value);
      return date >= new Date();
    }),
  expirationDate: yup.string()
    .required('Expiration date is required')
    .test('isValidDate', 'Expiration date must be in the future', (value) => {
      if (!value) return false;
      const date = new Date(value);
      return date >= new Date();
    }),
  patientNumber: yup.string().required('Patient number is required'),
});

export type NewCustomerFormData = yup.InferType<typeof newCustomerSchema>;
