import { ReactElement, useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { SystemContext } from 'context/SystemContext';
import { UserContext } from 'context/UserContext';
import { UserLocationAccessProvider } from 'context/UserLocationAccessContext';

import { auth } from 'config/firebase';
import { ADMIN_LOGIN_ROUTE } from 'constants/clientRoutes';
import { UserType } from 'constants/enums';
import { adminDetailsIncomplete } from 'utils/clientUtils';

const ProtectedAdminRoute = (): ReactElement => {
  const { user, userInfo } = useContext(UserContext);
  const { shopSettings } = useContext(SystemContext);

  if (adminDetailsIncomplete(user, userInfo, shopSettings)) {
    if (!user?.isAnonymous && userInfo.userType === UserType.Individual) {
      auth.signOut();
    }

    return <Navigate to={ADMIN_LOGIN_ROUTE} replace />;
  }

  return (
    <UserLocationAccessProvider>
      <Outlet />
    </UserLocationAccessProvider>
  );
};

export default ProtectedAdminRoute;
