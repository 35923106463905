import { AxiosResponse } from 'axios';

import { runGetApiRequest, runPostApiRequest } from 'services/Api';

import {
  INVENTORY_AREA_ROUTE,
  INVENTORY_BRAND_ROUTE,
  INVENTORY_CATEGORY_ROUTE,
  INVENTORY_UOM_ROUTE,
  INVENTORY_SEARCH_ROUTE,
  INVENTORY_STRAIN_ROUTE,
  INVENTORY_VENDOR_ROUTE,
} from 'constants/apiRoutes';
import {
  GenericPaginatedGETApiResponse,
  InventoryArea,
  InventoryBrand,
  InventoryCategory,
  InventoryStrain,
  InventoryUOM,
  InventoryVendor,
} from 'types/customInventoryFilters.interface';
import { formatRoute } from 'utils/formatters';

// TODO::WIP
export const postInventorySearch = async (clientId: string, payload: any) => (
  runPostApiRequest(formatRoute(INVENTORY_SEARCH_ROUTE, { clientId }), payload)
);

export const getInventoryBrands = (clientId: string)
: Promise<AxiosResponse<GenericPaginatedGETApiResponse<InventoryBrand>>> => runGetApiRequest(
  formatRoute(INVENTORY_BRAND_ROUTE, { clientId }),
);

export const getInventoryStrains = (clientId: string)
: Promise<AxiosResponse<GenericPaginatedGETApiResponse<InventoryStrain>>> => runGetApiRequest(
  formatRoute(INVENTORY_STRAIN_ROUTE, { clientId }),
);

export const getInventoryAreas = (clientId: string)
: Promise<AxiosResponse<GenericPaginatedGETApiResponse<InventoryArea>>> => runGetApiRequest(
  formatRoute(INVENTORY_AREA_ROUTE, { clientId }),
);

export const getInventoryVendors = (clientId: string)
: Promise<AxiosResponse<GenericPaginatedGETApiResponse<InventoryVendor>>> => runGetApiRequest(
  formatRoute(INVENTORY_VENDOR_ROUTE, { clientId }),
);

export const getInventoryUOMs = (clientId: string)
: Promise<AxiosResponse<GenericPaginatedGETApiResponse<InventoryUOM>>> => runGetApiRequest(
  formatRoute(INVENTORY_UOM_ROUTE, { clientId }),
);

export const getInventoryCategories = (clientId: string)
: Promise<AxiosResponse<GenericPaginatedGETApiResponse<InventoryCategory>>> => runGetApiRequest(
  formatRoute(INVENTORY_CATEGORY_ROUTE, { clientId }),
);
