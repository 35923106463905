import { alpha, styled } from '@mui/material/styles';

export const StyledImage = styled('img')<{ isSmall: boolean }>(({ isSmall, theme }) => ({
  height: isSmall ? '50px' : '130px',
  width: isSmall ? '50px' : '200px',
  marginRight: theme.spacing(1),
  objectFit: 'contain',
  borderRadius: '8px',

  [theme.breakpoints.down('sm')]: {
    height: isSmall ? '50px' : '75px',
    width: isSmall ? '50px' : '75px',
  },
}));

export const FreeText = styled('span')(({ theme }) => ({
  color: theme.palette.success.main,
  paddingRight: theme.spacing(0.5),
}));

export const Root = styled('div')<{ isSmall: boolean }>(({ isSmall, theme }) => ({
  padding: theme.spacing(isSmall ? 2 : 3.5, isSmall ? 1 : 2),
  display: 'flex',
  borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.2)}`,
  gap: theme.spacing(isSmall ? 1.5 : 3),

  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(1.5),
  },
}));
