import MuiFormControlLabel from '@mui/material/FormControlLabel';
import { alpha, styled } from '@mui/material/styles';

export const StyledWrapper = styled('div')<{ hasError?: boolean }>(({ theme, hasError }) => (hasError ? {
  '& .MuiFormHelperText-root': {
    color: theme.palette.error.main,
  },
} : {}));

export const FormControlLabel = styled(
  MuiFormControlLabel,
  { shouldForwardProp: (prop) => prop !== 'isActive' },
)<{ isActive: boolean }>(({ theme, isActive }) => ({
  backgroundColor: alpha(theme.palette.common.black, 0.05),
  border: `2px solid ${isActive ? theme.palette.primary.main : 'transparent'}`,
  borderRadius: '6px',
  padding: theme.spacing(0.25),
  width: '100%',
  margin: 0,
}));
