import { Box } from '@mui/material';
import { FC, ReactNode } from 'react';

import { Root } from './index.styled';

interface IncrementWrapperProps {
  onDecrease: () => void;
  onIncrease: () => void;
  children: ReactNode;
  isSmall?: boolean;
  kioskMode?: boolean;
}

const IncrementWrapper: FC<IncrementWrapperProps> = ({
  onDecrease,
  onIncrease,
  children,
  isSmall = false,
  kioskMode = false,
}) => (
  <Root isSmall={isSmall} kioskMode={kioskMode}>
    <Box height="100%">
      <button
        type="button"
        className="decrease"
        onClick={onDecrease}
      >
        -
      </button>
    </Box>
    {children}
    <Box height="100%">
      <button
        type="button"
        className="increase"
        onClick={onIncrease}
      >
        +
      </button>
    </Box>
  </Root>
);

export default IncrementWrapper;
