import { Box, SxProps, Typography } from '@mui/material';
import { TypographyVariant } from '@mui/material/styles';
import { FC } from 'react';

import CartPrice from 'components/CartTotal/CartPrice';

import { CartPriceDetails } from 'types/cart.interface';

import { CustomTypographyVariant } from 'assets/themes/defaultTheme';

interface CartTotalProps {
  cartPriceDetails: CartPriceDetails;
  isLoading?: boolean;
  hasFailed?: boolean;
  kioskMode?: boolean;
  sx?: SxProps;
  fontWeight?: number;
  productsCount: number;
  variant?: TypographyVariant | CustomTypographyVariant;
  totalVariant?: TypographyVariant | CustomTypographyVariant;
  totalLabel?: string;
}

const CartTotal: FC<CartTotalProps> = ({
  cartPriceDetails,
  isLoading = false,
  hasFailed = false,
  kioskMode = false,
  sx = {},
  fontWeight = 700,
  productsCount,
  variant = 'body1',
  totalVariant = 'body1',
  totalLabel = 'Total:',
}) => (
  <Box sx={sx}>
    <Box display="flex" justifyContent="space-between">
      <Typography variant={variant} fontWeight={fontWeight}>{`Items Total (${productsCount})`}</Typography>
      <CartPrice variant={variant} price={cartPriceDetails?.itemsTotal} isLoading={isLoading} hasFailed={hasFailed} />
    </Box>
    {cartPriceDetails?.discounts && (
      <Box display="flex" justifyContent="space-between">
        <Typography variant={variant} fontWeight={fontWeight}>Discount:</Typography>
        <CartPrice variant={variant} price={cartPriceDetails?.discounts} isLoading={isLoading} hasFailed={hasFailed} />
      </Box>
    )}
    <Box display="flex" justifyContent="space-between">
      <Typography variant={variant} fontWeight={fontWeight}>Subtotal:</Typography>
      <CartPrice variant={variant} price={cartPriceDetails?.subTotal} isLoading={isLoading} hasFailed={hasFailed} />
    </Box>
    {cartPriceDetails?.taxes && (
      <Box display="flex" justifyContent="space-between">
        <Typography variant={variant} fontWeight={fontWeight}>Taxes:</Typography>
        <CartPrice variant={variant} price={cartPriceDetails?.taxes} isLoading={isLoading} hasFailed={hasFailed} />
      </Box>
    )}
    {cartPriceDetails.roundingAmount && (
      <Box display="flex" justifyContent="space-between">
        <Typography variant={variant} fontWeight={fontWeight}>
          {+cartPriceDetails.roundingAmount > 0 ? 'Service Fee:' : 'Service Credit:'}
        </Typography>
        <CartPrice
          variant={variant}
          price={cartPriceDetails?.roundingAmount}
          isLoading={isLoading}
          hasFailed={hasFailed}
        />
      </Box>
    )}
    <Box display="flex" justifyContent="space-between" mt={2} className="totalSection">
      <Typography variant={kioskMode ? 'h5' : totalVariant} fontWeight={fontWeight}>
        {totalLabel}
      </Typography>
      <CartPrice
        variant={kioskMode ? 'h5' : totalVariant}
        price={cartPriceDetails?.formattedTotal}
        isLoading={isLoading}
        hasFailed={hasFailed}
        fontWeight={700}
      />
    </Box>
  </Box>
);

export default CartTotal;
