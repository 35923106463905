import { Grid, SelectChangeEvent } from '@mui/material';
import { get } from 'lodash';
import { ChangeEvent, FC } from 'react';

import CustomDatePicker from 'components/CustomDatePicker';
import CustomDropdown from 'components/CustomDropdown';
import CustomerAddressForm from 'components/CustomerAddressForm';
import CustomInput from 'components/CustomInput';
import CustomPhoneInput from 'components/CustomPhoneInput';

import { FieldRuleType } from 'constants/enums';
import {
  BIRTHDAY,
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  PHONE_NUMBER,
  STATE,
} from 'constants/fields';
import { CustomerForm } from 'types/checkout.interface';
import { getPhoneFromAddress } from 'utils/formatters';
import { getLocalUsStates } from 'utils/storageUtils';

interface UserInfoFormProps {
  onValueChange: (value: any, name: string) => void;
  customer: CustomerForm;
  fieldErrors: Record<string, string>;
  sx?: object;
  fieldsRules?: Record<string, FieldRuleType>;
  kioskMode?: boolean;
  spacing?: number;
}

const UserInfoForm :FC<UserInfoFormProps> = ({
  onValueChange,
  customer,
  fieldErrors,
  sx,
  fieldsRules,
  kioskMode,
  spacing = 4,
}) => {
  const usStates = getLocalUsStates() || [];

  const handleChange = ({
    target: { value, name },
  }: ChangeEvent<HTMLInputElement> | SelectChangeEvent) => {
    onValueChange(value, name);
  };

  return (
    <Grid container spacing={spacing} sx={sx}>
      {fieldsRules?.firstName !== FieldRuleType.Hidden && (
        <Grid size={{ xs: 12, sm: 6 }}>
          <CustomInput
            {...FIRST_NAME}
            required={fieldsRules?.firstName === FieldRuleType.Required}
            size="small"
            onChange={(e) => handleChange(e as ChangeEvent<HTMLInputElement>)}
            value={customer?.firstName}
            error={!!get(fieldErrors, FIRST_NAME.name)}
            helperText={get(fieldErrors, FIRST_NAME.name)}
          />
        </Grid>
      )}
      {fieldsRules?.lastName !== FieldRuleType.Hidden && (
        <Grid size={{ xs: 12, sm: 6 }}>
          <CustomInput
            {...LAST_NAME}
            required={fieldsRules?.lastName === FieldRuleType.Required}
            size="small"
            onChange={(e) => handleChange(e as ChangeEvent<HTMLInputElement>)}
            value={customer?.lastName}
            error={!!get(fieldErrors, LAST_NAME.name)}
            helperText={get(fieldErrors, LAST_NAME.name)}
          />
        </Grid>
      )}

      {(fieldsRules?.dob !== FieldRuleType.Hidden) && (
        <Grid size={{ xs: 12, sm: 6 }}>
          <CustomDatePicker
            {...BIRTHDAY}
            required={fieldsRules?.dob === FieldRuleType.Required}
            onChange={onValueChange}
            disableOpenPicker={kioskMode}
            size="small"
            value={customer?.dob}
            error={!!get(fieldErrors, BIRTHDAY.name)}
            helperText={get(fieldErrors, BIRTHDAY.name)}
          />
        </Grid>
      )}
      {(fieldsRules?.state !== FieldRuleType.Hidden) && (
        <Grid size={{ xs: 12, sm: 6 }}>
          <CustomDropdown
            {...STATE}
            variant="outlined"
            required={fieldsRules?.state === FieldRuleType.Required}
            options={usStates}
            size="small"
            displayEmpty
            onChange={(e) => handleChange(e as SelectChangeEvent)}
            value={customer?.address?.state}
            error={!!get(fieldErrors, STATE.name)}
            helperText={get(fieldErrors, STATE.name)}
          />
        </Grid>
      )}

      {fieldsRules?.email !== FieldRuleType.Hidden && (
        <Grid size={{ xs: 12, sm: 6 }}>
          <CustomInput
            {...EMAIL}
            required={fieldsRules?.email === FieldRuleType.Required}
            size="small"
            onChange={(e) => handleChange(e as ChangeEvent<HTMLInputElement>)}
            value={customer?.email}
            error={!!get(fieldErrors, EMAIL.name)}
            helperText={get(fieldErrors, EMAIL.name)}
          />
        </Grid>
      )}
      {fieldsRules?.phoneNumber !== FieldRuleType.Hidden && (
        <Grid size={{ xs: 12, sm: 6 }}>
          <CustomPhoneInput
            {...PHONE_NUMBER}
            required={fieldsRules?.phoneNumber === FieldRuleType.Required}
            onChange={onValueChange}
            phoneNumber={getPhoneFromAddress(customer?.address)}
            error={!!get(fieldErrors, PHONE_NUMBER.name)}
            helperText={get(fieldErrors, PHONE_NUMBER.name)}
          />
        </Grid>
      )}

      {fieldsRules?.address !== FieldRuleType.Hidden && (
        <Grid size={{ xs: 12 }}>
          <CustomerAddressForm
            onChange={handleChange}
            onValueChange={onValueChange}
            address={customer?.address}
            fieldErrors={fieldErrors}
            required={fieldsRules?.address === FieldRuleType.Required}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default UserInfoForm;
