import {
  createContext, useEffect, useState,
} from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';

import { getGistDetails } from 'services/Gist';

import { handleApiErrors } from 'utils/errorUtils';

const PreviewContext = createContext({
  gistContent: {} as Record<string, string>,
});

const PreviewProvider = () => {
  const [searchParams] = useSearchParams();
  const gistId = searchParams.get('gistId') || '';
  const [gistContent, setGistContent] = useState<Record<string, string>>({});

  useEffect(() => {
    if (gistId) {
      fetchGist();
    }
  }, [gistId]);

  const fetchGist = async () => {
    try {
      const response = await getGistDetails(gistId);
      setGistContent(response?.data);
    } catch (e) {
      handleApiErrors(e);
    }
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <PreviewContext.Provider value={{ gistContent }}>
      <Outlet />
    </PreviewContext.Provider>
  );
};

export {
  PreviewProvider,
  PreviewContext,
};
