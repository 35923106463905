import MuiIconButton from '@mui/material/IconButton';
import { alpha, styled } from '@mui/material/styles';

export const IconButton = styled(MuiIconButton)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.common.black, 0.05),
  borderRadius: '6px',
  padding: theme.spacing(0.5, 1),

  '& .MuiSvgIcon-root': {
    fill: alpha(theme.palette.common.black, 0.5),
  },
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
}));
