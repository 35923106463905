import MuiChip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

export const SummaryContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(2, 0, 1, 0),
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const Chip = styled(MuiChip)(({ theme }) => ({
  borderRadius: '6px',
  border: 'none',
  backgroundColor: theme.palette.highlightColor.main,
  color: theme.palette.text.primary,
  height: '33px',
  fontSize: '15px',
})) as typeof MuiChip;
