export const NEW_CUSTOMER_DEFAULT_VALUES = {
  firstName: '',
  lastName: '',
  customerName: '',
  phone: '',
  email: '',
  dateOfBirth: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  driversLicense: '',
  driversLicenseSt: '',
  driverLicenseExpires: '',
  expirationDate: '',
  patientNumber: '',
};
