import { Dialog } from '@mui/material';
import { isEmpty } from 'lodash';
import { FC, useEffect, useState } from 'react';

import CartConfirmation from 'containers/ProductDetailsPage/CartConfirmation';
import ProductDetails from 'containers/ProductDetailsPage/ProductDetails';

import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import useSummary from 'hooks/useSummary';
import { getProductDetails } from 'services/Product';

import { Bounty } from 'types/bounty.interface';
import { Cart, CartPriceDetails } from 'types/cart.interface';
import { formatCartDetails } from 'utils/checkoutUtils';
import { handleApiErrors } from 'utils/errorUtils';

interface ProductDetailsPageProps {
  bountyDetails: Bounty;
  onCancel: () => void;
  isOpen: boolean;
}

const ProductDetailsPage:FC<ProductDetailsPageProps> = ({ bountyDetails, onCancel, isOpen }) => {
  const [bounty, setBounty] = useState<Bounty>(bountyDetails);
  const { checkSummary, isLoading: isCheckingSummary } = useSummary();
  const [summary, setSummary] = useState({
    total: '',
    showSummary: false,
  });

  const { handleTrackViewItem } = useGoogleAnalytics();
  const { product } = bounty;

  useEffect(() => {
    if (bountyDetails?.id) {
      fetchProductDetails(bountyDetails.id);
    }
  }, [bountyDetails.id]);

  useEffect(() => {
    if (!isEmpty(product)) {
      handleTrackViewItem(bounty);
    }
  }, [product]);

  const fetchProductDetails = async (id: string) => {
    try {
      const { data } = await getProductDetails(id);
      setBounty(data);
    } catch (e) {
      handleApiErrors(e);
    }
  };

  const handleCartConfirmation = async (updatedCart: Cart) => {
    try {
      const data = await checkSummary('', updatedCart);
      const formattedCardDetails = !isEmpty(data) ? formatCartDetails(data) : {} as CartPriceDetails;

      setSummary({
        total: formattedCardDetails.total ? formattedCardDetails.formattedTotal : '',
        showSummary: true,
      });
    } catch (e) {
      setSummary({
        total: '',
        showSummary: true,
      });
    }
  };

  return (
    <Dialog
      open={isOpen}
      fullWidth
      onClose={onCancel}
      aria-describedby="select-tier-price"
      slotProps={{
        paper: {
          sx: { maxWidth: 800, borderRadius: '16px', p: '44px' },
        },
      }}
    >
      {summary.showSummary ? (
        <CartConfirmation
          cartTotal={summary.total}
          onClose={onCancel}
        />
      ) : (
        <ProductDetails
          className="hidden-scroll"
          onSubmit={handleCartConfirmation}
          onClose={onCancel}
          bounty={bounty}
          isLoading={false}
          isCheckingSummary={isCheckingSummary}
        />
      )}
    </Dialog>
  );
};

export default ProductDetailsPage;
