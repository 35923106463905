import { FC, useContext } from 'react';

import KioskCategories from 'containers/Categories/KioskCategories';
import { CategoryContext, CategoryDispatchContext } from 'context/CategoryContext';

const Categories:FC = () => {
  const { categories, selectedCategories } = useContext(CategoryContext);
  const { onCategoryChanges } = useContext(CategoryDispatchContext);

  return (
    <KioskCategories
      level={0}
      categories={categories}
      selectedCategories={selectedCategories}
      onCategoryChanges={onCategoryChanges}
    />
  );
};

export default Categories;
