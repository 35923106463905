import Delete from '@mui/icons-material/Delete';
import {
  Box,
} from '@mui/material';
import { FC, memo } from 'react';

import CustomButton from 'components/CustomButton';
import CustomDropdown from 'components/CustomDropdown';

import { InventoryGroupTypeOperator } from 'constants/enums';
import { InventoryTreeNode } from 'types/customInventoryFilters.interface';

import { GROUP_CONDITION_OPTIONS } from '../constants';

interface GroupNodeProps {
  node: InventoryTreeNode;
  onAddCondition: () => void;
  onAddGroup: () => void;
  onDelete: () => void;
  onUpdateNode: (nodeId: string, updates: Partial<InventoryTreeNode>) => void;
  isRoot?: boolean;
}

const GroupNode: FC<GroupNodeProps> = (
  {
    node, onAddCondition, onAddGroup, onDelete, onUpdateNode, isRoot,
  },
) => (
  <Box display="flex" alignItems="center" gap={1}>
    <CustomDropdown
      size="small"
      value={node.groupOperator ?? ''}
      options={GROUP_CONDITION_OPTIONS}
      onChange={(e) => {
        onUpdateNode(node.id, { groupOperator: e.target.value as InventoryGroupTypeOperator });
      }}
    />

    <CustomButton id="button-addCondition" size="small" onClick={onAddCondition}>+ Condition</CustomButton>
    <CustomButton id="button-addGroup" size="small" onClick={onAddGroup}>+ Group</CustomButton>
    {!isRoot && (
      <CustomButton id="button-deleteGroup" size="small" variant="outlined" onClick={onDelete}><Delete /></CustomButton>
    )}
  </Box>
);

export default memo(GroupNode);
