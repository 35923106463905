import {
  FormControl,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  Grid,
  Breakpoint,
  GridSize,
} from '@mui/material';
import { TypographyVariant } from '@mui/material/styles';
import { ChangeEvent, FC } from 'react';

import { StyledWrapper, FormControlLabel } from 'components/CustomRadioButton/index.styled';

import { Option } from 'types/option.interface';

interface CustomRadioButtonProps {
  fullWidth?: boolean;
  required?: boolean;
  label?: string;
  onChange: (value: any, name: string) => unknown;
  value?: string;
  name: string;
  isRow?: boolean;
  options: Array<Option>;
  disabled?: boolean;
  sx?: object;
  size?: 'small' | 'medium';
  fontWeight?: number;
  error?: boolean;
  helperText?: string;
  variant?: TypographyVariant;
  grid?: {
    [key in Breakpoint]?: GridSize | null;
  };
  spacing?: number;
}

const CustomRadioButton :FC<CustomRadioButtonProps> = (props) => {
  const {
    label, fullWidth = false, required, options, disabled, size, grid, spacing = 2,
    sx, name, variant = 'body1', fontWeight, error, helperText, onChange, value,
    ...remainingProps
  } = props;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e, name);
  };

  const renderGridOptions = () => (
    <Grid container spacing={spacing}>
      {options.map(({ key, value: optionLabel, disabled: isOptionDisabled }, index: number) => (
        <Grid
          size={grid}
          key={`${index}-${key}`}
        >
          <FormControlLabel
            name={name}
            value={key}
            label={optionLabel}
            sx={sx}
            isActive={value === key}
            control={<Radio id={`radio-${key}`} disabled={disabled || isOptionDisabled} size={size} />}
          />
        </Grid>
      ))}
    </Grid>
  );

  const renderOptions = () => (
    options.map(({ key, value: optionLabel, disabled: isOptionDisabled }, index: number) => (
      <FormControlLabel
        key={`${index}-${key}`}
        name={name}
        value={key}
        label={optionLabel}
        sx={sx}
        isActive={value === key}
        control={<Radio id={`radio-${key}`} disabled={disabled || isOptionDisabled} size={size} />}
      />
    ))
  );

  return (
    <StyledWrapper hasError={error}>
      <FormControl component="div" fullWidth={fullWidth}>
        {label && (
          <FormLabel required={required} sx={{ mb: 1.5 }}>
            <Typography component="span" color="textPrimary" variant={variant} fontWeight={fontWeight}>
              {label}
            </Typography>
          </FormLabel>
        )}
        <RadioGroup {...remainingProps} value={value} onChange={handleChange}>
          {grid ? renderGridOptions() : renderOptions()}
        </RadioGroup>
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </StyledWrapper>
  );
};

export default CustomRadioButton;
