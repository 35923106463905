import CloseIcon from '@mui/icons-material/Close';
import { SxProps } from '@mui/material';
import { FC } from 'react';

import { IconButton } from 'components/CloseButton/index.styled';

interface CloseButtonProps {
  onClose: () => void;
  sx?: SxProps;
  id?: string;
  isDisabled?: boolean;
}

const CloseButton:FC<CloseButtonProps> = ({
  onClose,
  sx,
  isDisabled = false,
  id = 'iconButton-close',
}) => (
  <IconButton onClick={onClose} id={id} sx={sx} disabled={isDisabled}>
    <CloseIcon />
  </IconButton>
);

export default CloseButton;
