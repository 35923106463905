import { SelectChangeEvent } from '@mui/material';
import { ChangeEvent, FC } from 'react';

import CustomButton from 'components/CustomButton';
import DriverLicenseForm from 'components/DriverLicenseForm';
import MedicalInfoForm from 'components/MedicalInfoForm';
import UserInfoForm from 'components/UserInfoForm';

import { FieldRuleType, OnboardingUseType } from 'constants/enums';
import { USE_TYPE_OPTIONS } from 'constants/options';
import { PlaceOrderInfo } from 'types/checkout.interface';
import { showCheckoutForms } from 'utils/checkoutUtils';

interface ContactInfoFormProps {
  checkoutForm: PlaceOrderInfo;
  fieldErrors: Record<string, string>;
  onChange: (value: any, name: string) => void;
  onContinue: () => void;
  useType: OnboardingUseType;
  checkoutFields?: Record<string, FieldRuleType>;
}

const ContactInfoForm: FC<ContactInfoFormProps> = ({
  checkoutForm,
  onChange,
  fieldErrors,
  onContinue,
  useType,
  checkoutFields = {},
}) => {
  const { showUserInfoForm, showDriverLicenseForm } = showCheckoutForms(checkoutFields);
  const showMedicalForm = useType === USE_TYPE_OPTIONS[1].key;

  const handleChange = (
    { target: { value, name } }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent,
  ) => {
    onChange(value, name);
  };

  return (
    <>
      {showUserInfoForm && (
        <UserInfoForm
          customer={checkoutForm?.customer}
          onValueChange={onChange}
          fieldErrors={fieldErrors}
          fieldsRules={checkoutFields}
          spacing={5}
        />
      )}

      {showDriverLicenseForm && (
        <DriverLicenseForm
          onChange={handleChange}
          onValueChange={onChange}
          driverLicense={checkoutForm?.driverLicense}
          fieldErrors={fieldErrors}
          sx={{ mt: 5 }}
          spacing={5}
          fieldsRules={checkoutFields}
        />
      )}

      {showMedicalForm && (
        <MedicalInfoForm
          onChange={handleChange}
          onValueChange={onChange}
          medical={checkoutForm?.medical}
          fieldErrors={fieldErrors}
          sx={{ mt: 5 }}
          spacing={5}
        />
      )}

      <CustomButton
        id="button-continueContactInfoForm"
        onClick={onContinue}
        fullWidth
        sx={{ mt: 7.5 }}
      >
        Continue
      </CustomButton>
    </>
  );
};

export default ContactInfoForm;
