import { styled } from '@mui/material/styles';

export const CertificateLink = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
  width: '100%',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));
