import { Box, Typography } from '@mui/material';
import { FC } from 'react';

import AddProductReview from 'containers/ProductReviews/AddProductReview';

import { Bounty } from 'types/bounty.interface';

interface EmptyReviewsProps {
  bounty: Bounty;
  canAddReview?: boolean;
}

const EmptyReviews:FC<EmptyReviewsProps> = ({ bounty, canAddReview = false }) => (
  <Box my={3}>
    <Typography variant="h6" fontWeight="500" mb={2}>
      Customer Reviews
    </Typography>
    <Typography color="textSecondary">
      There are no reviews for this product yet.
      {canAddReview && ' Be the first to add one!'}
    </Typography>
    {canAddReview && <AddProductReview bounty={bounty} />}
  </Box>
);

export default EmptyReviews;
