import { useEffect, useState } from 'react';

const useContentDimensions = () => {
  const [dimensions, setDimensions] = useState({
    height: 0,
    width: 0,
    allowStickyFooter: true,
  });

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const bodyHeight = entries[0]?.contentRect?.height || 0;
      const bodyWidth = entries[0]?.contentRect?.width || 0;

      setDimensions({
        width: bodyWidth,
        height: bodyHeight,
        allowStickyFooter: bodyHeight > 1200,
      });
    });

    if (document.getElementsByTagName('body')) {
      resizeObserver.observe(document.body);
    }
  }, []);

  return dimensions;
};

export default useContentDimensions;
