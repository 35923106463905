import { Box, Grid, SxProps } from '@mui/material';
import Typography from '@mui/material/Typography';
import { FC, useContext } from 'react';

import { CategoryContext } from 'context/CategoryContext';
import { SystemContext } from 'context/SystemContext';

import { PRODUCTS_ROUTE } from 'constants/clientRoutes';
import { Promotion } from 'types/promotion.interface';
import { findNode } from 'utils/categoriesUtils';
import { getPublicUrlForImage } from 'utils/publicUrl';

import { CategoryCard, StyledImage } from './index.styled';

interface HighlightsBannerProps {
  highlights: Promotion[];
  sx?: SxProps;
}

const HighlightsBanner: FC<HighlightsBannerProps> = ({ highlights, sx }) => {
  const { categories } = useContext(CategoryContext);
  const { detailedClientData } = useContext(SystemContext);

  const handleClick = (categoryCodes?: string) => {
    const productDetailsRoute = `${PRODUCTS_ROUTE}?category=${categoryCodes}`;
    const baseUrl = `${detailedClientData?.baseUrl}${detailedClientData?.basePath}`;
    window.location.href = `${baseUrl || window.location.origin}${productDetailsRoute}`;
  };

  return (
    <Box sx={sx}>
      <Typography variant="h2" fontWeight={600} mb={4}>
        Category Highlights
      </Typography>

      <Grid container rowSpacing={2} columnSpacing={2}>
        {highlights?.map((highlight, index) => {
          const url = highlight?.cdnImageUrl || highlight?.imageUrl;
          const highlightCategories = highlight?.action?.categories?.split(',') || [];
          const lastCategoryIndex = highlightCategories?.length;
          const node = findNode(highlightCategories?.[lastCategoryIndex - 1], categories);

          return node ? (
            <CategoryCard
              size={[12, 6, 4]}
              key={`highlight-${index}`}
              onClick={() => handleClick(highlight?.action?.categories)}
            >
              <StyledImage
                component="img"
                src={url || getPublicUrlForImage('images/placeholder_image.jpg')}
                alt={highlight?.alt}
              />

              <Typography variant="h5" fontWeight={600} mt={3}>
                {node?.name}
              </Typography>
            </CategoryCard>
          ) : null;
        })}
      </Grid>
    </Box>
  );
};

export default HighlightsBanner;
