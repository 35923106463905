import { Box, Divider, Typography } from '@mui/material';
import { onValue, query } from 'firebase/database';
import { isEmpty } from 'lodash';
import { FC, useEffect, useState } from 'react';

import CloseButton from 'components/CloseButton';
import { Drawer } from 'containers/ProductDetailsPage/index.styled';
import ProductDetails from 'containers/ProductDetailsPage/ProductDetails';
import ProductReviews from 'containers/ProductReviews';

import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { useKiosk } from 'hooks/useKiosk';
import { getBountyCommentsRef } from 'services/Comment';
import { getProductDetails } from 'services/Product';

import { Bounty } from 'types/bounty.interface';
import { Comment } from 'types/comment.interface';
import { isTestChannel } from 'utils/utils';

interface ProductDetailsPageProps {
  bountyDetails: Bounty|null;
  onClose: () => void;
  isOpen: boolean;
  skipCall?: boolean;
}

const ProductDetailsPage:FC<ProductDetailsPageProps> = ({
  bountyDetails,
  onClose,
  isOpen,
  skipCall = false,
}) => {
  const [comments, setComments] = useState<Comment[]>([]);
  const [bounty, setBounty] = useState<Bounty|null>(bountyDetails);
  const [isLoading, setIsLoading] = useState(!skipCall);

  const { handleTrackViewItem } = useGoogleAnalytics();
  const { kioskMode } = useKiosk();

  useEffect(() => {
    setBounty(bountyDetails);
  }, [bountyDetails?.id]);

  useEffect(() => {
    if (bountyDetails?.id && !skipCall) {
      fetchProductDetails(bountyDetails.id);
    }
  }, [bountyDetails?.id]);

  useEffect(() => {
    if (bounty && !isEmpty(bounty?.product)) {
      handleTrackViewItem(bounty);
    }
  }, [bounty?.product]);

  useEffect(() => {
    if (bounty?.id && isTestChannel()) {
      getBountyComments(bounty?.id);
    }
  }, [bounty?.id]);

  const getBountyComments = (bountyId: string) => {
    const dbQuery = query(getBountyCommentsRef(bountyId));

    onValue(dbQuery, (snapshot) => {
      const data = snapshot.val() as Comment[];
      setComments(data || []);
    });
  };

  useDocumentTitle({ title: 'Product Details' });

  const fetchProductDetails = async (id: string) => {
    try {
      setIsLoading(true);
      const { data } = await getProductDetails(id);

      setBounty(data);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
    >
      <Box className="hidden-scroll">
        <Box position="relative">
          <CloseButton onClose={onClose} sx={{ right: 0, top: 0, p: 1.5 }} />
          <Typography variant="h4" fontWeight={700} textAlign="center">
            Item details
          </Typography>
        </Box>
        <Divider sx={{ my: 3 }} />
        <ProductDetails
          onSubmit={onClose}
          onClose={onClose}
          bounty={bounty}
          isLoading={isLoading}
        />

        {isTestChannel() && !!bounty && !kioskMode && (
          <ProductReviews reviews={comments} bounty={bounty} />
        )}
      </Box>
    </Drawer>
  );
};

export default ProductDetailsPage;
