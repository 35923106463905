import { Box, Typography } from '@mui/material';
import { FC } from 'react';

import { PlaceOrderInfo } from 'types/checkout.interface';
import { formatPhoneNumber, formatUserName } from 'utils/formatters';

interface ContactInfoProps {
  checkoutForm: PlaceOrderInfo;
}

const ContactInfo:FC<ContactInfoProps> = ({ checkoutForm }) => {
  const { customer } = checkoutForm;

  return (
    <Box display="flex" flexDirection="column">
      {(customer?.firstName || customer?.lastName) && (
        <Typography variant="subtitle1" fontWeight={600}>
          {formatUserName(customer?.firstName, customer?.lastName)}
        </Typography>
      )}
      {customer?.address?.phoneNumber && (
        <Typography variant="subtitle1" fontWeight={600}>
          {formatPhoneNumber(customer?.address?.phoneNumber)}
        </Typography>
      )}
      {customer?.email && (
        <Typography variant="subtitle1" fontWeight={600}>
          {customer?.email}
        </Typography>
      )}
    </Box>
  );
};

export default ContactInfo;
