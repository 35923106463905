const config = {
  id: 'alleaves',
  title: 'Alleaves Shop',
  name: 'Alleaves Shop',
  brandName: 'Alleaves',
  appLinkSchema: 'alleaves',
  appTld: 'alleaves.shop',
  TERMS_OF_SERVICE_URL: 'https://www.alleaves.com/terms',
  PRIVACY_STATEMENT_URL: 'https://www.alleaves.com/privacy',
  googleMapsKey: 'AIzaSyDklVnT0bZovfpKEKfX_rpg_jhsTm5O0XA',
  tags: ['all', 'active'],
};

export default config;
