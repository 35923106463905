import {
  Box, Divider, Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { useContext } from 'react';

import CloseButton from 'components/CloseButton';
import OrderWarningMessage from 'components/OrderWarningMessage';
import Summary from 'containers/CartListingPage/Summary';
import CartProduct from 'containers/CartProduct';
import { PreviewContext } from 'context/PreviewProvider';
import PreviewLayout from 'layouts/PreviewLayout';
import { Drawer } from 'previews/PreviewCartPage/index.styled';

import { MOCK_CART_DETAILS, MOCK_PRODUCTS } from 'constants/mockData';
import { ProductInfo } from 'types/cart.interface';
import { calculateTotalQuantity } from 'utils/cartUtils';

const PreviewCartPage = () => {
  const { gistContent } = useContext(PreviewContext);
  const updatedProducts:{ [key: string]: ProductInfo } = { 'id 3': MOCK_PRODUCTS[2] };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleEmptyFunction = () => {};

  return (
    <PreviewLayout hideFooter hideNavbarActions>
      <Drawer anchor="right" open>
        <Box className="hidden-scroll" position="relative" height="100%">
          <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
            <Box mx={3}>
              <CloseButton sx={{ right: 28, top: 0, p: 1.5 }} onClose={handleEmptyFunction} />
              <Typography variant="h4" fontWeight={700} textAlign="center">
                Your cart
              </Typography>
              <Divider sx={{ my: 3 }} />
              {MOCK_PRODUCTS?.map((product) => {
                const isProductUpdated = !isEmpty(updatedProducts?.[product.id] || {});
                const updatedQuantity = 0;

                return (
                  <CartProduct
                    key={product?.id}
                    product={product}
                    isProductUpdated={isProductUpdated}
                    updatedQuantity={updatedQuantity}
                    isDisabled
                  />
                );
              })}
              {gistContent?.orderWarningMessage && (
                <Box mt={2.5}>
                  <OrderWarningMessage message={gistContent?.orderWarningMessage} />
                </Box>
              )}
            </Box>

            <Summary
              onSubmit={handleEmptyFunction}
              cartPriceDetails={MOCK_CART_DETAILS}
              onChangeSummary={handleEmptyFunction}
              showMedicalBalance
              productsCount={calculateTotalQuantity(updatedProducts)}
              orderDisclaimerMessage={gistContent?.orderDisclaimerMessage}
            />
          </Box>
        </Box>
      </Drawer>
    </PreviewLayout>
  );
};

export default PreviewCartPage;
