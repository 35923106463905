import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledSummaryPrice = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 3),
  width: '100%',
  borderRadius: '4px',
}));

export const StyledSummary = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  position: 'sticky',
  bottom: 0,
  minHeight: '450px',
  width: '100%',
  backgroundColor: theme.palette.background.secondary,

  '.MuiTabs-list > .MuiButtonBase-root': {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    border: 'none',
    borderRadius: '6px',
    minHeight: 'auto',
  },

  '.MuiTabs-list > .Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },

  [theme.breakpoints.down('md')]: {
    position: 'relative',
  },
}));
