import { isNil } from 'lodash';
import { useEffect } from 'react';

import { getShopConfiguration } from 'utils/storeUtils';

export const setDocumentTitle = ({ title }: { title: string }) => {
  const { embed, setDocTitle } = getShopConfiguration();
  if (isNil(setDocTitle) ? !embed : setDocTitle) {
    document.title = title;
  }
};

export const useDocumentTitle = ({ title }: { title: string }) => {
  useEffect(() => {
    setDocumentTitle({ title });

    return () => {
      setDocumentTitle({ title: 'Loading...' });
    };
  }, []);
};
