import { styled } from '@mui/material/styles';

export const SortContainer = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: '12px',
  padding: theme.spacing(0, 2),
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1),

  [theme.breakpoints.down(370)]: {
    padding: theme.spacing(1.5, 0.75),
  },
}));
