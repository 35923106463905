import { Box } from '@mui/material';
import { isEmpty } from 'lodash';
import { useContext, useEffect, useMemo } from 'react';

import CheckoutFooter from 'components/_CheckoutFooter';
import CustomContent from 'components/CustomContent';
import EmptyPreviewCart from 'components/EmptyPreviewCart';
import OrderWarningMessage from 'components/OrderWarningMessage';
import { Root } from 'containers/CartListingPage/index.styled';
import Products from 'containers/CartListingPage/Products';
import useCartListing from 'containers/CartListingPage/useCartListing';
import { CartContext } from 'context/CartContext';
import { SystemContext } from 'context/SystemContext';

import useContentDimensions from 'hooks/useContentDimensions';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

const CartListing = () => {
  const { shopSettings } = useContext(SystemContext);
  const cart = useContext(CartContext);
  const products = useMemo(() => Object.values(cart?.products) || [], [cart?.products]);
  const {
    goToCheckout,
    verifySummary,
    summaryFailedDetails,
    showMedicalBalance,
    checkSummary,
    formattedCartDetails,
    isDisabled,
    updatedProducts,
    isSummaryLoading,
  } = useCartListing();
  const { allowStickyFooter } = useContentDimensions();

  useDocumentTitle({ title: 'Cart summary' });

  useEffect(() => {
    if (!isEmpty(cart?.products)) {
      verifySummary();
    }
  }, [cart?.products]);

  return (
    <Root>
      {products?.length > 0 ? (
        <>
          <CustomContent kioskMode sx={{ flexGrow: 1 }} className={allowStickyFooter ? 'hidden-scroll' : ''}>
            <Products
              outlined
              updatedProducts={updatedProducts}
              summaryFailedDetails={summaryFailedDetails}
            />
            {shopSettings?.orderWarningMessage && (
            <Box mt={2.5}>
              <OrderWarningMessage message={shopSettings?.orderWarningMessage} />
            </Box>
            )}
          </CustomContent>
          <CheckoutFooter
            hasBack
            onSubmit={goToCheckout}
            disabled={isDisabled || summaryFailedDetails?.hasFailed}
            isSummaryLoading={isSummaryLoading}
            hasSummaryFailed={summaryFailedDetails?.hasFailed}
            cartPriceDetails={formattedCartDetails}
            onChangeSummary={checkSummary}
            continueLabel="Go to Checkout"
            showMedicalBalance={showMedicalBalance}
          />
        </>
      ) : (
        <Box mt={12}>
          <EmptyPreviewCart hasButton fontSize="large" variant="h6" />
        </Box>
      )}
    </Root>
  );
};

export default CartListing;
