import { Box } from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import EmptyState from 'components/EmptyState';
import Loader from 'components/Loader';
import PageTitle from 'components/PageTitle';
import OrderInformation from 'containers/OrdersPage/OrderInformation';
import { UserContext } from 'context/UserContext';

import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useKiosk } from 'hooks/useKiosk';
import { getMyOrders } from 'services/Order';

import { DEFAULT_PAGINATION_LIMIT } from 'constants/general';
import { Bounty } from 'types/bounty.interface';
import { Order } from 'types/order.interface';

import { CenteredContent } from 'assets/themes/styled/CenteredContent';

const OrdersPage = () => {
  const [orders, setOrders] = useState<Record<'order', Bounty>|null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const { user } = useContext(UserContext);
  const { kioskMode } = useKiosk();
  const hasMore = useMemo(() => !isNil(orders) && totalCount > Object.values(orders)?.length, [totalCount, orders]);

  useEffect(() => {
    if (user?.uid) {
      fetchMyOrders(0);
    }
  }, [user?.uid]);

  useDocumentTitle({ title: 'Orders' });

  const fetchMyOrders = async (page: number) => {
    setIsLoading(true);
    setCurrentPage(page);

    try {
      const params = { start: page * DEFAULT_PAGINATION_LIMIT, length: DEFAULT_PAGINATION_LIMIT };
      const { data } = await getMyOrders(user.uid, params);
      const prevOrders = page === 0 ? [] : orders || [];

      setOrders({
        ...prevOrders,
        ...(data?.list?.filter(({ order }: { order: Order }) => order) || []),
      });
      setTotalCount(data.totalCount);
    } finally {
      setIsLoading(false);
    }
  };

  const loadOrders = async () => {
    const maxPage = totalCount ? Math.floor(totalCount / DEFAULT_PAGINATION_LIMIT) : 0;
    const newPage = currentPage + 1;

    if (newPage > maxPage) {
      setIsLoading(false);
    } else if (!isLoading) {
      fetchMyOrders(newPage);
    }
  };

  return (
    isLoading && isNil(orders) ? (
      <Loader />
    ) : (
      <CenteredContent>
        {!kioskMode && <PageTitle hasBackButton title="My Orders" />}
        {!isEmpty(orders) ? (
          <InfiniteScroll
            pageStart={0}
            loadMore={loadOrders}
            hasMore={hasMore}
          >
            {Object.values(orders)?.map((bounty: Bounty, index) => (
              <Box mb={5} key={bounty?.id || index}>
                <OrderInformation bounty={bounty} />
              </Box>
            ))}
          </InfiniteScroll>
        ) : (
          <EmptyState message="No orders yet" />
        )}
      </CenteredContent>
    )
  );
};

export default OrdersPage;
