import { FC } from 'react';
import { Link } from 'react-router-dom';

import {
  StyledHeader, CardMedia, ActionWrapper, Content, ActionContent,
} from 'components/_Navbar/index.styled';
import StoreLocation from 'components/_Navbar/StoreLocation';
import ShopContent from 'components/_ShopContent';
import ShoppingCart from 'components/ShoppingCart';
import SearchProducts from 'containers/SearchProducts';
import User from 'containers/User';

import { getHomeRoute } from 'hooks/useRouting';

import { ClientInfoInterface } from 'types/clientInfo.interface';
import { getPublicUrlForImage } from 'utils/publicUrl';

interface NavbarProps {
  hasShoppingCart?: boolean;
  hasUser?: boolean;
  clientInfo?: ClientInfoInterface | null;
  hasStores?: boolean;
  hideActions?: boolean;
  hideLogo?: boolean;
}

const Navbar:FC<NavbarProps> = ({
  hasShoppingCart = false,
  hasUser = false,
  clientInfo = null,
  hasStores = false,
  hideActions = false,
  hideLogo = false,
}) => {
  const { name, branding } = clientInfo || {};

  return (
    <StyledHeader>
      <ShopContent>
        <Content>
          {!hideLogo && (
            <Link to={getHomeRoute()}>
              <CardMedia
                component="img"
                src={branding?.logoUrl || getPublicUrlForImage('images/appLogoText.png')}
                alt={name}
              />
            </Link>
          )}
          {!hideActions && (
            <ActionWrapper>
              <SearchProducts />
              <ActionContent>
                {hasStores && <StoreLocation />}
                {hasShoppingCart && <ShoppingCart />}
                {hasUser && <User />}
              </ActionContent>
            </ActionWrapper>
          )}
        </Content>
      </ShopContent>
    </StyledHeader>
  );
};

export default Navbar;
