import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Divider, Typography } from '@mui/material';
import { FC } from 'react';

import { IconButton } from 'components/SectionHeader/index.styled';

interface SectionHeaderProps {
  title: string;
  onNext: () => void;
  onPrevious: () => void;
  canNavigate?: boolean;
}

const SectionHeader: FC<SectionHeaderProps> = ({
  title,
  onNext,
  onPrevious,
  canNavigate,
}) => (
  <Box mx={0.5}>
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Typography variant="h4" fontWeight="700">
        {title}
      </Typography>

      {canNavigate && (
        <Box display="flex" alignItems="center" gap={1}>
          <IconButton onClick={onPrevious}>
            <ArrowBackIosNewIcon />
          </IconButton>
          <IconButton onClick={onNext}>
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>
      )}
    </Box>

    <Divider sx={{ my: 2.5 }} />
  </Box>
);

export default SectionHeader;
