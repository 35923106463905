import { alpha, styled } from '@mui/material/styles';

export const Card = styled('div')(({ theme }) => ({
  boxShadow: `inset 0 0 10px ${alpha(theme.palette.common.black, 0.1)}`,
  borderRadius: '12px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  backgroundColor: theme.palette.general.lightGrey11,
  padding: theme.spacing(0, 3),
  height: '100px',
  width: '150px',
}));
