import CategoryDefaultIcon from '@mui/icons-material/Category';
import { FC } from 'react';

import { CardMedia } from 'containers/Categories/CategoryIcon/index.styled';

import { Category } from 'types/category.interface';

interface CategoryIconProps {
  category?: Category;
  active?: boolean;
  size?: number;
}

const CategoryIcon:FC<CategoryIconProps> = ({ category, active, size = 18 }) => (
  category?.iconUrl
    ? (
      <CardMedia
        isActive={active}
        component="img"
        src={category?.iconUrl}
        size={size}
      />
    )
    : <CategoryDefaultIcon height={size} width={size} />
);

export default CategoryIcon;
