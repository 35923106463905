import { Box, Typography } from '@mui/material';
import { FieldErrors, UseFormRegister } from 'react-hook-form';

import CustomDropdown from 'components/CustomDropdown';
import CustomInput from 'components/CustomInput';

import { Option } from 'types/option.interface';
import { NewCustomerFormData } from 'validation/newCustomerSchema';

interface AddressInformationSectionProps {
  register: UseFormRegister<NewCustomerFormData>;
  errors: FieldErrors<NewCustomerFormData>;
  states: Option[];
}

const AddressInformationSection = ({ register, errors, states }: AddressInformationSectionProps) => (
  <Box sx={{ flex: 1 }}>
    <Typography variant="h6" mb={2}>Address Information</Typography>

    <Box display="flex" flexDirection="column" gap={2}>
      <CustomInput
        {...register('address1')}
        size="small"
        label="Address Line 1"
        fullWidth
        error={!!errors.address1}
        helperText={errors.address1?.message}
      />
      <CustomInput
        {...register('address2')}
        size="small"
        label="Address Line 2"
        fullWidth
        error={!!errors.address2}
        helperText={errors.address2?.message}
      />
      <CustomInput
        {...register('city')}
        size="small"
        label="City"
        fullWidth
        error={!!errors.city}
        helperText={errors.city?.message}
      />
      <CustomDropdown
        {...register('state')}
        size="small"
        label="State"
        options={states}
        fullWidth
        error={!!errors.state}
        helperText={errors.state?.message}
      />
      <CustomInput
        {...register('zip')}
        size="small"
        label="ZIP Code"
        fullWidth
        error={!!errors.zip}
        helperText={errors.zip?.message}
      />
    </Box>
  </Box>
);

export default AddressInformationSection;
