import CardMediaMui from '@mui/material/CardMedia';
import { styled } from '@mui/material/styles';

import { HEADER_HEIGHT } from 'constants/general';

export const Content = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.subheaderBackground.main,
  height: `calc(100vh - ${HEADER_HEIGHT}px)`,
  padding: theme.spacing(2.5, 0),
}));

export const CardMedia = styled(CardMediaMui)(({ theme }) => ({
  backgroundColor: theme.palette.subheaderBackground.main,
  height: '100%',
  width: '100%',
  objectFit: 'cover',
  borderRadius: '8px',
})) as typeof CardMediaMui;
