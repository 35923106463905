import { styled } from '@mui/material/styles';

export const StyledBox = styled('div')(({ theme }) => ({
  width: '100%',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1, 0, 1),
  color: theme.palette.primary.main,
  gap: theme.spacing(1.25),
  marginLeft: theme.spacing(-0.25),

  '& .MuiSvgIcon-root': {
    fill: theme.palette.general.lightGrey12,
  },
}));
